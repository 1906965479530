import { call, take, put, all } from 'redux-saga/effects';
import { callApi, parseQueryParams } from '../utils/helpers';
import {
  FETCH,
  fetchSuccess,
  fetchFailure,
  PUT_SALESFORCE,
  putFailure,
  putSuccess,
  UPDATE_SALESFORCE,
  updateFailure,
  updateSuccess,
  fetchByProjectSuccess,
  fetchByProjectFailure,
  FETCH_BY_PROJECT,
  DELETE_BY_PROJECT,
  delByProjectSuccess,
  delByProjectFailure,
  FETCH_BY_ID_LIST,
  fetchByIdListSuccess,
  fetchByIdListFailure,
} from '../actions/salesforce';
import api from '../utils/api';
import { IPlis, TSaga } from '../utils/api.d';
import { Routes } from '../constants';
import { push } from 'connected-react-router';
import { AxiosResponse } from 'axios';
import { IAction } from '../utils/redux-create-reducer';

export function* salesforce() {
  while (true) {
    yield take(FETCH);
    try {
      const response = yield call(callApi, api.getSalesforce);
      yield put(fetchSuccess(response.data.data));
    } catch (e) {
      console.error(e);
      yield put(fetchFailure(e));
    }
  }
}

export function* salesforceByProject(): TSaga<Array<AxiosResponse<{ data: IPlis[] }>> & IAction> {
  while (true) {
    const { payload: projectId } = yield take(FETCH_BY_PROJECT);
    const expectList = [call(callApi, api.getSalesforce, projectId)];
    try {
      const queryParams = parseQueryParams(location.href);
      if (queryParams.plis) {
        // transform '1,2,3' to '?id=1&id=2&id=3'
        const plis =
          '?' +
          queryParams.plis
            .split(',')
            .map(id => 'id=' + id)
            .join('&');
        expectList.push(call(callApi, api.getSalesforceByIdList, plis));
      }
      const [resultByProjectId, resultByPLIs] = yield all(expectList);
      const result = resultByPLIs
        ? resultByProjectId.data.data.concat(resultByPLIs.data.data)
        : resultByProjectId.data.data;
      yield put(fetchByProjectSuccess(result));
    } catch (e) {
      console.error(e);
      yield put(fetchByProjectFailure(e));
    }
  }
}

export function* salesforceByIdList() {
  while (true) {
    const { payload: plisQuery } = yield take(FETCH_BY_ID_LIST);
    try {
      const response = yield call(callApi, api.getSalesforceByIdList, plisQuery);
      yield put(fetchByIdListSuccess(response.data.data));
    } catch (e) {
      console.error(e);
      yield put(fetchByIdListFailure(e));
    }
  }
}

export function* putSalesforce() {
  while (true) {
    const salesforce = yield take(PUT_SALESFORCE);
    try {
      const response = yield call(callApi, api.addSalesforce, salesforce.payload);
      yield put(putSuccess(response.data));
      yield put(push(Routes.Salesforce));
    } catch (e) {
      console.error(e);
      yield put(putFailure(e));
    }
  }
}

export function* updateSalesforce() {
  while (true) {
    const salesforce = yield take(UPDATE_SALESFORCE);
    try {
      const response = yield call(callApi, api.updateSalesforce, salesforce.payload);
      yield put(updateSuccess(response.data));
      yield put(push(Routes.Salesforce));
    } catch (e) {
      console.error(e);
      yield put(updateFailure(e));
    }
  }
}

export function* deleteSalesforce() {
  while (true) {
    const action = yield take(DELETE_BY_PROJECT);
    try {
      yield call(callApi, api.delSalesforce, action.payload);
      yield put(delByProjectSuccess());
    } catch (e) {
      console.error(e);
      yield put(delByProjectFailure(e));
    }
  }
}
