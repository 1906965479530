import React from 'react';
import VirtualList from 'react-tiny-virtual-list';
import styles from './CustomSelectMenuList.module.scss';

const DefaultItemHeight = 40; // TODO: think about configurable value

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CustomSelectMenuList = (props: any) => {
  const { options, children, maxHeight, getValue } = props;
  const [value] = getValue();
  const initialOffset = options.indexOf(value) * DefaultItemHeight;
  const childrenOptions = React.Children.toArray(children);
  const wrapperHeight =
    maxHeight < childrenOptions.length * DefaultItemHeight ? maxHeight : childrenOptions.length * DefaultItemHeight;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderItem = (itemProps: any) => {
    if (Array.isArray(children)) {
      return (
        <li style={itemProps.style} className={styles.virtualizedMenuItem} key={itemProps.index}>
          {children[itemProps.index]}
        </li>
      );
    }
    const noOptionPlaceholder = children && children.props && children.props.children;

    return (
      <li style={itemProps.style} key={itemProps.index} className={styles.virtualizedMenuPlaceholder}>
        {noOptionPlaceholder}
      </li>
    );
  };

  return (
    <span className="react-virtualized-list-wrapper">
      <VirtualList
        width="100%"
        height={wrapperHeight + 12}
        scrollOffset={initialOffset}
        itemCount={childrenOptions.length}
        itemSize={DefaultItemHeight}
        renderItem={renderItem}
      />
    </span>
  );
};

export default CustomSelectMenuList;
