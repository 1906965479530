import { all } from 'redux-saga/effects';

// inject import
import auth from './auth';
import { updateStaff, addStaff, staff, staffs, deleteStaff, staffsBriefInfo } from './staffs';
import staffsPage from './staffsPage';
import newStaffPage from './newStaffsPage';
import events, {
  saveEvent,
  deleteEvent,
  getEvent,
  eventsByProject,
  setPrimaryEvent,
  getPrimaryEventDetails,
  putEventFulfill,
  removeEventsFromProject,
} from './events';
import getRsaDsas, { getDSAById, putDSA } from './dsas';
import eventPage from './eventPage';
import {
  projects,
  projectsById,
  putProject,
  postProject,
  addNewProject,
  deleteProject,
  projectsWithSubs,
  copyProject,
  removeStaffFromProject,
  addStaffToProject,
} from './projects';
import {
  timeTracking,
  addTimeTracking,
  updateTimeTracking,
  deleteTimeTracking,
  oneTimeTracking,
  timeTrackingByProjectId,
  timeTrackingBuckets,
} from './timeTracking';
import timeTrackingListViewPage from './timeTrackingListViewPage';
import {
  salesforce,
  putSalesforce,
  updateSalesforce,
  salesforceByProject,
  salesforceByIdList,
  deleteSalesforce,
} from './salesforce';
import pLIDetailPage from './pLIDetailPage';
import accounts, { oneAccount } from './accounts';
import newTimeTrackingPage from './newTimeTrackingPage';
import accountsDetailPage from './AccountsDetailPage';
import dsaDetailsPage from './dsaDetailsPage';
import { addAccountsToProject, getParentProjectInfo, projectById } from './project';

import addNewProjectsPage from './AddNewProjectsPage';
import { getReports, generateReport, generateReportAndRefreshReportsList } from './reports';
import reportsPage from './reportsPage';
import getGridConfigs, { saveGridConfig } from './gridConfig';
import eventsListViewPage from './eventsListViewPage';
import projectsListViewPage from './projectsListViewPage';

const allSagas = [
  auth,
  staffs,
  staff,
  updateStaff,
  addStaff,
  deleteStaff,
  staffsPage,
  newStaffPage,
  events,
  eventPage,
  addNewProject,
  projects,
  projectsById,
  putProject,
  postProject,
  newTimeTrackingPage,
  timeTrackingBuckets,
  oneTimeTracking,
  timeTrackingByProjectId,
  deleteTimeTracking,
  addTimeTracking,
  updateTimeTracking,
  salesforce,
  salesforceByIdList,
  putSalesforce,
  updateSalesforce,
  deleteSalesforce,
  accounts,
  oneAccount,
  saveEvent,
  deleteEvent,
  getEvent,
  setPrimaryEvent,
  eventsByProject,
  getPrimaryEventDetails,
  putEventFulfill,
  accountsDetailPage,
  salesforceByProject,
  pLIDetailPage,
  getRsaDsas,
  putDSA,
  getDSAById,
  dsaDetailsPage,
  projectById,
  addNewProjectsPage,
  deleteProject,
  projectsWithSubs,
  copyProject,
  getReports,
  reportsPage,
  generateReport,
  generateReportAndRefreshReportsList,
  removeStaffFromProject,
  addStaffToProject,
  removeEventsFromProject,
  staffsBriefInfo,
  getGridConfigs,
  saveGridConfig,
  eventsListViewPage,
  timeTrackingListViewPage,
  projectsListViewPage,
  getParentProjectInfo,
  addAccountsToProject,
];

export default function* appSagas() {
  yield all(allSagas.map(s => s()));
}
