// use 'npm run g:com {name}' - to generate new component with react hooks
import React, { useState } from 'react';
import styles from './ProjectsHeader.module.scss';
import { Col, Container, Row, Label } from 'reactstrap';
import { IProjectHeader } from '../../utils/api.d';
import { projectType as ProjectTypeEnum } from '../../enums';
import { Routes } from '../../constants';
import { getDate } from '../../utils/helpers';
import DatePicker from 'react-datepicker';

interface IProjectsHeaderProps {
  data: Partial<IProjectHeader>;
  type: number | undefined;
}

const eventDatesLabels = {
  parentProjectEventDates: 'Parent Project Event Start/End:',
  currentProjectEventDates: 'Event Start/End:',
};

const ProjectsHeader = ({ data, type }: IProjectsHeaderProps) => {
  const [eventStart, setEventStart] = useState(getDate(data, 'eventStart'));
  const [eventEnd, setEventEnd] = useState(getDate(data, 'eventEnd'));
  const parentProjectEventStart =
    data.parentProject && data.parentProject.eventStart && getDate(data.parentProject, 'eventStart');
  const parentProjectEventEnd =
    data.parentProject && data.parentProject.eventEnd && getDate(data.parentProject, 'eventEnd');
  const projectTypeId = (data.projectType && data.projectType.id) || type ? type : ProjectTypeEnum.Misc.id;
  //  If the project (data) has a type then we are in edit mode.  Otherwise type prop should have the value
  //  passed in the url params.
  const projectTypeEdit = data.projectType && data.projectType.name ? data.projectType.name : null;
  const projectTypeAdd = Object.values(ProjectTypeEnum).find(value => value.id === type);
  //  If edit mode use this value, if add mode use value, if something went wrong use misc project type.
  const projectType = projectTypeEdit
    ? projectTypeEdit
    : projectTypeAdd
    ? projectTypeAdd.name
    : ProjectTypeEnum.Misc.name;
  const accountName = data.dsa && data.dsa.name ? data.dsa.name : 'Unknown';
  const parentProjectName = data.parentProject && data.parentProject.name ? data.parentProject.name : null;
  const parentProjectId = data.parentProject && data.parentProject.id ? data.parentProject.id.toString() : '0';
  const dsaId = data.dsa && data.dsa.id ? data.dsa.id.toString() : '0';
  const accountLink = Routes.DSADetail.replace(':dsaid', dsaId);
  //  Add code for routing in place of link.  Get parent project id push history add-new-project with id, or connect to state.
  const parentProjectLink = Routes.NewEditProject.replace(':id', parentProjectId)
    .replace(':type', data.parentProject && data.parentProject.typeId ? data.parentProject.typeId.toString() : '0')
    .replace(':dsaId', dsaId);

  const areEventDatesFieldsShown =
    ProjectTypeEnum.WebcastSubProject.id === projectTypeId ||
    ProjectTypeEnum.VirtualEvent.id === projectTypeId ||
    ProjectTypeEnum.VirtualEventSubProject.id === projectTypeId;
  const eventDatesFieldsLabel =
    parentProjectEventStart && parentProjectEventEnd
      ? eventDatesLabels.parentProjectEventDates
      : eventDatesLabels.currentProjectEventDates;
  const areEventsDatesFieldsDisabled =
    ProjectTypeEnum.VirtualEventSubProject.id === projectTypeId || Boolean(data.areEventDatesFromPrimaryEvent);
  const projectIdLabel = data.parentProject ? 'Sub-Project ID:' : 'Project ID:';

  return (
    <div>
      <Container className={styles.headerContainer} fluid>
        <Row style={{ justifyContent: 'flex-start' }}>
          <Col xl={3} sm={3} md={3}>
            {data.id && (
              <div>
                <div>
                  <Label className={styles.colHeaderLabel}>{projectIdLabel}</Label>
                </div>
                <div>
                  <Label className={styles.colValueLabel}>{data.id}</Label>
                </div>
              </div>
            )}
          </Col>
          <Col xl={3} sm={3} md={3}>
            <div>
              <Label className={styles.colHeaderLabel}>Account Name:</Label>
            </div>
            <div>
              <a color="none" href={accountLink} className={styles.colValueLink}>
                {accountName}
              </a>
            </div>
          </Col>
          {areEventDatesFieldsShown && (
            <Col xl={6} sm={6} md={6}>
              <div>
                <Label className={styles.colHeaderLabel}>{eventDatesFieldsLabel}</Label>
              </div>
              <div className="row">
                <EventStart
                  eventStart={parentProjectEventStart || eventStart}
                  setEventStart={setEventStart}
                  disabled={areEventsDatesFieldsDisabled}
                />
                <EventEnd
                  eventEnd={parentProjectEventEnd || eventEnd}
                  setEventEnd={setEventEnd}
                  disabled={areEventsDatesFieldsDisabled}
                />
              </div>
            </Col>
          )}
        </Row>
        <Row style={{ justifyContent: 'flex-start' }}>
          <Col xl={3} sm={3} md={3}>
            <div>
              <Label className={styles.colHeaderLabel}>Project Type:</Label>
            </div>
            <div>
              <Label className={styles.colValueLabel}>{projectType}</Label>
            </div>
          </Col>
          <Col xl={6} sm={6} md={6}>
            {Boolean(parentProjectName && parentProjectLink) && (
              <div>
                <div>
                  <Label className={styles.colHeaderLabel}>Parent-Project Name:</Label>
                </div>
                <div>
                  <a href={parentProjectLink} className={styles.colValueLink}>
                    {parentProjectName}
                  </a>
                </div>
              </div>
            )}
          </Col>
        </Row>
        <Row style={{ justifyContent: 'flex-start' }}></Row>
      </Container>
    </div>
  );
};

const EventStart = ({
  eventStart,
  setEventStart,
  disabled,
  name,
  readOnly,
}: {
  eventStart: Date;
  setEventStart?: (d: Date) => void;
  disabled: boolean;
  name?: string | undefined;
  readOnly?: boolean;
}) => (
  <div className="col-sm-6">
    <DatePicker
      selected={eventStart}
      onChange={(date: Date) => setEventStart && setEventStart(date)}
      wrapperClassName="form-control"
      className="form-control"
      name={name ? name : 'eventStart'}
      required={false}
      disabled={disabled}
      readOnly={readOnly}
      autoComplete={'off'}
      showTimeSelect
      timeFormat="HH:mm"
      timeIntervals={1}
      timeCaption="time"
      dateFormat="MM/dd/yyyy h:mm aa"
    />
  </div>
);

const EventEnd = ({
  eventEnd,
  setEventEnd,
  disabled,
  name,
  readOnly,
}: {
  eventEnd: Date;
  setEventEnd?: (d: Date) => void;
  disabled: boolean;
  name?: string | undefined;
  readOnly?: boolean;
}) => (
  <div className="col-sm-6">
    <DatePicker
      selected={eventEnd}
      onChange={(date: Date) => setEventEnd && setEventEnd(date)}
      wrapperClassName="form-control"
      className="form-control"
      name={name ? name : 'eventEnd'}
      required={false}
      disabled={disabled}
      readOnly={readOnly}
      autoComplete={'off'}
      showTimeSelect
      timeFormat="HH:mm"
      timeIntervals={1}
      timeCaption="time"
      dateFormat="MM/dd/yyyy h:mm aa"
    />
  </div>
);

export default ProjectsHeader;
