import React, { useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import getColumns from './columns';
import styles from './Accounts.module.scss';

import { AgGridEvent, ColumnApi, GridApi, RowClickedEvent, ServerSideStoreType } from 'ag-grid-community';
import makeGridOptions from '../../gridOptions';
import { Routes } from '../../constants';
import { Grid } from '../../components/Grid';
import { AppGrids } from '../../enums';
import {
  checkForSavedGrid,
  createServerSideDatasource,
  getPathToPreviousScreen,
  gridStateSaver,
  parseQueryParams,
  resetAllGridFilters,
} from '../../utils/helpers';
import { IAccount } from '../../utils/api.d';
import { getUser } from '../../reducers/user';
import { history } from '../../store';
import { Button } from 'reactstrap';
import { urls } from '../../utils/api';
import { useParams } from 'react-router';
import AddAccountsModal from './AddAccountsModal';
import { ADD_ACCOUNTS_TO_PROJECT } from '../../actions/project';

const rowClassRules = {
  [styles.disabledRow]: (row: { data: { isProjectLinked: boolean } }) =>
    row.data ? !!row.data.isProjectLinked : false,
};

const Accounts = () => {
  const { backUrl } = useParams();
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [gridColumnApi, setGridColumnApi] = useState<ColumnApi | null>(null);
  const [selectedAccounts, setSelectedAccounts] = useState<IAccount[]>([]);
  const { emailAddress } = useSelector(getUser, shallowEqual) || {};
  const [isAddAccountsModalOpen, setAddAccountsModalOpen] = useState(false);
  const addressBarQueryParams = parseQueryParams(location.href);
  const projectIdLink = addressBarQueryParams.projectIdLink;
  const dispatch = useDispatch();

  const onGridReady = (event: AgGridEvent) => {
    setGridApi(event.api);
    setGridColumnApi(event.columnApi);
    const apiQueryParams = projectIdLink && backUrl ? `?projectIdLink=${projectIdLink}` : '';
    const datasource = createServerSideDatasource(`${urls.accountsLazy}${apiQueryParams}`);
    event.api.setServerSideDatasource(datasource);
  };

  const onRowSelected = (row: {
    node: { setSelected: (selected: boolean) => void };
    data: { isProjectLinked: number };
  }) => {
    if (row.data && row.data.isProjectLinked) {
      row.node.setSelected(false);
    }
  };

  const backToProject = () => {
    if (backUrl && backUrl !== ':backUrl') {
      history.push(getPathToPreviousScreen(backUrl));
    } else {
      history.push(Routes.Accounts);
    }
  };

  const onRowDoubleClicked = (row: RowClickedEvent) => {
    const platform = `?platform=${row.data.platform}`;
    window.open(Routes.AccountsDetail.replace(':id', row.data.accountId) + platform);
  };

  const rowsLimit = 500;
  const columnDefs = getColumns();
  const gridOptions = makeGridOptions(columnDefs, {
    rowModelType: 'serverSide',
    rowClassRules: rowClassRules,
    rowSelection: 'multiple',
    minColWidth: 200,
    cacheBlockSize: rowsLimit,
    serverSideStoreType: ServerSideStoreType.Partial,
    animateRows: true,
    statusBar: { statusPanels: [] },
    onGridReady: onGridReady,
    onRowSelected: onRowSelected,
    onRowDoubleClicked: onRowDoubleClicked,
  });

  const noItemsSelected = !selectedAccounts.length;

  const gridName = `${emailAddress}-${AppGrids.AccountsList}`;
  const gridConfigName = `${emailAddress}-${AppGrids.AccountsList}-config`;

  const saveState = () => gridColumnApi && gridStateSaver(gridColumnApi, gridName);

  if (gridColumnApi) {
    checkForSavedGrid(gridColumnApi, gridName, gridConfigName, columnDefs);
    gridStateSaver(gridColumnApi, gridName);
  }

  const onSelectionChanged = () => {
    if (gridApi) {
      const selectedRows = gridApi.getSelectedRows();
      setSelectedAccounts(selectedRows.map(i => i.id));
    }
  };

  const addAccountsModalToggle = () => setAddAccountsModalOpen(!isAddAccountsModalOpen);
  const resetAllFilters = () => resetAllGridFilters(gridApi);

  const proceedAddAccounts = () => {
    const returnPageUrl = getPathToPreviousScreen(backUrl);
    const payload = { projectId: projectIdLink, accountIds: selectedAccounts, backUrl: returnPageUrl };
    dispatch({ type: ADD_ACCOUNTS_TO_PROJECT, payload });
    addAccountsModalToggle();
  };

  return (
    <>
      <div>
        <div className="float-left page-title">Accounts</div>
        {backUrl && (
          <div className="float-right">
            <Button className={'margin-left-10'} color="primary" onClick={backToProject}>
              Back to project
            </Button>
            <Button
              className={'margin-left-10'}
              color="primary"
              disabled={noItemsSelected}
              onClick={addAccountsModalToggle}
            >
              Add selected to project
            </Button>
          </div>
        )}
        <div className={'float-right'}>
          <Button color="primary" onClick={resetAllFilters}>
            Reset Filters
          </Button>
        </div>
      </div>
      <Grid
        gridOptions={gridOptions}
        onSelectionChanged={onSelectionChanged}
        onColumnMoved={saveState}
        onColumnVisible={saveState}
        onColumnResized={saveState}
      />
      <AddAccountsModal
        isOpen={isAddAccountsModalOpen}
        proceedAddAccounts={proceedAddAccounts}
        cancelAddAccounts={addAccountsModalToggle}
      />
    </>
  );
};

export default Accounts;
