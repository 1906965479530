import React, { useEffect, useState } from 'react';
import styles from '../AddNewProject.module.scss';
import { Button, Modal, ModalFooter, ModalHeader } from 'reactstrap';
import { Grid } from '../../../components/Grid';
import makeSalesforceColumns from '../GridSettings/salesforce';
import { GridReadyEvent, RowDoubleClickedEvent, RowSelectedEvent } from 'ag-grid-community/dist/lib/events';
import makeGridOptions from '../../../gridOptions';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getSalesforce } from '../../../reducers/salesforce';
import { ColumnApi, GridApi } from 'ag-grid-community';
import MakeFulfilledModal from './MakeFulfilledModal';
import { IPlis } from '../../../utils/api.d';
import { delByProject } from '../../../actions/salesforce';
import AjaxSpinner from '../../../components/AjaxSpinner';
import { history } from '../../../store';
import { N3AEntitlements, Routes } from '../../../constants';
import pullAll from 'lodash/pullAll';
import stylesModal from './Salesforce.module.scss';
import AccessControl from '../../../components/AccessControl';
import { AppGrids } from '../../../enums';
import { checkForSavedGrid, gridStateSaver, resetAllGridFilters } from '../../../utils/helpers';
import AddPlisToFulfilledProjectsModal from '../AddPlisToFulfilledProjectsModal';

interface SalesforceTableProps {
  projectName: string;
  hidePlanProject: boolean;
  projectId?: string;
  accountLongId?: string;
  isProjectFulfilled?: boolean;
  emailAddress: string;
}

export default function SalesforceTable({
  projectName,
  hidePlanProject,
  projectId,
  accountLongId,
  isProjectFulfilled,
  emailAddress,
}: SalesforceTableProps) {
  const dispatch = useDispatch();
  const salesforceColumnDefs = makeSalesforceColumns(hidePlanProject);
  const salesforceGridOptions = makeGridOptions(salesforceColumnDefs);
  const salesforceData = useSelector(getSalesforce, shallowEqual);
  const [plis, setPlis] = useState<IPlis[]>([]);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [gridColumnApi, setColumnApi] = useState<ColumnApi | null>(null);
  const [updating, setUpdating] = useState(false);
  const [selectedRows, setSelectedRows] = useState<IPlis[]>([]);
  const [isMakeFulfilledModalOpen, setMakeFulfilledModalOpen] = useState(false);
  const [isRemovePLIConfirmModalOpen, setRemovePLIConfirmModalOpen] = useState(false);
  const [isAddPlisToFulfilledProjectsModalOpen, setAddPlisToFulfilledProjectsModalOpen] = useState(false);

  const goToSalesforce = () => {
    const queryParams = accountLongId ? `?accountLongId=${accountLongId}` : '';
    const url = `${Routes.SalesforceToProject.replace(
      ':backUrl',
      encodeURIComponent(location.pathname),
    )}${queryParams}`;

    return history.push(url);
  };

  const removePLIConfirmModalToggle = () => {
    setRemovePLIConfirmModalOpen(!isRemovePLIConfirmModalOpen);
  };

  const onPLIRemoveConfirm = () => {
    removePLIConfirmModalToggle();
    if (projectId) {
      let pliIdsPayload = '';
      setUpdating(true);
      selectedRows.forEach(el => {
        pliIdsPayload += `ids=${el.id}&`;
      });
      dispatch(delByProject(`projectId=${projectId}&${pliIdsPayload}`));
    }
    setPlis(pullAll(plis, selectedRows));
  };

  const makeFulfilledModalToggle = () => {
    setMakeFulfilledModalOpen(!isMakeFulfilledModalOpen);
  };

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
    setColumnApi(params.columnApi);
  };

  const onSelectionChanged = () => {
    if (gridApi) {
      const selectedRows = gridApi.getSelectedRows();
      setSelectedRows(selectedRows);
    }
  };

  const onRowSelected = (row: RowSelectedEvent) => {
    if (row.data && row.data.fulfilled) {
      row.node.setSelected(false);
    }
  };

  const onRowDoubleClicked = (row: RowDoubleClickedEvent) => {
    if (row.data && row.data.id) {
      const url = Routes.PLIDetail.replace(':id', row.data.id);
      window.open(url);
    }
  };

  const save = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const addedPlis = plis.map((pli: any) => {
      const selectedPli = selectedRows.find(selectedRow => selectedRow.id === pli.id);
      if (selectedPli) {
        return { ...pli, fulfilled: true };
      }
      return pli;
    });

    setPlis(addedPlis);

    makeFulfilledModalToggle();
  };

  useEffect(() => {
    if (!salesforceData.isLoading) {
      setPlis(salesforceData.list);
      setUpdating(false);
    }
  }, [salesforceData]);

  const gridName = `${emailAddress}-${AppGrids.ProjectDetailsProducts}`;
  const gridConfigName = `${emailAddress}-${AppGrids.ProjectDetailsProducts}-config`;

  const saveState = () => gridColumnApi && gridStateSaver(gridColumnApi, gridName);

  if (gridColumnApi) {
    checkForSavedGrid(gridColumnApi, gridName, gridConfigName, salesforceColumnDefs);
    gridStateSaver(gridColumnApi, gridName);
  }

  const addPlisToFulfilledProjectsModalToggle = () =>
    setAddPlisToFulfilledProjectsModalOpen(!isAddPlisToFulfilledProjectsModalOpen);

  const proceedAddingPLIS = () => {
    addPlisToFulfilledProjectsModalToggle();
    goToSalesforce();
  };

  const cancelAddingPLIS = () => addPlisToFulfilledProjectsModalToggle();

  const addPLIs = () => (isProjectFulfilled ? addPlisToFulfilledProjectsModalToggle() : goToSalesforce());

  const resetAllFilters = () => resetAllGridFilters(gridApi);

  return (
    <>
      {updating ? (
        <AjaxSpinner />
      ) : (
        <div>
          <div className={styles.flexButton}>
            <h5>Purchased Products</h5>
            <div>
              <Button color="primary" onClick={resetAllFilters} className={'margin-right-10'}>
                Reset Filters
              </Button>
              <AccessControl actions={[N3AEntitlements.Admin]}>
                <Button
                  color="primary"
                  className={'margin-right-10'}
                  disabled={isProjectFulfilled || !selectedRows.length}
                  onClick={removePLIConfirmModalToggle}
                >
                  Remove selected PLI(s)
                </Button>
                <Button color="primary" onClick={addPLIs}>
                  Add Salesforce PLI(s)
                </Button>
              </AccessControl>
            </div>
          </div>
          <Grid
            defaultHeight={false}
            className={styles.gridDefault}
            rowData={plis}
            rowSelection={'multiple'}
            gridOptions={salesforceGridOptions}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            getRowStyle={(params: any) => {
              if (params.data && params.data.fulfilled) {
                return { background: 'grey!important' };
              }

              if (params.node.data && params.node.data.id === '0') {
                return { fontWeight: '800' };
              } else return null;
            }}
            onGridReady={onGridReady}
            onRowSelected={onRowSelected}
            onSelectionChanged={onSelectionChanged}
            onRowDoubleClicked={onRowDoubleClicked}
            defaultColDef={{
              flex: 1,
              sortable: false,
              resizable: true,
              enablePivot: false,
              enableValue: false,
            }}
            onColumnMoved={saveState}
            onColumnVisible={saveState}
            onColumnResized={saveState}
          />
          <Modal
            className={stylesModal.modal}
            isOpen={isRemovePLIConfirmModalOpen}
            toggle={removePLIConfirmModalToggle}
          >
            <ModalHeader toggle={removePLIConfirmModalToggle}>Remove Selected PLIs?</ModalHeader>
            <ModalFooter>
              <Button color="secondary" onClick={removePLIConfirmModalToggle}>
                Cancel
              </Button>
              <Button color="danger" onClick={onPLIRemoveConfirm}>
                Confirm
              </Button>
            </ModalFooter>
          </Modal>
          <MakeFulfilledModal
            projectName={projectName}
            isOpen={isMakeFulfilledModalOpen}
            onSave={save}
            onClose={makeFulfilledModalToggle}
          />
          <AddPlisToFulfilledProjectsModal
            isModalOpen={isAddPlisToFulfilledProjectsModalOpen}
            proceedAddingPlis={proceedAddingPLIS}
            cancelAddingPlis={cancelAddingPLIS}
          />
          <br />
          <br />
        </div>
      )}
    </>
  );
}
