// use 'npm run g:com {name}' - to generate new component with react hooks
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import FormField from '../../FormField';
import { useBreakpoints } from '../../../utils/hooks';
import { convertToPlainObjectWithEnums, makeColumns } from '../../../utils/helpers';
import { dsasDetailsInfo } from '../../../tablesFormShapes/dsasDetails';
import { IDSAById, IRSADSAItemData } from '../../../utils/api.d';
import RelatedDSAField from './RelatedDSAField';

export interface IFormFieldsProps {
  currentDSAById: IDSAById;
  rsaDsa: IRSADSAItemData[];
}

const FormFields = ({ currentDSAById, rsaDsa }: IFormFieldsProps) => {
  const { xl } = useBreakpoints();
  const [currentDSA, setCurrentDSA] = useState(currentDSAById || {});

  useEffect(() => {
    const evt = convertToPlainObjectWithEnums(currentDSAById || {});
    setCurrentDSA(evt);
  }, [currentDSAById]);

  type eventsInfoKeys = keyof typeof dsasDetailsInfo;

  const keys = Object.keys(dsasDetailsInfo);

  const elements: eventsInfoKeys[][] = makeColumns<eventsInfoKeys>(keys, xl ? 3 : 2);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateValue = (key: keyof typeof dsasDetailsInfo) => (value: any) => {
    setCurrentDSA({ ...currentDSA, [key]: value });
  };

  const RelatedDSAFieldValue = RelatedDSAField({ currentDSAById, rsaDsa });

  const fields = elements.map((els, i) => (
    <Col key={`field${i}`} xl={6} md={6}>
      {els.map(key => {
        const currentDSAByIdInner = (dsasDetailsInfo[key] && dsasDetailsInfo[key]) || {};
        if (key === 'relatedDsas') {
          return <RelatedDSAFieldValue key={key} />;
        } else {
          return (
            <FormField
              key={key}
              name={key}
              disabled={key !== 'relatedDsas'}
              type={currentDSAByIdInner.type || 'text'}
              label={currentDSAByIdInner.label || key}
              value={
                key === 'accountTotalContractedValueUsd' && currentDSA[key]
                  ? `$${currentDSA[key]}`
                  : currentDSA[key]
                  ? currentDSA[key]
                  : ''
              }
              onChange={updateValue(key)}
            />
          );
        }
      })}
    </Col>
  ));

  return <Row>{fields}</Row>;
};

export default FormFields;
