import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import auth from './auth';
// inject import
import application from './application';
import user from './user';
import session from './session';
import locationHistory from './locationHistory';
import staffs from './staffs';
import projects from './projects';
import timeTracking from './timeTracking';
import events from './events';
import salesforce from './salesforce';
import accounts from './accounts';
import notification from './notification';
import dsas from './dsas';
import project from './project';
import completeEvent from './completeEvent';
import reportUrl from './reportsDownloader';
import reports from './reports';
import reportGeneration from './reportGeneration';
import gridConfig from './gridConfig';

export default (history: History) =>
  combineReducers({
    router: connectRouter(history),
    // inject usage
    application,
    auth,
    user,
    session,
    locationHistory,
    staffs,
    projects,
    events,
    timeTracking,
    salesforce,
    accounts,
    notification,
    dsas,
    project,
    completeEvent,
    reportUrl,
    reports,
    reportGeneration,
    gridConfig,
  });
