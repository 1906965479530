import React from 'react';
import cx from 'classnames';
import styles from './PLIDetail.module.scss';
import { shallowEqual, useSelector } from 'react-redux';
import { getCurrentPLI } from '../../reducers/salesforce';
import PLIDetails from '../../components/PLIDetails';

const PLIDetail = () => {
  const currentPLI = useSelector(getCurrentPLI, shallowEqual);

  return (
    <div className={cx(styles.root)}>
      <h3>PLI Details</h3>
      <div className={cx(styles.form, 'card')}>
        <PLIDetails pli={currentPLI} />
      </div>
    </div>
  );
};

export default PLIDetail;
