// use 'npm run g:r {name}' - to generate new reducer and action (use --skip if action not needed)
import createReducer from '../utils/redux-create-reducer';
import {
  FETCH,
  FETCH_FAILURE,
  FETCH_ONE,
  FETCH_ONE_FAILURE,
  FETCH_ONE_SUCCESS,
  FETCH_SUCCESS,
  FETCH_BY_PROJECT_ID,
  FETCH_BY_PROJECT_ID_SUCCESS,
  FETCH_BY_PROJECT_ID_FAILURE,
  FETCH_BUCKETS,
  FETCH_BUCKETS_SUCCESS,
  FETCH_BUCKETS_FAILURE,
  UPDATE_BUCKET,
} from '../actions/timeTracking';
import { ITimeTracking, ITimeTrackingBucket } from '../utils/api.d';
import { deepStateUpdate } from '../utils/helpers';

interface ITimeTrackingCurrentState {
  isLoading: boolean;
  error?: Error;
  data?: ITimeTracking;
}

interface ITimeTrackingBucketsState {
  isLoading: boolean;
  error?: Error;
  updatedBuckets: Record<string, Partial<ITimeTrackingBucket>>;
  data: ITimeTrackingBucket[];
}

export interface ITimeTrackingByProjectIdState {
  isLoading: boolean;
  error?: Error;
  data: ITimeTracking[];
}

export interface ITimeTrackingState {
  isLoading: boolean;
  error?: Error;
  timeTracking: ITimeTracking[];
  current: ITimeTrackingCurrentState;
  timeTrackingByProjectId: ITimeTrackingByProjectIdState;
  timeTrackingBuckets: ITimeTrackingBucketsState;
}

interface IState {
  timeTracking: ITimeTrackingState;
}

export const initialState: ITimeTrackingState = {
  isLoading: false,
  error: undefined,
  timeTracking: [],
  current: {
    isLoading: false,
    error: undefined,
    data: undefined,
  },
  timeTrackingByProjectId: {
    isLoading: false,
    error: undefined,
    data: [],
  },
  timeTrackingBuckets: {
    isLoading: false,
    error: undefined,
    updatedBuckets: {},
    data: [],
  },
};

export const getTimeTracking = (state: IState) => state.timeTracking;

export const getCurrentTimeTracking = (state: IState) => state.timeTracking.current;

export const getTimeTrackingBuckets = (state: IState) => state.timeTracking.timeTrackingBuckets;
export const getUpdatedTimeTrackingBuckets = (state: IState) =>
  Object.values(state.timeTracking.timeTrackingBuckets.updatedBuckets);

export const getTimeTrackingByProjectId = (state: IState) => state.timeTracking.timeTrackingByProjectId;

const timeTrackingByProjectIdHandler = deepStateUpdate('timeTrackingByProjectId');
const currentHandler = deepStateUpdate('current');
const bucketsHandler = deepStateUpdate('timeTrackingBuckets');

export default createReducer<ITimeTrackingState>(initialState, {
  [FETCH]: () => ({ isLoading: true }),
  [FETCH_SUCCESS]: timeTracking => ({ timeTracking, isLoading: false }),
  [FETCH_FAILURE]: error => ({ error, isLoading: false }),

  [UPDATE_BUCKET]: (updateBucket: ITimeTrackingBucket, state) => {
    const { data, updatedBuckets } = state.timeTrackingBuckets;
    const newBuckets = { ...updatedBuckets };
    const existingBucket = data.find(i => i.timetrackingType === updateBucket.timetrackingType);
    // Remove from updated if no change
    if (existingBucket && existingBucket.allottedTime === updateBucket.allottedTime) {
      delete newBuckets[updateBucket.timetrackingType];
    } else {
      // add to updated
      newBuckets[updateBucket.timetrackingType] = updateBucket;
    }
    return bucketsHandler({ updatedBuckets: newBuckets }, state);
  },
  [FETCH_BUCKETS]: (_, state) => bucketsHandler({ isLoading: true, updatedBuckets: {} }, state),
  [FETCH_BUCKETS_SUCCESS]: (data, state) => bucketsHandler({ data, isLoading: false }, state),
  [FETCH_BUCKETS_FAILURE]: (error, state) => bucketsHandler({ error, isLoading: false }, state),

  [FETCH_ONE]: (_, state) => currentHandler({ data: undefined, isLoading: true }, state),
  [FETCH_ONE_SUCCESS]: (data, state) => currentHandler({ data, isLoading: false }, state),
  [FETCH_ONE_FAILURE]: (error, state) => currentHandler({ error, isLoading: false }, state),

  [FETCH_BY_PROJECT_ID]: (_, state) => timeTrackingByProjectIdHandler({ data: undefined, isLoading: true }, state),
  [FETCH_BY_PROJECT_ID_SUCCESS]: ({ data }, state) => timeTrackingByProjectIdHandler({ data, isLoading: false }, state),
  [FETCH_BY_PROJECT_ID_FAILURE]: (error, state) => timeTrackingByProjectIdHandler({ error, isLoading: false }, state),
});
