import React, { FormEvent, useState } from 'react';
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import styles from './CompleteEventModal.module.scss';
import DatePicker from 'react-datepicker';
import { getDate } from '../../../utils/helpers';
import { ICompleteEeventDetails } from '../../../utils/api.d';

interface CompleteEventModalProps {
  isOpen: boolean;
  completeEventData: ICompleteEeventDetails;
  completeEvent: (completeEventData: ICompleteEeventDetails) => void;
  cancelCompleteEvent: () => void;
}

export function CompleteEventModal({
  isOpen,
  completeEventData,
  completeEvent,
  cancelCompleteEvent,
}: CompleteEventModalProps) {
  const [eventDate, setCompleteEventDate] = useState(getDate(completeEventData, 'eventDate'));
  const [eventEndDate, setCompleteEventEndDate] = useState(getDate(completeEventData, 'eventEndDate'));
  const [eventId, setCompleteEventId] = useState(completeEventData.eventId);
  const [eventTitle, setCompleteEventTitle] = useState(completeEventData.eventTitle);

  const resetFormToInitialState = () => {
    setCompleteEventTitle(completeEventData.eventTitle);
    setCompleteEventId(completeEventData.eventId);
    setCompleteEventDate(getDate(completeEventData, 'eventDate'));
    setCompleteEventEndDate(getDate(completeEventData, 'eventEndDate'));
  };

  const cancelComplete = () => {
    cancelCompleteEvent();
    resetFormToInitialState();
  };

  const saveEventInfo = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const isDatePresent = Boolean(eventDate);
    const isEventIdPresent = Boolean(eventId && eventId.trim());
    const isEventTitlePresent = Boolean(eventTitle && eventTitle.trim());

    if (isDatePresent && isEventIdPresent && isEventTitlePresent) {
      completeEvent({ eventTitle, eventDate, eventEndDate, eventId });
      resetFormToInitialState();
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleCompleteEventIdChange = (event: any) => {
    setCompleteEventId(event.target.value);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleCompleteEventTitleChange = (event: any) => {
    setCompleteEventTitle(event.target.value);
  };

  return (
    <div>
      <Modal className={styles.modal} isOpen={isOpen}>
        <ModalHeader>Complete Event</ModalHeader>
        <ModalBody>
          <form id="completeEventForm" autoComplete="off" onSubmit={saveEventInfo}>
            <FormGroup>
              <Label for="completeEventTitle">Event Title</Label>
              <Input
                required
                type="text"
                defaultValue={eventTitle}
                name="completeEventTitle"
                id="completeEventTitle"
                onChange={event => handleCompleteEventTitleChange(event)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="completeEventDate">Event Start Date</Label>
              <DatePicker
                id="completeEventDate"
                selected={eventDate}
                onChange={(date: Date) => setCompleteEventDate(date)}
                wrapperClassName="form-control"
                className="form-control"
                name="completeEventDate"
                required={true}
                autoComplete={'off'}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={1}
                timeCaption="time"
                dateFormat="MM/dd/yyyy h:mm aa"
              />
            </FormGroup>
            <FormGroup>
              <Label for="completeEventEndDate">Event End Date</Label>
              <DatePicker
                id="completeEventEndDate"
                selected={eventEndDate}
                onChange={(date: Date) => setCompleteEventEndDate(date)}
                wrapperClassName="form-control"
                className="form-control"
                name="completeEventEndDate"
                required={true}
                autoComplete={'off'}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={1}
                timeCaption="time"
                dateFormat="MM/dd/yyyy h:mm aa"
              />
            </FormGroup>
            <FormGroup>
              <Label for="completeEventId">Event ID</Label>
              <Input
                required
                type="text"
                defaultValue={eventId}
                name="completeEventId"
                id="completeEventId"
                onChange={handleCompleteEventIdChange}
              />
            </FormGroup>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={cancelComplete}>
            Cancel
          </Button>
          <Button
            color="primary"
            form={'completeEventForm'}
            type="submit"
            disabled={!eventTitle || !eventTitle.trim() || !eventId || !eventId.trim() || !eventDate || !eventEndDate}
          >
            Fulfill
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default CompleteEventModal;
