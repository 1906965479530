// use 'npm run g:a {name}' - to generate new action
import { createEmptyAction } from '../utils/actions';
import { IAction } from '../utils/redux-create-reducer';

export const NOTIFY = 'NOTIFICATION/NOTIFY';
export const CLOSE = 'NOTIFICATION/CLOSE';

export const types = {
  NOTIFY,
  CLOSE,
};

interface INotify {
  message: string;
  type: 'success' | 'danger' | 'warning' | 'info';
  duration?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  messageVars?: Record<string, any>;
}

export const notify = (a: string | INotify, b = 'info'): IAction => {
  if (typeof a === 'string') {
    return {
      type: NOTIFY,
      payload: {
        message: a,
        ...(b ? { type: b } : {}),
      },
    };
  }
  return {
    type: NOTIFY,
    payload: a,
  };
};
export const close = createEmptyAction(CLOSE);

export default {
  types,
  notify,
  close,
};
