// use 'npm run g:a {name}' - to generate new action
import { createEmptyAction, createAction } from '../utils/actions';

export const FETCH = 'DSALISTVIEW/FETCH';
export const FETCH_SUCCESS = 'DSALISTVIEW/FETCH_SUCCESS';
export const FETCH_FAILURE = 'DSALISTVIEW/FETCH_FAILURE';

export const CLEAN = 'DSALISTVIEW/CLEAN';

export const types = {
  FETCH,
  FETCH_SUCCESS,
  FETCH_FAILURE,
};

export const clean = createEmptyAction(CLEAN);

export const fetch = createEmptyAction(FETCH);
export const fetchSuccess = createAction(FETCH_SUCCESS);
export const fetchFailure = createAction<Error>(FETCH_FAILURE);

export default {
  types,
  fetch,
  fetchSuccess,
  fetchFailure,
};
