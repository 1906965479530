import { ColDef, ColGroupDef, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { dateFormatter } from '../../utils/helpers';
import { eventScheduleGridColumns } from '../../tablesFormShapes/eventSchedule';
import { transformToColumns, createMapFromObjectOfObjects } from '../../utils/helpers';

import {
  platformType as platformTypeEnum,
  serviceModelType as serviceModelTypeEnum,
  contentType as contentTypeEnum,
  usageType as usageTypeEnum,
  eventType as eventTypeEnum,
} from '../../enums';

const platformTypesMap = createMapFromObjectOfObjects(platformTypeEnum, 'id', 'name');
const serviceModelTypesMap = createMapFromObjectOfObjects(serviceModelTypeEnum, 'id', 'name');
const contentTypesMap = createMapFromObjectOfObjects(contentTypeEnum, 'id', 'name');
const eventTypesMap = createMapFromObjectOfObjects(eventTypeEnum, 'id', 'name');
const eventUsageTypesMap = createMapFromObjectOfObjects(usageTypeEnum, 'id', 'name');

export default function(): (ColDef | ColGroupDef)[] {
  return transformToColumns(
    eventScheduleGridColumns,
    {
      id: {
        hide: true,
      },
      name: {
        sort: 'asc',
      },
      startDate: {
        valueGetter: dateFormatter('startDate'),
        filterParams: {
          includeTime: true,
        },
      },
      platform: {
        filterParams: {
          suppressSorting: true,
          debounceMs: 1000,
          values: Object.keys(platformTypesMap),
          valueFormatter: (params: ValueFormatterParams) => platformTypesMap[params.value] || params.value,
        },
        valueGetter: (params: ValueGetterParams) => platformTypesMap[params.data.platform] || params.data.platform,
      },
      serviceModelType: {
        filterParams: {
          suppressSorting: true,
          debounceMs: 1000,
          values: Object.keys(serviceModelTypesMap),
          valueFormatter: (params: ValueFormatterParams) => serviceModelTypesMap[params.value] || params.value,
        },
        valueGetter: (params: ValueGetterParams) =>
          serviceModelTypesMap[params.data.serviceModelType] || params.data.serviceModelType,
      },
      contentType: {
        filterParams: {
          suppressSorting: true,
          debounceMs: 1000,
          values: Object.keys(contentTypesMap),
          valueFormatter: (params: ValueFormatterParams) => contentTypesMap[params.value] || params.value,
        },
        valueGetter: (params: ValueGetterParams) => contentTypesMap[params.data.contentType] || params.data.contentType,
      },
      usageType: {
        filterParams: {
          suppressSorting: true,
          debounceMs: 1000,
          values: Object.keys(eventUsageTypesMap),
          valueFormatter: (params: ValueFormatterParams) => eventUsageTypesMap[params.value] || params.value,
        },
        valueGetter: (params: ValueGetterParams) => eventUsageTypesMap[params.data.usageType] || params.data.usageType,
      },
      eventType: {
        filterParams: {
          suppressSorting: true,
          debounceMs: 1000,
          values: Object.keys(eventTypesMap),
          valueFormatter: (params: ValueFormatterParams) => eventTypesMap[params.value] || params.value,
        },
        valueGetter: (params: ValueGetterParams) => eventTypesMap[params.data.eventType] || params.data.eventType,
      },
    },
    { minWidth: 200 },
  );
}
