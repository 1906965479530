import React, { useState } from 'react';
import styles from './purchasedproductsgrid.module.scss';
import getColumns from './columns';
import makeGridOptions from '../../gridOptions';
import { Grid } from '../Grid';
import { ColumnApi, GridApi, ServerSideStoreType } from 'ag-grid-community';
import {
  checkForSavedGrid,
  createServerSideDatasource,
  gridStateSaver,
  resetAllGridFilters,
} from '../../utils/helpers';
import { urls } from '../../utils/api';
import { AgGridEvent } from 'ag-grid-community/dist/lib/events';
import { shallowEqual, useSelector } from 'react-redux';
import { getDSAReduxState } from '../../reducers/dsas';
import { AppGrids, subscriptionStatus } from '../../enums';
import { Button } from 'reactstrap';

interface IPurchasedProductsGridProps {
  accountLongId?: string;
  emailAddress: string;
}

const rowClassRules = {
  [styles.disabledRow]: (row: { data: { subscriptionStatus: string } }) =>
    row.data ? row.data.subscriptionStatus === subscriptionStatus.cancelled : false,
};

const PurchasedProductsGrid = (props: IPurchasedProductsGridProps) => {
  const { accountLongId, emailAddress } = props;
  const columnDefs = getColumns();
  const rowsLimit = 500;
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [gridColumnApi, setGridColumnApi] = useState<ColumnApi | null>(null);
  const { isLoading } = useSelector(getDSAReduxState, shallowEqual);

  const onGridReady = (params: AgGridEvent) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);

    const datasource = createServerSideDatasource(urls.salesforceLazy, `accountLongId=${accountLongId}`);
    params.api.setServerSideDatasource(datasource);
  };

  const gridOptions = makeGridOptions(columnDefs, {
    rowClassRules,
    rowModelType: 'serverSide',
    cacheBlockSize: rowsLimit,
    serverSideStoreType: ServerSideStoreType.Partial,
    animateRows: true,
    statusBar: { statusPanels: [] },
    onGridReady: onGridReady,
  });

  const gridName = `${emailAddress}-${AppGrids.DsaDetailsProducts}`;
  const gridConfigName = `${emailAddress}-${AppGrids.DsaDetailsProducts}-config`;

  const saveState = () => gridColumnApi && gridStateSaver(gridColumnApi, gridName);

  if (gridColumnApi) {
    checkForSavedGrid(gridColumnApi, gridName, gridConfigName, columnDefs);
    gridStateSaver(gridColumnApi, gridName);
  }

  const resetAllFilters = () => resetAllGridFilters(gridApi);

  return (
    <>
      {isLoading || accountLongId === '' ? (
        <></>
      ) : (
        <>
          <div className={styles.projectsHeader}>
            <h4>Purchased Products</h4>
            <div>
              <Button color="primary" onClick={resetAllFilters}>
                Reset Filters
              </Button>
            </div>
          </div>
          <Grid
            className={styles.purchasedProductsGrid}
            gridOptions={gridOptions}
            onColumnMoved={saveState}
            onColumnVisible={saveState}
            onColumnResized={saveState}
          />
        </>
      )}
    </>
  );
};
export default PurchasedProductsGrid;
