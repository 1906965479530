// use 'npm run g:r {name}' - to generate new reducer and action (use --skip if action not needed)
import createReducer from '../utils/redux-create-reducer';
import {
  FETCH_BYID,
  FETCH_BYID_SUCCESS,
  FETCH_BYID_FAILURE,
  GET_PARENT_PROJECT_INFO,
  GET_PARENT_PROJECT_INFO_SUCCESS,
  GET_PARENT_PROJECT_INFO_FAILURE,
  ADD_ACCOUNTS_TO_PROJECT,
  ADD_ACCOUNTS_TO_PROJECT_SUCCESS,
  ADD_ACCOUNTS_TO_PROJECT_FAILURE,
} from '../actions/project';
import { IProjectHeader } from '../utils/api.d';

export interface IProjectState {
  isLoading: boolean;
  error?: Error;
  data?: IProjectHeader;
  parentProjectInfo?: IProjectHeader;
}

interface IState {
  project: IProjectState;
}

export const initialState: IProjectState = {
  isLoading: false,
  error: undefined,
  data: undefined,
  parentProjectInfo: undefined,
};

export const getProject = (state: IState): IProjectState => state.project;

export default createReducer<IProjectState>(initialState, {
  [FETCH_BYID]: () => ({ isLoading: true }),
  [FETCH_BYID_SUCCESS]: data => ({ data, isLoading: false }),
  [FETCH_BYID_FAILURE]: error => ({ error, isLoading: false }),

  [GET_PARENT_PROJECT_INFO]: () => ({ isLoading: true }),
  [GET_PARENT_PROJECT_INFO_SUCCESS]: parentProjectInfo => ({ parentProjectInfo, isLoading: false }),
  [GET_PARENT_PROJECT_INFO_FAILURE]: error => ({ error, isLoading: false }),

  [ADD_ACCOUNTS_TO_PROJECT]: () => ({ isLoading: true }),
  [ADD_ACCOUNTS_TO_PROJECT_SUCCESS]: () => ({ isLoading: false }),
  [ADD_ACCOUNTS_TO_PROJECT_FAILURE]: error => ({ error, isLoading: false }),
});
