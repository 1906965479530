// use 'npm run g:com {name}' - to generate new component with react hooks
import React, { useMemo } from 'react';
import cx from 'classnames';
import styles from './FormField.module.scss';
import Select, { createFilter } from 'react-select';
import { IDSAById, IRSADSAItemData } from '../../../utils/api.d';
import CustomSelectMenuList from '../../CustomSelectMenuList';

export interface IRelatedDSAFieldProps {
  currentDSAById: IDSAById;
  rsaDsa: IRSADSAItemData[];
}

const RelatedDSAField = ({ currentDSAById, rsaDsa }: IRelatedDSAFieldProps) => {
  const filterOption = createFilter({ ignoreAccents: false });
  const relatedDsas = useMemo(() => {
    return currentDSAById
      ? currentDSAById.relatedDsas.map(item => {
          if (item.accountName) {
            return {
              id: item.id,
              value: item.id,
              name: item.accountName,
            };
          } else {
            return null;
          }
        })
      : null;
  }, [currentDSAById]);

  const relatedDSAsOptions = useMemo(() => {
    return rsaDsa.map(currentDSAById => {
      return {
        value: currentDSAById.id.toString(),
        label: currentDSAById.accountName,
      };
    });
  }, [rsaDsa]);

  const defRelatedDSAs = useMemo(() => {
    const defFiltered = rsaDsa.filter((dsaItem: IRSADSAItemData) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const foundItem = (relatedDsas || []).find((i: any) => i.id === dsaItem.id);
      return Boolean(foundItem);
    });
    const defFilteredAdjusted = defFiltered.map((dsaItem: IRSADSAItemData) => {
      return { value: dsaItem.id.toString(), label: dsaItem.accountName };
    });

    return defFilteredAdjusted;
  }, [relatedDsas, rsaDsa]);

  const RelatedDSAsSection = () => (
    <div className="form-group">
      <label className={cx(styles.multiSelectLabel)}>Related DSAs </label>
      <Select
        defaultValue={defRelatedDSAs}
        isMulti
        name="rsaDsa"
        options={relatedDSAsOptions}
        className={cx(styles.multiSelect)}
        classNamePrefix="select"
        filterOption={filterOption}
        captureMenuScroll={false}
        components={{ MenuList: CustomSelectMenuList }}
      />
    </div>
  );

  return RelatedDSAsSection;
};

export default RelatedDSAField;
