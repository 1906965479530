import { call, take, put, all } from 'redux-saga/effects';
import { callApi, parseQueryParams } from '../utils/helpers';
import {
  FETCH,
  fetchSuccess,
  fetchFailure,
  SAVE,
  saveSuccess,
  saveFailure,
  removeSuccess,
  REMOVE,
  removeFailure,
  FETCH_BY_ID,
  fetchByProjectSuccess,
  fetchByProjectFailure,
  FETCH_BY_PROJECT,
  SET_PRIMARY,
  setPrimarySuccess,
  setPrimaryFailure,
  FETCH_PRIMARY_DETAILS,
  fetchPrimaryDetailsSuccess,
  fetchPrimaryDetailsFailure,
  FULFILL,
  fulfillSuccess,
  fulfillFailure,
  REMOVE_EVENTS_FROM_PROJECT,
  removeEventsFromProjectSuccess,
  removeEventsFromProjectFailure,
} from '../actions/events';
import api from '../utils/api';
import { AxiosResponse } from 'axios';
import { IEvent, IEventsResponse, TSaga } from '../utils/api.d';
import { IAction } from '../utils/redux-create-reducer';
import { push, replace } from 'connected-react-router';
import { Routes } from '../constants';
import { fetchByIdFailure, fetchByIdSuccess } from '../actions/events';
import { notify } from '../actions/notification';
import { matchPath } from 'react-router-dom';

const getRedirectUrl = () => {
  const matches = matchPath(location.pathname, {
    path: Routes.EventDetail,
    exact: true,
  });
  const backUrl = matches && matches.params['backUrl'];
  if (backUrl && backUrl !== ':backUrl') {
    return decodeURIComponent(backUrl);
  }
  return Routes.Events;
};

export default function* events(): TSaga<AxiosResponse<IEventsResponse>> {
  while (true) {
    yield take(FETCH);
    try {
      const response = yield call(callApi, api.getEvents);
      yield put(fetchSuccess(response.data.data));
    } catch (e) {
      console.error(e);
      yield put(fetchFailure(e));
    }
  }
}

export function* saveEvent(): TSaga<IAction & AxiosResponse> {
  while (true) {
    const { payload } = yield take(SAVE);
    try {
      const response = yield call(callApi, api.putEvent, payload.projectId, payload.eventId);
      yield put(saveSuccess(response.data));
      yield put(notify('Your changes have been saved.', 'success'));
      yield put(push(getRedirectUrl()));
    } catch (e) {
      console.error(e);
      yield put(notify("We're sorry, there was a problem saving this Project please try again.", 'danger'));
      yield put(saveFailure(e));
    }
  }
}

export function* deleteEvent(): TSaga<IAction & AxiosResponse<IEvent>> {
  while (true) {
    const { payload } = yield take(REMOVE);
    try {
      const response = yield call(callApi, api.deleteEvent, payload.eventId, payload.platform);
      yield put(removeSuccess(response.data));
      yield put(notify('Your event have been deleted.', 'success'));
      yield put(push(getRedirectUrl()));
    } catch (e) {
      console.error(e);
      yield put(notify("We're sorry, there was a problem deleting this Event please try again.", 'danger'));
      yield put(removeFailure(e));
    }
  }
}

export function* setPrimaryEvent(): TSaga<IAction & AxiosResponse<IEvent>> {
  while (true) {
    const { payload } = yield take(SET_PRIMARY);
    const isPrimaryEvent = payload.isPrimary;
    const event = {
      eventId: payload.eventId,
      platformId: payload.platformId,
      projectId: payload.projectId,
      isPrimary: payload.isPrimary,
    };
    try {
      const action = isPrimaryEvent ? 'set' : 'unlinked';
      const response = yield call(callApi, api.putPrimaryEvent, event);
      yield put(setPrimarySuccess(response.data));
      yield put(push(payload.path));
      yield put(notify(`Event have been ${action} as primary.`, 'success'));
    } catch (e) {
      console.error(e);
      const action = isPrimaryEvent ? 'setting' : 'unlinking';
      yield put(notify(`We're sorry, there was a problem ${action} this Event as primary.`, 'danger'));
      yield put(setPrimaryFailure(e));
    }
  }
}

export function* getEvent(): TSaga<IAction & AxiosResponse<IEvent>> {
  while (true) {
    yield take(FETCH_BY_ID);
    try {
      const matches = matchPath(location.pathname, {
        path: Routes.EventDetail,
        exact: true,
      });
      const platform = new URLSearchParams(location.search).get('platform');
      const eventId = matches && matches.params['eventId'];

      const response = yield call(callApi, api.getEvent, eventId, platform);
      yield put(fetchByIdSuccess(response.data));
    } catch (e) {
      console.error(e);
      yield put(fetchByIdFailure(e));
    }
  }
}

//  Move here
export function* eventsByProject(): TSaga<Array<AxiosResponse<{ data: IEvent[] }>> & IAction> {
  while (true) {
    const { payload: projectId } = yield take(FETCH_BY_PROJECT);
    const expectList = [call(callApi, api.getEventsByProjectId, projectId)];
    try {
      const queryParams = parseQueryParams(location.href);
      if (queryParams.eventIds) {
        // transform '1,2,3' to '?id=1&id=2&id=3'
        const eventIdsQuery =
          '?' +
          queryParams.eventIds
            .split(',')
            .map(id => 'id=' + id)
            .join('&');

        expectList.push(call(callApi, api.getEventsById, eventIdsQuery));
      }

      const [resultByProjectId, resultByEventIds] = yield all(expectList);
      const result = resultByEventIds
        ? resultByProjectId.data.data.concat(resultByEventIds.data.data)
        : resultByProjectId.data.data;

      yield put(fetchByProjectSuccess(result));
    } catch (e) {
      console.error(e);
      yield put(fetchByProjectFailure(e));
    }
  }
}

//  Move to eventsByProject.  Check return data before making this request.
export function* getPrimaryEventDetails() {
  while (true) {
    const { payload: projectId } = yield take(FETCH_PRIMARY_DETAILS);
    try {
      const response = yield call(callApi, api.getPrimaryEventDetails, projectId);
      yield put(fetchPrimaryDetailsSuccess(response.data));
    } catch (e) {
      console.error(e);
      yield put(fetchPrimaryDetailsFailure(e));
    }
  }
}

export function* putEventFulfill(): TSaga<IAction & AxiosResponse> {
  while (true) {
    const { payload } = yield take(FULFILL);
    try {
      const response = yield call(callApi, api.putEventFulfill, payload);
      yield put(fulfillSuccess(response.data));
      yield put(push(payload.path));
      yield put(notify('Event Completed!', 'success'));
    } catch (e) {
      console.error(e);
      yield put(notify("We're sorry, there was a problem completing this event.", 'danger'));
      yield put(fulfillFailure(e));
    }
  }
}

export function* removeEventsFromProject(): TSaga<IAction & AxiosResponse<IEvent>> {
  while (true) {
    const { payload } = yield take(REMOVE_EVENTS_FROM_PROJECT);

    try {
      const response = yield call(callApi, api.removeEventsFromProject, payload.queryParams);
      yield put(removeEventsFromProjectSuccess(response.data));
      yield put(notify('Your Events have been deleted.', 'success'));
      yield put(replace(`${location.pathname}${location.search}`));
    } catch (e) {
      console.error(e);
      yield put(notify("We're sorry, there was a problem deleting these Events please try again.", 'danger'));
      yield put(removeEventsFromProjectFailure(e));
    }
  }
}
