import { FormShape } from '../utils/api.d';

export const eventScheduleGridColumns: FormShape = {
  name: { label: 'Event Name', type: 'text', filter: 'agTextColumnFilter' },
  startDate: { label: 'Start Date', type: 'datetime-local', filter: 'agDateColumnFilter' },
  platform: { label: 'Platform', type: 'select', filter: 'agSetColumnFilter' },
  serviceModelType: { label: 'Service Model', type: 'select', filter: 'agSetColumnFilter' },
  contentType: { label: 'Event Format', type: 'select', filter: 'agSetColumnFilter' },
  eventType: { label: 'Event Type', type: 'select', filter: 'agSetColumnFilter' },
  usageType: { label: 'Usage Type', type: 'select', filter: 'agSetColumnFilter' },
  eventCallProvider: { label: 'Call Provider', type: 'select', filter: 'agSetColumnFilter' },
};
