import createReducer from '../utils/redux-create-reducer';
import { SET_USER_SESSION } from '../actions/auth';

export interface IAuthState {
  authenticated?: boolean;
  tokens?: {
    accessToken?: string;
    idToken?: string;
    refreshToken?: string;
  };
}

export const initialState = {
  authenticated: false,
};

export const getAuth = (state: { auth: IAuthState }) => state.auth;

export default createReducer(initialState, {
  [SET_USER_SESSION]: tokens => ({ authenticated: true, tokens }),
});
