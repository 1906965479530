import { put, take, call } from 'redux-saga/effects';
import { fetch, FETCH_FAILURE, FETCH_SUCCESS } from '../actions/staffs';
import { Routes } from '../constants';
import { appLoadingPlaceholder } from '../utils/helpers';

export function* fetcher() {
  yield put(fetch({ isProjectManager: false }));
  return yield take([FETCH_SUCCESS, FETCH_FAILURE]);
}

export default function* staffsPage() {
  while (true) {
    yield call(appLoadingPlaceholder, Routes.Staff, fetcher);
  }
}
