import { FormShape } from '../utils/api.d';

export const accountsListGridColumns: FormShape = {
  name: { label: 'Name', type: 'text', filter: 'agTextColumnFilter' },
  platform: { label: 'Platform', type: 'select', filter: 'agSetColumnFilter' },
  accountId: { label: 'Account ID', type: 'text', filter: 'agTextColumnFilter' },
  mmcIsFolder: { label: 'Folder', type: 'boolean', filter: 'agSetColumnFilter' },
  mmcParentId: { label: 'Parent ID', type: 'text', filter: 'agTextColumnFilter' },
  studioMasterId: { label: 'Studio Master ID', type: 'text', filter: 'agTextColumnFilter' },
  studioMasterName: { label: 'Studio Master Name', type: 'text', filter: 'agTextColumnFilter' },
  active: { label: 'Active', type: 'boolean', filter: 'agSetColumnFilter' },
  usageType: { label: 'Usage Type', type: 'select', filter: 'agSetColumnFilter' },
  mmcType: { label: 'MMC Type', type: 'select', filter: 'agSetColumnFilter' },
  mmcProduct: { label: 'MMC Product', type: 'select', filter: 'agSetColumnFilter' },
  studioServiceType: { label: 'Studio Type', type: 'select', filter: 'agSetColumnFilter' },
  studioProduct: { label: 'Studio Product', type: 'select', filter: 'agSetColumnFilter' },
};
