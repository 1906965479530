// use 'npm run g:com {name}' - to generate new component with react hooks
import React, { FormEvent } from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import { Editor } from '@tinymce/tinymce-react';
import cx from 'classnames';
import styles from './DSADetails.module.scss';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { getDSAReduxState, getRsaDsas, IDSAReduxState } from '../../reducers/dsas';
import { SAVE } from '../../actions/dsas';
import { useParams } from 'react-router-dom';
import FormFields from './FormFields';
import { getTinymce } from '@tinymce/tinymce-react/lib/cjs/main/ts/TinyMCE';
import { history } from '../../store';
import { Routes } from '../../constants';
import { REACT_APP_TEAMWORK_BASE_URL } from '../../env';

const DSADetails = () => {
  const { currentDSAById, isLoading }: IDSAReduxState = useSelector(getDSAReduxState, shallowEqual);
  const { rsaDsa }: IDSAReduxState = useSelector(getRsaDsas, shallowEqual);
  const dispatch = useDispatch();
  const { dsaid } = useParams();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getRsaDsaValue = (rsaDsa: any) => {
    if (rsaDsa.length > 1) {
      return Object.keys(rsaDsa).map(n => Number(rsaDsa[n].value));
    } else {
      return rsaDsa.value ? [Number(rsaDsa.value)] : [];
    }
  };

  const saveChangesBtnHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const tinyMCE = getTinymce();
    const currentEditor = tinyMCE && tinyMCE.get('notes-editor').getContent();
    const rsaDsa = e.target['rsaDsa'];
    const rsaDsaValue = getRsaDsaValue(rsaDsa);
    const payload = { id: Number(dsaid), relatedDsaIds: rsaDsaValue, notes: currentEditor };
    const url = Routes.DSADetail.replace(':dsaid', dsaid);
    dispatch({ type: SAVE, payload });
    history.push(url);
  };

  return (
    <div>
      {!isLoading && rsaDsa.length && currentDSAById && (
        <form onSubmit={saveChangesBtnHandler} style={{ width: '100%' }}>
          <Container fluid>
            <Row style={{ justifyContent: 'flex-end' }}>
              <Col className="my-1" xl={1} sm={3} md={2}>
                <Button type="submit" block color="primary">
                  Save
                </Button>
              </Col>
            </Row>
            <hr />
            <FormFields currentDSAById={currentDSAById} rsaDsa={rsaDsa} />
          </Container>

          <div className={cx(styles.notes)}>
            <label className={cx(styles.notesLabel)}>Notes</label>
            <Editor
              tinymceScriptSrc={REACT_APP_TEAMWORK_BASE_URL + '/tinymce/tinymce.min.js'}
              id={'notes-editor'}
              initialValue={(currentDSAById || {}).notes || ''}
              init={{
                plugins: ['lists link image paste help'],
                toolbar:
                  'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | help',
              }}
            />
          </div>
        </form>
      )}
    </div>
  );
};

export default DSADetails;
