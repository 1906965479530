// use only for debug selectors
import application from './application';
import auth from './auth';
import session from './session';
import user from './user';
import projects from './projects';
import timeTracking from './timeTracking';
import salesforce from './salesforce';
import accounts from './accounts';
import dsalistview from './dsalistview';
import project from './project';

export default {
  application,
  auth,
  session,
  user,
  projects,
  timeTracking,
  salesforce,
  accounts,
  dsalistview,
  project,
};
