import { call, take, put } from 'redux-saga/effects';
import { callApi } from '../utils/helpers';
import {
  FETCH_GRID_CONFIGS,
  fetchGridConfigsSuccess,
  fetchGridConfigsFailure,
  UPDATE_GRID_CONFIG,
  updateGridConfigSuccess,
  updateGridConfigFailure,
  fetchGridConfigs,
} from '../actions/gridConfig';
import api from '../utils/api';
import { notify } from '../actions/notification';
import { convertEnumTypes, gridTypes } from '../enums';

export default function* getGridConfigs() {
  while (true) {
    const { payload } = yield take(FETCH_GRID_CONFIGS);
    try {
      const response = yield call(callApi, api.getGridConfigs, payload);
      yield put(fetchGridConfigsSuccess(response.data));
    } catch (e) {
      console.error(e);
      yield put(fetchGridConfigsFailure(e));
    }
  }
}

export function* saveGridConfig() {
  while (true) {
    const { payload } = yield take(UPDATE_GRID_CONFIG);
    const { gridType } = payload;
    const grid = convertEnumTypes(gridTypes).find(item => item.value === gridType);
    const gridId = grid && grid.id;
    try {
      const response = yield call(callApi, api.saveGridConfig, payload);
      yield put(updateGridConfigSuccess(response.data));
      yield put(notify('Your changes have been saved.', 'success'));
      // TODO: refactor in future: move this call outside
      yield put(fetchGridConfigs(gridId));
    } catch (e) {
      console.error(e);
      yield put(notify("We're sorry, there was a problem saving this Grid View please try again.", 'danger'));
      yield put(updateGridConfigFailure(e));
    }
  }
}
