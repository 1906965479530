// use 'npm run g:a {name}' - to generate new action
import { createEmptyAction, createAction } from '../utils/actions';
import { IEvent } from '../utils/api.d';

export const FETCH = 'EVENTS/FETCH';
export const FETCH_SUCCESS = 'EVENTS/FETCH_SUCCESS';
export const FETCH_FAILURE = 'EVENTS/FETCH_FAILURE';

export const FETCH_BY_ID = 'EVENTS/FETCH_BY_ID';
export const FETCH_BY_ID_SUCCESS = 'EVENTS/FETCH_BY_ID_SUCCESS';
export const FETCH_BY_ID_FAILURE = 'EVENTS/FETCH_BY_ID_FAILURE';

export const FETCH_BY_PROJECT = 'EVENTS/FETCH_BY_PROJECT';
export const FETCH_BY_PROJECT_SUCCESS = 'EVENTS/FETCH_BY_PROJECT_SUCCESS';
export const FETCH_BY_PROJECT_FAILURE = 'EVENTS/FETCH_BY_PROJECT_FAILURE';

export const FETCH_PRIMARY_DETAILS = 'EVENTS/FETCH_PRIMARY_DETAILS';
export const FETCH_PRIMARY_DETAILS_SUCCESS = 'EVENTS/FETCH_PRIMARY_DEATILS_SUCCESS';
export const FETCH_PRIMARY_DETAILS_FAILURE = 'EVENTS/FETCH_PRIMARY_DETAILS_FAILURE';

export const SAVE = 'EVENTS/SAVE';
export const SAVE_SUCCESS = 'EVENTS/SAVE_SUCCESS';
export const SAVE_FAILURE = 'EVENTS/SAVE_FAILURE';

export const SET_PRIMARY = 'EVENTS/SET_PRIMARY';
export const SET_PRIMARY_SUCCESS = 'EVENTS/SET_PRIMARY_SUCCESS';
export const SET_PRIMARY_FAILURE = 'EVENTS/SET_PRIMARY_FAILURE';

export const FULFILL = 'EVENTS/FULFILL';
export const FULFILL_SUCCESS = 'EVENTS/FULFILL_SUCCESS';
export const FULFILL_FAILURE = 'EVENTS/FULFILL_FAILURE';

export const REMOVE = 'EVENTS/REMOVE';
export const REMOVE_SUCCESS = 'EVENTS/REMOVE_SUCCESS';
export const REMOVE_FAILURE = 'EVENTS/REMOVE_FAILURE';

export const REMOVE_EVENTS_FROM_PROJECT = 'EVENTS/REMOVE_EVENTS_FROM_PROJECT';
export const REMOVE_EVENTS_FROM_PROJECT_SUCCESS = 'EVENTS/REMOVE_EVENTS_FROM_PROJECT_SUCCESS';
export const REMOVE_EVENTS_FROM_PROJECT_FAILURE = 'EVENTS/REMOVE_EVENTS_FROM_PROJECT_FAILURE';

export const types = {
  FETCH,
  FETCH_SUCCESS,
  FETCH_FAILURE,
  FETCH_BY_PROJECT,
  FETCH_BY_PROJECT_SUCCESS,
  FETCH_BY_PROJECT_FAILURE,
  FETCH_PRIMARY_DETAILS,
  FETCH_PRIMARY_DETAILS_SUCCESS,
  FETCH_PRIMARY_DETAILS_FAILURE,
  SET_PRIMARY,
  SET_PRIMARY_SUCCESS,
  SET_PRIMARY_FAILURE,
  FULFILL,
  FULFILL_SUCCESS,
  FULFILL_FAILURE,
  SAVE,
  SAVE_SUCCESS,
  SAVE_FAILURE,
  REMOVE,
  REMOVE_SUCCESS,
  REMOVE_FAILURE,
  REMOVE_EVENTS_FROM_PROJECT,
  REMOVE_EVENTS_FROM_PROJECT_SUCCESS,
  REMOVE_EVENTS_FROM_PROJECT_FAILURE,
};

export const fetch = createEmptyAction(FETCH);
export const fetchSuccess = createAction(FETCH_SUCCESS);
export const fetchFailure = createAction<Error>(FETCH_FAILURE);

export const fetchById = createAction(FETCH_BY_ID);
export const fetchByIdSuccess = createAction(FETCH_BY_ID_SUCCESS);
export const fetchByIdFailure = createAction<Error>(FETCH_BY_ID_FAILURE);

export const fetchByProject = createAction(FETCH_BY_PROJECT);
export const fetchByProjectSuccess = createAction(FETCH_BY_PROJECT_SUCCESS);
export const fetchByProjectFailure = createAction<Error>(FETCH_BY_PROJECT_FAILURE);

export const fetchPrimaryDetails = createAction(FETCH_PRIMARY_DETAILS);
export const fetchPrimaryDetailsSuccess = createAction(FETCH_PRIMARY_DETAILS_SUCCESS);
export const fetchPrimaryDetailsFailure = createAction<Error>(FETCH_PRIMARY_DETAILS_FAILURE);

export const save = createAction(SAVE);
export const saveSuccess = createAction(SAVE_SUCCESS);
export const saveFailure = createAction<Error>(SAVE_FAILURE);

export const setPrimary = createAction(SET_PRIMARY);
export const setPrimarySuccess = createAction(SET_PRIMARY_SUCCESS);
export const setPrimaryFailure = createAction<Error>(SET_PRIMARY_FAILURE);

export const fulfill = createAction(FULFILL);
export const fulfillSuccess = createAction(FULFILL_SUCCESS);
export const fulfillFailure = createAction<Error>(FULFILL_FAILURE);

export const remove = createAction<IEvent>(REMOVE);
export const removeSuccess = createAction(REMOVE_SUCCESS);
export const removeFailure = createAction<Error>(REMOVE_FAILURE);

export const removeEventsFromProject = createAction(REMOVE_EVENTS_FROM_PROJECT);
export const removeEventsFromProjectSuccess = createAction(REMOVE_EVENTS_FROM_PROJECT_SUCCESS);
export const removeEventsFromProjectFailure = createAction<Error>(REMOVE_EVENTS_FROM_PROJECT_FAILURE);

export default {
  fetch,
  fetchSuccess,
  fetchFailure,
  fetchByProject,
  fetchByProjectSuccess,
  fetchByProjectFailure,
  fetchPrimaryDetails,
  fetchPrimaryDetailsSuccess,
  fetchPrimaryDetailsFailure,
  remove,
  removeSuccess,
  removeFailure,
  setPrimary,
  setPrimarySuccess,
  setPrimaryFailure,
  fulfill,
  fulfillSuccess,
  fulfillFailure,
  removeEventsFromProject,
  removeEventsFromProjectSuccess,
  removeEventsFromProjectFailure,
};
