// use 'npm run g:r {name}' - to generate new reducer and action (use --skip if action not needed)
import createReducer from '../utils/redux-create-reducer';
import {
  PUT_SUCCESS,
  PUT_PROJECTS,
  PUT_FAILURE,
  FETCH,
  FETCH_FAILURE,
  FETCH_SUCCESS,
  FETCH_BYDSAID,
  FETCH_BYDSAID_SUCCESS,
  FETCH_BYDSAID_FAILURE,
  FETCH_WITH_SUBS,
  FETCH_WITH_SUBS_SUCCESS,
  FETCH_WITH_SUBS_FAILURE,
  COPY_PROJECT_SUCCESS,
  COPY_PROJECT,
  COPY_PROJECT_FAILURE,
  DELETE_STAFF_FROM_PROJECT,
  DELETE_STAFF_FROM_PROJECT_FAILURE,
  DELETE_STAFF_FROM_PROJECT_SUCCESS,
  ADD_STAFF_TO_PROJECT_FAILURE,
  ADD_STAFF_TO_PROJECT_SUCCESS,
  ADD_STAFF_TO_PROJECT,
  DELETE_PROJECT,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAILURE,
} from '../actions/projects';
import { IProject } from '../utils/api.d';

export interface IProjectsState {
  isLoading: boolean;
  projects: IProject[];
  error?: Error;
  currentProject: Partial<IProject>[];
}

interface IState {
  projects: IProjectsState;
}

export const initialState: IProjectsState = {
  isLoading: false,
  projects: [],
  error: undefined,
  currentProject: [],
};

export const getProjects = (state: IState) => state.projects;

export default createReducer<IProjectsState>(initialState, {
  [FETCH]: () => ({ isLoading: true }),
  [FETCH_SUCCESS]: projects => ({ projects, isLoading: false }),
  [FETCH_FAILURE]: error => ({ error, isLoading: false }),
  [FETCH_BYDSAID]: () => ({ isLoading: true }),
  [FETCH_BYDSAID_SUCCESS]: projects => ({ projects, isLoading: false }),
  [FETCH_BYDSAID_FAILURE]: error => ({ error, isLoading: false }),
  [PUT_PROJECTS]: () => ({ isLoading: true }),
  [PUT_SUCCESS]: () => ({ isLoading: false }),
  [PUT_FAILURE]: error => ({ error, isLoading: false }),

  [FETCH_WITH_SUBS]: () => ({ isLoading: true }),
  [FETCH_WITH_SUBS_SUCCESS]: projects => ({ projects, isLoading: false }),
  [FETCH_WITH_SUBS_FAILURE]: error => ({ error, isLoading: false }),

  [COPY_PROJECT]: () => ({ isLoading: true }),
  [COPY_PROJECT_SUCCESS]: () => ({ isLoading: false }),
  [COPY_PROJECT_FAILURE]: error => ({ error, isLoading: false }),

  [DELETE_STAFF_FROM_PROJECT]: () => ({ isLoading: true }),
  [DELETE_STAFF_FROM_PROJECT_SUCCESS]: () => ({ isLoading: false }),
  [DELETE_STAFF_FROM_PROJECT_FAILURE]: error => ({ error, isLoading: false }),

  [ADD_STAFF_TO_PROJECT]: () => ({ isLoading: true }),
  [ADD_STAFF_TO_PROJECT_SUCCESS]: () => ({ isLoading: false }),
  [ADD_STAFF_TO_PROJECT_FAILURE]: error => ({ error, isLoading: false }),

  [DELETE_PROJECT]: () => ({ isLoading: true }),
  [DELETE_PROJECT_SUCCESS]: () => ({ isLoading: false }),
  [DELETE_PROJECT_FAILURE]: error => ({ error, isLoading: false }),
});
