import { StrictEffect } from 'redux-saga/effects';
export interface IEnumType {
  id: number;
  value: string;
  name: string;
}
export type TSaga<Next, Return = void> = Generator<StrictEffect, Return, Next>;

export type InputFieldType = 'text' | 'select' | 'datetime-local' | 'boolean' | 'number';
export type FormShape<K = string> = Record<
  K,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  { label: string; type: InputFieldType; valueFormat?: (value: any) => any; filter?: string | boolean }
>;

export interface IEntitlement {
  name: string;
  url: string;
  customerId: number;
  siteName: string;
  tenantId: string | number;
}

export interface IRSADSAItemData {
  id: number;
  accountName: string;
}

export interface IDSA {
  id: number;
  relatedDsaIds: number[];
  notes: string;
}

export interface IEvent {
  id: number;
  eventId: string;
  name: string;
  platform: string | number;
  accountId: string;
  description: string;
  conferenceName: string;
  eventType: string;
  eventSubType: string;
  startDate: string;
  endDate: string;
  expiryDate: string;
  serviceModelType: string;
  contentType: string;
  deliveryType: string;
  eventMode: string;
  usageType: string;
  eventCallProvider: string;
  isActive: boolean;
  isVideoChat: boolean;
  languageCount: number;
  registrationsCount: number;
  linksCount: number;
  projectId: number;
  projectTypeId: number;
  breakoutRoomsCount: number;
  registrations: number;
  liveViewers: number;
  onDemandViewers: number;
  serviceTeamMember: string;
  contentType: IEnumType;
  deliveryType: IEnumType;
  eventMode: IEnumType;
  eventSubType: IEnumType;
  eventType: IEnumType;
  platform: IEnumType;
  serviceModelType: IEnumType;
  usageType: IEnumType;
  eventCallProvider: IEnumType;
  project?: {
    projectId: number;
    fulfillmentStatus: IEnumType;
  };
}

export interface IDSAById {
  id: number;
  accountName?: string;
  accountOwner?: string;
  accountLongId?: string;
  accountCustomerSuccessMgr?: string;
  sfdcGupa?: string;
  notes?: string;
  accountTotalContractedValueUsd?: number;
  accountCountry?: string;
  accountRegion?: string;
  relatedDsas: {
    id: number;
    accountName?: string;
  }[];
}

export interface IEventFormModified extends IEvent {
  contentType: string;
  deliveryType: string;
  eventMode: string;
  eventSubType: string;
  eventType: string;
  platform: string;
  serviceModelType: string;
  usageType: string;
  eventCallProvider: string;
}

export interface IEventRequestShape extends IEventFormModified {
  contentTypeId: string;
  deliveryTypeId: string;
  modeId: string;
  subTypeId: string;
  typeId: string;
  platformId: string;
  serviceModelTypeId: string;
  usageTypeId: string;
  eventCallProvider: string;
}

export interface IManager {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
}

export interface IStaff {
  id: number;
  first_name: string;
  last_name: string;
  company: string;
  isActive: boolean;
  created: string;
  lastUpdated: string;
  email: string;
  title: string;
  region: string;
  team: string;
  manager: IManager;
  managerId: number | null;
}

export interface IProjectDTO {
  id: number | null;
  parentId?: number | null;
  dsaCustomerId?: number | null;
  name: string;
  projectType?: string;
  projectSubType: string | null;
  serviceModelType: string | null;
  notes: string;
  usageType: string | null;
  duration: number | null;
  hostingDays: number | null;
  languages: number | null;
  eventQuantity: number;
  liveViewers: number | null;
  contentType?: string | null;
  deliveryType?: string | null;
  projectStaff:
    | {
        staffRoleType: string;
        staffId: number | null;
      }[]
    | null;
  projectAccounts:
    | {
        id: string;
        order: number;
        platform: string;
      }[]
    | null;
  fulfillmentStart: string;
  fulfillmentEnd: string;
  projectStatus: number;
  supportRegion: string;
  showInProjectPicker: boolean;
  recurringRolloverTerm?: number | null;
  expectedFulfillment?: string;
  eventStart?: string | null;
  eventEnd?: string | null;
  cfpOpenDate?: string | null;
  cfpCloseDate?: string | null;
  clientContactName?: string | null;
  clientContactEmail?: string | null;
  siteId?: string | null;
  siteUrl?: string | null;
  registrants?: number | null;
}

export interface ITimeTrackingDTO {
  staffId: number;
  projectId: number;
  date: string;
  type: string;
  subtype: string;
  description: string;
  duration: number;
}

export interface ITimeTrackingPutDTO extends ITimeTrackingDTO {
  id: number;
}

export interface IAccount {
  id: number;
  accountId: string;
  platform: string;
  name: string;
  description: string;
  active: boolean;
  usageType: number;
  mmcIsFolder: boolean;
  mmcParentId: string;
  mmcType?: number;
  mmcProduct?: number;
  mmcResellerType?: string;
  studioMasterId: string;
  studioMasterName: string;
  studioServiceType?: number;
  studioProduct?: number;
  isProjectLinked: boolean;
}

export interface IEquipment {
  id: number;
  name: string;
  description: string;
  created: string;
  isActive: boolean;
  lastUpdated: string;
  regionId: string;
  region: {
    id: number;
    name: string;
  };
}

export interface IProject {
  id: number;
  name: string;
  projectType: {
    value?: string;
    id: number;
    name: string;
  };
  parentId: number | null;
  projectSubType: {
    value: string;
    id: number;
    name: string;
  };
  serviceModelType: {
    value: string;
    id: number;
    name: string;
  };
  usageType: {
    value: string;
    id: number;
    name: string;
  };
  projectAccounts: [
    {
      order: number;
      id: number;
      accountId: string;
      name: string;
      platform: string;
    },
  ];
  startDate: string;
  duration: number;
  hostingDays: number;
  languages: number;
  liveViewers: number;
  contentType: {
    value: string;
    id: number;
    name: string;
  };
  deliveryType: {
    value: string;
    id: number;
    name: string;
  };
  projectStaff: [
    {
      staff: {
        id: number;
        firstName: string;
        lastName: string;
      };
      id: number | null;
      staffRoleType: string;
      name: string;
    },
  ];
  projectStatus: {
    id: number;
    name: string;
  };
  serviceHours: number;
  projectExpiryDate: string;
  expectedFulfillment: string;
  eventQuantity: number;
  eventStart: string;
  eventEnd: string;
}

export interface IProjectHeader {
  id: number;
  name: string;
  parentProject: {
    id: number;
    name: string;
    eventEnd: string;
    eventStart: string;
    typeId: number;
    fulfillmentStart: string;
    fulfillmentEnd: string;
  };
  dsa: {
    id: number;
    name: string;
    accountLongId?: string;
  };
  projectStatus: {
    id: number;
    name: string;
    value: string;
  };
  projectType: {
    id: number;
    name: string;
    value: string;
  };
  projectSubType: {
    id: number;
    name: string;
    value: string;
  };
  duration: number;
  hostingDays: number;
  languagesQty: number;
  liveViewers: number;
  projectAccounts: [
    {
      id: number;
      name: string;
      accountId: string;
      platformId: number;
      platform: string;
      order: number;
    },
  ];
  supportRegion: {
    id: number;
    name: string;
  };
  showInProjectPicker: boolean;
  notes: string;
  duration: number;
  eventStart: string;
  eventEnd: string;
  eventsAllowed: number;
  eventsUsed: number;
  languagesQty: number;
  liveViewers: number;
  serviceModelType: {
    id: number;
    name: string;
    value: string;
  };
  usageType: {
    id: number;
    name: string;
    value: string;
  };
  hostingDuration: number;
  languages: number;
  contentType: {
    id: number;
    name: string;
    value: string;
  };
  deliveryType: {
    id: number;
    name: string;
    value: string;
  };
  areEventDatesFromPrimaryEvent: boolean;
  fulfillmentStart: string;
  fulfillmentEnd: string;
  eventsAllowed: number;
  eventsUsed: number;
  fulfillmentStatus: {
    id: number;
    name: string;
    value: string;
  };
  projectStaff: [
    {
      staff: {
        id: number;
        firstName: string;
        lastName: string;
      };
      id: number | null;
      staffRoleType: string;
      name: string;
    },
  ];
  recurringRolloverTerm: number;
  created?: string;
  createdBy?: string;
  lastUpdated?: string;
  lastUpdatedBy?: string;
  cfpOpenDate?: string;
  cfpCloseDate?: string;
  clientContactName?: string;
  clientContactEmail?: string;
  siteId?: string;
  siteUrl?: string;
  registrants?: number | null;
  hasChildren: boolean;
}

export interface ITimeTracking {
  id: number;
  staffId: number;
  projectId: number;
  staffName: string;
  projectName: string;
  date: string;
  type: string;
  subType: string;
  description: string;
  duration: number;
  staffEmail: string;
  staffRegion: string;
  staffTeam: string;
  staffManager: string;
}

export interface ITimeTrackingBucket {
  actualTime: number;
  timetrackingType: string;
  allottedTime: number;
}

interface ICondition {
  dateFrom?: string;
  dateTo?: string | null;
  filter?: string;
  filterTo?: string;
  type: string;
  filterType: string;
}

interface IFilterModel {
  colId: string;
  operator: string;
  conditions: ICondition[];
}

interface ISortModel {
  colId: string;
  sort: string;
}

export interface IDataGridLazy {
  startRow: number;
  endRow: number;
  filterModel: IFilterModel[];
  sortModel: ISortModel[];
}

export interface IPlis {
  id: number;
  projectId: number;
  subscriptionId: string;
  contractId: string;
  orderId: string;
  quoteId: string;
  opportunityId: string;
  opportunityName: string;
  pliCode: string;
  pliName: string;
  quantity: number;
  frequency: string;
  contractStartDate: string;
  contractEndDate: string;
  contractRenewalDate: string;
  dsaId: string;
  dsaName: string;
  dsaRegion: string;
  statusName: string;
  statusId: number;
  statusBilling: string;
  total: number;
  created: string;
  lastUpdated: string;
  orderProductEventId?: number;
  orderProductEventDate?: Date;
  orderProductEventTitle?: string;
}

export interface IEventsResponse {
  totalCount: number;
  data: IEvent[];
}

export interface IDSAResponse {
  totalCount: number;
  data: IRSADSAItemData[];
}

export enum ProjectTypes {
  Webcast = 1,
  VirtualEvent = 2,
  Plan = 3,
  Misc = 6,
  WebCastSubProject = 7,
  VESubProject = 8,
  PlanSubProject = 9,
}

export interface IPrimaryEeventDetails {
  name: string;
  startDate: Date;
  endDate: Date;
  eventId: string;
}

export interface ICompleteEeventDetails {
  eventTitle: string;
  eventDate: Date | string;
  eventEndDate: Date | string;
  eventId: string;
  projectId?: number;
}

export interface IReport {
  id: string;
  reportUid: string;
  reportType: string;
  status: string;
  url: string;
  created: Date | string;
}

export interface IReportGeneration {
  reportType: string;
  start?: Date | string;
  end?: Date | string;
  reportFilterType?: string;
  platform?: string;
  eventType?: string;
}

export interface IGridConfiguration {
  gridType: string;
  optionIndex: number;
  title: string;
  paramsJson: string;
  isConfigInvalid?: boolean;
}
