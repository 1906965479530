/* eslint-disable @typescript-eslint/no-explicit-any */
export interface IAction<P = any> {
  type: string;
  payload: P;
}

export type Reducer<S> = (payload: any, state: S, type: string) => Partial<S>;

let __DEV__ = false;
try {
  __DEV__ = process.env.NODE_ENV !== 'production';
} catch (e) {
  console.warn(e);
}

const createReducer = <S = Record<string, any>>(initialState: S, handlers: Record<string, Reducer<S>>) => {
  if (__DEV__ && handlers['undefined']) {
    console.warn('Reducer contains an "undefined" action type. Have you misspelled a constant?');
  }

  return function reducer(state = initialState, action: IAction): S {
    // eslint-disable-next-line no-prototype-builtins
    if (handlers.hasOwnProperty(action.type)) {
      const result = handlers[action.type](action.payload, state, action.type);
      return { ...state, ...result };
    }
    return state;
  };
};

export default createReducer;
