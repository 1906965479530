// use 'npm run g:com {name}' - to generate new component with react hooks
import React, { FormEvent, useEffect, useState, MouseEvent } from 'react';
import { useDispatch } from 'react-redux';
import { Container, Row, Col, Form, Button } from 'reactstrap';
import omit from 'lodash/omit';
import { IEvent } from '../../utils/api.d';
import FormField from '../FormField';
import { useBreakpoints } from '../../utils/hooks';
import { convertToPlainObjectWithEnums, makeColumns, standardDateFormat } from '../../utils/helpers';
import { eventsListGridColumns } from '../../tablesFormShapes/events';
import {
  contentType,
  deliveryType,
  eventSubType,
  eventType,
  serviceModelType,
  usageType,
  eventModeType,
  convertEnumTypes,
  platformType,
  eventCallProvider,
  fulfillmentStatus as fulfillmentStatusEnum,
} from '../../enums';
import { save } from '../../actions/events';
import { history } from '../../store';
import { Routes } from '../../constants';
export interface IEventDetailsProps {
  event?: IEvent;
}

const enumsMapping = {
  platform: convertEnumTypes(platformType),
  eventType: convertEnumTypes(eventType),
  eventSubType: convertEnumTypes(eventSubType),
  serviceModelType: convertEnumTypes(serviceModelType),
  contentType: convertEnumTypes(contentType),
  deliveryType: convertEnumTypes(deliveryType),
  eventMode: convertEnumTypes(eventModeType),
  usageType: convertEnumTypes(usageType),
  eventCallProvider: convertEnumTypes(eventCallProvider),
};

const EventDetails = ({ event }: IEventDetailsProps) => {
  const { xl } = useBreakpoints();
  const evt = convertToPlainObjectWithEnums(event || {});
  const [currentEvent, setCurrentEvent] = useState(evt);
  const isProjectFulfilled = Boolean(
    event && event.project && event.project.fulfillmentStatus.value === fulfillmentStatusEnum.Fulfilled.value,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const evt = convertToPlainObjectWithEnums(event || {});
    setCurrentEvent(evt);
  }, [event]);

  type eventsInfoKeys = keyof typeof eventsListGridColumns;

  const keys = Object.keys(omit(currentEvent, ['id', 'created', 'lastUpdated', 'project'])) as eventsInfoKeys[];
  const elements: eventsInfoKeys[][] = makeColumns<eventsInfoKeys>(keys, xl ? 3 : 2);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateValue = (key: keyof typeof eventsListGridColumns) => (value: any) => {
    setCurrentEvent({ ...currentEvent, [key]: value });
  };

  const saveData = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const projectId = currentEvent.projectId || currentEvent.projectId === 0 ? Number(currentEvent.projectId) : null;
    const eventId = currentEvent.id;
    const payload = { projectId, eventId };

    dispatch(save(payload));
  };

  const close = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    history.push(Routes.Events);
  };

  const customFields = ['isPrimary', 'serviceTeamMember', 'lobby'];

  const lobbyVisible =
    currentEvent.platform !== enumsMapping.platform[0].value && // 'MmcVex'
    currentEvent.eventType === enumsMapping.eventType[0].value; // 'Webcast'

  const fields = elements.map((els, i) => (
    <Col key={`field${i}`} xl={4} md={6}>
      {els
        .filter(item => !customFields.includes(item))
        .map(key => {
          const event = eventsListGridColumns[key] || {};
          let date;

          if (event.type === 'datetime-local' && currentEvent[key]) {
            date = standardDateFormat(currentEvent[key]);
          }

          return (
            <FormField
              key={key}
              name={key}
              disabled={key !== 'projectId' || (key === 'projectId' && isProjectFulfilled)}
              type={event.type || 'text'}
              label={event.label || key}
              valueFormat={event.valueFormat}
              options={enumsMapping[key]}
              value={date || currentEvent[key]}
              onChange={updateValue(key)}
            />
          );
        })}
    </Col>
  ));

  return (
    <Form onSubmit={saveData} style={{ width: '100%' }}>
      <Container fluid>
        <Row>{fields}</Row>
        <Row>
          <Col xl={4} md={6}>
            <FormField
              name={'isPrimary'}
              disabled={true}
              type={'text'}
              label={'Is Primary'}
              value={currentEvent.isPrimary}
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              onChange={() => {}}
            />
          </Col>
          <Col xl={4} md={6}>
            <FormField
              name={'serviceTeamMember'}
              disabled={true}
              type={'text'}
              label={'Service Team Member'}
              value={currentEvent.serviceTeamMember}
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              onChange={() => {}}
            />
          </Col>
          <Col xl={4} md={6}>
            {lobbyVisible && (
              <FormField
                name={'lobby'}
                disabled={true}
                type={'text'}
                label={'Lobby'}
                value={currentEvent.lobby}
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onChange={() => {}}
              />
            )}
          </Col>
        </Row>
        <hr />
        <Row>
          <Col className="my-1" xl={1} sm={3} md={2}>
            <Button type="submit" block color="primary">
              Save
            </Button>
          </Col>
          <Col className="my-1" xl={1} sm={3} md={2}>
            <Button onClick={close} block color="secondary">
              Close
            </Button>
          </Col>
          <Col />
        </Row>
      </Container>
    </Form>
  );
};

export default EventDetails;
