import { ColDef, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { subProjectsTableConfig } from '../../../tablesFormShapes/subProjects';
import {
  numberParserForFilter as numberParser,
  transformToColumns,
  dateFormatter,
  createMapFromObjectOfObjects,
} from '../../../utils/helpers';
import {
  projectType as projectTypeEnum,
  projectStatus as projectStatusEnum,
  eventSubType as eventSubTypeEnum,
} from '../../../enums';

const projectTypesMap = {};
const projectStatusesMap = createMapFromObjectOfObjects(projectStatusEnum, 'id', 'name');
const eventSubTypesMap = createMapFromObjectOfObjects(eventSubTypeEnum, 'id', 'name');

const redundantProjectTypes = [
  projectTypeEnum.Unknown.value,
  projectTypeEnum.ResellerPlan.value,
  projectTypeEnum.ProjectGroup.value,
];

Object.keys(projectTypeEnum).forEach((projectType: string) => {
  const projectTypeValue = projectTypeEnum[projectType].value;

  if (!redundantProjectTypes.includes(projectTypeValue)) {
    const { id, name } = projectTypeEnum[projectType];

    projectTypesMap[id] = name;
  }
});

export default function(): ColDef[] {
  const defaultConfigColumns = {
    id: {
      hide: true,
      filterParams: {
        numberParser,
      },
    },
    name: {
      hide: false,
      sort: 'asc',
    },
    eventStart: {
      valueGetter: dateFormatter('eventStart'),
      hide: false,
      filterParams: {
        includeTime: true,
      },
    },
    eventEnd: {
      valueGetter: dateFormatter('eventEnd'),
      hide: true,
      filterParams: {
        includeTime: true,
      },
    },
    eventQtyPurchased: {
      hide: false,
      filterParams: {
        numberParser,
      },
    },
    eventQtyUsed: {
      hide: true,
      filterParams: {
        numberParser,
      },
    },
    fulfillmentEnd: {
      valueGetter: dateFormatter('fulfillmentEnd', false),
      hide: true,
    },
    type: {
      filter: 'agSetColumnFilter',
      filterParams: {
        suppressSorting: true,
        debounceMs: 1000,
        values: Object.keys(projectTypesMap),
        valueFormatter: (params: ValueFormatterParams) => projectTypesMap[params.value] || params.value,
      },
      valueGetter: (params: ValueGetterParams) => projectTypesMap[params.data.type] || params.data.type,
    },
    package: {
      filter: 'agSetColumnFilter',
      filterParams: {
        suppressSorting: true,
        debounceMs: 1000,
        values: Object.keys(eventSubTypesMap),
        valueFormatter: (params: ValueFormatterParams) => eventSubTypesMap[params.value] || params.value,
      },
      valueGetter: (params: ValueGetterParams) => eventSubTypesMap[params.data.package] || params.data.package,
    },
    viewers: {
      hide: true,
      filterParams: {
        numberParser,
      },
    },
    projectManager: {
      hide: false,
    },
    status: {
      filter: 'agSetColumnFilter',
      filterParams: {
        suppressSorting: true,
        debounceMs: 1000,
        values: Object.keys(projectStatusesMap),
        valueFormatter: (params: ValueFormatterParams) => projectStatusesMap[params.value] || params.value,
      },
      valueGetter: (params: ValueGetterParams) => projectStatusesMap[params.data.status] || params.data.status,
    },
  };

  return transformToColumns(subProjectsTableConfig, defaultConfigColumns, { minWidth: 200 });
}
