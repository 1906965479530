import { FormShape } from '../utils/api.d';

export const timeTrackingListGridColumns: FormShape = {
  id: { label: 'Id', type: 'number', filter: 'agNumberColumnFilter' },
  staffId: { label: 'Staff Id', type: 'number', filter: 'agNumberColumnFilter' },
  projectId: { label: 'Project Id', type: 'number', filter: 'agNumberColumnFilter' },
  staffName: { label: 'Staff Name', type: 'text', filter: 'agTextColumnFilter' },
  projectName: { label: 'Project Name', type: 'text', filter: 'agTextColumnFilter' },
  type: { label: 'Type', type: 'text', filter: 'agSetColumnFilter' },
  subType: { label: 'Sub Type', type: 'text', filter: 'agSetColumnFilter' },
  duration: { label: 'Duration', type: 'number', filter: 'agNumberColumnFilter' },
  date: { label: 'Date', type: 'datetime-local', filter: 'agDateColumnFilter' },
  staffRegion: { label: 'Region', type: 'text', filter: 'agSetColumnFilter' },
  staffTeam: { label: 'Team', type: 'text', filter: 'agTextColumnFilter' },
  staffManager: { label: 'Manager', type: 'text', filter: 'agTextColumnFilter' },
  description: { label: 'Description', type: 'text', filter: 'agTextColumnFilter' },
};
