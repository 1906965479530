import React, { ChangeEvent, FormEvent, useState } from 'react';
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import styles from './GridViewConfigModal.module.scss';

interface GridViewConfigModalProps {
  isOpen: boolean;
  saveGridViewConfig: (gridViewConfig: string) => void;
  cancelSaveGridView: () => void;
  gridViewName: string;
}

export function GridViewConfigModal({
  isOpen,
  saveGridViewConfig,
  cancelSaveGridView,
  gridViewName,
}: GridViewConfigModalProps) {
  const [gridViewTitle, setGridViewTitle] = useState(gridViewName);

  const resetFormToInitialState = () => {
    setGridViewTitle(gridViewName);
  };

  const cancelSave = () => {
    cancelSaveGridView();
    resetFormToInitialState();
  };

  const saveGridView = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const isGridViewTitlePresent = Boolean(gridViewTitle && gridViewTitle.trim());

    if (isGridViewTitlePresent) {
      saveGridViewConfig(gridViewTitle);
      resetFormToInitialState();
    }
  };

  const handleGridViewTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setGridViewTitle(event.target.value);
  };

  return (
    <div>
      <Modal className={styles.modal} isOpen={isOpen}>
        <ModalHeader>Create/Overwrite Grid View</ModalHeader>
        <ModalBody>
          <form id="gridViewConfigForm" autoComplete="off" onSubmit={saveGridView}>
            <FormGroup>
              <Label for="gridViewTitle">Grid View Title</Label>
              <Input
                required
                type="text"
                defaultValue={gridViewTitle}
                name="gridViewTitle"
                id="gridViewTitle"
                onChange={handleGridViewTitleChange}
              />
            </FormGroup>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={cancelSave}>
            Cancel
          </Button>
          <Button
            color="primary"
            form={'gridViewConfigForm'}
            type="submit"
            disabled={!gridViewTitle || !gridViewTitle.trim()}
          >
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default GridViewConfigModal;
