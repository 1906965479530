import {
  projectType as ProjectTypeEnum,
  eventSubType as eventSubTypeEnum,
  usageType as usageTypeEnum,
  contentType as contentTypeEnum,
  platformType as platformTypeEnum,
} from '../../enums';

export const showInProjectPickerConfig = {
  YES: 'true',
  NO: 'false',
};

/**
 * NOTE: there is no Misc Sub-project. That is the reason, why 'MiscSubProject' is used here instead of enum value
 */
export const MiscSubProject = 'MiscSubProject';

export const showProjectPickerConfig = {
  [ProjectTypeEnum.Webcast.id]: false,
  [ProjectTypeEnum.VirtualEvent.id]: true,
  [ProjectTypeEnum.Plan.id]: false,
  [ProjectTypeEnum.Misc.id]: false,
  [ProjectTypeEnum.WebcastSubProject.id]: true,
  [ProjectTypeEnum.VirtualEventSubProject.id]: true,
  [ProjectTypeEnum.PlatformPlanSubProject.id]: false,
  [MiscSubProject]: true,
};

export const eventTypeDropdownOptions = {
  [ProjectTypeEnum.Webcast.id]: [eventSubTypeEnum.Other],
  [ProjectTypeEnum.WebcastSubProject.id]: [
    eventSubTypeEnum.MMCEssentials,
    eventSubTypeEnum.MMCExpert,
    eventSubTypeEnum.StudioEssentials,
    eventSubTypeEnum.StudioExpert,
    eventSubTypeEnum.Other,
  ],
  [ProjectTypeEnum.VirtualEvent.id]: [
    eventSubTypeEnum.VEExpress,
    eventSubTypeEnum.VEEssentials,
    eventSubTypeEnum.VEExpert,
    eventSubTypeEnum.ThirdPartyLink,
    eventSubTypeEnum.Expand,
    eventSubTypeEnum.Evolve,
    eventSubTypeEnum.Enterprise,
    eventSubTypeEnum.Other,
  ],
  [ProjectTypeEnum.VirtualEventSubProject.id]: [
    eventSubTypeEnum.MMCEssentials,
    eventSubTypeEnum.MMCExpert,
    eventSubTypeEnum.StudioEssentials,
    eventSubTypeEnum.StudioExpert,
    eventSubTypeEnum.ThirdPartyLink,
    eventSubTypeEnum.Other,
  ],
  [ProjectTypeEnum.Plan.id]: [eventSubTypeEnum.Other],
  [ProjectTypeEnum.PlatformPlanSubProject.id]: [
    eventSubTypeEnum.MMCEssentials,
    eventSubTypeEnum.MMCExpert,
    eventSubTypeEnum.StudioEssentials,
    eventSubTypeEnum.StudioExpert,
    eventSubTypeEnum.ThirdPartyLink,
    eventSubTypeEnum.Expand,
    eventSubTypeEnum.Evolve,
    eventSubTypeEnum.Enterprise,
    eventSubTypeEnum.Other,
  ],
  [ProjectTypeEnum.Misc.id]: [eventSubTypeEnum.VEExpress, eventSubTypeEnum.VEEssentials, eventSubTypeEnum.VEExpert],
  [MiscSubProject]: [
    eventSubTypeEnum.MMCEssentials,
    eventSubTypeEnum.MMCExpert,
    eventSubTypeEnum.StudioEssentials,
    eventSubTypeEnum.StudioExpert,
    eventSubTypeEnum.VEExpress,
    eventSubTypeEnum.VEEssentials,
    eventSubTypeEnum.VEExpert,
    eventSubTypeEnum.ThirdPartyLink,
    eventSubTypeEnum.Other,
  ],
};

export const completeEventButtonText = {
  complete: 'Complete Event',
  fulfilled: 'Event Fulfilled',
};

export const showEventDetailsForProjectConfig = [
  ProjectTypeEnum.VirtualEvent.id,
  ProjectTypeEnum.Misc.id,
  ProjectTypeEnum.WebcastSubProject.id,
  ProjectTypeEnum.VirtualEventSubProject.id,
];

export const showSubProjectSectionForProjectConfig = [
  ProjectTypeEnum.Webcast.id,
  ProjectTypeEnum.VirtualEvent.id,
  ProjectTypeEnum.Plan.id,
  ProjectTypeEnum.Misc.id,
  ProjectTypeEnum.PlatformPlanSubProject.id,
];

export const showSalesForceSectionForProjectConfig = [
  ProjectTypeEnum.WebcastSubProject.id,
  ProjectTypeEnum.VirtualEvent.id,
  ProjectTypeEnum.Plan.id,
  ProjectTypeEnum.Misc.id,
];

export const showTimeTrackingForProjectConfig = [
  ProjectTypeEnum.VirtualEvent.id,
  ProjectTypeEnum.Plan.id,
  ProjectTypeEnum.Misc.id,
  ProjectTypeEnum.WebcastSubProject.id,
  ProjectTypeEnum.PlatformPlanSubProject.id,
];

export const showStaffGridForProjectConfig = [ProjectTypeEnum.VirtualEvent.id, ProjectTypeEnum.WebcastSubProject.id];

export const redundantProjectsConfig = [ProjectTypeEnum.ResellerPlan.id, ProjectTypeEnum.ProjectGroup.id];

export const usageTypeDropdownOptions = {
  [ProjectTypeEnum.Webcast.id]: [
    usageTypeEnum.Unknown,
    usageTypeEnum.Production,
    usageTypeEnum.Demo,
    usageTypeEnum.Test,
  ],
  [ProjectTypeEnum.WebcastSubProject.id]: [
    usageTypeEnum.Unknown,
    usageTypeEnum.Production,
    usageTypeEnum.Demo,
    usageTypeEnum.Test,
    usageTypeEnum.TelcoOnly,
    usageTypeEnum.PreRecord,
  ],
  [ProjectTypeEnum.VirtualEvent.id]: [
    usageTypeEnum.Unknown,
    usageTypeEnum.Production,
    usageTypeEnum.Demo,
    usageTypeEnum.Test,
  ],
  [ProjectTypeEnum.VirtualEventSubProject.id]: [
    usageTypeEnum.Unknown,
    usageTypeEnum.Production,
    usageTypeEnum.Demo,
    usageTypeEnum.Test,
  ],
  [ProjectTypeEnum.Plan.id]: [usageTypeEnum.Unknown, usageTypeEnum.Production, usageTypeEnum.Demo, usageTypeEnum.Test],
  [ProjectTypeEnum.PlatformPlanSubProject.id]: [
    usageTypeEnum.Unknown,
    usageTypeEnum.Production,
    usageTypeEnum.Demo,
    usageTypeEnum.Test,
  ],
  [ProjectTypeEnum.Misc.id]: [usageTypeEnum.Unknown, usageTypeEnum.Production, usageTypeEnum.Demo, usageTypeEnum.Test],
};

/**
 * NOTE: According to https://notified.atlassian.net/browse/TW-996 Values listed High to Low:
 * Video HD: value for comparison - 3
 * Video: value for comparison - 2
 * Audio: value for comparison - 1
 * Unknown: value for comparison - 0
 * */
export const contentTypeOveragesValuesMap = {
  [contentTypeEnum.Unknown.name]: 0,
  [contentTypeEnum.AudioOnly.name]: 1,
  [contentTypeEnum.Video.name]: 2,
  [contentTypeEnum.VideoHD.name]: 3,
};

export const MMCProducts = {
  [eventSubTypeEnum.MMCEssentials.name]: 1,
  [eventSubTypeEnum.MMCExpert.name]: 2,
};

export const studioProducts = {
  [eventSubTypeEnum.StudioEssentials.name]: 1,
  [eventSubTypeEnum.StudioExpert.name]: 2,
};

export const eventCloudProducts = {
  [eventSubTypeEnum.Expand.name]: 1,
  [eventSubTypeEnum.Evolve.name]: 2,
  [eventSubTypeEnum.Enterprise.name]: 3,
};

export const legacyVxProducts = {
  [eventSubTypeEnum.VEExpress.name]: 1,
  [eventSubTypeEnum.VEEssentials.name]: 2,
  [eventSubTypeEnum.VEExpert.name]: 3,
};

export const eventTypeOveragesGroupsMap = {
  [eventSubTypeEnum.MMCEssentials.name]: MMCProducts,
  [eventSubTypeEnum.MMCExpert.name]: MMCProducts,
  [eventSubTypeEnum.StudioEssentials.name]: studioProducts,
  [eventSubTypeEnum.StudioExpert.name]: studioProducts,
  [eventSubTypeEnum.Expand.name]: eventCloudProducts,
  [eventSubTypeEnum.Evolve.name]: eventCloudProducts,
  [eventSubTypeEnum.Enterprise.name]: eventCloudProducts,
  [eventSubTypeEnum.VEExpress.name]: legacyVxProducts,
  [eventSubTypeEnum.VEEssentials.name]: legacyVxProducts,
  [eventSubTypeEnum.VEExpert.name]: legacyVxProducts,
};

export const specificOveragesNames = {
  contentType: 'contentType.name',
  eventSubType: 'projectSubType.name',
};

export const canAddEventsToProjectConfig = {
  [ProjectTypeEnum.Webcast.id]: false,
  [ProjectTypeEnum.VirtualEvent.id]: true,
  [ProjectTypeEnum.Plan.id]: false,
  [ProjectTypeEnum.Misc.id]: true,
  [ProjectTypeEnum.WebcastSubProject.id]: true,
  [ProjectTypeEnum.VirtualEventSubProject.id]: true,
  [ProjectTypeEnum.PlatformPlanSubProject.id]: false,
};

export const showAccountsSection = {
  [ProjectTypeEnum.Webcast.id]: false,
  [ProjectTypeEnum.VirtualEvent.id]: true,
  [ProjectTypeEnum.Plan.id]: false,
  [ProjectTypeEnum.Misc.id]: true,
  [ProjectTypeEnum.WebcastSubProject.id]: true,
  [ProjectTypeEnum.VirtualEventSubProject.id]: true,
  [ProjectTypeEnum.PlatformPlanSubProject.id]: false,
};

export const accountPlatformMap = {
  [platformTypeEnum.MmcVex.value]: platformTypeEnum.MmcVex.name,
  [platformTypeEnum.StudioVeAmer.value]: platformTypeEnum.StudioVeAmer.name,
  [platformTypeEnum.StudioVeEmea.value]: platformTypeEnum.StudioVeEmea.name,
  [platformTypeEnum.StudioVeApac.value]: platformTypeEnum.StudioVeApac.name,
};

export const MMCGroup = [eventSubTypeEnum.MMCExpert.value, eventSubTypeEnum.MMCEssentials.value];
export const PlatformsForMMCGroup = `${platformTypeEnum.MmcVex.value}`;
export const StudioGroup = [eventSubTypeEnum.StudioEssentials.value, eventSubTypeEnum.StudioExpert.value];
export const PlatformsForStudioGroup = `${platformTypeEnum.StudioVeAmer.value}&${platformTypeEnum.StudioVeEmea.value}&${platformTypeEnum.StudioVeApac.value}`;
