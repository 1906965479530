import { put, take, call, StrictEffect } from 'redux-saga/effects';
import {
  fetchById,
  fetchRSADSA,
  FETCH_BY_ID_FAILURE,
  FETCH_BY_ID_SUCCESS,
  FETCH_RSADSA_SUCCESS,
  FETCH_RSADSA_FAILURE,
} from '../actions/dsas';
import { Routes } from '../constants';
import { appLoadingPlaceholder } from '../utils/helpers';
import { IAction } from '../utils/redux-create-reducer';

export function* fetcher(match: { params: Record<string, string> }): Generator<StrictEffect, IAction, IAction> {
  yield put(fetchById(match.params.dsaid));
  yield put(fetchRSADSA(match.params.dsaid));
  return yield take([FETCH_BY_ID_SUCCESS, FETCH_BY_ID_FAILURE, FETCH_RSADSA_SUCCESS, FETCH_RSADSA_FAILURE]);
}

export default function* dsaDetailsPage() {
  while (true) {
    yield call(appLoadingPlaceholder, Routes.DSADetail, fetcher);
  }
}
