import { ColDef, ColGroupDef, ValueGetterParams } from 'ag-grid-community';
import {
  dateFormatter,
  getProjectType,
  getProjectManager,
  getProjectSubType,
  getProjectStatus,
} from '../../utils/helpers';

export default function(): (ColDef | ColGroupDef)[] {
  return [
    {
      headerName: 'ID',
      field: 'id',
      hide: true,
    },
    {
      headerName: 'Name',
      field: 'name',
      sort: 'asc',
    },
    {
      headerName: 'Qty',
      field: 'eventQuantity',
    },
    {
      headerName: 'Event Start',
      field: 'eventStart',
      valueGetter: dateFormatter('eventStart'),
      filterParams: {
        includeTime: true,
      },
    },
    {
      headerName: 'Event End',
      field: 'eventEnd',
      valueGetter: dateFormatter('eventEnd'),
      filterParams: {
        includeTime: true,
      },
    },
    {
      headerName: 'Project Type',
      field: 'projectType',
      valueGetter: (params: ValueGetterParams) => getProjectType(params),
    },
    {
      headerName: 'Project Type Id',
      field: 'projectTypeId',
      hide: true,
    },
    {
      headerName: 'Event Type',
      field: 'projectSubType',
      valueGetter: (params: ValueGetterParams) => getProjectSubType(params),
    },
    {
      headerName: 'Project Manager',
      field: 'projectStaff',
      valueGetter: (params: ValueGetterParams) => getProjectManager(params),
    },
    {
      headerName: 'Project Status',
      field: 'projectStatus',
      valueGetter: (params: ValueGetterParams) => getProjectStatus(params),
    },
  ];
}
