import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Callback from './pages/Callback';
import Logout from './pages/Logout';
import Events from './pages/Events';
import EventDetail from './pages/EventDetail/index';
import TimeTracking from './pages/TimeTracking';
import NewTimeTracking from './pages/NewTimeTracking';
import Staff from './pages/Staff';
import NewStaff from './pages/NewStaff';
import Projects from './pages/Projects';
import ProjectAuditLog from './pages/ProjectAuditLog';
import Accounts from './pages/Accounts';
import EditAccounts from './pages/AccountsDetail';
import Salesforce from './pages/Salesforce';
import DSAListView from './pages/DSAListView';
import PLIDetail from './pages/PLIDetail';
import DSADetail from './pages/DSADetail';
import AddNewProject from './pages/AddNewProject';
import Reports from './pages/Reports';
import { N3AEntitlements, Routes } from './constants';
import { ConnectedRouter } from 'connected-react-router';
import { history } from './store';
import { LayoutWrapper } from './containers/Layout';

export const NoMatchPage = () => {
  return (
    <div>
      <h3>404 - Not found</h3>
    </div>
  );
};

const App = () => {
  return (
    <div className="App">
      <ConnectedRouter history={history}>
        <Switch>
          <Route path={Routes.Callback} exact component={Callback} />
          <Route path={Routes.Logout} exact component={Logout} />
          <Route path={Routes.Projects} exact component={LayoutWrapper(Projects)} />
          <Route path={[Routes.AddNewProject, Routes.NewEditProject]} exact component={LayoutWrapper(AddNewProject)} />
          <Route path={Routes.ProjectAuditLog} exact component={LayoutWrapper(ProjectAuditLog)} />
          <Route path={[Routes.Accounts, Routes.AccountsToProject]} exact component={LayoutWrapper(Accounts)} />
          <Route path={Routes.AccountsDetail} exact component={LayoutWrapper(EditAccounts)} />
          <Route path={[Routes.Events, Routes.EventsToProject]} exact component={LayoutWrapper(Events)} />
          <Route path={Routes.EventDetail} exact component={LayoutWrapper(EventDetail)} />
          <Route path={Routes.TimeTracking} exact component={LayoutWrapper(TimeTracking)} />
          <Route path={Routes.PLIDetail} exact component={LayoutWrapper(PLIDetail, N3AEntitlements.Admin)} />
          <Route
            path={[Routes.NewTimeTracking, Routes.EditTimeTracking]}
            exact
            component={LayoutWrapper(NewTimeTracking)}
          />
          <Route
            path={[Routes.Salesforce, Routes.SalesforceToProject]}
            exact
            component={LayoutWrapper(Salesforce, N3AEntitlements.Admin)}
          />
          <Route path={Routes.Staff} exact component={LayoutWrapper(Staff, N3AEntitlements.Admin)} />
          <Route path={Routes.NewStaff} exact component={LayoutWrapper(NewStaff, N3AEntitlements.Admin)} />
          <Route path={[Routes.Root, Routes.DSAListView]} exact component={LayoutWrapper(DSAListView)} />
          <Route path={Routes.DSADetail} exact component={LayoutWrapper(DSADetail)} />
          <Route path={Routes.Reports} exact component={LayoutWrapper(Reports, N3AEntitlements.Admin)} />
          <Route component={NoMatchPage} />
        </Switch>
      </ConnectedRouter>
    </div>
  );
};

export default App;
