import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import getColumns from './columns';
import makeGridOptions from '../../gridOptions';
import { AgGridEvent, ColumnApi, GridApi, ServerSideStoreType } from 'ag-grid-community';
import { Routes } from '../../constants';
import { Grid } from '../../components/Grid';
import {
  checkForSavedGrid,
  createServerSideDatasource,
  getPathToPreviousScreen,
  gridStateSaver,
  resetAllGridFilters,
  createDefaultGridConfigViewOptions,
  createGridConfigOption,
  isValidJSON,
} from '../../utils/helpers';
import { urls } from '../../utils/api';
import { AppGrids, convertEnumTypes, gridTypes, platformType } from '../../enums';
import { IEnumType, IEvent, IGridConfiguration } from '../../utils/api.d';
import { GetContextMenuItemsParams } from 'ag-grid-community/dist/lib/entities/gridOptions';
import { Button } from 'reactstrap';
import styles from './Events.module.scss';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getUser } from '../../reducers/user';
import GridViewConfigModal from '../../components/GridViewConfigModal';
import GridViewConfig from '../../components/GridViewConfig';
import { getGridConfigs } from '../../reducers/gridConfig';
import { updateGridConfig } from '../../actions/gridConfig';
import AjaxSpinner from '../../components/AjaxSpinner';

export const onCellClicked = (eventName: string | undefined, eventData: IEvent, callback: (route: string) => void) => {
  const platformTypeConverted = convertEnumTypes(platformType);
  const platform = platformTypeConverted.filter((o: IEnumType) => o.id === eventData.platform);
  const platformQueryStringValue = platform && platform.length == 1 ? platform[0].value : null;
  const queryString = `?platform=${platformQueryStringValue}`;

  let route = '';
  if (eventName === 'name') {
    route = Routes.EventDetail.replace(':eventId', eventData.eventId) + queryString;
    callback(route);
  } else if (eventName === 'projectName' && eventData.projectId) {
    route = Routes.NewEditProject.replace(':id', eventData.projectId.toString())
      .replace(':type', eventData.projectTypeId.toString())
      .replace(':backUrl', encodeURIComponent(location.pathname));
    callback(route);
  }
};

const Events = () => {
  const dispatch = useDispatch();
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [gridColumnApi, setGridColumnApi] = useState<ColumnApi | null>(null);
  const [selectedEvents, setselectedEvents] = useState<IEvent[]>([]);
  const { emailAddress } = useSelector(getUser, shallowEqual) || {};
  const { data: gridConfigs, isLoading: isGridConfigLoading } = useSelector(getGridConfigs, shallowEqual) || [];
  const history = useHistory();
  const { backUrl } = useParams();

  const columnDefs = getColumns();
  const rowsLimit = 500;
  const noEventsSelected = !selectedEvents.length;

  const addEventsToProject = () => {
    if (backUrl && backUrl !== ':backUrl') {
      history.push(getPathToPreviousScreen(backUrl) + `?eventIds=` + selectedEvents.join(','));
    }
  };

  const onGridReady = (event: AgGridEvent) => {
    setGridApi(event.api);
    setGridColumnApi(event.columnApi);
    const addressBarQueryString = location.href.split('?')[1] || '';
    const datasource = createServerSideDatasource(`${urls.eventsLazy}?${addressBarQueryString}`);
    event.api.setServerSideDatasource(datasource);
  };

  //TODO: there're some issues, have to revert until fixed
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getContextMenuItems = (params: GetContextMenuItemsParams) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const defaultContextMenu = ['copy', 'copyWithHeaders', 'paste', 'separator', 'export'] as any[];
    const setPrimaryMenu = [
      'separator',
      {
        name: 'Open in New Tab',
        action: () => {
          onCellClicked(params.column.getColId(), params.node.data, window.open);
        },
      },
    ];
    if (!params.node.data.isPrimary) {
      defaultContextMenu.push(...setPrimaryMenu);
    }
    return defaultContextMenu;
  };

  const onSelectionChanged = () => {
    if (gridApi) {
      const selectedRows = gridApi.getSelectedRows();
      setselectedEvents(selectedRows.map(i => i.id));
    }
  };

  const rowSelection = backUrl ? 'multiple' : 'single';

  const gridOptions = makeGridOptions(columnDefs, {
    rowModelType: 'serverSide',
    rowSelection: rowSelection,
    cacheBlockSize: rowsLimit,
    serverSideStoreType: ServerSideStoreType.Partial,
    animateRows: true,
    statusBar: { statusPanels: [] },
    onGridReady: onGridReady,
    rowStyle: { cursor: 'default' },
  });

  const backToProject = () => {
    if (backUrl && backUrl !== ':backUrl') {
      history.push(getPathToPreviousScreen(backUrl));
    } else {
      history.push(Routes.Events);
    }
  };

  const gridName = `${emailAddress}-${AppGrids.EventsList}`;
  const gridConfigName = `${emailAddress}-${AppGrids.EventsList}-config`;

  const saveState = () => gridColumnApi && gridStateSaver(gridColumnApi, gridName);

  if (gridColumnApi) {
    checkForSavedGrid(gridColumnApi, gridName, gridConfigName, columnDefs);
    gridStateSaver(gridColumnApi, gridName);
  }

  const resetAllFilters = () => resetAllGridFilters(gridApi);

  const defaultGridConfigs = createDefaultGridConfigViewOptions(gridTypes.EventsList.value, 5);
  const finalGridConfigs = createGridConfigOption(defaultGridConfigs, gridConfigs, columnDefs);
  const [editedGridViewConfig, setEditedGridViewConfig] = useState(defaultGridConfigs[0]);
  const [editedGridViewConfigName, setEditedGridViewConfigName] = useState('');
  const [isGridViewConfigModalOpen, setGridViewConfigModalOpen] = useState(false);
  const gridViewConfigModalToggle = () => setGridViewConfigModalOpen(!isGridViewConfigModalOpen);

  // TODO: think about moving this method to helpers or smth like that
  const saveGridViewConfig = (gridViewTitle: string) => {
    const finalData = {
      gridType: gridTypes.EventsList.value,
      optionIndex: editedGridViewConfig.optionIndex,
      paramsJson: JSON.stringify({
        defaultConfig: columnDefs,
        customConfig: gridColumnApi && gridColumnApi.getColumnState(),
        filterParams: gridApi && gridApi.getFilterModel(),
      }),
      title: gridViewTitle,
    };

    dispatch(updateGridConfig(finalData));
    gridViewConfigModalToggle();
  };

  // TODO: think about moving this method to helpers or smth like that
  const selectForEditingGridViewConfig = (gridConfig: IGridConfiguration) => {
    setEditedGridViewConfig(gridConfig);
    setEditedGridViewConfigName(gridConfig.title);
    gridViewConfigModalToggle();
  };

  // TODO: think about moving this method to helpers or smth like that
  const applyGridViewConfig = (gridViewConfig: IGridConfiguration) => {
    const savedConfigs = isValidJSON(gridViewConfig.paramsJson) && JSON.parse(gridViewConfig.paramsJson);

    savedConfigs && gridColumnApi && gridColumnApi.setColumnState(savedConfigs.customConfig);
    savedConfigs && gridApi && gridApi.setFilterModel(savedConfigs.filterParams);
  };

  return (
    <>
      {isGridConfigLoading && <AjaxSpinner />}
      <div>
        <div className={styles.title}>Events</div>

        {backUrl && (
          <div className="float-right">
            <Button className={'margin-left-10'} color="primary" onClick={backToProject}>
              Back to project
            </Button>
            <Button
              className={'margin-left-10'}
              color="primary"
              onClick={addEventsToProject}
              disabled={noEventsSelected}
            >
              Add selected to project
            </Button>
          </div>
        )}

        <div className="float-right">
          <Button color="primary" onClick={resetAllFilters}>
            Reset Filters
          </Button>
        </div>

        <div className="float-right margin-left-10 margin-right-10">
          <GridViewConfig
            applyGridViewConfig={applyGridViewConfig}
            saveGridViewConfig={selectForEditingGridViewConfig}
            gridViewConfigList={finalGridConfigs}
          ></GridViewConfig>
        </div>
      </div>
      <Grid
        gridOptions={gridOptions}
        onSelectionChanged={onSelectionChanged}
        onColumnMoved={saveState}
        onColumnVisible={saveState}
        onColumnResized={saveState}
      />

      <GridViewConfigModal
        key={editedGridViewConfigName}
        isOpen={isGridViewConfigModalOpen}
        saveGridViewConfig={saveGridViewConfig}
        cancelSaveGridView={gridViewConfigModalToggle}
        gridViewName={editedGridViewConfigName}
      ></GridViewConfigModal>
    </>
  );
};

export default Events;
