import { ICellEditorParams } from 'ag-grid-community';
import React, { useImperativeHandle, useRef, useState } from 'react';
import Select from 'react-select';
import styles from './GridSelect.module.scss';

const GridSelect = (params: ICellEditorParams, ref: any) => {
  const refInput = useRef(null);
  const selectOptions = Object.values(params.data).map(val => ({ value: val, label: val })) || [];
  const [selectedStaff, setSelectedStaff] = useState<any>(selectOptions[0].value);

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return selectedStaff;
      },
    };
  });

  const updateSelection = (option: any | null) => {
    const optionValue = option.value || '';
    setSelectedStaff(optionValue);
  };

  return (
    <Select
      defaultValue={selectOptions[0]}
      options={selectOptions}
      onChange={option => {
        updateSelection(option);
      }}
      isSearchable={true}
      menuPortalTarget={document.body}
      menuPosition={'fixed'}
      menuShouldBlockScroll={true}
      className={styles.gridSelect}
      ref={refInput}
    ></Select>
  );
};

export default React.forwardRef(GridSelect);
