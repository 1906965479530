import { put, take, call, all } from 'redux-saga/effects';
import { fetchOne, fetch, FETCH_ONE_FAILURE, FETCH_ONE_SUCCESS, FETCH_FAILURE, FETCH_SUCCESS } from '../actions/staffs';
import { Routes } from '../constants';
import { appLoadingPlaceholder } from '../utils/helpers';

export function* fetcher() {
  yield all([put(fetchOne()), put(fetch({ isProjectManager: false }))]);
  return yield take([FETCH_ONE_SUCCESS, FETCH_ONE_FAILURE, FETCH_FAILURE, FETCH_SUCCESS]);
}

export default function* newStaffPage() {
  while (true) {
    yield call(appLoadingPlaceholder, Routes.NewStaff, fetcher);
  }
}
