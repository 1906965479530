import React from 'react';
import cx from 'classnames';
import styles from './Toggle.module.scss';

interface IToggleProps {
  checked: boolean;
  disabled: boolean;
  onChange: () => void;
}

export default function Toggle({ checked, onChange, disabled }: IToggleProps) {
  return (
    <label
      className={cx({
        [styles.switch]: true,
        [styles.switchDisabled]: disabled,
      })}
    >
      <input checked={checked} onChange={onChange} type="checkbox" disabled={disabled} />
      <span className={cx(styles.slider, styles.round)} />
    </label>
  );
}
