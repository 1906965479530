// use 'npm run g:r {name}' - to generate new reducer and action (use --skip if action not needed)
import createReducer from '../utils/redux-create-reducer';
import {
  FETCH,
  FETCH_SUCCESS,
  FETCH_FAILURE,
  FETCH_BY_ID,
  FETCH_BY_ID_SUCCESS,
  FETCH_BY_ID_FAILURE,
  FETCH_BY_PROJECT,
  FETCH_BY_PROJECT_SUCCESS,
  FETCH_BY_PROJECT_FAILURE,
  FETCH_PRIMARY_DETAILS,
  FETCH_PRIMARY_DETAILS_SUCCESS,
  FETCH_PRIMARY_DETAILS_FAILURE,
  REMOVE_EVENTS_FROM_PROJECT,
  REMOVE_EVENTS_FROM_PROJECT_SUCCESS,
  REMOVE_EVENTS_FROM_PROJECT_FAILURE,
} from '../actions/events';
import { IEvent, IPrimaryEeventDetails } from '../utils/api.d';

export interface IEventsState {
  isLoading?: boolean;
  list: IEvent[];
  currentEvent?: IEvent;
  primaryEventDetails?: IPrimaryEeventDetails;
  error?: Error;
}

interface IState {
  events: IEventsState;
}

export const initialState: IEventsState = {
  isLoading: false,
  list: [],
  currentEvent: undefined,
  primaryEventDetails: undefined,
  error: undefined,
};

export const getEvents = (state: IState): IEventsState => state.events;

export default createReducer<IEventsState>(initialState, {
  [FETCH]: () => ({ isLoading: true }),
  [FETCH_SUCCESS]: list => ({ list, isLoading: false }),
  [FETCH_FAILURE]: error => ({ error, isLoading: false }),

  [FETCH_BY_ID]: () => ({ isLoading: true }),
  [FETCH_BY_ID_SUCCESS]: currentEvent => ({ currentEvent, isLoading: false }),
  [FETCH_BY_ID_FAILURE]: error => ({ error, isLoading: false }),

  [FETCH_BY_PROJECT]: () => ({ isLoading: true }),
  [FETCH_BY_PROJECT_SUCCESS]: list => ({ list, isLoading: false }),
  [FETCH_BY_PROJECT_FAILURE]: error => ({ error, isLoading: false }),

  [FETCH_PRIMARY_DETAILS]: () => ({ isLoading: true }),
  [FETCH_PRIMARY_DETAILS_SUCCESS]: primaryEventDetails => ({ primaryEventDetails, isLoading: false }),
  [FETCH_PRIMARY_DETAILS_FAILURE]: error => ({ error, isLoading: false }),

  [REMOVE_EVENTS_FROM_PROJECT]: () => ({ isLoading: true }),
  [REMOVE_EVENTS_FROM_PROJECT_SUCCESS]: () => ({ isLoading: false }),
  [REMOVE_EVENTS_FROM_PROJECT_FAILURE]: error => ({ error, isLoading: false }),
});
