import { REACT_APP_API_BASE_URL, REACT_APP_ENV, SIGNIN_URL } from '../env';
import axios from 'axios';
import { getItem, removeItem } from './browserAPI';
import fakeSession from './cognitoSession.json';
import {
  IProjectDTO,
  ITimeTrackingDTO,
  ITimeTrackingPutDTO,
  IStaff,
  IPlis,
  IEvent,
  IDSA,
  IDSAById,
  IDataGridLazy,
  ICompleteEeventDetails,
  IReportGeneration,
  IGridConfiguration,
} from '../utils/api.d';
import Auth from './cognitoauth';

const userAuth = Auth.getInstance();

export enum urls {
  test = '/test',
  timetrackingBuckets = '/timetrackingBuckets',
  projects = '/projects',
  projectsLazy = '/projects/data',
  projectsCopy = '/projects/copy',
  projectAuditLogLazy = '/projects/{projectId}/auditLog/data',
  events = '/events',
  updateEvent = '/events/{eventId}/project',
  eventsLazy = '/events/data',
  eventsPrimary = '/events/primaryEvent',
  eventsMarkFulfilled = '/projects/markfulfilled',
  accounts = '/accounts',
  accountsLazy = '/accounts/data',
  staff = '/staff',
  timeTracking = '/time-tracking',
  timeTrackingLazy = '/time-tracking/data',
  salesforce = '/plis',
  salesforceLazy = '/plis/data',
  logout = '/logout',
  dsas = '/dsacustomers',
  dsasLazy = '/dsacustomers/data',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  dsasEventsLazy = '/events/data',
  subProjectsLazy = '/subprojects/data',
  reports = '/reports',
  deleteProjectStaff = '/projects/staff',
  staffListBriefInfo = '/staff/list',
  gridConfigs = '/staff/gridSettings/{gridType}',
  saveGridConfig = '/staff/gridSettings',
}

export const api = axios.create({
  baseURL: REACT_APP_API_BASE_URL,
  withCredentials: REACT_APP_ENV !== 'local',
});

api.interceptors.request.use(
  config => {
    userAuth.refreshUserSession();

    const token = getItem('token');
    if (token && token.length > 10) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  error => Promise.reject(error),
);

api.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      removeItem('token');
      location.href = SIGNIN_URL;
    }

    return Promise.reject(error);
  },
);

export const getFakeSession = () => Promise.resolve(fakeSession);

export const getTimeTrackingBuckets = (projectId: string) =>
  api.get(urls.timetrackingBuckets + '?ProjectId=' + projectId);
export const getSalesforce = (projectId?: string) =>
  api.get(`${urls.salesforce}${projectId ? '?projectId=' + projectId : ''}`);
export const getSalesforceByIdList = (query: string) => api.get(urls.salesforce + query);
export const addSalesforce = (data: IPlis) => api.post(urls.salesforce, data);
export const updateSalesforce = (data: IPlis) => api.put(urls.salesforce, data);
export const delSalesforce = (payload: string) => api.delete(urls.salesforce + '?' + payload);

export const getProjects = () => api.get(urls.projects);
export const getProjectsById = (id: string) => api.get(urls.projects + (id ? '/' + id : ''));
//export const getProjectsById = (id: string) => api.get(urls.projects + (id ? ('/' + id + '/header') : ''));
export const getProjectsByDSAId = (id: string) => api.get(urls.projects + (id ? '?dsa=' + id : ''));
export const getProjectsByIdHeader = (id: string) => api.get(urls.projects + (id ? '/' + id + '/header' : ''));
export const postProject = (data: IProjectDTO) => api.post(urls.projects, data);
export const putProject = (data: IProjectDTO) => api.put(urls.projects, data);
export const deleteProject = (id: string) => api.delete(`${urls.projects}/${id}`);
export const getProjectsWithSubs = () => api.get(`${urls.projects}?includeSubProjects=true`);
export const copyProject = (data: { projectId: number; numberOfCopies: number }) => api.post(urls.projectsCopy, data);
export const deleteStaffFromProjects = (staff: { id: number | string }) =>
  api.delete(`${urls.deleteProjectStaff}/${staff.id}`);
export const addStaffToProject = (staff: { staffId: number | string; staffRole: string; projectId: number | string }) =>
  api.post(`${urls.projects}/${staff.projectId}/staff`, staff);

export const getTimeTracking = () => api.get(urls.timeTracking);
export const getTimeTrackingById = (id: string) => api.get(urls.timeTracking + '/' + id);
export const getTimeTrackingByProjectId = (projectid: string) => api.get(urls.timeTracking + `?projectid=${projectid}`);
export const delTimeTracking = (id: string) => api.delete(urls.timeTracking + '/' + id);
export const addTimeTracking = (data: ITimeTrackingDTO) => api.post(urls.timeTracking, data);
export const updateTimeTracking = (data: ITimeTrackingPutDTO) => api.put(urls.timeTracking, data);

export const dataGridLazy = (url: string, data: IDataGridLazy, param?: string) =>
  api.post(param ? url + `?${param}` : url, data);

export const getStaffs = (data: { isProjectManager: boolean }) =>
  api.get(`${urls.staff}${data.isProjectManager ? `?isProjectManager=${data.isProjectManager}` : ''}`);

export const getStaffListBriefInfo = () => api.get(`${urls.staffListBriefInfo}`);

export const deleteStaff = (id: string) => api.delete(urls.staff + '/' + id);
export const getStaff = (id: number | string) => api.get(urls.staff + '/' + id);
export const addStaff = (newStaff: IStaff) => api.post(urls.staff, newStaff);
export const updateStaff = (updatedStaff: IStaff) => api.put(urls.staff, updatedStaff);

export const getDSA = (dsaid: number) => api.get(`${urls.dsas}/${dsaid}/relateddsas`);
export const putDSA = (dsa: IDSA) => api.put(urls.dsas, dsa);
export const getDSAById = (dsaid: number) => api.get<IDSAById>(`${urls.dsas}/${dsaid}`);

export const getEvent = (eventId: string, platform: string) =>
  api.get<IEvent>(urls.events + '/' + eventId + `?platform=${platform}`);
export const getEvents = () => api.get(urls.events);
export const getEventsByProjectId = (projectId: string) => api.get(urls.events + `?projectid=${projectId}`);
export const getEventsById = (query: string) => api.get(urls.events + query);
export const getPrimaryEventDetails = (projectId: string) => api.get(`${urls.eventsPrimary}/${projectId}`);
export const postEvents = (event: IEvent) => api.post(urls.events, event);
export const putEvent = (projectId: number | null, eventId: number) =>
  api.put(urls.updateEvent.replace('{eventId}', eventId.toString()), { projectId });
export const putPrimaryEvent = (event: {
  eventId: number;
  platformId: string;
  projectId: number;
  isPrimary: boolean;
}) => api.put(urls.eventsPrimary, event);
export const putEventFulfill = (completeEventData: ICompleteEeventDetails) =>
  api.put(urls.eventsMarkFulfilled, completeEventData);
export const deleteEvent = (eventId: number, platform: string) =>
  api.delete(`${urls.events}/${eventId}` + `?platform=${platform}`);
export const removeEventsFromProject = (payload: string) => api.delete(urls.events + '?' + payload);

export const getAccounts = () => api.get(urls.accounts);
export const getAccount = (id: string, platform: string) => api.get(urls.accounts + '/' + id + `?platform=${platform}`);

export const addAccountsToProject = (projectId: number, payload: string) =>
  api.put(`${urls.projects}/${projectId}/accounts`, { accountIds: payload });

export const deleteLogout = () => api.delete(urls.logout);

export const generateReport = (reportGenerationData: IReportGeneration) => api.post(urls.reports, reportGenerationData);
export const getReports = () => api.get(urls.reports);

export const getGridConfigs = (gridType: number) =>
  api.get(urls.gridConfigs.replace('{gridType}', gridType.toString()));

export const saveGridConfig = (gridConfig: IGridConfiguration) => api.put(urls.saveGridConfig, gridConfig);
export const searchAccounts = (searchTerm: string, platform: string) =>
  api.get(`${urls.accounts}?searchTerm=${searchTerm}${platform ? `&platform=${platform}` : ''}`);

export default {
  urls,
  getFakeSession,
  getEvents,
  getEventsById,
  getEventsByProjectId,
  getEvent,
  getPrimaryEventDetails,
  putPrimaryEvent,
  putEventFulfill,
  getStaff,
  putEvent,
  deleteEvent,
  postEvents,
  deleteLogout,
  deleteStaff,
  getStaffs,
  getTimeTrackingBuckets,
  getSalesforce,
  addSalesforce,
  getSalesforceByIdList,
  updateSalesforce,
  dataGridLazy,
  delSalesforce,
  addStaff,
  updateStaff,
  getProjects,
  getProjectsById,
  getProjectsByDSAId,
  putProject,
  postProject,
  getTimeTracking,
  getTimeTrackingById,
  getTimeTrackingByProjectId,
  delTimeTracking,
  addTimeTracking,
  updateTimeTracking,
  getAccounts,
  getAccount,
  addAccountsToProject,
  getDSA,
  putDSA,
  getDSAById,
  getProjectsByIdHeader,
  deleteProject,
  getProjectsWithSubs,
  copyProject,
  generateReport,
  getReports,
  deleteStaffFromProjects,
  addStaffToProject,
  removeEventsFromProject,
  getStaffListBriefInfo,
  getGridConfigs,
  saveGridConfig,
  searchAccounts,
};
