import React, { useState } from 'react';
import styles from './TeamworkNavBar.module.scss';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { Link } from 'react-router-dom';

// NOTE: was taken from private package '@intrado/unison-navbar'
interface INavElement {
  /**
   * Unique ID for this NavElement. This ID will be passed back to handler functions if applicable when the
   * element is selected by the user.
   */
  id: string;
  /**
   * Title of the navigation element which will display on the UI. Keep concise!
   */
  title: string;
  /**
   * The route the navbar should write to the browser address bar when the user clicks the navigation
   * item.
   */
  route: string;
  /**
   * Sub navigation items, if applicable. If supplied the navigation control will display nested items
   * below this item.
   */
  subNavElements?: Array<INavElement>;
  /**
   * Indicates if the link should be opened in a new tab.
   */
  openInNewTab?: boolean;
  /**
   * The string name of the icon to show for this item, if the navigation type supports icons.
   * Possible values can be found at {@link https://confluence.ops1.clb.wdc.west.com/display/UNISON/Navbar+JavaScript+API#NavbarJavaScriptAPI-Icons}
   */
  icon?: string;
  /**
   * Indicates if a horizontal divider should be displayed below this element.
   */
  hasDivider?: boolean;
}

// NOTE: these icons were taken from private package 'unison-icons'
const logoIcon = (
  <svg width="35px" height="37px" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 39.19 41.2">
    <path
      fill="#93D500"
      className="st0"
      d="M18.16,11.34c0.88,2.26,0.67,4.68-1.27,7.06c2.61,1.64,3.57,3.86,3.47,6.28c6.42,1,15.78-5.28,18.84-9.95
	C34.8,11.3,23.92,8.34,18.16,11.34z"
    />
    <path
      fill="#B31983"
      className="st1"
      d="M14.87,20.05c-2.72,1.44-5.13,1.16-7.18-0.14c-4.07,5.06-3.32,16.31-0.8,21.29
	c5.18-2.08,13.18-10.02,13.46-16.51C17.96,24.32,15.97,22.93,14.87,20.05z"
    />
    <path
      fill="#00C1DE"
      className="st2"
      d="M14.45,17.48c0.11-3.07,1.56-5.02,3.71-6.14C15.82,5.28,5.7,0.32,0.13,0C-0.66,5.52,2.22,16.42,7.7,19.91
	C9.22,18.02,11.42,16.99,14.45,17.48z"
    />
    <path
      fill="#58C3BE"
      className="st3"
      d="M18.16,11.34c-2.15,1.12-3.6,3.07-3.71,6.14c0.86,0.14,1.7,0.46,2.44,0.92C18.83,16.02,19.04,13.6,18.16,11.34z
	"
    />
    <path
      fill="#B46B8D"
      className="st4"
      d="M14.87,20.05L14.87,20.05c1.09,2.88,3.08,4.26,5.48,4.64c0.11-2.43-0.86-4.65-3.47-6.28
	C16.34,19.08,15.64,19.65,14.87,20.05L14.87,20.05z"
    />
    <path
      fill="#00C1DE"
      className="st2"
      d="M16.89,18.4L16.89,18.4c-0.74-0.46-1.58-0.78-2.44-0.92v0C15.31,17.62,16.15,17.94,16.89,18.4z"
    />
    <path
      fill="#70AACB"
      className="st5"
      d="M14.87,20.05c0.77-0.41,1.46-0.97,2.01-1.65c-0.74-0.46-1.58-0.78-2.44-0.92l0,0
	c-3.04-0.49-5.23,0.54-6.75,2.43C9.75,21.22,12.15,21.49,14.87,20.05C14.87,20.05,14.87,20.05,14.87,20.05L14.87,20.05z"
    />
  </svg>
);

const profileIcon = (
  <svg width="24px" height="24px" viewBox="0 0 24 24">
    <path
      data-name="Path 107"
      d="M12 0a11.994 11.994 0 00-5.556 22.628h.006c.328.172.663.33 1.008.471.034.014.069.025.1.038.316.126.635.243.963.343.081.025.165.041.246.063.287.081.575.159.869.219.143.028.291.043.436.067.241.039.481.083.726.108.261.026.528.031.794.04.136 0 .268.02.4.02s.252-.015.38-.02c.275-.009.549-.014.819-.041.237-.024.468-.068.7-.1.153-.025.309-.04.461-.071.285-.057.563-.133.842-.211.091-.026.184-.044.274-.071.317-.1.627-.211.932-.332.044-.018.091-.031.135-.049.336-.138.662-.293.982-.46l.032-.016A11.994 11.994 0 0011.991.001zm7.918 18.453a4.922 4.922 0 00-.3-.869 10.856 10.856 0 01-.661-1.138 11.079 11.079 0 00-2.632-1.232s-1.756-.789-1.913-1.139a2.717 2.717 0 01-.11-.972v-.08a6.925 6.925 0 00.877-2.1c.007 0 .113.042.217-.108a2.94 2.94 0 00.2-1.584s-.05-.484-.288 0a10.123 10.123 0 00.222-2.986 3.2 3.2 0 00-1.258-1.746c-.385-.1-.38-.412-.672-.568a4.179 4.179 0 00-.931-.112c-.052-.007-.038-.031 0-.06.079-.069.287-.174.118-.174-.5.065-1.725.3-2.228.325-.832.507-1.963 1.438-2.089 2.335a10.172 10.172 0 00.221 2.986c-.237-.484-.289 0-.289 0-.065 1.93.419 1.689.419 1.689a6.884 6.884 0 00.877 2.108 3.075 3.075 0 01-.108 1.052c-.154.35-1.91 1.139-1.91 1.139a11.067 11.067 0 00-2.635 1.232 11.167 11.167 0 01-.66 1.138 4.975 4.975 0 00-.3.87 10.223 10.223 0 1115.836 0z"
      fill="#e4eaec"
    ></path>
  </svg>
);

interface TeamworkNavBarProps {
  navElements: INavElement[];
}

export function TeamworkNavBar({ navElements }: TeamworkNavBarProps) {
  const [isNavMenuOpen, setIsNavMenuOpen] = useState(false);

  const toggleNavBarMenu = () => setIsNavMenuOpen(!isNavMenuOpen);

  const navigationMenu = navElements.map((item: INavElement) => {
    return (
      <NavItem key={item.id}>
        <Link to={item.route} className={'nav-link' + ' ' + styles.navigationMenuItem}>
          {item.title}
        </Link>
      </NavItem>
    );
  });

  return (
    <Navbar color="dark" dark expand="lg">
      <NavbarBrand href="/">{logoIcon} Teamwork</NavbarBrand>
      <NavbarToggler onClick={toggleNavBarMenu} />
      <Collapse isOpen={isNavMenuOpen} navbar>
        <Nav navbar className={styles.nav}>
          {navigationMenu}
          <UncontrolledDropdown nav inNavbar className={styles.profileDropdown}>
            <DropdownToggle nav>{profileIcon}</DropdownToggle>
            <DropdownMenu right>
              <DropdownItem className={styles.dropdownMenuOption}>
                <Link to={'/logout'} className={styles.dropdownMenuOptionLink}>
                  Logout
                </Link>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </Collapse>
    </Navbar>
  );
}

export default TeamworkNavBar;
