import createReducer from '../utils/redux-create-reducer';
import { FETCH_REPORTS, FETCH_REPORTS_FAILURE, FETCH_REPORTS_SUCCESS } from '../actions/reports';
import { IReport } from '../utils/api.d';

export interface IReportsState {
  isLoading?: boolean;
  reports: IReport[];
  error?: Error;
}

interface IState {
  reports: IReportsState;
}

export const initialState: IReportsState = {
  isLoading: false,
  reports: [],
  error: undefined,
};

export const getReports = (state: IState): IReportsState => state.reports;

export default createReducer<IReportsState>(initialState, {
  [FETCH_REPORTS]: () => ({ isLoading: true }),
  [FETCH_REPORTS_SUCCESS]: reports => ({ reports, isLoading: false }),
  [FETCH_REPORTS_FAILURE]: error => ({ error, isLoading: false }),
});
