// use 'npm run g:a {name}' - to generate new action
import { createEmptyAction, createAction } from '../utils/actions';

export const FETCH = 'ACCOUNTS/FETCH';
export const FETCH_SUCCESS = 'ACCOUNTS/FETCH_SUCCESS';
export const FETCH_FAILURE = 'ACCOUNTS/FETCH_FAILURE';

export const FETCH_ONE = 'ACCOUNTS/FETCH_ONE';
export const FETCH_ONE_SUCCESS = 'ACCOUNTS/FETCH_ONE_SUCCESS';
export const FETCH_ONE_FAILURE = 'ACCOUNTS/FETCH_ONE_FAILURE';

export const types = {
  FETCH,
  FETCH_SUCCESS,
  FETCH_FAILURE,
  FETCH_ONE,
  FETCH_ONE_SUCCESS,
  FETCH_ONE_FAILURE,
};

export const fetch = createEmptyAction(FETCH);
export const fetchSuccess = createAction(FETCH_SUCCESS);
export const fetchFailure = createAction<Error>(FETCH_FAILURE);

export const fetchOne = createEmptyAction(FETCH_ONE);
export const fetchOneSuccess = createAction(FETCH_ONE_SUCCESS);
export const fetchOneFailure = createAction<Error>(FETCH_ONE_FAILURE);

export default {
  fetch,
  fetchSuccess,
  fetchFailure,
};
