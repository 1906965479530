import { Dispatch } from 'redux';
import { push } from 'connected-react-router';
import { N3AEntitlements, Routes } from '../constants';

interface IHandlers {
  [key: string]: (navElementId: string) => void;
}

/**
 * @typedef {Object} MenuItem
 * @property {string} id - item id
 * @property {string} title - menu item title
 * @property {boolean} openInNewTab
 * @property {string[]} routes - routes list
 * @property {string} access - access instance
 */

/**
 * @name menu
 * @type {MenuItem[]}
 */
export const menu = [
  {
    id: 'dsalistview',
    title: 'DSAs',
    openInNewTab: false,
    routes: [Routes.DSAListView, Routes.Root, Routes.DSADetail],
    access: N3AEntitlements.Teamwork,
  },
  {
    id: 'projects',
    title: 'Projects',
    openInNewTab: false,
    routes: [Routes.Projects, Routes.AddNewProject, Routes.NewEditProject, Routes.AddNewProject],
    access: N3AEntitlements.Teamwork,
  },
  {
    id: 'events',
    title: 'Events',
    openInNewTab: false,
    routes: [Routes.Events, Routes.EventDetail],
    access: N3AEntitlements.Teamwork,
  },
  {
    id: 'timeTracking',
    title: 'Time Tracking',
    openInNewTab: false,
    routes: [Routes.TimeTracking, Routes.NewTimeTracking, Routes.EditTimeTracking],
    access: N3AEntitlements.Teamwork,
  },
  {
    id: 'accounts',
    title: 'Accounts',
    openInNewTab: false,
    routes: [Routes.Accounts, Routes.AccountsDetail],
    access: N3AEntitlements.Teamwork,
  },
  {
    id: 'staff',
    title: 'Staff',
    openInNewTab: false,
    routes: [Routes.Staff, Routes.NewStaff],
    access: N3AEntitlements.Admin,
  },
  {
    id: 'salesforce',
    title: 'Salesforce',
    openInNewTab: false,
    routes: [Routes.Salesforce, Routes.SalesforceToProject],
    access: N3AEntitlements.Admin,
  },
  {
    id: 'reports',
    title: 'Reports',
    openInNewTab: false,
    routes: [Routes.Reports],
    access: N3AEntitlements.Admin,
  },
];

/**
 * Navbar handlers generator
 * @function
 * @param handlers {function(IHandlers): function(string)}
 */
export const makeNavbarHandlers = (handlers: IHandlers) => (navElementId: string) => {
  // eslint-disable-next-line no-prototype-builtins
  if (handlers.hasOwnProperty(navElementId)) {
    handlers[navElementId](navElementId);
  }
};

/**
 * Create navbar handlers
 * @param dispatch {Dispatch} - action dispatcher
 */
export const createNavBarHandlers = (dispatch: Dispatch) =>
  makeNavbarHandlers({
    projects: () => dispatch(push(Routes.Projects)),
    events: () => dispatch(push(Routes.Events)),
    timeTracking: () => dispatch(push(Routes.TimeTracking)),
    accounts: () => dispatch(push(Routes.Accounts)),
    staff: () => dispatch(push(Routes.Staff)),
    salesforce: () => dispatch(push(Routes.Salesforce)),
    dsalistview: () => dispatch(push(Routes.DSAListView)),
    reports: () => dispatch(push(Routes.Reports)),
    logout: () => {
      dispatch(push(Routes.Logout));
    },
  });
