import { createAction, createEmptyAction } from '../utils/actions';

export const RELOAD = 'SESSION/RELOAD';
export const FETCH_SESSION = 'SESSION/FETCH';
export const SET_SESSION = 'SESSION/SET_SESSION';
export const FETCH_SESSION_FAILURE = 'SESSION/FETCH_FAILURE_OR_EXPIRED';
export const SET_USER_ACTIVITY = 'SESSION/SET_USER_ACTIVITY';

export const types = {
  FETCH_SESSION,
  SET_SESSION,
  FETCH_SESSION_FAILURE,
  SET_USER_ACTIVITY,
};

export const fetch = createEmptyAction(FETCH_SESSION);
export const reload = createEmptyAction(RELOAD);
export const setSession = createAction(SET_SESSION);
export const fetchFailure = createAction<Error>(FETCH_SESSION_FAILURE);

export default {
  fetch,
  reload,
  setSession,
  fetchFailure,
};
