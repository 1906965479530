import { FormShape } from '../utils/api.d';

export const reportsListGridColumns: FormShape = {
  id: { label: 'ID', type: 'text', filter: 'agTextColumnFilter' },
  reportUid: { label: 'UID', type: 'text', filter: 'agTextColumnFilter' },
  reportType: { label: 'Type', type: 'text', filter: 'agTextColumnFilter' },
  status: { label: 'Status', type: 'text', filter: 'agTextColumnFilter' },
  url: { label: 'Link', type: 'text', filter: 'agTextColumnFilter' },
  created: { label: 'Created', type: 'datetime-local', filter: 'agDateColumnFilter' },
};
