import { ColDef, ColGroupDef, ValueGetterParams } from 'ag-grid-community';

export default function(): (ColDef | ColGroupDef)[] {
  return [
    {
      headerName: 'Lastname',
      minWidth: 200,
      field: 'last_name',
    },
    {
      headerName: 'Firstname',
      minWidth: 200,
      field: 'first_name',
    },
    {
      headerName: 'Title',
      minWidth: 200,
      field: 'title',
    },
    {
      headerName: 'Email',
      minWidth: 200,
      field: 'email',
    },
    {
      headerName: 'Company',
      minWidth: 200,
      field: 'company',
    },
    {
      headerName: 'Region',
      minWidth: 200,
      field: 'region',
    },
    {
      headerName: 'Team',
      minWidth: 200,
      field: 'team',
    },
    {
      headerName: 'Active',
      field: 'isActive',
      minWidth: 200,
      valueGetter: (params: ValueGetterParams) => (params.data.isActive ? 'Yes' : 'No'),
    },
    {
      headerName: 'Manager',
      minWidth: 200,
      field: 'manager',
      valueGetter: (params: ValueGetterParams) =>
        params.data.manager ? params.data.manager.firstName + ' ' + params.data.manager.lastName : '',
    },
  ];
}
