import React, { useState, MouseEvent } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import styles from './GridViewConfig.module.scss';
import { IGridConfiguration } from '../../utils/api.d';

interface GridViewConfigProps {
  applyGridViewConfig: (gridViewConfig: IGridConfiguration) => void;
  saveGridViewConfig: (gridViewConfig: IGridConfiguration) => void;
  gridViewConfigList: IGridConfiguration[];
  emptyOptionTitle?: string;
}

export function GridViewConfig({
  applyGridViewConfig,
  saveGridViewConfig,
  gridViewConfigList,
  emptyOptionTitle,
}: GridViewConfigProps) {
  const DEFAULT_TITLE = 'Empty';
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownToggle = () => setDropdownOpen(prevState => !prevState);
  const saveGridConfig = (event: MouseEvent<HTMLElement>, gridViewConfig: IGridConfiguration) => {
    event.preventDefault();
    event.stopPropagation();
    saveGridViewConfig(gridViewConfig);
  };

  const applyConfig = (gridViewConfig: IGridConfiguration) => {
    !gridViewConfig.isConfigInvalid && gridViewConfig.title && applyGridViewConfig(gridViewConfig);
  };

  const dropdownItems =
    gridViewConfigList &&
    gridViewConfigList.length &&
    gridViewConfigList.map((item: IGridConfiguration) => {
      return (
        <DropdownItem
          key={item.optionIndex}
          onClick={() => applyConfig(item)}
          disabled={item.isConfigInvalid || !item.title}
          className={styles.gridConfigOption}
          tag={'div'}
        >
          <div className={styles.menuItemWrapper}>
            <div className={styles.infoContainer}>
              <strong>{item.title || emptyOptionTitle || DEFAULT_TITLE}</strong>
              {item.isConfigInvalid && (
                <div className={styles.projectDescription}>This grid view config is invalid</div>
              )}
            </div>
            <div className={styles.saveControl} onClick={e => saveGridConfig(e, item)}></div>
          </div>
        </DropdownItem>
      );
    });

  return (
    <>
      <Dropdown direction="left" isOpen={dropdownOpen} toggle={dropdownToggle}>
        <DropdownToggle className={styles.dropDownToggle} caret color="primary">
          Grid Config
        </DropdownToggle>
        <DropdownMenu>{dropdownItems}</DropdownMenu>
      </Dropdown>
    </>
  );
}

export default GridViewConfig;
