import { createEmptyAction, createAction } from '../utils/actions';

export const GET_REPORT_URL = 'GET_REPORT_URL';
export const getReportUrl = createEmptyAction(GET_REPORT_URL);

export const GET_REPORT_URL_SUCCESS = 'GET_REPORT_URL_SUCCESS';
export const getReportUrlSuccess = createAction(GET_REPORT_URL_SUCCESS);

export const GET_REPORT_URL_FAILURE = 'GET_REPORT_URL_FAILURE';
export const getReportUrlFailure = createAction(GET_REPORT_URL_FAILURE);
