import { call, take, put } from 'redux-saga/effects';
import { callApi } from '../utils/helpers';
import {
  FETCH,
  fetchSuccess,
  fetchFailure,
  ADD_STAFF,
  addStaffSuccess,
  updateStaffSuccess,
  updateStaffFailure,
  UPDATE_STAFF,
  FETCH_ONE,
  fetchOneSuccess,
  fetchOneFailure,
  addStaffFailure,
  DELETE_STAFF,
  deleteStaffSuccess,
  deleteStaffFailure,
  FETCH_STAFF_LIST_BRIEF_INFO,
  fetchStaffListBriefInfoSuccess,
  fetchStaffListBriefInfoFailure,
} from '../actions/staffs';
import api from '../utils/api';
import { Routes } from '../constants';
import { push } from 'connected-react-router';

export function* staffs() {
  while (true) {
    const { payload } = yield take(FETCH);

    try {
      const response = yield call(callApi, api.getStaffs, payload);

      const sortedStaffByName = response.data.data.sort(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (a: any, b: any) => a.first_name.localeCompare(b.first_name) || a.last_name.localeCompare(b.last_name),
      );
      yield put(fetchSuccess(sortedStaffByName));
    } catch (e) {
      console.error(e);
      yield put(fetchFailure(e));
    }
  }
}

export function* staff() {
  while (true) {
    yield take(FETCH_ONE);
    const params = new URLSearchParams(location.search);
    const id = params.get('id');
    if (id) {
      try {
        const response = yield call(callApi, api.getStaff, id);
        yield put(fetchOneSuccess(response.data));
      } catch (e) {
        console.error(e);
        yield put(fetchOneFailure(e));
      }
    } else {
      yield put(fetchOneFailure(null));
    }
  }
}

export function* updateStaff() {
  while (true) {
    const action = yield take(UPDATE_STAFF);
    try {
      yield call(callApi, api.updateStaff, action.payload);
      yield put(updateStaffSuccess());
      yield put(push(Routes.Staff));
    } catch (e) {
      console.error(e);
      yield put(updateStaffFailure(e));
    }
  }
}

export function* addStaff() {
  while (true) {
    const action = yield take(ADD_STAFF);
    try {
      yield call(callApi, api.addStaff, action.payload);
      yield put(addStaffSuccess());
      yield put(push(Routes.Staff));
    } catch (e) {
      console.error(e);
      yield put(addStaffFailure(e));
    }
  }
}

export function* deleteStaff() {
  while (true) {
    const action = yield take(DELETE_STAFF);
    try {
      yield call(callApi, api.deleteStaff, action.payload);
      yield put(deleteStaffSuccess());
      yield put(push(Routes.Staff));
    } catch (e) {
      console.error(e);
      yield put(deleteStaffFailure(e));
    }
  }
}

export function* staffsBriefInfo() {
  while (true) {
    yield take(FETCH_STAFF_LIST_BRIEF_INFO);

    try {
      const response = yield call(callApi, api.getStaffListBriefInfo);

      const sortedStaffByName = response.data.sort(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (a: any, b: any) => a.firstName.localeCompare(b.firstName) || a.lastName.localeCompare(b.lastName),
      );
      yield put(fetchStaffListBriefInfoSuccess(sortedStaffByName));
    } catch (e) {
      console.error(e);
      yield put(fetchStaffListBriefInfoFailure(e));
    }
  }
}
