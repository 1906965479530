// use 'npm run g:a {name}' - to generate new action
import { createEmptyAction, createAction } from '../utils/actions';
import { IDSAById } from '../utils/api.d';

export const FETCH = 'DSAS/FETCH';
export const FETCH_SUCCESS = 'DSAS/FETCH_SUCCESS';
export const FETCH_FAILURE = 'DSAS/FETCH_FAILURE';

export const FETCH_BY_ID = 'DSAS/FETCH_BY_ID';
export const FETCH_BY_ID_SUCCESS = 'DSAS/FETCH_BY_ID_SUCCESS';
export const FETCH_BY_ID_FAILURE = 'DSAS/FETCH_BY_ID_FAILURE';

export const SAVE = 'DSAS/SAVE';
export const SAVE_SUCCESS = 'DSAS/SAVE_SUCCESS';
export const SAVE_FAILURE = 'DSAS/SAVE_FAILURE';

export const FETCH_RSADSA = 'DSAS/FETCH_RSADSA';
export const FETCH_RSADSA_SUCCESS = 'DSAS/FETCH_RSADSA_SUCCESS';
export const FETCH_RSADSA_FAILURE = 'DSAS/FETCH_RSADSA_FAILURE';

export const types = {
  FETCH,
  FETCH_SUCCESS,
  FETCH_FAILURE,
  SAVE,
  SAVE_SUCCESS,
  SAVE_FAILURE,
  FETCH_RSADSA,
  FETCH_RSADSA_SUCCESS,
  FETCH_RSADSA_FAILURE,
};

export const fetch = createEmptyAction(FETCH);
export const fetchSuccess = createAction(FETCH_SUCCESS);
export const fetchFailure = createAction<Error>(FETCH_FAILURE);

export const fetchById = createAction(FETCH_BY_ID);
export const fetchByIdSuccess = createAction(FETCH_BY_ID_SUCCESS);
export const fetchByIdFailure = createAction<Error>(FETCH_BY_ID_FAILURE);

export const save = createAction<IDSAById>(SAVE);
export const saveSuccess = createAction(SAVE_SUCCESS);
export const saveFailure = createAction<Error>(SAVE_FAILURE);

export const fetchRSADSA = createAction(FETCH_RSADSA);
export const fetchRSADSASuccess = createAction(FETCH_RSADSA_SUCCESS);
export const fetchRSADSAFailure = createAction<Error>(FETCH_RSADSA_FAILURE);

export default {
  fetch,
  fetchSuccess,
  fetchFailure,
};
