import { Store } from 'redux';
import actions from '../actions';
import pack from '../../package.json';
import { getItem, setItem } from './browserAPI';

export const getModeStatus = () => Boolean(Number(getItem('isAuthDisabled')));

export const enableAuth = () => {
  setItem('isAuthDisabled', '0');
  return getModeStatus();
};

export const disableAuth = () => {
  setItem('isAuthDisabled', '1');
  return getModeStatus();
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const actionsDispatchers = (store: any) =>
  Object.keys(actions).reduce((actionsList, actFileName) => {
    const methodsList = Object.keys(actions[actFileName]).reduce((methods, methodName) => {
      const method = actions[actFileName][methodName];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return { ...methods, [methodName]: (payload?: any) => store.dispatch(method(payload)) };
    }, {});

    return { ...actionsList, [actFileName]: methodsList };
  }, {});

export default (store: Store, isDevelopment = false) => {
  const unisonProd = {
    versions: {
      weconvene: pack.version,
    },
  };
  const unisonDev = {
    getModeStatus,
    enableAuth,
    disableAuth,
    actions: actionsDispatchers(store),
  };

  const unison = {
    ...unisonProd,
    ...(isDevelopment ? unisonDev : {}),
  };

  if (isDevelopment) {
    Object.defineProperty(unison, 'state', {
      get: () => store.getState(),
    });

    Object.defineProperty(unison, 'dispatch', {
      get: () => store.dispatch,
    });
  }

  Object.defineProperty(window, 'unison', {
    get: () => unison,
  });
};
