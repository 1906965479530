import { call, take, put } from 'redux-saga/effects';
import {
  FETCH_BY_ID,
  SAVE,
  fetchByIdFailure,
  fetchByIdSuccess,
  FETCH_RSADSA,
  fetchRSADSASuccess,
  fetchRSADSAFailure,
  saveSuccess,
  saveFailure,
} from '../actions/dsas';
import { fetchByDSAIdSuccess, fetchByDSAIdFailure } from '../actions/projects';
import api from '../utils/api';
import { AxiosResponse } from 'axios';
import { IDSAById, IDSAResponse, TSaga } from '../utils/api.d';
import { IAction } from '../utils/redux-create-reducer';
import { notify } from '../actions/notification';

export default function* getRsaDsas(): TSaga<IAction & AxiosResponse<IDSAResponse>> {
  while (true) {
    const { payload } = yield take(FETCH_RSADSA);
    try {
      const response = yield call(api.getDSA, payload);
      yield put(fetchRSADSASuccess(response.data));
    } catch (e) {
      console.error(e);
      yield put(fetchRSADSAFailure(e));
    }
  }
}

export function* getDSAById(): TSaga<IAction & AxiosResponse<IDSAById>> {
  while (true) {
    const { payload } = yield take(FETCH_BY_ID);
    try {
      try {
        const projectsResponse = yield call(api.getProjectsByDSAId, payload);
        yield put(fetchByDSAIdSuccess(projectsResponse.data));
      } catch (e) {
        console.error(e);
        yield put(fetchByDSAIdFailure(e));
      }

      const response = yield call(api.getDSAById, payload);
      yield put(fetchByIdSuccess(response.data));
    } catch (e) {
      console.error(e);
      yield put(fetchByIdFailure(e));
    }
  }
}

export function* putDSA() {
  while (true) {
    const dsa = yield take(SAVE);
    try {
      const response = yield call(api.putDSA, dsa.payload);
      yield put(saveSuccess(response.data));
      yield put(notify('Your changes have been saved.', 'success'));
    } catch (e) {
      console.error(e);
      yield put(saveFailure(e));
      yield put(notify("We're sorry, there was a problem saving this DSA, please try again.", 'danger'));
    }
  }
}
