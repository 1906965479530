// use 'npm run g:r {name}' - to generate new reducer and action (use --skip if action not needed)
import createReducer from '../utils/redux-create-reducer';
import {
  FETCH,
  FETCH_SUCCESS,
  FETCH_FAILURE,
  FETCH_BY_ID,
  FETCH_BY_ID_SUCCESS,
  FETCH_BY_ID_FAILURE,
  FETCH_RSADSA,
  FETCH_RSADSA_SUCCESS,
  FETCH_RSADSA_FAILURE,
} from '../actions/dsas';
import { IDSAById, IRSADSAItemData } from '../utils/api.d';

export interface IDSAReduxState {
  isLoading?: boolean;
  list: IDSAById[];
  currentDSAById?: IDSAById;
  rsaDsa: IRSADSAItemData[];
  error?: Error;
}

interface IState {
  dsas: IDSAReduxState;
}

export const initialState: IDSAReduxState = {
  isLoading: false,
  list: [],
  currentDSAById: undefined,
  error: undefined,
  rsaDsa: [],
};

export const getRsaDsas = (state: IState): IDSAReduxState => state.dsas;
export const getDSAReduxState = (state: IState): IDSAReduxState => state.dsas;
export const getAccountLongId = (state: IState): string | undefined =>
  state.dsas.currentDSAById ? state.dsas.currentDSAById.accountLongId : '';

export default createReducer<IDSAReduxState>(initialState, {
  [FETCH]: () => ({ isLoading: true }),
  [FETCH_SUCCESS]: list => ({ list, isLoading: false }),
  [FETCH_FAILURE]: error => ({ error, isLoading: false }),

  [FETCH_BY_ID]: () => ({ ...initialState, isLoading: true }),
  [FETCH_BY_ID_SUCCESS]: currentDSAById => ({ currentDSAById, isLoading: false }),
  [FETCH_BY_ID_FAILURE]: error => ({ error, isLoading: false }),

  [FETCH_RSADSA]: () => ({ isLoading: true }),
  [FETCH_RSADSA_SUCCESS]: rsaDsa => ({ rsaDsa, isLoading: false }),
  [FETCH_RSADSA_FAILURE]: error => ({ error, isLoading: false }),
});
