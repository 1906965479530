import React, { useState } from 'react';
import cx from 'classnames';
import styles from './Reports.module.scss';
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import { checkForSavedGrid, getDateNoOffset, gridStateSaver, resetAllGridFilters } from '../../utils/helpers';
import AjaxSpinner from '../../components/AjaxSpinner';
import {
  reportsType,
  filterTypes,
  projectTimeTrackingFilterTypes,
  showDateRangeFilter,
  showPlatformFilter,
  showEventTypeFilter,
  defaultOptions,
} from './constants';
import { AgGridEvent, ColumnApi, GridApi } from 'ag-grid-community';
import { getReports } from '../../reducers/reports';
import { generateReport } from '../../reducers/reportGeneration';
import { getUser } from '../../reducers/user';
import getColumns from './columns';
import makeGridOptions from '../../gridOptions';
import { AppGrids, eventType as eventTypeEnum, platformType as platformTypeEnum } from '../../enums';
import { Grid } from '../../components/Grid';
import { FETCH_REPORTS, GENERATE_REPORT_AND_REFRESH } from '../../actions/reports';
import { IReportGeneration } from '../../utils/api.d';

const Reports = () => {
  const dispatch = useDispatch();
  const [filterStartDate, setFilterStartDate] = useState(new Date());
  const [filterEndDate, setFilterEndDate] = useState(new Date());
  const [selectedReport, setSelectedReport] = useState(reportsType.pliReport.value);
  const [selectedPlatform, setSelectedPlatform] = useState('');
  const [selectedEventType, setSelectedEventType] = useState('');
  const pliReport = reportsType.pliReport.value;
  const projectsInfo = reportsType.projectsInfo.value;
  const projectTimeTracking = reportsType.projectTimeTracking.value;
  const [dropdownButtonOpen, setDropdownButtonOpen] = useState(false);
  const dropdownButtonToggle = () => setDropdownButtonOpen(prevState => !prevState);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [gridColumnApi, setColumnApi] = useState<ColumnApi | null>(null);
  const { emailAddress } = useSelector(getUser, shallowEqual) || {};
  const reportGeneration = useSelector(generateReport, shallowEqual);
  const reportsList = useSelector(getReports, shallowEqual);

  const columnDefs = getColumns();
  const onGridReady = (event: AgGridEvent) => {
    setGridApi(event.api);
    setColumnApi(event.columnApi);
  };

  const gridOptions = makeGridOptions(columnDefs, { onGridReady, pagination: true, paginationPageSize: 10 });
  const gridName = `${emailAddress}-${AppGrids.ReportsList}`;
  const gridConfigName = `${emailAddress}-${AppGrids.ReportsList}-config`;

  const saveState = () => gridColumnApi && gridStateSaver(gridColumnApi, gridName);

  if (gridColumnApi) {
    checkForSavedGrid(gridColumnApi, gridName, gridConfigName, columnDefs);
    gridStateSaver(gridColumnApi, gridName);
  }

  const proceedReportGeneration = (filterType: string | null = null) => {
    const payload: IReportGeneration = { reportType: selectedReport };

    if (showDateRangeFilter.includes(selectedReport)) {
      payload.start = getDateNoOffset(filterStartDate);
      payload.end = getDateNoOffset(filterEndDate);
    }

    if (filterType) {
      payload.reportFilterType = filterType;
    }

    if (showPlatformFilter.includes(selectedReport) && selectedPlatform) {
      payload.platform = selectedPlatform;
    }

    if (showEventTypeFilter.includes(selectedReport) && selectedEventType) {
      payload.eventType = selectedEventType;
    }

    dispatch({ type: GENERATE_REPORT_AND_REFRESH, payload });
  };

  const getReportsList = () => dispatch({ type: FETCH_REPORTS });

  const startDate = (
    <div className="col-sm-2">
      <DatePicker
        placeholderText={'Start Date'}
        maxDate={filterEndDate}
        onChange={(date: Date) => setFilterStartDate(date)}
        selected={filterStartDate}
        wrapperClassName="form-control"
        className="form-control"
        name="startDate"
        required={selectedReport !== pliReport}
        disabled={selectedReport === pliReport}
      />
    </div>
  );

  const endDate = (
    <div className="col-sm-2">
      <DatePicker
        placeholderText={'End Date'}
        minDate={filterStartDate}
        onChange={(date: Date) => setFilterEndDate(date)}
        selected={filterEndDate}
        wrapperClassName="form-control"
        className="form-control"
        name="endDate"
        required={selectedReport !== pliReport}
        disabled={selectedReport === pliReport}
      />
    </div>
  );

  const dateFilter = (
    <>
      {startDate}
      {endDate}
    </>
  );

  const dropDownMenu = (
    <DropdownMenu>
      {filterTypes.map(filterType => (
        <DropdownItem key={filterType.id} onClick={() => proceedReportGeneration(filterType.id)}>
          <strong>{filterType.name}</strong>
          <div className="project-description">{filterType.desc}</div>
        </DropdownItem>
      ))}
    </DropdownMenu>
  );

  const projectTimeTrackingMenu = (
    <DropdownMenu>
      {projectTimeTrackingFilterTypes.map(filterType => (
        <DropdownItem key={filterType.id} onClick={() => proceedReportGeneration(filterType.id)}>
          <strong>{filterType.name}</strong>
          <div className="project-description">{filterType.desc}</div>
        </DropdownItem>
      ))}
    </DropdownMenu>
  );

  const regularButton = (
    <Button
      color="primary"
      className={styles.reportButton}
      onClick={() => proceedReportGeneration(null)}
      disabled={selectedReport !== pliReport && (!filterStartDate || !filterEndDate)}
    >
      Generate Report
    </Button>
  );
  const dropdownButton = (
    <Dropdown isOpen={dropdownButtonOpen} toggle={dropdownButtonToggle} direction="down">
      <DropdownToggle
        color="primary"
        className={styles.reportButton}
        disabled={selectedReport !== pliReport && (!filterStartDate || !filterEndDate)}
        caret
      >
        Generate Report &nbsp;
      </DropdownToggle>
      {selectedReport === projectTimeTracking ? projectTimeTrackingMenu : dropDownMenu}
    </Dropdown>
  );

  const platformFilter = (
    <div className="col-sm-2">
      <select className="form-control" name="platform" onChange={event => setSelectedPlatform(event.target.value)}>
        <option value={''} key={defaultOptions.platform}>
          {defaultOptions.platform}
        </option>

        {Object.values(platformTypeEnum).map(platform => {
          return (
            <option value={platform.value} key={platform.id}>
              {platform.name}
            </option>
          );
        })}
      </select>
    </div>
  );

  const eventTypeFilter = (
    <div className="col-sm-2">
      <select className="form-control" name="eventType" onChange={event => setSelectedEventType(event.target.value)}>
        <option value={''} key={defaultOptions.eventType}>
          {defaultOptions.eventType}
        </option>

        {Object.values(eventTypeEnum).map(eventType => {
          return (
            <option value={eventType.value} key={eventType.id}>
              {eventType.name}
            </option>
          );
        })}
      </select>
    </div>
  );

  const resetAllFilters = () => resetAllGridFilters(gridApi);

  return (
    <>
      {((reportGeneration && reportGeneration.isLoading) || (reportsList && reportsList.isLoading)) && <AjaxSpinner />}
      <div className="float-left page-title">Reports</div>
      <div className={cx(styles.cardWrapper, 'card')}>
        <div className={'form-group row'}>
          <div className="col-sm-2">
            <select
              className="form-control"
              name="reportType"
              required={true}
              defaultValue={selectedReport}
              onChange={event => setSelectedReport(event.target.value)}
            >
              {Object.values(reportsType).map(report => {
                return (
                  <option value={report.value} key={report.id}>
                    {report.name}
                  </option>
                );
              })}
            </select>
          </div>
          {showDateRangeFilter.includes(selectedReport) ? dateFilter : null}
          {showPlatformFilter.includes(selectedReport) ? platformFilter : null}
          {showEventTypeFilter.includes(selectedReport) ? eventTypeFilter : null}

          <div className="col-sm-2">
            {selectedReport === projectsInfo || selectedReport === projectTimeTracking ? dropdownButton : regularButton}
          </div>
        </div>

        <div>
          <Button className={'float-right margin-left-10'} color="primary" onClick={() => getReportsList()}>
            Reload Reports
          </Button>
          <Button className={'float-right'} color="primary" onClick={resetAllFilters}>
            Reset Filters
          </Button>
        </div>

        <Grid
          rowData={Array.isArray(reportsList.reports) ? reportsList.reports : []}
          gridOptions={gridOptions}
          onColumnMoved={saveState}
          onColumnVisible={saveState}
          onColumnResized={saveState}
        />
      </div>
    </>
  );
};

export default Reports;
