import {
  getTodayDate,
  getTomorrowDate,
  getYesterdayDate,
  getThisWeekRange,
  getNextWeekRange,
  getThisMonthRange,
  getLastMonthRange,
} from './helpers';

export const today = {
  displayKey: 'today',
  displayName: 'Today',
  test: () => true, // NOTE: used for client side model filtering
  hideFilterInput: true,
};

export const yesterday = {
  displayKey: 'yesterday',
  displayName: 'Yesterday',
  test: () => true, // NOTE: used for client side model filtering
  hideFilterInput: true,
};

export const tomorrow = {
  displayKey: 'tomorrow',
  displayName: 'Tomorrow',
  test: () => true, // NOTE: used for client side model filtering
  hideFilterInput: true,
};

export const thisWeek = {
  displayKey: 'thisWeek',
  displayName: 'This Week',
  test: () => true, // NOTE: used for client side model filtering
  hideFilterInput: true,
};

export const nextWeek = {
  displayKey: 'nextWeek',
  displayName: 'Next Week',
  test: () => true, // NOTE: used for client side model filtering
  hideFilterInput: true,
};

export const thisMonth = {
  displayKey: 'thisMonth',
  displayName: 'This Month',
  test: () => true, // NOTE: used for client side model filtering
  hideFilterInput: true,
};

export const lastMonth = {
  displayKey: 'lastMonth',
  displayName: 'Last Month',
  test: () => true, // NOTE: used for client side model filtering
  hideFilterInput: true,
};

function filterForTodayDate() {
  const type = 'equals';
  const dateFrom = getTodayDate();

  return { type, dateFrom };
}
function filterForTomorrowDate() {
  const type = 'equals';
  const dateFrom = getTomorrowDate();

  return { type, dateFrom };
}
function filterForYesterdayDate() {
  const type = 'equals';
  const dateFrom = getYesterdayDate();

  return { type, dateFrom };
}
function filterForThisWeekDateRange() {
  const type = 'inRange';
  const range = getThisWeekRange();

  return {
    type,
    dateFrom: range.startOfThisWeek,
    dateTo: range.endOfThisWeek,
  };
}
function filterForNextWeekDateRange() {
  const type = 'inRange';
  const range = getNextWeekRange();

  return {
    type,
    dateFrom: range.startOfNextWeek,
    dateTo: range.endOfNextWeek,
  };
}
function filterForThisMonthDateRange() {
  const type = 'inRange';
  const range = getThisMonthRange();

  return {
    type,
    dateFrom: range.startOfThisMonth,
    dateTo: range.endOfThisMonth,
  };
}
function filterForLastMonthDateRange() {
  const type = 'inRange';
  const range = getLastMonthRange();

  return {
    type,
    dateFrom: range.startOfLastMonth,
    dateTo: range.endOfLastMonth,
  };
}

export const customFilterOptionsMap = {
  [today.displayKey]: filterForTodayDate,
  [tomorrow.displayKey]: filterForTomorrowDate,
  [yesterday.displayKey]: filterForYesterdayDate,
  [thisWeek.displayKey]: filterForThisWeekDateRange,
  [nextWeek.displayKey]: filterForNextWeekDateRange,
  [thisMonth.displayKey]: filterForThisMonthDateRange,
  [lastMonth.displayKey]: filterForLastMonthDateRange,
};
