import { ColDef, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { createMapFromObjectOfObjects, transformToColumns } from '../../utils/helpers';
import {
  mmcTypes as mmcTypesEnum,
  mmcProducts as mmcProductsEnum,
  platformType as platformTypeEnum,
  studioProduct as studioProductEnum,
  studioServiceTypes as studioServiceTypesEnum,
  usageType as usageTypeEnum,
} from '../../enums';
import { accountsListGridColumns } from '../../tablesFormShapes/accountsList';

const platformTypesMap = createMapFromObjectOfObjects(platformTypeEnum, 'id', 'name');
const accountUsageTypesMap = createMapFromObjectOfObjects(usageTypeEnum, 'id', 'name');
const mmcTypesMap = createMapFromObjectOfObjects(mmcTypesEnum, 'id', 'name');
const mmcProductsMap = createMapFromObjectOfObjects(mmcProductsEnum, 'id', 'name');
const studioServiceTypesMap = createMapFromObjectOfObjects(studioServiceTypesEnum, 'id', 'name');
const studioProductsMap = createMapFromObjectOfObjects(studioProductEnum, 'id', 'name');
const booleanMap = {
  0: 'False',
  1: 'True',
};
export default function(): ColDef[] {
  return transformToColumns(
    accountsListGridColumns,
    {
      platform: {
        filterParams: {
          suppressSorting: true,
          debounceMs: 1000,
          values: Object.keys(platformTypesMap),
          valueFormatter: (params: ValueFormatterParams) => platformTypesMap[params.value] || params.value,
        },
        valueGetter: (params: ValueGetterParams) => platformTypesMap[params.data.platform] || params.data.platform,
      },
      mmcIsFolder: {
        filterParams: {
          suppressSorting: true,
          debounceMs: 1000,
          values: Object.keys(booleanMap),
          valueFormatter: (params: ValueFormatterParams) => booleanMap[params.value] || params.value,
        },
      },
      active: {
        filterParams: {
          suppressSorting: true,
          debounceMs: 1000,
          values: Object.keys(booleanMap),
          valueFormatter: (params: ValueFormatterParams) => booleanMap[params.value] || params.value,
        },
      },
      usageType: {
        filterParams: {
          suppressSorting: true,
          debounceMs: 1000,
          values: Object.keys(accountUsageTypesMap),
          valueFormatter: (params: ValueFormatterParams) => accountUsageTypesMap[params.value] || params.value,
        },
        valueGetter: (params: ValueGetterParams) =>
          accountUsageTypesMap[params.data.usageType] || params.data.usageType,
      },
      mmcType: {
        filterParams: {
          suppressSorting: true,
          debounceMs: 1000,
          values: Object.keys(mmcTypesMap),
          valueFormatter: (params: ValueFormatterParams) => mmcTypesMap[params.value] || params.value,
        },
        valueGetter: (params: ValueGetterParams) => mmcTypesMap[params.data.mmcType] || params.data.mmcType,
      },
      mmcProduct: {
        filterParams: {
          suppressSorting: true,
          debounceMs: 1000,
          values: Object.keys(mmcProductsMap),
          valueFormatter: (params: ValueFormatterParams) => mmcProductsMap[params.value] || params.value,
        },
        valueGetter: (params: ValueGetterParams) => mmcProductsMap[params.data.mmcProduct] || params.data.mmcProduct,
      },
      studioServiceType: {
        filterParams: {
          suppressSorting: true,
          debounceMs: 1000,
          values: Object.keys(studioServiceTypesMap),
          valueFormatter: (params: ValueFormatterParams) => studioServiceTypesMap[params.value] || params.value,
        },
        valueGetter: (params: ValueGetterParams) =>
          studioServiceTypesMap[params.data.studioServiceType] || params.data.studioServiceType,
      },
      studioProduct: {
        filterParams: {
          suppressSorting: true,
          debounceMs: 1000,
          values: Object.keys(studioProductsMap),
          valueFormatter: (params: ValueFormatterParams) => studioProductsMap[params.value] || params.value,
        },
        valueGetter: (params: ValueGetterParams) =>
          studioProductsMap[params.data.studioProduct] || params.data.studioProduct,
      },
    },
    { minWidth: 200 },
  );
}
