import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { getUser } from '../../reducers/user';

/**
 * @children - children components
 * @actions - array of actions: canView, canDelete etc. Stored here: 'enum N3AEntitlements'
 * @RenderError - Element for rendering in case, when user don't have permissions
 * @errorProps - object with props
 *
 * Usage:
 *  Hide elements for user without appropriate permissions:
 *  <AccessControl actions={[N3AEntitlements.CAN_ADD_PROJECTS]}>
 *    <Button>Add Project</Button>
 *  </AccessControl>
 *
 *  Display custom error message if the user does not have correct permissions:
 *  <AccessControl actions={[N3AEntitlements.CAN_ADD_PROJECTS]} RenderError={() => <p>You can't add projects!</p>}>
 *    <Button>Add Project</Button>
 *  </AccessControl>
 *
 *  If you don’t want to hide children and instead, you might want to make children aware by passing a custom set of props.
 *  For example: disable input for anybody without the permission
 *  <AccessControl actions={[N3AEntitlements.CAN_EDIT_SOMETHING]} errorProps={{ disabled: true }}>
 *      <Input />
 *  </AccessControl>
 *
 */

export interface IAccessControlProps {
  // children: ReactChild | ReactChildren | ReactNode | ReactNode[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
  actions: Array<string>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  RenderError?: () => any;
  errorProps?: object | null; //
}

const hasPermission = (entitlements: Array<string>, actions: Array<string>) => {
  const actionsMap = {};
  actions.forEach((action: string) => {
    actionsMap[action] = true;
  });

  return entitlements.some((permission: string) => actionsMap[permission]);
};

const AccessControl = ({
  children,
  actions = [],
  RenderError = () => <></>,
  errorProps = null,
}: IAccessControlProps) => {
  const { entitlements } = useSelector(getUser, shallowEqual) || {};
  const permissionGranted = hasPermission(entitlements, actions);

  if (!permissionGranted && !errorProps) {
    return <RenderError />;
  }

  if (!permissionGranted && errorProps) {
    return React.Children.map(children, child => React.cloneElement(child, { ...errorProps }));
  }

  return <>{children}</>;
};

export default AccessControl;
