import React, { useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Alert } from 'reactstrap';
import 'ag-grid-enterprise';
import { LicenseManager } from 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { getApplication, IApplication } from '../../reducers/application';
import { IUser, getUser } from '../../reducers/user';
import { menu } from '../../utils/NavbarData';

import AjaxSpinner from '../../components/AjaxSpinner';
import Notification from '../../containers/Notification';
import { getAuth, IAuthState } from '../../reducers/auth';
import omit from 'lodash/fp/omit';
import { N3AEntitlements } from '../../constants';
import TeamworkNavBar from '../../components/TeamworkNavBar';

export interface ILayoutProps {
  children: React.ReactNode;
}

const agGridLicenseKey =
  'CompanyName=SHI International Corp_on_behalf_of_Intrado Corporation,LicensedApplication=JavaScript frontend React library,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=3,LicensedProductionInstancesCount=1,AssetReference=AG-024695,ExpiryDate=4_March_2023_[v2]_MTY3Nzg4ODAwMDAwMA==6f89e66f76dbb4c381d1316ea14ca72a';

LicenseManager.setLicenseKey(agGridLicenseKey);

const noAccessMessages = {
  notAuthorized: `You are not authorized to access this application`,
  noPermission: `You don't have permissions to access this page`, // TODO: Think about 404 instead
};

const Layout = ({ children }: ILayoutProps) => {
  const app: IApplication = useSelector(getApplication, shallowEqual);
  const user: IUser = useSelector(getUser, shallowEqual);
  const navElements = menu
    .filter(l => user.entitlements.includes(l.access))
    .map(omit(['access']))
    .map(el => {
      return { ...el, route: el.routes[0] };
    });

  const { tokens }: IAuthState = useSelector(getAuth, shallowEqual);

  const [isErrorMessage, setErrState] = useState(true);

  const spinner = app.isLoading && <AjaxSpinner />;

  const errorMessage = app.error && !app.isLoading && (
    <Alert color="danger" isOpen={isErrorMessage} toggle={() => setErrState(false)}>
      Oops!, something went wrong.
    </Alert>
  );

  if (tokens && tokens.idToken) {
    return (
      <div id="TeamworkApp">
        <div id="unison-navbar" style={{ position: 'fixed', top: 0, right: 0, left: 0, zIndex: 1051 }}>
          <TeamworkNavBar navElements={navElements}></TeamworkNavBar>
        </div>
        {spinner}
        {errorMessage}
        <div className="container-fluid page-container">{children}</div>
        <Notification />
      </div>
    );
  }
  return <div />;
};

export const LayoutWrapper = (Page: () => JSX.Element, access = N3AEntitlements.Teamwork) => {
  return function InnerWrapper() {
    const { entitlements: ents } = useSelector(getUser, shallowEqual) || {};
    const { authenticated }: IAuthState = useSelector(getAuth, shallowEqual);
    const isEntitled = (authenticated && ents && ents.length > 0 && ents.includes(access)) || false;

    let noAccessMessage = noAccessMessages.notAuthorized;

    if (authenticated && (!ents || !ents.includes(access))) {
      noAccessMessage = noAccessMessages.noPermission;
    }

    return <Layout>{isEntitled ? <Page /> : <div>{noAccessMessage}</div>}</Layout>;
  };
};
