import React from 'react';
import cx from 'classnames';
import styles from './EventDetail.module.scss';
import { shallowEqual, useSelector } from 'react-redux';
import { getEvents } from '../../reducers/events';
import EventDetails from '../../components/EventDetails';

const EventDetail = () => {
  const { currentEvent } = useSelector(getEvents, shallowEqual);

  return (
    <div className={cx(styles.root)}>
      <h3>Events edit</h3>
      <div className={cx(styles.form, 'card')}>
        <EventDetails event={currentEvent} />
      </div>
    </div>
  );
};

export default EventDetail;
