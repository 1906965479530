import { put, take, call } from 'redux-saga/effects';
import { fetchOne, FETCH_ONE_SUCCESS, FETCH_ONE_FAILURE } from '../actions/accounts';
import { Routes } from '../constants';
import { appLoadingPlaceholder } from '../utils/helpers';

export function* fetcher() {
  yield put(fetchOne());
  return yield take([FETCH_ONE_SUCCESS, FETCH_ONE_FAILURE]);
}

export default function* accountsDetailPage() {
  while (true) {
    yield call(appLoadingPlaceholder, Routes.AccountsDetail, fetcher);
  }
}
