import { IProject } from '../../utils/api.d';
import { FormEvent, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getProjects } from '../../reducers/projects';
import { getStaffs } from '../../reducers/staffs';
import { IProjectDTO, ProjectTypes } from '../../utils/api.d';
import { getDateNoOffset, createUTCDate } from '../../utils/helpers';
import { getEvents } from '../../reducers/events';
import {
  projectType as ProjectTypeEnum,
  contentType as contentTypeEnum,
  deliveryType as deliveryTypeEnum,
} from '../../enums';
import {
  accountPlatformMap,
  contentTypeOveragesValuesMap,
  eventTypeOveragesGroupsMap,
  MMCGroup,
  PlatformsForMMCGroup,
  PlatformsForStudioGroup,
  showInProjectPickerConfig,
  specificOveragesNames,
  StudioGroup,
} from './constants';
import { searchAccounts } from '../../utils/api';

const getAccountModel = (element: HTMLInputElement) => {
  const accountsInputValue = element.value.split(' ');
  const accPlatform = accountsInputValue[0];
  const accName = accountsInputValue[1];

  return {
    id: accName,
    platform: accPlatform,
    order: 0,
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getProjectAccountsValue = (projectAccounts: any) => {
  let projectAccountValues = null;

  if (projectAccounts.length > 1) {
    projectAccountValues = Object.keys(projectAccounts).map(n => getAccountModel(projectAccounts[n]));
  }

  if (projectAccounts.value) {
    projectAccountValues = [getAccountModel(projectAccounts)];
  }

  return projectAccountValues;
};

export const transformer = (
  eventObj: FormEvent<HTMLFormElement>,
  parentId?: string,
  id?: string,
  type?: string,
  dsaId?: string,
  currentEditor?: string,
) => {
  const fields = eventObj.target;
  const projectAccounts = fields['projectAccounts'];
  const projectAccountsValue = projectAccounts ? getProjectAccountsValue(projectAccounts) : [];
  const projectStaff = ['projectManager'].map((staffRoleType: string) => {
    return {
      staffRoleType: staffRoleType,
      staffId: (fields[staffRoleType] && Number(fields[staffRoleType].value)) || null,
    };
  });
  const projectId = id ? (Number(id) === -1 ? null : Number(id)) : null;
  const parentIdNumber = parentId ? Number(parentId) : null;
  const dsaCustomerId = dsaId ? Number(dsaId) : null;
  const canSendStartEndDate = ProjectTypeEnum.VirtualEventSubProject.id.toString() !== type;

  const resultObj: Partial<IProjectDTO> = {
    parentId: parentIdNumber,
    dsaCustomerId: dsaCustomerId,
    projectType: ProjectTypes[type || ProjectTypes.VirtualEvent],
    name: fields['name'].value || '',
    id: projectId,
    projectSubType: (fields['projectSubType'] && fields['projectSubType'].value) || null,
    serviceModelType: (fields['serviceModelType'] && fields['serviceModelType'].value) || null,
    usageType: (fields['usageType'] && fields['usageType'].value) || null,
    eventQuantity: (fields['eventsAllowed'] && Number(fields['eventsAllowed'].value)) || 0,
    fulfillmentStart: (fields['fulfillmentStart'] && getDateNoOffset(fields['fulfillmentStart'].value)) || undefined,
    fulfillmentEnd: (fields['fulfillmentEnd'] && getDateNoOffset(fields['fulfillmentEnd'].value)) || undefined,
    duration: fields['duration'] && fields['duration'].value ? Number(fields['duration'].value) : null,
    hostingDays: fields['hostingDays'] && fields['hostingDays'].value ? Number(fields['hostingDays'].value) : null,
    languages: fields['languages'] && fields['languages'].value ? Number(fields['languages'].value) : null,
    liveViewers: fields['liveViewers'] && fields['liveViewers'].value ? Number(fields['liveViewers'].value) : null,
    contentType: (fields['contentType'] && fields['contentType'].value) || null,
    deliveryType: (fields['deliveryType'] && fields['deliveryType'].value) || null,
    projectStaff: projectStaff,
    notes: currentEditor ? currentEditor : '',
    projectAccounts: projectAccountsValue,
    projectStatus:
      (fields['projectStatus'] && fields['projectStatus'].value && Number(fields['projectStatus'].value)) || 1,
    supportRegion: fields['supportRegion'] && fields['supportRegion'].value,
    showInProjectPicker: fields['showInProjectPicker']
      ? fields['showInProjectPicker'] && showInProjectPickerConfig.YES === fields['showInProjectPicker'].value
      : true,
    recurringRolloverTerm:
      fields['recurringRolloverTerm'] && fields['recurringRolloverTerm'].value
        ? Number(fields['recurringRolloverTerm'].value)
        : null,
    expectedFulfillment: fields['expectedFulfillment'] && fields['expectedFulfillment'].value,
    eventStart: (canSendStartEndDate && fields['eventStart'] && createUTCDate(fields['eventStart'].value)) || null,
    eventEnd: (canSendStartEndDate && fields['eventEnd'] && createUTCDate(fields['eventEnd'].value)) || null,
    cfpOpenDate: (fields['cfpOpenDate'] && getDateNoOffset(fields['cfpOpenDate'].value)) || null,
    cfpCloseDate: (fields['cfpCloseDate'] && getDateNoOffset(fields['cfpCloseDate'].value)) || null,
    siteId: (fields['siteId'] && fields['siteId'].value) || null,
    siteUrl: (fields['siteUrl'] && fields['siteUrl'].value) || null,
    clientContactName: (fields['clientContactName'] && fields['clientContactName'].value) || null,
    clientContactEmail: (fields['clientContactEmail'] && fields['clientContactEmail'].value) || null,
    registrants: fields['registrants'] && fields['registrants'].value ? Number(fields['registrants'].value) : null,
  };
  return resultObj;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const sortProjects = (projects: any[]) =>
  [...projects].sort((a, b) => {
    if (!a.parentId) {
      return -1;
    }
    if (!b.parentId) {
      return 2;
    }
    if (a.id && b.id) {
      return a.id > b.id ? 1 : -1;
    } else {
      return 0;
    }
  });

export const getTitle = (id?: string) => (id ? 'Project Edit' : 'Create New Project');

export const useProjectData = (id?: string) => {
  const dispatch = useDispatch();
  //  Get project list
  const { isLoading, currentProject } = useSelector(getProjects, shallowEqual);
  const projectGridData = useMemo(() => sortProjects(currentProject), [currentProject]);
  const staffs = useSelector(getStaffs, shallowEqual);
  const title = getTitle(id);
  const eventsInfo = useSelector(getEvents, shallowEqual);
  const eventsList = eventsInfo.list;
  const isEventsListLoading = eventsInfo.isLoading;
  const primaryEventInfo = useSelector(getEvents, shallowEqual);
  const primaryEventDetails = primaryEventInfo.primaryEventDetails;
  const isPrimaryEventLoading = primaryEventInfo.isLoading;

  const selectedProjectData = useMemo(() => {
    const prId = id ? +id : -1;
    return projectGridData.find(i => i.id === prId) || {};
  }, [id, projectGridData]);

  return {
    title,
    staffs,
    projectGridData,
    isLoading,
    selectedProjectData,
    eventsList,
    isEventsListLoading,
    primaryEventDetails,
    isPrimaryEventLoading,
    dispatch,
  };
};

export const hasNumberOverages = (rowValue: number, formValue: number) => rowValue > formValue;

export const hasStringOverages = (rowValue: string, formValue: string) => {
  const form = formValue && formValue.toLowerCase();
  const row = rowValue && rowValue.toLowerCase();

  return form !== row && form !== 'unknown';
};

export const hasContentOverages = (rowValue: string, formValue: string) => {
  const unknownName = contentTypeEnum.Unknown.name;
  const form = contentTypeOveragesValuesMap[formValue];
  const row = contentTypeOveragesValuesMap[rowValue];

  return form < row || (formValue !== unknownName && rowValue === unknownName);
};

export const hasEventTypeOverages = (rowValue: string, formValue: string) => {
  let hasOverages = true;
  const rowValueProductGroup = eventTypeOveragesGroupsMap[rowValue];
  const formValueProductGroup = eventTypeOveragesGroupsMap[formValue];
  const isSameGroup = rowValueProductGroup === formValueProductGroup;
  const isGroupChecking = isSameGroup && Boolean(formValueProductGroup);

  if (isSameGroup) {
    hasOverages = isGroupChecking
      ? rowValueProductGroup[rowValue] > formValueProductGroup[formValue]
      : rowValue !== formValue;
  }

  return hasOverages;
};

export const specificOveragesCheckingFields = {
  [specificOveragesNames.contentType]: (rowValue: string, formValue: string) => hasContentOverages(rowValue, formValue),
  [specificOveragesNames.eventSubType]: (rowValue: string, formValue: string) =>
    hasEventTypeOverages(rowValue, formValue),
};

export const skipOverageCheckingFields = {
  eventType: () => true,
  name: () => true,
  duration: (formData: Partial<IProject>) =>
    Boolean(formData.deliveryType && formData.deliveryType.id === deliveryTypeEnum.Ondemand.id),
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createPlatformQueryString = (projectSubtype: any) => {
  const projectSubtypeValue = (projectSubtype && projectSubtype.value) || null;
  const isStudioGroup = projectSubtypeValue && StudioGroup.includes(projectSubtypeValue);
  const isMMCGroup = projectSubtypeValue && MMCGroup.includes(projectSubtypeValue);
  let platformQueryString = '';

  if (isMMCGroup || isStudioGroup) {
    platformQueryString = isMMCGroup ? PlatformsForMMCGroup : PlatformsForStudioGroup;
  }

  return platformQueryString;
};

export const getAccountOptions = (inputText: string, platformQueryString: string) => {
  const minChar = 3;
  if (inputText.trim().length < minChar) {
    return new Promise(resolve => resolve([]));
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return searchAccounts(inputText, platformQueryString)
    .then(response => {
      const accounts = response.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return accounts.data.map((account: any) => {
        return {
          value: `${account.platform} ${account.accountId}`,
          label: `${account.name} (${accountPlatformMap[account.platform]})`,
        };
      });
    })
    .catch(error => {
      console.log(error);
    });
};
