import { FormShape } from '../utils/api.d';

export const dsasDetailsInfo: FormShape = {
  accountLongId: { label: 'Account ID', type: 'text' },
  accountName: { label: 'Account Name', type: 'text' },
  accountOwner: { label: 'Account Owner', type: 'text' },

  accountTotalContractedValueUsd: { label: 'Account Total Contracted Value (USD)', type: 'text' },
  accountCountry: { label: 'Account Country', type: 'text' },

  accountCustomerSuccessMgr: { label: 'Account Customer Success Manager', type: 'text' },
  parentAccountName: { label: 'SFDC GUPA', type: 'text' },

  accountRegion: { label: 'Account Region', type: 'text' },
  relatedDsas: { label: 'Related DSAs', type: 'select' },
};
