import { ColDef, ColGroupDef, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { tracingTypes } from '../../NewTimeTracking/tracingTypesAndDuration';
import {
  dateFormatter,
  durationConverter,
  durationParserForFilter,
  numberParserForFilter as numberParser,
  transformToColumns,
} from '../../../utils/helpers';
import omit from 'lodash/omit';
import { timeTrackingListGridColumns } from '../../../tablesFormShapes/timeTrackingList';

const timeTrackingTypesMap = {};
const timeTrackingSubTypesMap = {};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
tracingTypes.forEach((type: any) => {
  const { id, name, subtypes } = type;

  timeTrackingTypesMap[id] = name;

  subtypes.forEach((subtype: { value: string; name: string; id: number }) => {
    const { id, name } = subtype;
    timeTrackingSubTypesMap[id] = name;
  });
});

export default function(isAdmin = false): (ColDef | ColGroupDef)[] {
  return transformToColumns(
    omit(
      timeTrackingListGridColumns,
      'staffId',
      'projectId',
      'projectName',
      'staffRegion',
      'staffTeam',
      'staffManager',
      'description',
    ),
    {
      id: {
        hide: true,
        filterParams: {
          numberParser,
        },
      },
      staffName: {
        filter: isAdmin ? timeTrackingListGridColumns.staffName.filter : false,
      },
      type: {
        filterParams: {
          suppressSorting: true,
          debounceMs: 1000,
          values: Object.keys(timeTrackingTypesMap),
          valueFormatter: (params: ValueFormatterParams) => timeTrackingTypesMap[params.value] || params.value,
        },
        valueGetter: (params: ValueGetterParams) => timeTrackingTypesMap[params.data.type] || params.data.type,
      },
      subType: {
        filterParams: {
          suppressSorting: true,
          debounceMs: 1000,
          values: Object.keys(timeTrackingSubTypesMap),
          valueFormatter: (params: ValueFormatterParams) => timeTrackingSubTypesMap[params.value] || params.value,
        },
        valueGetter: (params: ValueGetterParams) => timeTrackingSubTypesMap[params.data.subType] || params.data.subType,
      },
      duration: {
        floatingFilter: false,
        filterParams: {
          allowedCharPattern: `\\d\\:`, // new RegExp(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/); // HH:MM - duration pattern; doesn't work
          numberParser: durationParserForFilter,
        },
        valueGetter: (params: ValueGetterParams) =>
          params.data.duration ? durationConverter(params.data.duration) : '',
      },
      date: {
        valueGetter: dateFormatter('date', false),
      },
    },
    { minWidth: 200 },
  );
}
