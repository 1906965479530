// use 'npm run g:com {name}' - to generate new component with react hooks
import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import { IEnumType, InputFieldType } from '../../utils/api.d';
import Toggle from '../Toggle';
import styles from './FormField.module.scss';

interface IField {
  name: string;
  label: string;
  value: string;
  disabled?: boolean;
  type: InputFieldType;
  options?: IEnumType[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  valueFormat?: (value: any) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (value: any) => any;
}

const FormField = ({ name, label, value, onChange, type, options, disabled = false, valueFormat }: IField) => {
  let inputType: 'text' | 'number' | 'datetime-local' = 'text';

  value = valueFormat ? valueFormat(value) : value;

  if (type === 'number') {
    inputType = 'number';
  }

  if (type === 'datetime-local') {
    inputType = 'text';
  }

  let formField = (
    <Input
      type={inputType}
      id={name}
      name={name}
      value={value}
      disabled={disabled}
      onChange={e => onChange(e.target.value)}
    />
  );

  if (type === 'boolean') {
    let val = Boolean(value);
    if (value === 'true') {
      val = true;
    }

    if (value === 'true') {
      val = false;
    }

    formField = (
      <>
        <br />
        <Toggle checked={val} onChange={() => onChange(!val)} disabled={disabled} />
      </>
    );
  }

  if (type === 'select' && options) {
    formField = (
      <Input
        defaultValue={value}
        type="select"
        onChange={e => onChange(e.target.value)}
        name={name}
        id={name}
        disabled={disabled}
      >
        {options.map(o => (
          <option key={o.id ? o.id : o.name} value={o.value} label={o.name} selected={value === o.value}>
            {o.name}
          </option>
        ))}
      </Input>
    );
  }
  return (
    <FormGroup>
      <Label for={name} className={styles['teamwork-form-field-label']}>
        {label} &nbsp;
      </Label>
      {formField}
    </FormGroup>
  );
};

export default FormField;
