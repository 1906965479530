import { ColDef } from 'ag-grid-community';

export default function(): ColDef[] {
  return [
    {
      headerName: 'ID',
      field: 'id',
      hide: true,
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Product Name',
      field: 'productName',
      filter: 'agTextColumnFilter',
      sort: 'asc',
    },
    {
      headerName: 'Revenue Type',
      field: 'productRevenueType',
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Net Qty',
      field: 'netQuantity',
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Subscription Status',
      field: 'subscriptionStatus',
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Parent Project',
      field: 'parentProjectName',
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Sub-Project',
      field: 'subProjectName',
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Contract Number',
      field: 'contractNumber',
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Opportunity Name',
      field: 'opportunityName',
      filter: 'agTextColumnFilter',
    },
  ];
}
