import { ColDef } from 'ag-grid-community';
import { transformToColumns } from '../../utils/helpers';
import { dsaListGridColumns } from '../../tablesFormShapes/dsas';

export default function(): ColDef[] {
  return transformToColumns(
    dsaListGridColumns,
    {
      id: {
        minWidth: 80,
      },
    },
    { minWidth: 200 },
  );
}
