import React from 'react';
import cx from 'classnames';
import styles from './DSADetail.module.scss';
import DSADetails from '../../components/DSADetails';
import ParentProjectGrid from '../../components/ParentProjectGrid';
import EventsGrid from '../../components/EventsGrid';
import PurchasedProductsGrid from '../../components/PurchasedProductsGrid';
import SubProjectsGrid from '../../components/SubProjectsGrid';
import { useParams } from 'react-router-dom';
import { getAccountLongId } from '../../reducers/dsas';
import { shallowEqual, useSelector } from 'react-redux';
import { getUser } from '../../reducers/user';

const DSADetail = () => {
  const { dsaid } = useParams();
  const accountLongId = useSelector(getAccountLongId, shallowEqual);
  const { emailAddress } = useSelector(getUser, shallowEqual) || {};

  return (
    <div className={cx(styles.root)}>
      <h3>Customer (DSA)</h3>
      <div className={cx(styles.form, 'card')}>
        <DSADetails />
        <div>
          <ParentProjectGrid dsaId={String(dsaid)} emailAddress={emailAddress} />
        </div>
        <div>
          <SubProjectsGrid dsaId={String(dsaid)} emailAddress={emailAddress} />
        </div>
        <div>
          <PurchasedProductsGrid accountLongId={accountLongId} emailAddress={emailAddress} />
        </div>
        <div>
          <EventsGrid emailAddress={emailAddress} />
        </div>
      </div>
    </div>
  );
};

export default DSADetail;
