import React, { useState } from 'react';
import { Button } from 'reactstrap';
import getColumns from './columns';
import makeGridOptions from '../../gridOptions';
import { Grid } from '../../components/Grid';
import { GridReadyEvent } from 'ag-grid-community/dist/lib/events';
import { GridApi, ColumnApi, ServerSideStoreType, IServerSideDatasource } from 'ag-grid-community';
import { createServerSideDatasource, getPathToPreviousScreen } from '../../utils/helpers';
import { urls } from '../../utils/api';
import { useParams } from 'react-router';
import { history } from '../../store';
import { Routes } from '../../constants';

const ProjectAuditLog = () => {
  const [gridApi, setGridApi] = useState<GridApi | null>(null);

  const columnDefs = getColumns();
  const rowsLimit = 500;
  const { id, backUrl } = useParams();

  const getDataSource = (projectId: string): IServerSideDatasource => {
    return createServerSideDatasource(urls.projectAuditLogLazy.replace('{projectId}', projectId));
  };

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);

    const datasource = getDataSource(id);
    params.api.setServerSideDatasource(datasource);
  };

  const gridOptions = makeGridOptions(columnDefs, {
    rowModelType: 'serverSide',
    cacheBlockSize: rowsLimit,
    serverSideStoreType: ServerSideStoreType.Partial,
    animateRows: true,
    statusBar: { statusPanels: [] },
    onGridReady: onGridReady,
  });

  const backToProject = () => {
    if (backUrl && backUrl !== ':backUrl') {
      history.push(getPathToPreviousScreen(backUrl));
    } else {
      history.push(Routes.Projects);
    }
  };

  return (
    <>
      <div>
        <div className={'pageTitle float-left'}>Project Audit Log</div>
        <div className="float-right">
          <Button className={'margin-left-10'} color="primary" onClick={backToProject}>
            Back to project
          </Button>
        </div>
      </div>
      <Grid gridOptions={gridOptions} />
    </>
  );
};

export default ProjectAuditLog;
