export const reportsType = {
  pliReport: {
    value: 'pliWaterfall',
    name: 'PLI Report',
    id: 'pliWaterfall',
  },
  eventOveragesReport: {
    value: 'eventOverages',
    name: 'Event Overages Report',
    id: 'eventOverages',
  },
  projectOveragesReport: {
    value: 'projectOverages',
    name: 'Project Overages Report',
    id: 'projectOverages',
  },
  projectsInfo: {
    value: 'projectsInfo',
    name: 'Projects Report',
    id: 'projectsInfo',
  },
  telcoBillingReport: {
    value: 'telcoBilling',
    name: 'Telco Billing Report',
    id: 'telcoBilling',
  },
  timeTrackingReport: {
    value: 'timeTracking',
    name: 'Time Tracking Report',
    id: 'timeTracking',
  },
  eventsReport: {
    value: 'events',
    name: 'Events Report',
    id: 'events',
  },
  projectTimeTracking: {
    value: 'projectTimeTracking',
    name: 'Project Time Tracking Report',
    id: 'projectTimeTracking',
  },
};

export const filterTypes = [
  {
    id: 'dateRange',
    name: 'Projects In The Range',
    desc: 'Export projects in the Fulfillment Start date range',
  },
  {
    id: 'emptyDate',
    name: 'Projects Without Fulfillment Start Date',
    desc: 'Export projects which do not have Fulfillment Start date',
  },
];

export const projectTimeTrackingFilterTypes = [
  {
    id: 'projectEndDate',
    name: 'Projects In The Range',
    desc: 'Export projects in the End date range',
  },
  {
    id: 'timeTrackingLastUpdated',
    name: 'Projects by Time Tracking Entry',
    desc: 'Export projects by time tracking entry creation date range',
  },
];

export const showDateRangeFilter = [
  reportsType.eventOveragesReport.value,
  reportsType.projectOveragesReport.value,
  reportsType.projectsInfo.value,
  reportsType.telcoBillingReport.value,
  reportsType.timeTrackingReport.value,
  reportsType.eventsReport.value,
  reportsType.projectTimeTracking.value,
];

export const showPlatformFilter = [reportsType.eventsReport.value];
export const showEventTypeFilter = [reportsType.eventsReport.value];

export const defaultOptions = {
  platform: 'Select Platform',
  eventType: 'Select Event Type',
};
