import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { signout } from '../../actions/auth';

const Logout = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(signout());
  }, [dispatch]);
  return <div></div>;
};

export default Logout;
