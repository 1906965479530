import { call, put, take } from 'redux-saga/effects';
import { Routes } from '../constants';
import { appLoadingPlaceholder } from '../utils/helpers';
import {
  fetchGridConfigs,
  FETCH_GRID_CONFIGS,
  FETCH_GRID_CONFIGS_SUCCESS,
  FETCH_GRID_CONFIGS_FAILURE,
} from '../actions/gridConfig';
import { gridTypes } from '../enums';

export function* fetcher() {
  yield put(fetchGridConfigs(gridTypes.ProjectsList.id));
  return yield take([FETCH_GRID_CONFIGS, FETCH_GRID_CONFIGS_SUCCESS, FETCH_GRID_CONFIGS_FAILURE]);
}

export default function* projectsListViewPage() {
  while (true) {
    yield call(appLoadingPlaceholder, Routes.Projects, fetcher);
  }
}
