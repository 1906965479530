import createReducer from '../utils/redux-create-reducer';
import { FULFILL, FULFILL_FAILURE, FULFILL_SUCCESS } from '../actions/events';

export interface ICompleteEventState {
  isLoading?: boolean;
  error?: Error;
}

interface IState {
  completeEvent: ICompleteEventState;
}

export const initialState: ICompleteEventState = {
  isLoading: false,
  error: undefined,
};

export const getCompleteEventInfo = (state: IState): ICompleteEventState => state.completeEvent;

export default createReducer<ICompleteEventState>(initialState, {
  [FULFILL]: () => ({ isLoading: true }),
  [FULFILL_SUCCESS]: () => ({ isLoading: false }),
  [FULFILL_FAILURE]: error => ({ error, isLoading: false }),
});
