import React, { useState } from 'react';
import makeGridOptions from '../../../gridOptions';
import { Grid } from '../../../components/Grid';
import {
  AgGridEvent,
  ColumnApi,
  GridApi,
  IServerSideDatasource,
  RowClickedEvent,
  ServerSideStoreType,
} from 'ag-grid-community';
import {
  checkForSavedGrid,
  createServerSideDatasource,
  gridStateSaver,
  resetAllGridFilters,
} from '../../../utils/helpers';
import { urls } from '../../../utils/api';
import getSubProjectColumns from '../GridSettings/subProjects';
import styles from '../AddNewProject.module.scss';
import { ProjectTypes } from '../../../utils/api.d';
import { N3AEntitlements, Routes } from '../../../constants';
import { Button, Dropdown, DropdownToggle } from 'reactstrap';
import { SelectProjectType } from '../../../components/SelectProjectType';
import { AppGrids } from '../../../enums';
import AccessControl from '../../../components/AccessControl';

interface IProps {
  projectId?: string;
  projectType?: string;
  isAddProjectBtnVisible?: boolean;
  addSubProject: (type: number) => void;
  dsaId: string;
  emailAddress: string;
}

const projectTypesDropdownConfig = {
  [ProjectTypes.Webcast]: [ProjectTypes.WebCastSubProject, ProjectTypes.Misc],
  [ProjectTypes.VirtualEvent]: [ProjectTypes.VESubProject, ProjectTypes.Misc],
  [ProjectTypes.Plan]: [
    ProjectTypes.Webcast,
    ProjectTypes.VirtualEvent,
    ProjectTypes.PlanSubProject,
    ProjectTypes.Misc,
  ],
  [ProjectTypes.PlanSubProject]: [
    ProjectTypes.Webcast,
    ProjectTypes.VirtualEvent,
    ProjectTypes.PlanSubProject,
    ProjectTypes.Misc,
  ],
  [ProjectTypes.Misc]: [ProjectTypes.Misc],
};

const SubProjectsGrid = ({
  projectId,
  projectType,
  isAddProjectBtnVisible,
  addSubProject,
  dsaId,
  emailAddress,
}: IProps) => {
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [gridColumnApi, setColumnApi] = useState<ColumnApi | null>(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);
  const addSubProjectDropdownMenu =
    (projectType && projectTypesDropdownConfig[projectType]) || projectTypesDropdownConfig[ProjectTypes.Misc];
  const columnDefs = getSubProjectColumns();

  const getDataSource = (projectId?: string): IServerSideDatasource => {
    return createServerSideDatasource(urls.subProjectsLazy, `parentProjectId=${projectId || 0}`);
  };

  const onGridReady = (event: AgGridEvent) => {
    setGridApi(event.api);
    setColumnApi(event.columnApi);

    const datasource = getDataSource(projectId);
    event.api.setServerSideDatasource(datasource);
  };

  const onRowClicked = (row: RowClickedEvent) => {
    const url = Routes.NewEditProject.replace(':id', row.data.id)
      .replace(':type', row.data.type)
      .replace(':dsaId', dsaId);

    window.open(url);
  };

  const gridOptions = makeGridOptions(columnDefs, {
    onGridReady,
    rowModelType: 'serverSide',
    rowSelection: 'single',
    cacheBlockSize: 10,
    serverSideStoreType: ServerSideStoreType.Partial,
    animateRows: true,
    statusBar: { statusPanels: [] },
  });

  const gridName = `${emailAddress}-${AppGrids.ProjectDetailsSubProjects}-${projectType}`;
  const gridConfigName = `${emailAddress}-${AppGrids.ProjectDetailsSubProjects}-${projectType}-config`;

  const saveState = () => gridColumnApi && gridStateSaver(gridColumnApi, gridName);

  if (gridColumnApi) {
    checkForSavedGrid(gridColumnApi, gridName, gridConfigName, columnDefs);
    gridStateSaver(gridColumnApi, gridName);
  }

  const resetAllFilters = () => resetAllGridFilters(gridApi);

  return (
    <>
      <div className={styles.flexButton}>
        <h5>Sub-Projects</h5>
        <div>
          {isAddProjectBtnVisible && (
            <AccessControl actions={[N3AEntitlements.Admin]}>
              <Dropdown isOpen={dropdownOpen} toggle={toggle} direction="down" className="float-right">
                <DropdownToggle className={styles.dropDownToggle} caret>
                  Add New Project &nbsp;
                </DropdownToggle>
                <SelectProjectType
                  onItemClick={type => addSubProject(type.id)}
                  typesFilter={addSubProjectDropdownMenu}
                  right
                />
              </Dropdown>
            </AccessControl>
          )}

          <Button color="primary" onClick={resetAllFilters} className={'margin-right-10'}>
            Reset Filters
          </Button>
        </div>
      </div>
      <Grid
        gridOptions={gridOptions}
        className={styles.gridDefault}
        onRowClicked={onRowClicked}
        onColumnMoved={saveState}
        onColumnVisible={saveState}
        onColumnResized={saveState}
      />
    </>
  );
};

export default SubProjectsGrid;
