export const tracingTypes = [
  {
    value: 'WebcastSupport',
    name: 'Webcast Support',
    id: 1,
    subtypes: [
      { value: 'WebcastProjectManagement', name: 'Webcast Project Management', id: 1 },
      { value: 'WebcastConfiguration', name: 'Webcast Configuration', id: 2 },
      { value: 'WebcastEngineering', name: 'Webcast Engineering', id: 3 },
      { value: 'WebcastEditing', name: 'Webcast Editing', id: 4 },
      { value: 'WebcastPostProduction', name: 'Webcast Post Production', id: 5 },
    ],
  },
  {
    value: 'WebcastOnboarding',
    name: 'Webcast Onboarding',
    id: 2,
    subtypes: [{ value: 'WebcastOnboarding', name: 'Webcast Onboarding', id: 6 }],
  },
  {
    value: 'EventConfiguration',
    name: 'Event Configuration',
    id: 3,
    subtypes: [{ value: 'EventConfiguration', name: 'Event Configuration', id: 7 }],
  },
  {
    value: 'EventProjectManagement',
    name: 'Event Project Management',
    id: 4,
    subtypes: [
      { value: 'EventProjectManagement', name: 'Event Project Management', id: 8 },
      { value: 'EventOnboarding', name: 'Event Onboarding', id: 9 },
    ],
  },
  {
    value: 'EventFrontLineSupport',
    name: 'Event Front Line Support',
    id: 5,
    subtypes: [{ value: 'EventFrontLineSupport', name: 'Event Front Line Support', id: 10 }],
  },
  {
    value: 'SolutionsSupport',
    name: 'Solutions Support',
    id: 6,
    subtypes: [{ value: 'SolutionsSupport', name: 'Solutions Support', id: 11 }],
  },
  {
    value: 'TechnicalConsultingServices',
    name: 'Technical Consulting Services',
    id: 7,
    subtypes: [{ value: 'TechnicalConsultingServices', name: 'Technical Consulting Services', id: 12 }],
  },
  {
    value: 'CreativeCustomServices',
    name: 'Creative/Custom Services',
    id: 8,
    subtypes: [
      { value: 'CreativeServices', name: 'Creative Services', id: 13 },
      { value: 'CustomServices', name: 'Custom Services', id: 14 },
    ],
  },
  {
    value: 'OnSiteServices',
    name: 'OnSite Services',
    id: 9,
    subtypes: [{ value: 'OnSiteServices', name: 'OnSite Services', id: 15 }],
  },
  {
    value: 'Issues',
    name: 'Issues',
    id: 10,
    subtypes: [
      { value: 'IssueCommunications', name: 'Issue Communications', id: 16 },
      { value: 'IssueTechnicalInvestigation', name: 'Issue Technical Investigation', id: 17 },
    ],
  },
  {
    value: 'Administration',
    name: 'Administration',
    id: 11,
    subtypes: [
      { value: 'Administration', name: 'Administration', id: 18 },
      { value: 'BenchTime', name: 'Bench Time', id: 19 },
      { value: 'Expenses', name: 'Expenses', id: 20 },
      { value: 'Lunch', name: 'Lunch', id: 21 },
    ],
  },
  { value: 'Vacation', name: 'Vacation', id: 12, subtypes: [{ value: 'Vacation', name: 'Vacation', id: 22 }] },
  {
    value: 'InternalEvents',
    name: 'Internal Events',
    id: 13,
    subtypes: [
      { value: 'DepartmentMeeting', name: 'Department Meeting', id: 23 },
      { value: 'TeamMeeting', name: 'Team Meeting', id: 24 },
      { value: 'CompanyEvents', name: 'Company Events', id: 25 },
    ],
  },
  {
    value: 'TimeCredited',
    name: 'Time Credited',
    id: 14,
    subtypes: [
      { value: 'ProductTesting', name: 'Product Testing', id: 26 },
      { value: 'PreSales', name: 'Pre-Sales', id: 27 },
      { value: 'SolutionsAssistance', name: 'Solutions Assistance', id: 28 },
      { value: 'TrainingINXPOU', name: 'Training - INXPO U', id: 29 },
      { value: 'TrainingHCM', name: 'Training - HCM', id: 30 },
      { value: 'TrainingDocumentationAssistance', name: 'Training - Documentation Assistance', id: 31 },
      { value: 'TrainingStudioVE', name: 'Training - Studio/VE', id: 32 },
      { value: 'TrainingMMCVEX', name: 'Training - MMC/VEX', id: 33 },
      { value: 'TrainingShadowing', name: 'Training - Shadowing', id: 34 },
      { value: 'ClientTravel', name: 'Client Travel', id: 35 },
    ],
  },
  { value: 'Other', name: 'Other', id: 15, subtypes: [{ value: 'Other', name: 'Other', id: 36 }] },
];

export const duration = [
  { value: '00:15', key: 15 },
  { value: '00:30', key: 30 },
  { value: '00:45', key: 45 },
  { value: '01:00', key: 60 },
  { value: '01:15', key: 75 },
  { value: '01:30', key: 90 },
  { value: '01:45', key: 105 },
  { value: '02:00', key: 120 },
  { value: '02:15', key: 135 },
  { value: '02:30', key: 150 },
  { value: '02:45', key: 165 },
  { value: '03:00', key: 180 },
  { value: '03:15', key: 195 },
  { value: '03:30', key: 210 },
  { value: '03:45', key: 225 },
  { value: '04:00', key: 240 },
  { value: '04:15', key: 255 },
  { value: '04:30', key: 270 },
  { value: '04:45', key: 285 },
  { value: '05:00', key: 300 },
  { value: '05:15', key: 315 },
  { value: '05:30', key: 330 },
  { value: '05:45', key: 345 },
  { value: '06:00', key: 360 },
  { value: '06:15', key: 375 },
  { value: '06:30', key: 390 },
  { value: '06:45', key: 405 },
  { value: '07:00', key: 420 },
  { value: '07:15', key: 435 },
  { value: '07:30', key: 450 },
  { value: '07:45', key: 465 },
  { value: '08:00', key: 480 },
];
