import React, { useState } from 'react';
import getColumns from './columns';
import makeGridOptions from '../../gridOptions';
import { Grid } from '../../components/Grid';
import { GridReadyEvent } from 'ag-grid-community/dist/lib/events';
import { GridApi, ColumnApi, ServerSideStoreType, RowClickedEvent } from 'ag-grid-community';
import {
  checkForSavedGrid,
  createServerSideDatasource,
  gridStateSaver,
  resetAllGridFilters,
} from '../../utils/helpers';
import { Routes } from '../../constants';
import { AppGrids } from '../../enums';
import { urls } from '../../utils/api';
import { shallowEqual, useSelector } from 'react-redux';
import { getUser } from '../../reducers/user';
import { Button } from 'reactstrap';

const DSAListView = () => {
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [gridColumnApi, setGridColumnApi] = useState<ColumnApi | null>(null);
  const { emailAddress } = useSelector(getUser, shallowEqual) || {};

  const columnDefs = getColumns();
  const rowsLimit = 500;

  const onRowClicked = (row: RowClickedEvent) => {
    window.open(Routes.DSADetail.replace(':dsaid', row.data.id));
  };

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);

    const datasource = createServerSideDatasource(urls.dsasLazy);
    params.api.setServerSideDatasource(datasource);
  };

  const gridOptions = makeGridOptions(columnDefs, {
    rowModelType: 'serverSide',
    cacheBlockSize: rowsLimit,
    serverSideStoreType: ServerSideStoreType.Partial,
    animateRows: true,
    statusBar: { statusPanels: [] },
    onGridReady: onGridReady,
  });

  const gridName = `${emailAddress}-${AppGrids.DsaList}`;
  const gridConfigName = `${emailAddress}-${AppGrids.DsaList}-config`;

  const saveState = () => gridColumnApi && gridStateSaver(gridColumnApi, gridName);

  if (gridColumnApi) {
    checkForSavedGrid(gridColumnApi, gridName, gridConfigName, columnDefs);
    gridStateSaver(gridColumnApi, gridName);
  }

  const resetAllFilters = () => resetAllGridFilters(gridApi);

  return (
    <>
      <div>
        <div className={'pageTitle float-left'}>DSAs</div>

        <div className={'float-right'}>
          <Button color="primary" onClick={resetAllFilters}>
            Reset Filters
          </Button>
        </div>
      </div>

      <Grid
        gridOptions={gridOptions}
        onRowClicked={onRowClicked}
        onColumnMoved={saveState}
        onColumnVisible={saveState}
        onColumnResized={saveState}
      />
    </>
  );
};

export default DSAListView;
