import { call, take, put } from 'redux-saga/effects';
import { callApi } from '../utils/helpers';
import {
  FETCH_REPORTS,
  fetchReports,
  fetchReportsSuccess,
  fetchReportsFailure,
  GENERATE_REPORT,
  generateReportSuccess,
  generateReportFailure,
  GENERATE_REPORT_AND_REFRESH,
} from '../actions/reports';
import api from '../utils/api';
import { notify } from '../actions/notification';
import { IReportGeneration } from '../utils/api.d';

export function* getReports() {
  while (true) {
    yield take(FETCH_REPORTS);
    try {
      const response = yield call(callApi, api.getReports);
      yield put(fetchReportsSuccess(response.data));
    } catch (e) {
      console.error(e);
      yield put(fetchReportsFailure(e));
    }
  }
}

export function* reportGeneration(payload: IReportGeneration) {
  try {
    const response = yield call(callApi, api.generateReport, payload);
    yield put(generateReportSuccess(response.data));
    yield put(notify('Report generation in progress', 'success'));
  } catch (e) {
    console.error(e);
    yield put(notify("We're sorry, there was an error running this report", 'danger'));
    yield put(generateReportFailure(e));
  }
}

export function* generateReport() {
  while (true) {
    const { payload } = yield take(GENERATE_REPORT);
    yield call(reportGeneration, payload);
  }
}

export function* generateReportAndRefreshReportsList() {
  while (true) {
    const { payload } = yield take(GENERATE_REPORT_AND_REFRESH);
    yield call(reportGeneration, payload);
    yield put(fetchReports());
  }
}
