let env = {};
function getEnv() {
  if (process.env.NODE_ENV === 'test') {
    env = require('../env.json');
    return;
  }
  const script = document.getElementById('allEnv-sc');
  const error = new Error('Env vars not found or parse error');
  if (script) {
    const vars = script.dataset && script.dataset.vars;
    if (!vars) {
      throw error;
    }
    try {
      env = JSON.parse(atob(vars.replace(/INSERT_START-|-INSERT_END/g, '')));
    } catch (e) {
      throw error;
    }
    script.parentNode && script.parentNode.removeChild(script);
  } else {
    throw error;
  }
}
getEnv();

export const {
  REACT_APP_TEAMWORK_BASE_URL,
  REACT_APP_ENV,
  REACT_APP_N3A_AUTH_URI,
  REACT_APP_N3A_SITE_ID,
  REACT_APP_AWS_USERPOOLBASEURI,
  REACT_APP_CLIENT_ID,
  REACT_APP_CALLBACK_URI,
  REACT_APP_DASHBOARD_API_BASE_URL,
  REACT_APP_SESSION_API_BASE_URL,
  REACT_APP_S3_PUBLIC_RESOURCES,
  REACT_APP_AWS_REGION,
  REACT_APP_AWS_USERPOOL,
  REACT_APP_AWS_TOKENSCOPES,
  REACT_APP_N3A_URL,
  REACT_APP_API_BASE_URL,
  REACT_APP_KEEP_ALIVE_URL,
  REACT_APP_DELETE_SESSION,
  REACT_APP_KEEP_ALIVE_PING_TIME,
  REACT_APP_DISABLE_AUTH,
  REACT_APP_AMPLITUDE_API_KEY,
  REACT_APP_SEGMENT_API_KEY,
  REACT_APP_NEW_RELIC_ACCOUNT_ID,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} = env as any;
export const SIGNIN_URL = `${REACT_APP_AWS_USERPOOLBASEURI}/authorize?identity_provider=N3A&response_type=code&client_id=${REACT_APP_CLIENT_ID}&redirect_uri=${REACT_APP_TEAMWORK_BASE_URL}${REACT_APP_CALLBACK_URI}`;
export const SIGNOUT_URL = `${REACT_APP_TEAMWORK_BASE_URL}/logout`;
export const PASSWORD_CHANGE_URL = `${REACT_APP_N3A_AUTH_URI}/Register/ChangePassword?siteId=${REACT_APP_N3A_SITE_ID}`;
export const QUESTION_CHANGE_URL = `${REACT_APP_N3A_AUTH_URI}/Register/EditQuestionAndAnswer?siteId=${REACT_APP_N3A_SITE_ID}`;

export const TEAMWORK_APP_ID = 'fc62a98e-0eef-4ca3-8dc6-94d84be9a1d6';
