import { put, take, call } from 'redux-saga/effects';
import { fetchReports, FETCH_REPORTS_FAILURE, FETCH_REPORTS_SUCCESS } from '../actions/reports';
import { Routes } from '../constants';
import { appLoadingPlaceholder } from '../utils/helpers';

export function* fetcher() {
  yield put(fetchReports());
  return yield take([FETCH_REPORTS_SUCCESS, FETCH_REPORTS_FAILURE]);
}

export default function* reportsPage() {
  while (true) {
    yield call(appLoadingPlaceholder, Routes.Reports, fetcher);
  }
}
