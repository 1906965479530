import { ColDef, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { eventsListGridColumns } from '../../tablesFormShapes/events';
import {
  numberParserForFilter as numberParser,
  transformToColumns,
  dateFormatter,
  createMapFromObjectOfObjects,
  createMapFromArrayOfObjects,
} from '../../utils/helpers';
import { CellClickedEvent } from 'ag-grid-community';
import { onCellClicked } from './index';

import {
  platformType as platformTypeEnum,
  usageType as usageTypeEnum,
  eventType as eventTypeEnum,
  eventSubType as eventSubTypeEnum,
  serviceModelType as serviceModelTypeEnum,
  contentType as contentTypeEnum,
  deliveryType as deliveryTypeEnum,
  eventModeType as eventModeTypeEnum,
  supportRegions as supportRegionsEnum,
  eventCallProvider as eventCallProviderEnum,
  eventLobby as eventLobbyEnum,
  mmcResellerTypes as mmcResellerEnum,
} from '../../enums';
import {
  today,
  tomorrow,
  yesterday,
  thisWeek,
  nextWeek,
  thisMonth,
  lastMonth,
} from '../../utils/agGridCustomFilterOptions';

const platformTypesMap = createMapFromObjectOfObjects(platformTypeEnum, 'id', 'name');
const accountUsageTypesMap = createMapFromObjectOfObjects(usageTypeEnum, 'id', 'name');
const accountMmcResellerTypesMap = createMapFromObjectOfObjects(mmcResellerEnum, 'id', 'name');
const eventTypesMap = createMapFromObjectOfObjects(eventTypeEnum, 'id', 'name');
const eventSubTypesMap = createMapFromObjectOfObjects(eventSubTypeEnum, 'id', 'name');
const serviceModelTypesMap = createMapFromObjectOfObjects(serviceModelTypeEnum, 'id', 'name');
const contentTypesMap = createMapFromObjectOfObjects(contentTypeEnum, 'id', 'name');
const deliveryTypesMap = createMapFromObjectOfObjects(deliveryTypeEnum, 'id', 'name');
const eventModeTypesMap = createMapFromObjectOfObjects(eventModeTypeEnum, 'id', 'name');
const eventUsageTypesMap = createMapFromObjectOfObjects(usageTypeEnum, 'id', 'name');
const supportRegionsMap = createMapFromArrayOfObjects(supportRegionsEnum, 'id', 'name');
const eventCallProvidersMap = createMapFromObjectOfObjects(eventCallProviderEnum, 'id', 'name');
const eventLobbyMap = createMapFromObjectOfObjects(eventLobbyEnum, 'id', 'name');
const booleanMap = {
  0: 'False',
  1: 'True',
};

const dateFilterOptions = [
  'equals',
  'greaterThan',
  'lessThan',
  'notEqual',
  'inRange',
  today,
  yesterday,
  tomorrow,
  thisWeek,
  nextWeek,
  thisMonth,
  lastMonth,
];

export default function(): ColDef[] {
  return transformToColumns(
    eventsListGridColumns,
    {
      name: {
        onCellClicked: (event: CellClickedEvent) => onCellClicked(event.colDef.field, event.data, window.open),
        cellStyle: { color: '#459699', cursor: 'pointer' },
      },
      projectName: {
        onCellClicked: (event: CellClickedEvent) => onCellClicked(event.colDef.field, event.data, window.open),
        cellStyle: { color: '#459699', cursor: 'pointer' },
      },
      platform: {
        filterParams: {
          suppressSorting: true,
          debounceMs: 1000,
          values: Object.keys(platformTypesMap),
          valueFormatter: (params: ValueFormatterParams) => platformTypesMap[params.value] || params.value,
        },
        valueGetter: (params: ValueGetterParams) => platformTypesMap[params.data.platform] || params.data.platform,
      },
      id: {
        hide: true,
        sort: 'desc',
      },

      accountUsageType: {
        filterParams: {
          suppressSorting: true,
          debounceMs: 1000,
          values: Object.keys(accountUsageTypesMap),
          valueFormatter: (params: ValueFormatterParams) => accountUsageTypesMap[params.value] || params.value,
        },
        valueGetter: (params: ValueGetterParams) =>
          accountUsageTypesMap[params.data.accountUsageType] || params.data.accountUsageType,
      },
      eventType: {
        filterParams: {
          suppressSorting: true,
          debounceMs: 1000,
          values: Object.keys(eventTypesMap),
          valueFormatter: (params: ValueFormatterParams) => eventTypesMap[params.value] || params.value,
        },
        valueGetter: (params: ValueGetterParams) => eventTypesMap[params.data.eventType] || params.data.eventType,
      },
      eventSubType: {
        filterParams: {
          suppressSorting: true,
          debounceMs: 1000,
          values: Object.keys(eventSubTypesMap),
          valueFormatter: (params: ValueFormatterParams) => eventSubTypesMap[params.value] || params.value,
        },
        valueGetter: (params: ValueGetterParams) =>
          eventSubTypesMap[params.data.eventSubType] || params.data.eventSubType,
      },
      startDate: {
        floatingFilter: false,
        valueGetter: dateFormatter('startDate'),
        filter: 'agDateColumnFilter',
        filterParams: {
          includeTime: true,
          filterOptions: dateFilterOptions,
        },
      },
      endDate: {
        floatingFilter: false,
        valueGetter: dateFormatter('endDate'),
        hide: true,
        filterParams: {
          includeTime: true,
          filterOptions: dateFilterOptions,
        },
      },
      expiryDate: {
        floatingFilter: false,
        valueGetter: dateFormatter('expiryDate'),
        hide: true,
        filterParams: {
          includeTime: true,
          filterOptions: dateFilterOptions,
        },
      },
      serviceModelType: {
        filterParams: {
          suppressSorting: true,
          debounceMs: 1000,
          values: Object.keys(serviceModelTypesMap),
          valueFormatter: (params: ValueFormatterParams) => serviceModelTypesMap[params.value] || params.value,
        },
        valueGetter: (params: ValueGetterParams) =>
          serviceModelTypesMap[params.data.serviceModelType] || params.data.serviceModelType,
      },
      contentType: {
        filterParams: {
          suppressSorting: true,
          debounceMs: 1000,
          values: Object.keys(contentTypesMap),
          valueFormatter: (params: ValueFormatterParams) => contentTypesMap[params.value] || params.value,
        },
        valueGetter: (params: ValueGetterParams) => contentTypesMap[params.data.contentType] || params.data.contentType,
      },
      deliveryType: {
        filterParams: {
          suppressSorting: true,
          debounceMs: 1000,
          values: Object.keys(deliveryTypesMap),
          valueFormatter: (params: ValueFormatterParams) => deliveryTypesMap[params.value] || params.value,
        },
        valueGetter: (params: ValueGetterParams) =>
          deliveryTypesMap[params.data.deliveryType] || params.data.deliveryType,
      },
      eventMode: {
        filterParams: {
          suppressSorting: true,
          debounceMs: 1000,
          values: Object.keys(eventModeTypesMap),
          valueFormatter: (params: ValueFormatterParams) => eventModeTypesMap[params.value] || params.value,
        },
        valueGetter: (params: ValueGetterParams) => eventModeTypesMap[params.data.eventMode] || params.data.eventMode,
      },
      usageType: {
        filterParams: {
          suppressSorting: true,
          debounceMs: 1000,
          values: Object.keys(eventUsageTypesMap),
          valueFormatter: (params: ValueFormatterParams) => eventUsageTypesMap[params.value] || params.value,
        },
        valueGetter: (params: ValueGetterParams) => eventUsageTypesMap[params.data.usageType] || params.data.usageType,
      },
      accountId: {
        hide: true,
      },
      eventId: {
        hide: true,
      },
      description: {
        hide: true,
      },
      isActive: {
        hide: true,
        filterParams: {
          suppressSorting: true,
          debounceMs: 1000,
          values: Object.keys(booleanMap),
          valueFormatter: (params: ValueFormatterParams) => booleanMap[params.value] || params.value,
        },
      },
      languageCount: {
        hide: true,
        filterParams: {
          numberParser,
        },
      },
      registrations: {
        hide: true,
        filterParams: {
          numberParser,
        },
      },
      liveViewers: {
        hide: true,
        filterParams: {
          numberParser,
        },
      },
      onDemandViewers: {
        hide: true,
        filterParams: {
          numberParser,
        },
      },
      isVideoChat: {
        hide: true,
        filterParams: {
          suppressSorting: true,
          debounceMs: 1000,
          values: Object.keys(booleanMap),
          valueFormatter: (params: ValueFormatterParams) => booleanMap[params.value] || params.value,
        },
      },
      breakoutRoomsCount: {
        hide: true,
        filterParams: {
          numberParser,
        },
      },
      linksCount: {
        hide: true,
        filterParams: {
          numberParser,
        },
      },
      projectId: {
        hide: true,
        filterParams: {
          numberParser,
        },
      },
      supportRegion: {
        hide: true,
        filterParams: {
          suppressSorting: true,
          debounceMs: 1000,
          values: Object.keys(supportRegionsMap),
          valueFormatter: (params: ValueFormatterParams) => supportRegionsMap[params.value] || params.value,
        },
        valueGetter: (params: ValueGetterParams) =>
          supportRegionsMap[params.data.supportRegion] || params.data.supportRegion,
      },
      eventCallProvider: {
        filterParams: {
          suppressSorting: true,
          debounceMs: 1000,
          values: Object.keys(eventCallProvidersMap),
          valueFormatter: (params: ValueFormatterParams) => eventCallProvidersMap[params.value] || params.value,
        },
        valueGetter: (params: ValueGetterParams) =>
          eventCallProvidersMap[params.data.eventCallProvider] || params.data.eventCallProvider,
      },
      lobby: {
        hide: true,
        filterParams: {
          suppressSorting: true,
          debounceMs: 1000,
          values: Object.keys(eventLobbyMap),
          valueFormatter: (params: ValueFormatterParams) => eventLobbyMap[params.value] || params.value,
        },
        valueGetter: (params: ValueGetterParams) => eventLobbyMap[params.data.lobby] || params.data.lobby,
      },
      accountMmcResellerType: {
        hide: true,
        filterParams: {
          suppressSorting: true,
          debounceMs: 1000,
          values: Object.keys(accountMmcResellerTypesMap),
          valueFormatter: (params: ValueFormatterParams) => accountMmcResellerTypesMap[params.value] || params.value,
        },
        valueGetter: (params: ValueGetterParams) =>
          accountMmcResellerTypesMap[params.data.accountMmcResellerType] || params.data.accountMmcResellerType,
      },
      projectManager: { hide: true },
      mmcItemId: { hide: true },
      mmcItemHash: { hide: true },
      mmcPlayerId: { hide: true },
      studioEventKey: { hide: true },
      studioShowKey: { hide: true },
      studioShowName: { hide: true },
      duration: {
        filterParams: {
          numberParser,
        },
      },
      hostingDuration: {
        filterParams: {
          numberParser,
        },
      },
      participants: {
        filterParams: {
          numberParser,
        },
      },
    },
    { minWidth: 200 },
  );
}
