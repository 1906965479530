import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import styles from './Salesforce.module.scss';

export interface MakeFulfilledData {
  projectName: string;
  fullfillmentDate: Date;
}

interface MakeFulfilledModalProps {
  isOpen: boolean;
  projectName: string;
  onSave: (data: MakeFulfilledData) => void;
  onClose: () => void;
}

export function MakeFulfilledModal({ isOpen, projectName, onSave, onClose }: MakeFulfilledModalProps) {
  const [projectNameForm, setProjectName] = useState(projectName);
  const [fullfillmentDate, setDate] = useState(new Date());
  const [isConfirmOpen, setConfirmOpen] = useState(false);

  const toggleConfirm = () => {
    setConfirmOpen(!isConfirmOpen);
  };

  const save = () => {
    toggleConfirm();
    onSave({ fullfillmentDate, projectName: projectNameForm });
  };

  useEffect(() => {
    setProjectName(projectName);
  }, [projectName]);

  return (
    <div>
      <Modal className={styles.modal} isOpen={isOpen} toggle={onClose}>
        <ModalHeader toggle={onClose}>Fulfill the selected PLI(s)</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="MakeFulfilledModalProjectName">Project Name</Label>
            <Input
              type="text"
              value={projectNameForm}
              onChange={e => setProjectName(e.target.value)}
              name="projectName"
              id="MakeFulfilledModalProjectName"
            />
          </FormGroup>
          <FormGroup>
            <Label for="MakeFulfilledModalFulfillmentDate">Fulfillment Date</Label>
            <DatePicker
              selected={fullfillmentDate}
              onChange={(date: Date) => setDate(date)}
              wrapperClassName="form-control"
              className="form-control"
              name="date"
              id="date"
              required={true}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button color="primary" onClick={toggleConfirm}>
            Save
          </Button>
        </ModalFooter>
      </Modal>
      <Modal className={styles.modal} isOpen={isConfirmOpen} toggle={toggleConfirm}>
        <ModalHeader toggle={toggleConfirm}>
          Are you sure you want to Fulfill the selected PLI(s)? Once fulfilled it cannot be undone.
        </ModalHeader>
        <ModalFooter>
          <Button color="secondary" onClick={toggleConfirm}>
            Cancel
          </Button>
          <Button color="danger" onClick={save}>
            Yes
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default MakeFulfilledModal;
