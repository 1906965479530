import { call, take, put } from 'redux-saga/effects';
import { callApi } from '../utils/helpers';
import { FETCH, fetchSuccess, fetchFailure, FETCH_ONE, fetchOneSuccess, fetchOneFailure } from '../actions/accounts';
import api from '../utils/api';
import { matchPath } from 'react-router-dom';
import { Routes } from '../constants';

export default function* accounts() {
  while (true) {
    yield take(FETCH);
    try {
      const response = yield call(callApi, api.getAccounts);
      yield put(fetchSuccess(response.data));
    } catch (e) {
      console.error(e);
      yield put(fetchFailure(e));
    }
  }
}

export function* oneAccount() {
  while (true) {
    yield take(FETCH_ONE);
    try {
      const matches = matchPath(location.pathname, {
        path: Routes.AccountsDetail,
        exact: true,
      });
      const platform = new URLSearchParams(location.search).get('platform');
      const accountId = matches && matches.params['id'];
      const response = yield call(callApi, api.getAccount, accountId, platform);

      yield put(fetchOneSuccess(response.data));
    } catch (e) {
      console.error(e);
      yield put(fetchOneFailure(e));
    }
  }
}
