import React, { useEffect, useState, MouseEvent } from 'react';
import styles from './PLIDetail.module.scss';
import { Container, Row, Col, Form, Button } from 'reactstrap';
import omit from 'lodash/omit';
import { IPlis } from '../../utils/api.d';
import FormField from '../FormField';
import { useBreakpoints } from '../../utils/hooks';
import { convertToPlainObjectWithEnums, makeColumns, standardDateFormat } from '../../utils/helpers';
import { salesforceListGridColumns } from '../../tablesFormShapes/salesforce';
import { history } from '../../store';
import { Routes } from '../../constants';
import { dateOnlySalesForceFields } from '../../enums';

export interface IPLIState {
  pli: IPlis;
}

const PLIDetails = ({ pli }: IPLIState) => {
  const { xl } = useBreakpoints();
  const cpli = convertToPlainObjectWithEnums(pli || {});
  const [cPLI, setCurrentPLI] = useState(cpli);

  useEffect(() => {
    const _pli = convertToPlainObjectWithEnums(pli || {});
    setCurrentPLI(_pli);
  }, [pli]);

  type plisInfoKeys = keyof typeof salesforceListGridColumns;

  const keys = Object.keys(omit(cPLI, ['id', 'projectId'])) as plisInfoKeys[];
  const elements: plisInfoKeys[][] = makeColumns<plisInfoKeys>(keys, xl ? 3 : 2);

  const close = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    history.push(Routes.Salesforce);
  };

  const fields = elements.map((els, i) => (
    <Col key={`field${i}`} xl={4} md={6}>
      {els.map(key => {
        const pli = salesforceListGridColumns[key] || {};

        let date;

        if (pli.type === 'datetime-local' && cPLI[key]) {
          date = standardDateFormat(cPLI[key], !dateOnlySalesForceFields.includes(key));
        }

        return (
          <FormField
            key={key}
            name={key}
            disabled={key !== 'projectId'}
            type={pli.type || 'text'}
            label={pli.label || key}
            valueFormat={pli.valueFormat}
            value={date || cPLI[key]}
            onChange={() => true}
          />
        );
      })}
    </Col>
  ));

  return (
    <Form style={{ width: '100%' }}>
      <Container fluid>
        <Row>{fields}</Row>
        <hr />
        <Row>
          <Col className="my-1" xl={1} sm={3} md={2}>
            <Button className={styles.closeButton} onClick={close} color="secondary">
              Return to List
            </Button>
          </Col>
          <Col />
        </Row>
      </Container>
    </Form>
  );
};

export default PLIDetails;
