export const projectLabelsMap = {
  ['Name']: 'Project Name',
  ['TypeId']: 'Project Type',
  ['StatusId']: 'Project Status',
  ['EventSubTypeId']: 'Event Type',
  ['ServiceModelId']: 'Service Model',
  ['UsageTypeId']: 'Usage',
  ['Duration']: 'Purchased Duration',
  ['HostingDays']: 'Hosting Duration',
  ['Languages']: 'Language Qty',
  ['LiveViewers']: 'Viewers Purchased',
  ['ContentTypeId']: 'Content',
  ['DeliveryTypeId']: 'Delivery',
  ['ProjectExpiryDate']: 'Project Expiry Date',
  ['ExpectedFulfillment']: 'Expected Fulfillment',
  ['EventQuantity']: 'Events Allowed',
  ['ShowInProjectPicker']: 'Show in project picker',
  ['NotesText']: 'Notes',
  ['RecurringRolloverTerm']: 'Recurring Rollover Term',
  ['EventStart']: 'Event Start',
  ['EventEnd']: 'Event End',
  ['SupportRegionId']: 'Support Region',
  ['FulfillmentStart']: 'Fulfillment Start',
  ['FulfillmentEnd']: 'Fulfillment End',
  ['FulfillmentStatusId']: 'Fulfillment Status',
  ['Registrants']: 'Registrants',
  ['SiteId']: 'Site Id',
  ['SiteUrl']: 'Site Url',
  ['CfpOpenDate']: 'Cfp Open Date',
  ['CfpCloseDate']: 'Cfp Close Date',
  ['ClientContactName']: 'Client Contact Name',
  ['ClientContactEmail']: 'Client Contact Email',
  ['AllottedTimeEventProjectManagement']: 'Event Project Management',
  ['AllottedTimeEventConfiguration']: 'Event Configuration',
  ['AllottedTimeEventFrontLineSupport']: 'Event FrontLine Support',
  ['AllottedTimeTechnicalConsultingServices']: 'Technical Consulting Services',
  ['AllottedTimeCreativeCustomServices']: 'Creative Custom Services',
  ['AllottedTimeWebcastSupport']: 'Webcast Support',
  ['AllottedTimeWebcastOnboarding']: 'Webcast Onboarding',
  ['ProjectManager']: 'Project Manager',
};

export const dateTimeFields = ['EventStart', 'EventEnd'];
export const dateFields = ['FulfillmentStart', 'FulfillmentEnd', 'CfpOpenDate', 'CfpCloseDate'];
