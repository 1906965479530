import React, { ChangeEvent } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getTimeTrackingBuckets } from '../../../reducers/timeTracking';
import { ITimeTrackingBucket } from '../../../utils/api.d';
import styles from '../AddNewProject.module.scss';
import cn from 'classnames';
import { updateBucket } from '../../../actions/timeTracking';
import { N3AEntitlements } from '../../../constants';
import AccessControl from '../../../components/AccessControl';
import { durationConverter } from '../../../utils/helpers';

const bucketTypes = {
  EventProjectManagement: {
    value: 'EventProjectManagement',
    name: 'Event Project Management',
  },
  EventConfiguration: {
    value: 'EventConfiguration',
    name: 'Event Configuration',
  },
  EventFrontLineSupport: {
    value: 'EventFrontLineSupport',
    name: 'Event Front Line Support',
  },
  TechnicalConsultingServices: {
    value: 'TechnicalConsultingServices',
    name: 'Tech Consulting Services',
  },
  CreativeCustomServices: {
    value: 'CreativeCustomServices',
    name: 'Creative/Custom Services',
  },
  WebcastSupport: {
    value: 'WebcastSupport',
    name: 'Webcast Support',
  },
  WebcastOnboarding: {
    value: 'WebcastOnboarding',
    name: 'Webcast Onboarding',
  },
};
const bucketKeys = Object.keys(bucketTypes);

export function TimeTrackingBuckets() {
  const buckets = useSelector(getTimeTrackingBuckets, shallowEqual);
  const dispatch = useDispatch();
  const onBucketChange = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const bucket = target.dataset.bucket;
    const value = +event.target.value;
    dispatch(
      updateBucket({
        timetrackingType: bucket,
        allottedTime: value,
      }),
    );
  };
  if (buckets.isLoading) {
    return null;
  }
  return (
    <AccessControl actions={[N3AEntitlements.Admin]} errorProps={{ disabled: true }}>
      <fieldset>
        <div className={styles.timeTrackingBuckets}>
          {bucketKeys.map(bucket => {
            const defValue = buckets.data.find(i => i.timetrackingType === bucket) || ({} as ITimeTrackingBucket);
            const actualTime = defValue.actualTime || 0; // NOTE: in minutes
            const max = defValue.allottedTime || 0; // NOTE: in hours
            const allottedTimeMinutes = max * 60; // convert hours into minutes
            return (
              <label
                key={bucket}
                className={cn(
                  styles.timeTrackingBucket,
                  actualTime >= allottedTimeMinutes * 0.75 && styles.timeTrackingBucketWarning,
                  actualTime >= allottedTimeMinutes && styles.timeTrackingBucketOvertime,
                )}
              >
                <div className={styles.timeTrackingBucketTitle}>{bucketTypes[bucket].name}</div>
                <input
                  form="projectFormId"
                  min={0}
                  type="number"
                  step="0.01"
                  defaultValue={max}
                  className="form-control"
                  onBlur={onBucketChange}
                  data-bucket={bucket}
                />
                <progress
                  className={styles.timeTrackingBucketProgress}
                  title={`Actual time: ${durationConverter(actualTime)}, Allotted time: ${durationConverter(
                    allottedTimeMinutes,
                  )}`}
                  value={actualTime}
                  max={allottedTimeMinutes}
                />
              </label>
            );
          })}
        </div>
      </fieldset>
    </AccessControl>
  );
}
