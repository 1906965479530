import createReducer from '../utils/redux-create-reducer';
import { GET_REPORT_URL, GET_REPORT_URL_FAILURE, GET_REPORT_URL_SUCCESS } from '../actions/reportsDownloader';

export interface IReportUrlState {
  isLoading?: boolean;
  error?: Error;
}

interface IState {
  reportUrl: IReportUrlState;
}

export const initialState: IReportUrlState = {
  isLoading: false,
  error: undefined,
};

export const getReportUrl = (state: IState): IReportUrlState => state.reportUrl;

export default createReducer<IReportUrlState>(initialState, {
  [GET_REPORT_URL]: () => ({ isLoading: true }),
  [GET_REPORT_URL_SUCCESS]: () => ({ isLoading: false }),
  [GET_REPORT_URL_FAILURE]: error => ({ error, isLoading: false }),
});
