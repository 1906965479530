import React, { useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { Button } from 'reactstrap';
import getColumns from './columns';

import { IStaffsState, getStaffs } from '../../reducers/staffs';
import { AgGridEvent, ColumnApi, GridApi, RowClickedEvent } from 'ag-grid-community';
import makeGridOptions from '../../gridOptions';
import { Routes } from '../../constants';
import { history } from '../../store';
import { Grid } from '../../components/Grid';
import { AppGrids } from '../../enums';
import { checkForSavedGrid, gridStateSaver, resetAllGridFilters } from '../../utils/helpers';
import { getUser } from '../../reducers/user';

export default function Staffs() {
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [gridColumnApi, setColumnApi] = useState<ColumnApi | null>(null);
  const { list }: IStaffsState = useSelector(getStaffs, shallowEqual);
  const { emailAddress } = useSelector(getUser, shallowEqual) || {};

  const columnDefs = getColumns();
  const onGridReady = (event: AgGridEvent) => {
    setGridApi(event.api);
    setColumnApi(event.columnApi);
  };

  const gridOptions = makeGridOptions(columnDefs, { onGridReady, pagination: true, paginationPageSize: 10 });

  /*const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    gridApi && gridApi.setQuickFilter(e.target.value);
  };*/

  const newStaff = () => {
    history.push(Routes.NewStaff);
  };

  const onRowClicked = (row: RowClickedEvent) => {
    window.open(Routes.NewStaff + '?id=' + row.data.id);
  };

  const gridName = `${emailAddress}-${AppGrids.StaffList}`;
  const gridConfigName = `${emailAddress}-${AppGrids.StaffList}-config`;

  const saveState = () => gridColumnApi && gridStateSaver(gridColumnApi, gridName);
  if (gridColumnApi) {
    checkForSavedGrid(gridColumnApi, gridName, gridConfigName, columnDefs);
    gridStateSaver(gridColumnApi, gridName);
  }

  const resetAllFilters = () => resetAllGridFilters(gridApi);

  return (
    <>
      <div>
        <div
          className="float-left"
          style={{
            fontSize: '24px',
            fontFamily:
              '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
          }}
        >
          Staff
        </div>

        <Button
          color="primary"
          className="float-right btn-primary"
          onClick={newStaff}
          style={{
            fontFamily:
              '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
            fontSize: '1rem',
            backgroundColor: 'rgb(67, 73, 77)',
            borderBottomColor: 'rgb(67, 73, 77)',
          }}
        >
          Add staff
        </Button>

        <Button className={'float-right margin-right-10'} color="primary" onClick={resetAllFilters}>
          Reset Filters
        </Button>
      </div>
      <Grid
        rowData={Array.isArray(list) ? list : []}
        gridOptions={gridOptions}
        onRowClicked={onRowClicked}
        onColumnMoved={saveState}
        onColumnVisible={saveState}
        onColumnResized={saveState}
      />
    </>
  );
}
