// Polyfills to support IE11
import '@babel/polyfill';
import 'react-app-polyfill/ie11';
import 'core-js/features/array/includes';
import 'core-js/features/array/find';
import 'core-js/features/object/assign';
import 'core-js/features/object/values';
import 'core-js/features/promise';
import 'date-time-format-timezone';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.css';

import App from './App';
import store from './store';
import './assets/font-awesome/css/all.min.css';
import './App.scss';

const rootElement = document.getElementById('unison-teamwork') as HTMLElement;

const render = (Application: React.FC): void => {
  ReactDOM.render(
    <Provider store={store}>
      <Application />
    </Provider>,
    rootElement,
  );
};

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(<App />, rootElement);
} else {
  render(App);
}

if (module.hot) {
  module.hot.accept('./App', () => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const NextApp = require('./App').default;
    render(NextApp);
  });
}
