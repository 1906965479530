import { call, take, put } from 'redux-saga/effects';
import { callApi } from '../utils/helpers';
import {
  FETCH,
  fetchSuccess,
  fetchFailure,
  UPDATE_TIME_TRACKING,
  updateFailure,
  updateSuccess,
  DELETE,
  delSuccess,
  delFailure,
  ADD_TIME_TRACKING,
  addSuccess,
  addFailure,
  FETCH_ONE,
  fetchOneSuccess,
  fetchOneFailure,
  FETCH_BY_PROJECT_ID,
  fetchByProjectIdSuccess,
  fetchByProjectIdFailure,
  FETCH_BUCKETS,
  fetchBucketsSuccess,
  fetchBucketsFailure,
} from '../actions/timeTracking';
import api from '../utils/api';
import { Routes } from '../constants';
import { push } from 'connected-react-router';
import { matchPath } from 'react-router-dom';
import { replace } from 'connected-react-router';
import { notify } from '../actions/notification';
import omit from 'lodash/omit';

const getRedirectUrl = () => {
  const newMatches = matchPath(location.pathname, {
    path: Routes.NewTimeTracking,
    exact: true,
  });
  const editMatches = matchPath(location.pathname, {
    path: Routes.EditTimeTracking,
    exact: true,
  });
  const matches = newMatches || editMatches;
  const backUrl = matches && matches.params['backUrl'];
  if (backUrl && backUrl !== ':backUrl') {
    return decodeURIComponent(backUrl);
  }
  return Routes.TimeTracking;
};

export function* timeTracking() {
  while (true) {
    yield take(FETCH);
    try {
      const response = yield call(callApi, api.getTimeTracking);
      yield put(fetchSuccess(response.data.data));
    } catch (e) {
      console.error(e);
      yield put(fetchFailure(e));
    }
  }
}

export function* oneTimeTracking() {
  while (true) {
    yield take(FETCH_ONE);
    const matches = matchPath(location.pathname, {
      path: Routes.EditTimeTracking,
      exact: true,
    });
    const id = matches && matches.params['id'];
    if (id) {
      try {
        const response = yield call(callApi, api.getTimeTrackingById, id);
        yield put(fetchOneSuccess(response.data));
      } catch (e) {
        console.error(e);
        yield put(fetchOneFailure(e));
      }
    } else {
      yield put(fetchOneFailure(new Error('no id')));
    }
  }
}

export function* addTimeTracking() {
  while (true) {
    const project = yield take(ADD_TIME_TRACKING);
    const data = omit(project.payload, 'saveAndCreateNewTimeTracking');
    const { saveAndCreateNewTimeTracking } = project.payload;
    try {
      const response = yield call(callApi, api.addTimeTracking, data);
      yield put(addSuccess(response.data));

      if (saveAndCreateNewTimeTracking) {
        yield put(replace(location.pathname));
      } else {
        yield put(push(getRedirectUrl()));
      }
    } catch (e) {
      console.error(e);
      yield put(addFailure(e));
      yield put(replace(location.pathname));
      yield put(notify("We're sorry, there was a problem. Please try again.", 'danger'));
    }
  }
}

export function* updateTimeTracking() {
  while (true) {
    const timeTracking = yield take(UPDATE_TIME_TRACKING);
    try {
      const response = yield call(callApi, api.updateTimeTracking, timeTracking.payload);
      yield put(updateSuccess(response.data));
    } catch (e) {
      console.error(e);
      yield put(updateFailure(e));
    }
    yield put(push(getRedirectUrl()));
  }
}

export function* deleteTimeTracking() {
  while (true) {
    const action = yield take(DELETE);
    try {
      yield call(callApi, api.delTimeTracking, action.payload);
      yield put(delSuccess());
    } catch (e) {
      console.error(e);
      yield put(delFailure(e));
    }
    yield put(push(getRedirectUrl()));
  }
}

export function* timeTrackingByProjectId() {
  while (true) {
    const { payload } = yield take(FETCH_BY_PROJECT_ID);
    try {
      const response = yield call(callApi, api.getTimeTrackingByProjectId, payload);
      yield put(fetchByProjectIdSuccess(response.data));
    } catch (e) {
      console.error(e);
      yield put(fetchByProjectIdFailure(e));
    }
  }
}

export function* timeTrackingBuckets() {
  while (true) {
    const { payload: projectId } = yield take(FETCH_BUCKETS);
    try {
      const response = yield call(callApi, api.getTimeTrackingBuckets, projectId);
      yield put(fetchBucketsSuccess(response.data));
    } catch (e) {
      console.error(e);
      yield put(fetchBucketsFailure(e));
    }
  }
}
