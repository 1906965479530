// use 'npm run g:a {name}' - to generate new action
import { createEmptyAction, createAction } from '../utils/actions';

export const FETCH = 'SALESFORCE/FETCH';
export const FETCH_SUCCESS = 'SALESFORCE/FETCH_SUCCESS';
export const FETCH_FAILURE = 'SALESFORCE/FETCH_FAILURE';

export const FETCH_BY_PROJECT = 'SALESFORCE/FETCH_BY_PROJECT';
export const FETCH_BY_PROJECT_SUCCESS = 'SALESFORCE/FETCH_BY_PROJECT_SUCCESS';
export const FETCH_BY_PROJECT_FAILURE = 'SALESFORCE/FETCH_BY_PROJECT_FAILURE';

export const FETCH_BY_ID_LIST = 'SALESFORCE/FETCH_BY_ID_LIST';
export const FETCH_BY_ID_LIST_SUCCESS = 'SALESFORCE/FETCH_BY_ID_LIST_SUCCESS';
export const FETCH_BY_ID_LIST_FAILURE = 'SALESFORCE/FETCH_BY_ID_LIST_FAILURE';

export const PUT_SALESFORCE = 'SALESFORCE/PUT';
export const PUT_SUCCESS = 'SALESFORCE/PUT_SUCCESS';
export const PUT_FAILURE = 'SALESFORCE/PUT_FAILURE';

export const UPDATE_SALESFORCE = 'SALESFORCE/UPDATE';
export const UPDATE_SUCCESS = 'SALESFORCE/UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'SALESFORCE/UPDATE_FAILURE';

export const CLEAN = 'SALESFORCE/CLEAN';

export const DELETE_BY_PROJECT = 'TIME_TRACKING/DELETE_BY_PROJECT';
export const DELETE_BY_PROJECT_SUCCESS = 'TIME_TRACKING/DELETE_BY_PROJECT_SUCCESS';
export const DELETE_BY_PROJECT_FAILURE = 'TIME_TRACKING/DELETE_BY_PROJECT_FAILURE';

export const types = {
  PUT_SALESFORCE,
  PUT_SUCCESS,
  PUT_FAILURE,
  FETCH,
  FETCH_SUCCESS,
  FETCH_FAILURE,
  FETCH_BY_PROJECT,
  FETCH_BY_PROJECT_SUCCESS,
  FETCH_BY_PROJECT_FAILURE,
  FETCH_BY_ID_LIST,
  FETCH_BY_ID_LIST_SUCCESS,
  FETCH_BY_ID_LIST_FAILURE,
  DELETE_BY_PROJECT,
  DELETE_BY_PROJECT_SUCCESS,
  DELETE_BY_PROJECT_FAILURE,
};

export const clean = createEmptyAction(CLEAN);

export const put = createAction(PUT_SALESFORCE);
export const putSuccess = createAction(PUT_SUCCESS);
export const putFailure = createAction<Error>(PUT_FAILURE);

export const fetch = createEmptyAction(FETCH);
export const fetchSuccess = createAction(FETCH_SUCCESS);
export const fetchFailure = createAction<Error>(FETCH_FAILURE);

export const fetchByIdList = createAction(FETCH_BY_ID_LIST);
export const fetchByIdListSuccess = createAction(FETCH_BY_ID_LIST_SUCCESS);
export const fetchByIdListFailure = createAction<Error>(FETCH_BY_ID_LIST_FAILURE);

export const fetchByProject = createAction(FETCH_BY_PROJECT);
export const fetchByProjectSuccess = createAction(FETCH_BY_PROJECT_SUCCESS);
export const fetchByProjectFailure = createAction<Error>(FETCH_BY_PROJECT_FAILURE);

export const update = createAction(UPDATE_SALESFORCE);
export const updateSuccess = createAction(UPDATE_SUCCESS);
export const updateFailure = createAction<Error>(UPDATE_FAILURE);

export const delByProject = createAction(DELETE_BY_PROJECT);
export const delByProjectSuccess = createEmptyAction(DELETE_BY_PROJECT_SUCCESS);
export const delByProjectFailure = createAction<Error>(DELETE_BY_PROJECT_FAILURE);

export default {
  types,
  put,
  putSuccess,
  putFailure,
  fetch,
  fetchSuccess,
  fetchFailure,
  fetchByProject,
  fetchByProjectSuccess,
  fetchByProjectFailure,
  fetchByIdList,
  fetchByIdListSuccess,
  fetchByIdListFailure,
};
