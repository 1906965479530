// use 'npm run g:a {name}' - to generate new action
import { createEmptyAction, createAction } from '../utils/actions';

export const FETCH = 'PROJECTS/FETCH';
export const FETCH_SUCCESS = 'PROJECTS/FETCH_SUCCESS';
export const FETCH_FAILURE = 'PROJECTS/FETCH_FAILURE';

export const ADD_NEW_PROJECT = 'PROJECTS/ADD_NEW_UI';

export const FETCH_BYID = 'PROJECTS/FETCH_BYID';
export const FETCH_BYID_SUCCESS = 'PROJECTS/FETCH_BYID_SUCCESS';
export const FETCH_BYID_FAILURE = 'PROJECTS/FETCH_BYID_FAILURE';

export const FETCH_BYDSAID = 'PROJECTS/FETCH_BYDSAID';
export const FETCH_BYDSAID_SUCCESS = 'PROJECTS/FETCH_BYDSAID_SUCCESS';
export const FETCH_BYDSAID_FAILURE = 'PROJECTS/FETCH_BYDSAID_FAILURE';

export const FETCH_WITH_SUBS = 'PROJECTS/FETCH_WITH_SUBS';
export const FETCH_WITH_SUBS_SUCCESS = 'PROJECTS/FETCH_WITH_SUBS_SUCCESS';
export const FETCH_WITH_SUBS_FAILURE = 'PROJECTS/FETCH_WITH_SUBS_FAILURE';

export const PUT_PROJECTS = 'PROJECTS/PUT';
export const PUT_SUCCESS = 'PROJECTS/PUT_SUCCESS';
export const PUT_FAILURE = 'PROJECTS/PUT_FAILURE';

export const POST_PROJECTS = 'PROJECTS/POST';
export const POST_SUCCESS = 'PROJECTS/POST_SUCCESS';
export const POST_FAILURE = 'PROJECTS/POST_FAILURE';

export const DELETE_PROJECT = 'PROJECTS/DELETE_PROJECT';
export const DELETE_PROJECT_SUCCESS = 'PROJECTS/DELETE_PROJECT_SUCCESS';
export const DELETE_PROJECT_FAILURE = 'PROJECTS/DELETE_PROJECT_FAILURE';

export const COPY_PROJECT = 'PROJECTS/COPY_PROJECT';
export const COPY_PROJECT_SUCCESS = 'PROJECTS/COPY_PROJECT_SUCCESS';
export const COPY_PROJECT_FAILURE = 'PROJECTS/COPY_PROJECT_FAILURE';

export const DELETE_STAFF_FROM_PROJECT = 'PROJECTS/DELETE_STAFF_FROM_PROJECT';
export const DELETE_STAFF_FROM_PROJECT_SUCCESS = 'PROJECTS/DELETE_STAFF_FROM_PROJECT_SUCCESS';
export const DELETE_STAFF_FROM_PROJECT_FAILURE = 'PROJECTS/DELETE_STAFF_FROM_PROJECT_FAILURE';

export const ADD_STAFF_TO_PROJECT = 'PROJECTS/ADD_STAFF_TO_PROJECT';
export const ADD_STAFF_TO_PROJECT_SUCCESS = 'PROJECTS/ADD_STAFF_TO_PROJECT_SUCCESS';
export const ADD_STAFF_TO_PROJECT_FAILURE = 'PROJECTS/ADD_STAFF_TO_PROJECT_FAILURE';

export const types = {
  PUT_PROJECTS,
  PUT_SUCCESS,
  PUT_FAILURE,
  FETCH,
  FETCH_SUCCESS,
  FETCH_FAILURE,
  FETCH_BYID,
  FETCH_BYID_SUCCESS,
  FETCH_BYID_FAILURE,
  FETCH_BYDSAID,
  FETCH_BYDSAID_SUCCESS,
  FETCH_BYDSAID_FAILURE,
  FETCH_WITH_SUBS,
  FETCH_WITH_SUBS_SUCCESS,
  FETCH_WITH_SUBS_FAILURE,
  DELETE_PROJECT,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAILURE,
  COPY_PROJECT,
  COPY_PROJECT_SUCCESS,
  COPY_PROJECT_FAILURE,
  DELETE_STAFF_FROM_PROJECT,
  DELETE_STAFF_FROM_PROJECT_SUCCESS,
  DELETE_STAFF_FROM_PROJECT_FAILURE,
  ADD_STAFF_TO_PROJECT,
  ADD_STAFF_TO_PROJECT_SUCCESS,
  ADD_STAFF_TO_PROJECT_FAILURE,
};

export const addNewProject = createAction(ADD_NEW_PROJECT);

export const put = createAction(PUT_PROJECTS);
export const putSuccess = createEmptyAction(PUT_SUCCESS);
export const putFailure = createAction<Error>(PUT_FAILURE);

export const fetch = createEmptyAction(FETCH);
export const fetchSuccess = createAction(FETCH_SUCCESS);
export const fetchFailure = createAction<Error>(FETCH_FAILURE);

export const fetchById = createAction(FETCH_BYID);
export const fetchByIdSuccess = createAction(FETCH_BYID_SUCCESS);
export const fetchByIdFailure = createAction<Error>(FETCH_BYID_FAILURE);

export const fetchByDSAId = createAction(FETCH_BYDSAID);
export const fetchByDSAIdSuccess = createAction(FETCH_BYDSAID_SUCCESS);
export const fetchByDSAIdFailure = createAction<Error>(FETCH_BYDSAID_FAILURE);

export const post = createAction(POST_PROJECTS);
export const postSuccess = createAction(POST_SUCCESS);
export const postFailure = createAction<Error>(POST_FAILURE);

export const deleteProject = createAction(DELETE_PROJECT);
export const deleteProjectSuccess = createEmptyAction(DELETE_PROJECT_SUCCESS);
export const deleteProjectFailure = createAction<Error>(DELETE_PROJECT_FAILURE);

export const fetchWithSubs = createEmptyAction(FETCH_WITH_SUBS);
export const fetchWithSubsSuccess = createAction(FETCH_WITH_SUBS_SUCCESS);
export const fetchWithSubsFailure = createAction<Error>(FETCH_WITH_SUBS_FAILURE);

export const copyProject = createAction(COPY_PROJECT);
export const copyProjectSuccess = createAction(COPY_PROJECT_SUCCESS);
export const copyProjectFailure = createAction<Error>(COPY_PROJECT_FAILURE);

export const deleteStaffFromProject = createAction<string>(DELETE_STAFF_FROM_PROJECT);
export const deleteStaffFromProjectSuccess = createEmptyAction(DELETE_STAFF_FROM_PROJECT_SUCCESS);
export const deleteStaffFromProjectFailure = createAction<Error>(DELETE_STAFF_FROM_PROJECT_FAILURE);

export const addStaffToProject = createAction<string>(ADD_STAFF_TO_PROJECT);
export const addStaffToProjectSuccess = createEmptyAction(ADD_STAFF_TO_PROJECT_SUCCESS);
export const addStaffToProjectFailure = createAction<Error>(ADD_STAFF_TO_PROJECT_FAILURE);

export default {
  types,
  put,
  putSuccess,
  putFailure,
  fetch,
  fetchSuccess,
  fetchFailure,
  fetchById,
  fetchByIdSuccess,
  fetchByIdFailure,
  fetchByDSAId,
  fetchByDSAIdSuccess,
  fetchByDSAIdFailure,
  deleteProject,
  deleteProjectSuccess,
  deleteProjectFailure,
  fetchWithSubs,
  fetchWithSubsSuccess,
  fetchWithSubsFailure,
  copyProject,
  copyProjectSuccess,
  copyProjectFailure,
  deleteStaffFromProject,
  deleteStaffFromProjectSuccess,
  deleteStaffFromProjectFailure,
  addStaffToProject,
  addStaffToProjectSuccess,
  addStaffToProjectFailure,
};
