import React, { useState } from 'react';
import styles from './eventsgrid.module.scss';
import getColumns from './columns';
import makeGridOptions from '../../gridOptions';
import { Grid } from '../Grid';
import { AgGridEvent, ColumnApi, GridApi, ServerSideStoreType } from 'ag-grid-community';
import {
  checkForSavedGrid,
  createServerSideDatasource,
  gridStateSaver,
  resetAllGridFilters,
} from '../../utils/helpers';
import { urls } from '../../utils/api';
import { useParams } from 'react-router-dom';
import { AppGrids } from '../../enums';
import { Button } from 'reactstrap';

interface IEventsGridProps {
  emailAddress: string;
}

const EventsGrid = (props: IEventsGridProps) => {
  const { emailAddress } = props;
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [gridColumnApi, setColumnApi] = useState<ColumnApi | null>(null);
  const columnDefs = getColumns();
  const { dsaid } = useParams();
  const onGridReady = (event: AgGridEvent) => {
    setGridApi(event.api);
    setColumnApi(event.columnApi);

    const datasource = createServerSideDatasource(urls.dsasEventsLazy, `dsaid=${dsaid}`);
    event.api.setServerSideDatasource(datasource);
  };

  const gridOptions = makeGridOptions(columnDefs, {
    onGridReady,
    rowModelType: 'serverSide',
    rowSelection: 'single',
    cacheBlockSize: 500,
    serverSideStoreType: ServerSideStoreType.Partial,
    animateRows: true,
    statusBar: { statusPanels: [] },
    sideBar: null,
  });

  const gridName = `${emailAddress}-${AppGrids.DsaDetailsEvents}`;
  const gridConfigName = `${emailAddress}-${AppGrids.DsaDetailsEvents}-config`;

  const saveState = () => gridColumnApi && gridStateSaver(gridColumnApi, gridName);

  if (gridColumnApi) {
    checkForSavedGrid(gridColumnApi, gridName, gridConfigName, columnDefs);
    gridStateSaver(gridColumnApi, gridName);
  }

  const resetAllFilters = () => resetAllGridFilters(gridApi);

  return (
    <>
      <div className={styles.projectsHeader}>
        <h4>Event Schedule</h4>
        <div>
          <Button color="primary" onClick={resetAllFilters}>
            Reset Filters
          </Button>
        </div>
      </div>
      <Grid
        className={styles.parentGrid}
        gridOptions={gridOptions}
        onColumnMoved={saveState}
        onColumnVisible={saveState}
        onColumnResized={saveState}
      />
    </>
  );
};

export default EventsGrid;
