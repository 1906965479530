// use 'npm run g:r {name}' - to generate new reducer and action (use --skip if action not needed)
import createReducer from '../utils/redux-create-reducer';
import {
  PUT_SUCCESS,
  PUT_SALESFORCE,
  PUT_FAILURE,
  FETCH,
  FETCH_SUCCESS,
  FETCH_FAILURE,
  FETCH_BY_PROJECT,
  FETCH_BY_PROJECT_SUCCESS,
  FETCH_BY_PROJECT_FAILURE,
  FETCH_BY_ID_LIST,
  FETCH_BY_ID_LIST_SUCCESS,
  FETCH_BY_ID_LIST_FAILURE,
  CLEAN,
  DELETE_BY_PROJECT,
  DELETE_BY_PROJECT_SUCCESS,
  DELETE_BY_PROJECT_FAILURE,
} from '../actions/salesforce';
import { IPlis } from '../utils/api.d';

export interface ISalesforceState {
  isLoading?: boolean;
  list: IPlis[];
  currentPLI: IPlis;
  error?: Error;
}

interface IState {
  salesforce: ISalesforceState;
}

export const initialState: ISalesforceState = {
  isLoading: false,
  list: [],
  currentPLI: {} as IPlis,
  error: undefined,
};

export const getSalesforce = (state: IState): ISalesforceState => state.salesforce;

export const getCurrentPLI = (state: IState): IPlis => state.salesforce.currentPLI;

export default createReducer<ISalesforceState>(initialState, {
  [FETCH]: () => ({ isLoading: true }),
  [FETCH_SUCCESS]: list => ({ list, isLoading: false }),
  [FETCH_FAILURE]: error => ({ error, isLoading: false }),
  [FETCH_BY_PROJECT]: () => ({ isLoading: true }),
  [FETCH_BY_PROJECT_SUCCESS]: list => ({ list, isLoading: false }),
  [FETCH_BY_PROJECT_FAILURE]: error => ({ error, isLoading: false }),
  [FETCH_BY_ID_LIST]: () => ({ isLoading: true }),
  [FETCH_BY_ID_LIST_SUCCESS]: list => ({
    list,
    isLoading: false,
    currentPLI: list && list.length > 0 ? list[0] : ({} as IPlis),
  }),
  [FETCH_BY_ID_LIST_FAILURE]: error => ({ error, isLoading: false }),
  [PUT_SALESFORCE]: () => ({ isLoading: true }),
  [PUT_SUCCESS]: list => ({ list, isLoading: false }),
  [PUT_FAILURE]: error => ({ error, isLoading: false }),
  [CLEAN]: () => ({ error: undefined, list: [], isLoading: false }),
  [DELETE_BY_PROJECT]: () => ({ isLoading: true }),
  [DELETE_BY_PROJECT_SUCCESS]: () => ({ isLoading: false }),
  [DELETE_BY_PROJECT_FAILURE]: error => ({ error, isLoading: false }),
});
