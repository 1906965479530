import { createAction, createEmptyAction } from '../utils/actions';

export const SET_USER = 'USER/SET';
export const DROP_USER = 'USER/DROP';
export const SWITCH_CUSTOMER = 'USER/SWITCH_CUSTOMER';

export const types = {
  SET_USER,
  DROP_USER,
  SWITCH_CUSTOMER,
};

export const switchCustomer = createAction(SWITCH_CUSTOMER);
export const setUser = createAction(SET_USER);
export const dropUser = createEmptyAction(DROP_USER);

export default {
  setUser,
  dropUser,
  switchCustomer,
};
