// use 'npm run g:r {name}' - to generate new reducer and action (use --skip if action not needed)
import createReducer from '../utils/redux-create-reducer';
import {
  FETCH,
  FETCH_SUCCESS,
  FETCH_FAILURE,
  FETCH_ONE,
  FETCH_ONE_SUCCESS,
  FETCH_ONE_FAILURE,
  ADD_STAFF,
  ADD_STAFF_FAILURE,
  ADD_STAFF_SUCCESS,
  UPDATE_STAFF,
  UPDATE_STAFF_FAILURE,
  UPDATE_STAFF_SUCCESS,
  FETCH_STAFF_LIST_BRIEF_INFO,
  FETCH_STAFF_LIST_BRIEF_INFO_SUCCESS,
  FETCH_STAFF_LIST_BRIEF_INFO_FAILURE,
} from '../actions/staffs';
import { IStaff } from '../utils/api.d';

export interface IStaffListBriefInfo {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  isActive: boolean;
}

export interface ICurrentStaff {
  isLoading?: boolean;
  data?: IStaff;
  error?: Error;
}

export interface IStaffsState {
  isLoading?: boolean;
  list: IStaff[];
  error?: Error;
  currentStaff: ICurrentStaff;
  briefInfoList: IStaffListBriefInfo[];
}

interface IState {
  staffs: IStaffsState;
}

export const initialState: IStaffsState = {
  isLoading: false,
  list: [],
  error: undefined,
  currentStaff: {
    isLoading: false,
    data: undefined,
    error: undefined,
  },
  briefInfoList: [],
};

export const getStaffs = (state: IState): IStaffsState => state.staffs;

export const getCurrentStaff = (state: IState): ICurrentStaff => state.staffs.currentStaff;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const oneStaffHandler = (payload: Record<string, any>, state: IStaffsState) => {
  return {
    ...state,
    currentStaff: {
      ...state.currentStaff,
      ...payload,
    },
  };
};

export default createReducer<IStaffsState>(initialState, {
  [FETCH]: () => ({ isLoading: true }),
  [FETCH_SUCCESS]: list => ({ list, isLoading: false }),
  [FETCH_FAILURE]: error => ({ error, isLoading: false }),

  [FETCH_ONE]: (_, state) => oneStaffHandler({ isLoading: true, data: undefined }, state),
  [FETCH_ONE_SUCCESS]: (data, state) => oneStaffHandler({ data, isLoading: false }, state),
  [FETCH_ONE_FAILURE]: (error, state) => oneStaffHandler({ error, isLoading: false }, state),

  [ADD_STAFF]: () => ({ isLoading: true }),
  [ADD_STAFF_SUCCESS]: list => ({ list, isLoading: false }),
  [ADD_STAFF_FAILURE]: error => ({ error, isLoading: false }),

  [UPDATE_STAFF]: () => ({ isLoading: true }),
  [UPDATE_STAFF_SUCCESS]: () => ({ isLoading: false }),
  [UPDATE_STAFF_FAILURE]: error => ({ error, isLoading: false }),

  [FETCH_STAFF_LIST_BRIEF_INFO]: () => ({ isLoading: true }),
  [FETCH_STAFF_LIST_BRIEF_INFO_SUCCESS]: briefInfoList => ({ briefInfoList, isLoading: false }),
  [FETCH_STAFF_LIST_BRIEF_INFO_FAILURE]: error => ({ error, isLoading: false }),
});
