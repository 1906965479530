// use 'npm run g:r {name}' - to generate new reducer and action (use --skip if action not needed)
import createReducer from '../utils/redux-create-reducer';
import { LOCATION_CHANGE } from 'connected-react-router';
import last from 'lodash/last';
import get from 'lodash/get';
import { Location } from 'history';

export interface ILocList {
  urlList: string[];
}

export const initialState: ILocList = {
  urlList: [],
};

interface IState {
  locationHistory: ILocList;
}

export const getLocationHistory = (state: IState): ILocList => state.locationHistory;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getPreviousLocation = (state: IState): string => {
  const list = getLocationHistory(state).urlList;
  return list[list.length - 2] || '';
};

export default createReducer<ILocList>(initialState, {
  [LOCATION_CHANGE]: (payload: { location: Location }, { urlList }: ILocList) => {
    const lastUrl = last(urlList);
    if (lastUrl !== get(payload, 'location.pathname')) {
      if (urlList.length > 6) {
        urlList.shift();
      }
      urlList.push(get(payload, 'location.pathname'));
    }
    return { urlList };
  },
});
