import { ColDef, ColGroupDef, ValueGetterParams } from 'ag-grid-community';
import {
  dateFormatter,
  transformToColumns,
  createMapFromObjectOfObjects,
  standardDateFormat,
} from '../../utils/helpers';
import { projectAuditLogGridColumns } from '../../tablesFormShapes/projectAuditLog';
import { auditLogActionTypes } from '../../enums';
import { projectLabelsMap, dateTimeFields, dateFields } from './constants';

const auditLogActionTypesMap = createMapFromObjectOfObjects(auditLogActionTypes, 'id', 'name');

const auditValuesFormatter = (params: ValueGetterParams) => {
  const fieldName = params.data.field || '';
  const column = params.colDef.field || '';
  const value = params.data[column];

  if (dateTimeFields.some(x => x === fieldName)) {
    return value ? standardDateFormat(value, true) : value;
  }

  if (dateFields.some(x => x === fieldName)) {
    return value ? standardDateFormat(value, false) : value;
  }

  return value;
};

export default function(): (ColDef | ColGroupDef)[] {
  return transformToColumns(projectAuditLogGridColumns, {
    created: {
      hide: false,
      sortable: false,
      valueGetter: dateFormatter('created'),
    },
    userFullName: {
      hide: false,
      sortable: false,
    },
    field: {
      valueGetter: (params: ValueGetterParams) => projectLabelsMap[params.data.field] || params.data.field,
      hide: false,
      sortable: false,
      floatingFilter: false,
    },
    action: {
      valueGetter: (params: ValueGetterParams) => auditLogActionTypesMap[params.data.action] || params.data.action,
      sortable: false,
      floatingFilter: false,
    },
    oldValue: {
      hide: false,
      sortable: false,
      floatingFilter: false,
      valueGetter: (params: ValueGetterParams) => auditValuesFormatter(params),
    },
    newValue: {
      hide: false,
      sortable: false,
      floatingFilter: false,
      valueGetter: (params: ValueGetterParams) => auditValuesFormatter(params),
    },
  });
}
