// use 'npm run g:a {name}' - to generate new action
import { createEmptyAction, createAction } from '../utils/actions';

export const FETCH = 'STAFFS/FETCH';
export const FETCH_SUCCESS = 'STAFFS/FETCH_SUCCESS';
export const FETCH_FAILURE = 'STAFFS/FETCH_FAILURE';

export const FETCH_ONE = 'STAFFS/FETCH_ONE';
export const FETCH_ONE_SUCCESS = 'STAFFS/FETCH_ONE_SUCCESS';
export const FETCH_ONE_FAILURE = 'STAFFS/FETCH_ONE_FAILURE';

export const ADD_STAFF = 'STAFFS/ADD_STAFF';
export const ADD_STAFF_SUCCESS = 'STAFFS/ADD_STAFF_SUCCESS';
export const ADD_STAFF_FAILURE = 'STAFFS/ADD_STAFF_FAILURE';

export const UPDATE_STAFF = 'STAFFS/UPDATE_STAFF';
export const UPDATE_STAFF_SUCCESS = 'STAFFS/UPDATE_STAFF_SUCCESS';
export const UPDATE_STAFF_FAILURE = 'STAFFS/UPDATE_STAFF_FAILURE';

export const DELETE_STAFF = 'STAFFS/DELETE_STAFF';
export const DELETE_STAFF_SUCCESS = 'STAFFS/DELETE_STAFF_SUCCESS';
export const DELETE_STAFF_FAILURE = 'STAFFS/DELETE_STAFF_FAILURE';

export const FETCH_STAFF_LIST_BRIEF_INFO = 'STAFFS/FETCH_STAFF_LIST_BRIEF_INFO';
export const FETCH_STAFF_LIST_BRIEF_INFO_SUCCESS = 'STAFFS/FETCH_STAFF_LIST_BRIEF_INFO_SUCCESS';
export const FETCH_STAFF_LIST_BRIEF_INFO_FAILURE = 'STAFFS/FETCH_STAFF_LIST_BRIEF_INFO_FAILURE';

export const types = {
  FETCH,
  FETCH_SUCCESS,
  FETCH_FAILURE,
  ADD_STAFF,
  FETCH_STAFF_LIST_BRIEF_INFO,
  FETCH_STAFF_LIST_BRIEF_INFO_SUCCESS,
  FETCH_STAFF_LIST_BRIEF_INFO_FAILURE,
};

export const fetch = createAction(FETCH);
export const fetchSuccess = createAction(FETCH_SUCCESS);
export const fetchFailure = createAction<Error>(FETCH_FAILURE);

export const fetchOne = createEmptyAction(FETCH_ONE);
export const fetchOneSuccess = createAction(FETCH_ONE_SUCCESS);
export const fetchOneFailure = createAction<Error | null>(FETCH_ONE_FAILURE);

export const addStaff = createAction(ADD_STAFF);
export const addStaffSuccess = createEmptyAction(ADD_STAFF_SUCCESS);
export const addStaffFailure = createAction(ADD_STAFF_FAILURE);

export const updateStaff = createAction(UPDATE_STAFF);
export const updateStaffSuccess = createEmptyAction(UPDATE_STAFF_SUCCESS);
export const updateStaffFailure = createAction(UPDATE_STAFF_FAILURE);

export const deleteStaff = createAction<string>(DELETE_STAFF);
export const deleteStaffSuccess = createEmptyAction(DELETE_STAFF_SUCCESS);
export const deleteStaffFailure = createAction(DELETE_STAFF_FAILURE);

export const fetchStaffListBriefInfo = createEmptyAction(FETCH_STAFF_LIST_BRIEF_INFO);
export const fetchStaffListBriefInfoSuccess = createAction(FETCH_STAFF_LIST_BRIEF_INFO_SUCCESS);
export const fetchStaffListBriefInfoFailure = createAction<Error>(FETCH_STAFF_LIST_BRIEF_INFO_FAILURE);

export default {
  fetch,
  fetchSuccess,
  fetchFailure,
  addStaff,
  fetchStaffListBriefInfo,
  fetchStaffListBriefInfoSuccess,
  fetchStaffListBriefInfoFailure,
};
