import { FormShape } from '../utils/api.d';

export const projectAuditLogGridColumns: FormShape = {
  created: { label: 'Time', type: 'datetime-local', filter: 'agDateColumnFilter' },
  userFullName: { label: 'User', type: 'text', filter: 'agTextColumnFilter' },
  action: { label: 'Action', type: 'text', filter: 'agTextColumnFilter' },
  field: { label: 'Field', type: 'text', filter: 'agTextColumnFilter' },
  oldValue: { label: 'Old value', type: 'text', filter: 'agTextColumnFilter' },
  newValue: { label: 'New value', type: 'text', filter: 'agTextColumnFilter' },
};
