import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { Grid } from '../../../components/Grid';
import makeStaffColumns from '../GridSettings/staff';
import { GridReadyEvent } from 'ag-grid-community/dist/lib/events';
import makeGridOptions from '../../../gridOptions';
import { ColumnApi, GridApi } from 'ag-grid-community';
import { AppGrids } from '../../../enums';
import { checkForSavedGrid, gridStateSaver } from '../../../utils/helpers';
import GridSelect from '../../../components/GridSelect';
import styles from '../AddNewProject.module.scss';

interface StaffGridProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  assignedStaff: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  staffList: any;
  roles: string[];
  emailAddress: string;
  isAdmin: boolean;
  removeStaff: (staffData: { id: number | string }) => void;
  saveStaff: (staffData: { roleName: string; staffName: string }) => void;
}

export default function StaffGrid({
  assignedStaff,
  staffList,
  roles,
  emailAddress,
  removeStaff,
  saveStaff,
}: StaffGridProps) {
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [gridColumnApi, setColumnApi] = useState<ColumnApi | null>(null);
  const [isStaffAddingModeEnabled, setStaffAddingMode] = useState(false);

  const staffColumnDefs = makeStaffColumns(staffList, roles);
  const staffGridOptions = makeGridOptions(staffColumnDefs);

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
    setColumnApi(params.columnApi);
  };

  const toggleStaffAddingMode = () => {
    setStaffAddingMode(!isStaffAddingModeEnabled);
  };

  const gridName = `${emailAddress}-${AppGrids.ProjectDetailsStaff}`;
  const gridConfigName = `${emailAddress}-${AppGrids.ProjectDetailsStaff}-config`;

  const saveState = () => gridColumnApi && gridStateSaver(gridColumnApi, gridName);

  if (gridColumnApi) {
    checkForSavedGrid(gridColumnApi, gridName, gridConfigName, staffColumnDefs);
    gridStateSaver(gridColumnApi, gridName);
  }

  let isSaveClicked = false;
  let isCancelClicked = false;

  const addStaffToProject = () => {
    toggleStaffAddingMode();

    const editedRow = gridApi && gridApi.applyTransaction({ add: [{}] });

    editedRow && gridApi && gridApi.ensureIndexVisible(editedRow.add[0].childIndex, null);
    gridApi && gridApi.setFocusedCell(1, 'staffName');
    editedRow &&
      gridApi &&
      gridApi.startEditingCell({
        rowIndex: editedRow.add[0].childIndex,
        colKey: 'staffName',
      });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onCellClicked = (params: any) => {
    // Handle click event for action cells
    if (params.column.colId === 'action' && params.event.target.dataset.action) {
      const action = params.event.target.dataset.action;

      if (action === 'edit') {
        params.api.startEditingCell({
          rowIndex: params.node.rowIndex,
          // gets the first columnKey
          colKey: params.columnApi.getDisplayedCenterColumns()[0].colId,
        });
      }

      if (action === 'delete') {
        removeStaff(params.node.data);
        params.api.applyTransaction({
          remove: [params.node.data],
        });
      }

      if (action === 'save') {
        isSaveClicked = true;
        params.api.stopEditing(false);
        saveStaff(params.node.data);
        toggleStaffAddingMode();
      }

      if (action === 'cancel') {
        isCancelClicked = true;
        params.api.stopEditing(true);
        params.api.applyTransaction({
          remove: [params.node.data],
        });
        toggleStaffAddingMode();
      }
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onRowEditingStarted = (params: any) => {
    isSaveClicked = false;
    isCancelClicked = false;

    params.api.refreshCells({
      columns: ['action'],
      rowNodes: [params.node],
      force: true,
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onRowEditingStopped = (params: any) => {
    if (!isSaveClicked && !isCancelClicked) {
      gridApi &&
        gridApi.startEditingCell({
          rowIndex: params.rowIndex,
          colKey: 'staffName',
        });
    }

    params.api.refreshCells({
      columns: ['action'],
      rowNodes: [params.node],
      force: true,
    });
  };

  const suppressKeyboardEvent = () => true;
  const frameworkComponents = { gridSelect: GridSelect };

  return (
    <div>
      <div className={styles.flexButton}>
        <h5>Staff</h5>
        <div>
          <Button color="primary" onClick={addStaffToProject} disabled={isStaffAddingModeEnabled || !staffList.length}>
            Add Staff
          </Button>
        </div>
      </div>
      <Grid
        frameworkComponents={frameworkComponents}
        suppressContextMenu={true}
        onRowEditingStopped={onRowEditingStopped}
        onRowEditingStarted={onRowEditingStarted}
        onCellClicked={onCellClicked}
        defaultHeight={false}
        className={styles.gridDefault}
        rowData={assignedStaff}
        editType="fullRow"
        suppressClickEdit={true}
        suppressKeyboardEvent={suppressKeyboardEvent}
        stopEditingWhenGridLosesFocus={false}
        gridOptions={staffGridOptions}
        animateRows={true}
        sideBar={null}
        onGridReady={onGridReady}
        defaultColDef={{
          flex: 1,
          resizable: true,
          enablePivot: false,
          enableValue: false,
        }}
        onColumnMoved={saveState}
        onColumnVisible={saveState}
        onColumnResized={saveState}
      />
    </div>
  );
}
