import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export function useQuery(keys: string[]): { [key: string]: string } {
  const res = {};
  const params = new URLSearchParams(useLocation().search);
  keys.forEach(key => (res[key] = params.get(key)));
  return res;
}

const mediaSizes = {
  xxl: 1400,
  xl: 1200,
  lg: 992,
  md: 768,
  sm: 576,
  xs: 576,
};

type MediaQueryName = keyof typeof mediaSizes;

function isMediaQuery(name: MediaQueryName, size: number) {
  if (name === 'xs') {
    return size < mediaSizes[name];
  }

  return size >= mediaSizes[name];
}

export function useMediaQuery(size: MediaQueryName) {
  const [isFit, setFit] = useState(isMediaQuery(size, window.innerWidth));

  // TODO: investigate this block and remove dependency
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const listener = () => {
    setFit(isMediaQuery(size, window.innerWidth));
  };
  useEffect(() => {
    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
  }, [listener]);

  return isFit;
}

export function useBreakpoints() {
  const makeBP = (): Record<MediaQueryName, boolean> => ({
    xxl: isMediaQuery('xxl', window.innerWidth),
    xl: isMediaQuery('xl', window.innerWidth),
    lg: isMediaQuery('lg', window.innerWidth),
    md: isMediaQuery('md', window.innerWidth),
    sm: isMediaQuery('sm', window.innerWidth),
    xs: isMediaQuery('xs', window.innerWidth),
  });

  const [breakpoints, setBreakpoints] = useState(makeBP());

  // TODO: investigate this block and remove dependency
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const listener = () => {
    setBreakpoints(makeBP());
  };
  useEffect(() => {
    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
  }, [listener]);

  return breakpoints;
}
