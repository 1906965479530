// TODO: we have "projects" saga, also. Think about structuring sagas in appropriate files

import { call, take, put } from 'redux-saga/effects';
import { callApi } from '../utils/helpers';
import {
  fetchByIdSuccess,
  fetchByIdFailure,
  FETCH_BYID,
  GET_PARENT_PROJECT_INFO,
  getParentProjectInfoSuccess,
  getParentProjectInfoFailure,
  ADD_ACCOUNTS_TO_PROJECT,
  addAccountsToProjectSuccess,
  addAccountsToProjectFailure,
} from '../actions/project';
import api from '../utils/api';
import { push } from 'connected-react-router';
import { IAction } from '../utils/redux-create-reducer';
import { AxiosResponse } from 'axios';
import { TSaga } from '../utils/api.d';
import { notify } from '../actions/notification';

export function* projectById() {
  while (true) {
    const action = yield take(FETCH_BYID);
    try {
      const projectId = action.payload.projectId;
      const response = yield call(callApi, api.getProjectsByIdHeader, projectId);
      yield put(fetchByIdSuccess(response.data));
    } catch (e) {
      console.error(e);
      yield put(fetchByIdFailure(e));
    }
  }
}

export function* getParentProjectInfo() {
  while (true) {
    const action = yield take(GET_PARENT_PROJECT_INFO);
    try {
      const parentId = action.payload.parentId;
      const response = yield call(callApi, api.getProjectsByIdHeader, parentId);
      yield put(getParentProjectInfoSuccess(response.data));
    } catch (e) {
      console.error(e);
      yield put(getParentProjectInfoFailure(e));
    }
  }
}

export function* addAccountsToProject(): TSaga<IAction & AxiosResponse> {
  while (true) {
    const { payload } = yield take(ADD_ACCOUNTS_TO_PROJECT);
    try {
      const projectId = payload.projectId;
      yield call(callApi, api.addAccountsToProject, projectId, payload.accountIds);
      yield put(addAccountsToProjectSuccess());
      yield put(push(payload.backUrl));
      yield put(notify('Your Accounts have been added', 'success'));
    } catch (e) {
      console.error(e);
      yield put(addAccountsToProjectFailure(e));
    }
  }
}
