import { CellClassParams, ColDef } from 'ag-grid-community';
import { eventsConfig } from '../../../tablesFormShapes/events';
import { transformToColumns, dateFormatter, dateFiltering } from '../../../utils/helpers';
import { specificOveragesNames } from '../constants';

const primaryFormatter = () => (params: { data: { [key: string]: string } }) => {
  if (!params || !params.data) return ' ';
  const value = params.data['isPrimary'];
  return value ? 'Yes' : 'No';
};

export type ICellClass = (formField: string, eventField?: string) => (params: CellClassParams) => string;
export default function(cellClass: ICellClass): ColDef[] {
  const defaultConfigColumns = {
    isPrimary: {
      cellClass: cellClass('primary'),
      valueGetter: primaryFormatter(),
    },
    eventId: {
      hide: true,
      cellClass: cellClass('eventId'),
    },
    name: {
      cellClass: cellClass('name'),
    },
    startDate: {
      cellClass: cellClass('startDate'),
      valueGetter: dateFormatter('startDate'),
      filterParams: {
        inRangeInclusive: true,
        comparator: dateFiltering,
      },
    },
    eventSubType: {
      cellClass: cellClass(specificOveragesNames.eventSubType, 'eventSubType'),
    },
    serviceModelType: {
      cellClass: cellClass('serviceModelType.name', 'serviceModelType'),
    },
    usageType: {
      cellClass: cellClass('usageType.name', 'usageType'),
    },
    languageCount: {
      cellClass: cellClass('languages', 'languageCount'),
    },
    duration: {
      cellClass: cellClass('duration'),
    },
    hostingDuration: {
      cellClass: cellClass('hostingDays', 'hostingDuration'),
    },
    liveViewers: {
      cellClass: cellClass('liveViewers'),
    },
    contentType: {
      cellClass: cellClass(specificOveragesNames.contentType, 'contentType'),
    },
    deliveryType: {
      cellClass: cellClass('deliveryType.name', 'deliveryType'),
    },
    studioEventKey: {
      cellClass: cellClass('studioEventKey'),
    },
    studioShowKey: {
      cellClass: cellClass('studioShowKey'),
    },
  };

  return transformToColumns(eventsConfig, defaultConfigColumns, { minWidth: 200 });
}
