import { DropdownItem, DropdownMenu, DropdownMenuProps } from 'reactstrap';
import React from 'react';
import { projectType } from '../../enums';

const types = [
  projectType.Webcast,
  projectType.VirtualEvent,
  projectType.Plan,
  projectType.WebcastSubProject,
  projectType.VirtualEventSubProject,
  projectType.PlatformPlanSubProject,
  projectType.Misc,
];

interface ISelectProjectTypeProps extends DropdownMenuProps {
  onItemClick: (type: { name: string; value: string; id: number }) => void;
  typesFilter?: number[];
}

export function SelectProjectType(props: ISelectProjectTypeProps) {
  const { onItemClick, typesFilter, ...rest } = props;
  return (
    <DropdownMenu {...rest}>
      {types
        .filter(opt => !typesFilter || typesFilter.includes(opt.id))
        .map(type => (
          <DropdownItem key={type.id} onClick={() => onItemClick(type)}>
            <strong>{type.name}</strong>
            <div className="project-description">{type.desc}</div>
          </DropdownItem>
        ))}
    </DropdownMenu>
  );
}
