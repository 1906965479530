import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './Notification.module.scss';
import { Alert } from 'reactstrap';

import { close } from '../../actions/notification';
import { getNotification, INotificationState } from '../../reducers/notification';

const Notification = () => {
  const dispatch = useDispatch();
  const { isOpen, message, duration, type }: INotificationState = useSelector(getNotification);
  const [timer, setTimer] = useState(duration);

  useEffect(() => {
    setTimer(duration);
  }, [duration]);

  const handleClose = () => {
    dispatch(close());
  };

  useEffect(() => {
    const ticker = setInterval(() => {
      if (timer > 0) {
        setTimer(timer - 1000);
      } else if (duration && isOpen) {
        handleClose();
        clearInterval(ticker);
      }
    }, 1000);

    return () => clearInterval(ticker);
  });

  return (
    <div className={styles.root}>
      <Alert isOpen={isOpen} onClose={handleClose} toggle={handleClose} color={type} fade>
        {message}
      </Alert>
    </div>
  );
};

export default Notification;
