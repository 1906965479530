import React from 'react';
import styles from '../AddNewProject.module.scss';
import { Routes } from '../../../constants';
import { history } from '../../../store';
import { Grid } from '../../../components/Grid';
import eventColumns, { ICellClass } from '../GridSettings/events';
import { RowDoubleClickedEvent } from 'ag-grid-community/dist/lib/events';
import { useParams } from 'react-router-dom';
import makeGridOptions from '../../../gridOptions';
import { shallowEqual, useSelector } from 'react-redux';
import { getEvents } from '../../../reducers/events';
import {
  checkForSavedGrid,
  convertToPlainObjectWithEnums,
  getPlatformValue,
  gridStateSaver,
  resetAllGridFilters,
} from '../../../utils/helpers';
import { IProject, IEvent, IProjectHeader } from '../../../utils/api.d';
import { AgGridEvent, CellClassParams, ColDef, ColumnApi, GridApi, GridOptions } from 'ag-grid-community';
import { get } from 'lodash';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { GetContextMenuItemsParams } from 'ag-grid-community/dist/lib/entities/gridOptions';
import { useDispatch } from 'react-redux';
import { removeEventsFromProject, setPrimary } from '../../../actions/events';
import { AppGrids } from '../../../enums';
import {
  specificOveragesCheckingFields,
  hasNumberOverages,
  hasStringOverages,
  skipOverageCheckingFields,
} from '../helpers';

const getUri = (url: string, id?: string, projectId?: string, platform?: string) => {
  let resultUrl = url;
  if (id) {
    resultUrl = resultUrl.replace(':eventId', id);
  }
  if (projectId) {
    resultUrl = resultUrl.replace(':projectId', projectId);
  }
  if (platform) {
    resultUrl = resultUrl += `?platform=${platform}`;
  }
  resultUrl = resultUrl.replace(':backUrl', encodeURIComponent(location.pathname));
  return resultUrl;
};

const removeEventsMessages = {
  fulfilledProjectMessage:
    'You are removing an event(s) to a project that is already fulfilled, do you wish to continue?',
  regularMessage: 'You are about to remove the selected events from this project, do you wish to continue?',
};

interface MatchParams {
  id: string;
}

interface Props {
  formDataInit: Partial<IProject>;
  list?: IEvent[];
  children?: JSX.Element;
  projectType: string;
  eventHeaderData?: Partial<IProjectHeader>;
  isNewProject: boolean;
  emailAddress: string;
  isProjectFulfilled: boolean;
  isAddEventsBtnVisible: boolean;
  isAdmin: boolean;
}

type OnSetPrimaryProps = {
  eventId: string;
  platformId: string;
  projectId: number;
  path: string;
  isPrimary: boolean;
};

interface EventTableProps extends Props {
  id: string;
  onSetPrimary: (props: OnSetPrimaryProps) => void;
  removeEvents: (props: { queryParams: string; projectId: number }) => void;
}

interface EventTableState {
  modal: boolean;
  newPrimaryEvent: {
    eventId: string;
    platform: string;
    projectId: number;
    isPrimary: boolean;
  };
  removeEventsModal: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedRows: any[];
}

class EventTable extends React.Component<EventTableProps, EventTableState> {
  gridName: string;
  gridConfigName: string;
  gridColumnApi: ColumnApi | null = null;
  private readonly eventsGridOptions: GridOptions;
  private readonly eventsColumnDefs: ColDef[];
  private readonly cellClass: ICellClass;
  state: EventTableState = {
    modal: false,
    newPrimaryEvent: {
      eventId: '',
      platform: '',
      projectId: 0,
      isPrimary: false,
    },
    removeEventsModal: false,
    selectedRows: [],
  };

  constructor(props: EventTableProps) {
    super(props);
    this.state = {
      modal: false,
      newPrimaryEvent: {
        eventId: '',
        platform: '',
        projectId: 0,
        isPrimary: false,
      },
      removeEventsModal: false,
      selectedRows: [],
    };
    this.cellClass = (formField: string, eventField?: string) => {
      return (rowData: CellClassParams) => {
        const formDataInit = props.eventHeaderData;
        eventField = eventField || formField;
        const formValue = get(formDataInit, formField, null);
        const rowValue = get(rowData.data, eventField, null);
        const skipOverageChecking =
          skipOverageCheckingFields[formField] && skipOverageCheckingFields[formField](formDataInit);

        if (formValue !== null && rowValue !== null && !skipOverageChecking) {
          const specificChecking = formField in specificOveragesCheckingFields;

          if (specificChecking) {
            const specificOveragesMethod = specificOveragesCheckingFields[formField];

            return specificOveragesMethod(rowValue, formValue) ? styles.warningCell : '';
          }

          if (!isNaN(Number(formValue))) {
            return hasNumberOverages(rowValue, formValue) ? styles.warningCell : '';
          }

          if (!specificChecking && (typeof formValue === 'string' || typeof rowValue === 'string')) {
            return hasStringOverages(rowValue, formValue) ? styles.warningCell : '';
          }
        }
        return '';
      };
    };

    this.eventsColumnDefs = eventColumns(this.cellClass);
    this.eventsGridOptions = makeGridOptions(this.eventsColumnDefs, {
      getContextMenuItems: this.getContextMenuItems,
      rowSelection: 'multiple',
      animateRows: true,
    });

    this.gridName = `${this.props.emailAddress}-${AppGrids.ProjectDetailsEvents}-${this.props.projectType}`;
    this.gridConfigName = `${this.props.emailAddress}-${AppGrids.ProjectDetailsEvents}-${this.props.projectType}-config`;
  }

  componentDidUpdate(prevProps: Readonly<EventTableProps>) {
    if (
      prevProps.formDataInit !== this.props.formDataInit ||
      prevProps.list !== this.props.list ||
      prevProps.id !== this.props.id
    ) {
      this.updateGrid();
    }
  }

  grid: GridApi | null = null;

  onGridReady = (event: AgGridEvent) => {
    this.grid = event.api;
    this.gridColumnApi = event.columnApi;

    this.updateGrid();

    if (this.gridColumnApi) {
      checkForSavedGrid(this.gridColumnApi, this.gridName, this.gridConfigName, this.eventsColumnDefs);
      gridStateSaver(this.gridColumnApi, this.gridName);
    }
  };

  setPrimaryEvent() {
    this.props.onSetPrimary({
      eventId: this.state.newPrimaryEvent.eventId,
      platformId: getPlatformValue(this.state.newPrimaryEvent.platform) || '',
      projectId: this.state.newPrimaryEvent.projectId,
      path: location.pathname,
      isPrimary: !this.state.newPrimaryEvent.isPrimary,
    });
    this.closeModal();
  }

  updateGrid = () => {
    if (this.grid) {
      const { list } = this.props;
      const events = (list && list.map(e => convertToPlainObjectWithEnums(e, 'name'))) || [];
      this.grid.setRowData(events);
    }
  };

  goToEventDetails = (eventId: string, prId: string, platform: string) => {
    window.open(getUri(Routes.EventDetail, eventId, prId, getPlatformValue(platform)));
  };

  goToEventsByAccounts = () => {
    const headerData = this.props.eventHeaderData;
    const projectAccounts = headerData && headerData.projectAccounts;
    const accountIdParams = projectAccounts && projectAccounts.map(account => `accountId=${account.id}`).join('&');
    const queryParams = '?projectUnlinked=true&' + accountIdParams;
    const url = `${Routes.EventsToProject.replace(':backUrl', encodeURIComponent(location.pathname))}${queryParams}`;
    return history.push(url);
  };

  openModal() {
    this.setState({ modal: true });
  }
  closeModal() {
    this.setState({ modal: false });
  }

  getContextMenuItems = (params: GetContextMenuItemsParams) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const defaultContextMenu = ['copy', 'copyWithHeaders', 'paste', 'separator', 'export'] as any[];
    const menuTitles = {
      setAsPrimary: 'Set as Primary',
      unlinkAsPrimary: 'Unlink as Primary',
    };
    const isPrimaryEvent = params.node.data.isPrimary;
    const setPrimaryMenu = [
      'separator',
      {
        name: isPrimaryEvent ? menuTitles.unlinkAsPrimary : menuTitles.setAsPrimary,
        action: () => {
          this.setState({ newPrimaryEvent: params.node.data });
          this.openModal();
        },
      },
    ];

    defaultContextMenu.push(...setPrimaryMenu);

    return defaultContextMenu;
  };

  saveState() {
    this.gridColumnApi && gridStateSaver(this.gridColumnApi, this.gridName);
  }

  showRemoveEventsModal() {
    this.setState({ removeEventsModal: true });
  }

  closeRemoveEventsModal() {
    this.setState({ removeEventsModal: false });
  }

  removeEvents() {
    const projectId = this.props.eventHeaderData && this.props.eventHeaderData.id;
    const selectedRows = this.state.selectedRows;
    const searchParams = new URLSearchParams(location.search);
    const eventsIdsInQueryParams = searchParams.get('eventIds');
    const hasNotSavedEvents = Boolean(eventsIdsInQueryParams && eventsIdsInQueryParams.trim());
    let eventIdsPayload = '';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const selectedNotSavedEvents: any[] = [];
    let selectedNotSavedEventsIds: string[] =
      hasNotSavedEvents && eventsIdsInQueryParams ? eventsIdsInQueryParams.split(',') : [];

    selectedRows.forEach(row => {
      if (hasNotSavedEvents && selectedNotSavedEventsIds.includes(row.id.toString())) {
        selectedNotSavedEvents.push(row);
        selectedNotSavedEventsIds = selectedNotSavedEventsIds.filter(id => id !== row.id.toString());
      } else {
        eventIdsPayload += `eventIds=${row.id}&`;
      }
    });

    if (selectedNotSavedEvents.length) {
      // Remove selected rows without sending their ids to BE
      this.grid && this.grid.applyTransaction({ remove: selectedNotSavedEvents });

      // Remove EventIds from query params
      searchParams.set('eventIds', selectedNotSavedEventsIds.join(','));
      window.history.pushState({}, '', `${location.pathname}?${searchParams.toString()}`);
    }

    if (projectId && eventIdsPayload) {
      this.props.removeEvents({ queryParams: `${eventIdsPayload}projectId=${projectId}`, projectId });
    }

    this.setState({ selectedRows: [] });
    this.closeRemoveEventsModal();
  }

  onSelectionChanged() {
    if (this.grid) {
      const selectedRows = this.grid.getSelectedRows();
      this.setState({ selectedRows });
    }
  }

  render() {
    const { id, children } = this.props;

    return (
      <>
        <div className={styles.flexButton}>
          <h5>Events</h5>
          <div>
            <Button color="primary" onClick={() => resetAllGridFilters(this.grid)}>
              Reset Filters
            </Button>
            <Button
              className={'margin-left-10'}
              color="primary"
              onClick={this.showRemoveEventsModal.bind(this)}
              disabled={(this.props.isProjectFulfilled && !this.props.isAdmin) || !this.state.selectedRows.length}
            >
              Remove Events
            </Button>
            {this.props.isAddEventsBtnVisible ? (
              <Button className={'margin-left-10'} color="primary" onClick={this.goToEventsByAccounts}>
                Add Events
              </Button>
            ) : null}
          </div>
        </div>
        {children}
        <Grid
          onGridReady={this.onGridReady}
          defaultHeight={false}
          className={styles.gridDefault}
          onRowDoubleClicked={(row: RowDoubleClickedEvent) =>
            this.goToEventDetails(row.data.eventId, id, row.data.platform)
          }
          gridOptions={this.eventsGridOptions}
          getRowNodeId={data => data.id}
          defaultColDef={{
            flex: 1,
            sortable: false,
            resizable: true,
            enablePivot: false,
            enableValue: false,
          }}
          onSelectionChanged={this.onSelectionChanged.bind(this)}
          onColumnMoved={this.saveState.bind(this)}
          onColumnVisible={this.saveState.bind(this)}
          onColumnResized={this.saveState.bind(this)}
        />
        <Modal className={styles.modal} isOpen={this.state.modal} toggle={this.closeModal.bind(this)}>
          <ModalHeader toggle={this.closeModal.bind(this)}>Are you sure?</ModalHeader>
          <ModalFooter>
            <Button color="primary" onClick={this.setPrimaryEvent.bind(this)}>
              Save
            </Button>
            <Button color="secondary" onClick={this.closeModal.bind(this)}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          className={styles.modal}
          isOpen={this.state.removeEventsModal}
          toggle={this.closeRemoveEventsModal.bind(this)}
        >
          <ModalHeader toggle={this.closeRemoveEventsModal.bind(this)}>Are you sure?</ModalHeader>
          <ModalBody>
            {this.props.isProjectFulfilled
              ? removeEventsMessages.fulfilledProjectMessage
              : removeEventsMessages.regularMessage}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.closeRemoveEventsModal.bind(this)}>
              Cancel
            </Button>
            <Button color="danger" onClick={this.removeEvents.bind(this)}>
              Yes
            </Button>
          </ModalFooter>
        </Modal>
        <br />
        <br />
      </>
    );
  }
}

// TODO: investigate this block and remove conditional call of 'useSelector'
export default function EventTableWrapper(props: Props) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { list } = props.isNewProject ? { list: [] } : useSelector(getEvents, shallowEqual);
  const { id } = useParams<MatchParams>();
  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSetPrimary = (props: any) => {
    dispatch(setPrimary(props));
  };

  const removeEvents = (props: { queryParams: string; projectId: number }) => {
    dispatch(removeEventsFromProject(props));
  };

  return <EventTable {...props} id={id} list={list} onSetPrimary={onSetPrimary} removeEvents={removeEvents} />;
}
