import React, { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import styles from './parentprojectgrid.module.scss';
import getColumns from './columns';
import { IProjectsState, getProjects } from '../../reducers/projects';
import makeGridOptions from '../../gridOptions';
import { Grid } from '../Grid';
import { AgGridEvent, ColumnApi, GridApi, RowClickedEvent } from 'ag-grid-community';
import { N3AEntitlements, Routes } from '../../constants';
import { history } from '../../store';
import { ProjectTypes } from '../../utils/api.d';
import { Button, Dropdown, DropdownToggle } from 'reactstrap';
import { SelectProjectType } from '../SelectProjectType';
import AccessControl from '../AccessControl';
import { AppGrids } from '../../enums';
import { checkForSavedGrid, gridStateSaver, resetAllGridFilters } from '../../utils/helpers';

interface IParentProjectGridProps {
  dsaId: string;
  emailAddress: string;
}

const ParentProjectGrid = (props: IParentProjectGridProps) => {
  const { dsaId, emailAddress } = props;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [gridColumnApi, setColumnApi] = useState<ColumnApi | null>(null);
  const { projects }: IProjectsState = useSelector(getProjects, shallowEqual);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const parentProjects = projects.filter((p: any) => {
    const q = p;
    q['projectTypeId'] = p.projectType.id;
    return q.parentId === null;
  });
  const columnDefs = getColumns();
  const onGridReady = (event: AgGridEvent) => {
    setGridApi(event.api);
    setColumnApi(event.columnApi);
  };

  const onAddProjectClicked = (type: ProjectTypes) => {
    history.push(
      Routes.AddNewProject.replace(':type', type.toString())
        .replace(':parentId', 'parent')
        .replace(':dsaId', dsaId)
        .replace(':backUrl', encodeURIComponent(location.pathname)),
    );
  };

  const gridOptions = makeGridOptions(columnDefs, {
    onGridReady,
    statusBar: { statusPanels: [] },
  });

  const onRowClicked = (row: RowClickedEvent) => {
    const url = Routes.NewEditProject.replace(':id', row.data.id)
      .replace(':type', row.data.projectTypeId)
      .replace(':dsaId', dsaId);
    window.open(url);
  };

  const gridName = `${emailAddress}-${AppGrids.DsaDetailsProjects}`;
  const gridConfigName = `${emailAddress}-${AppGrids.DsaDetailsProjects}-config`;

  const saveState = () => gridColumnApi && gridStateSaver(gridColumnApi, gridName);

  if (gridColumnApi) {
    checkForSavedGrid(gridColumnApi, gridName, gridConfigName, columnDefs);
    gridStateSaver(gridColumnApi, gridName);
  }

  const resetAllFilters = () => resetAllGridFilters(gridApi);

  return (
    <>
      <div className={styles.projectsHeader}>
        <h4 className={styles.projectsHeaderTitle}>Projects</h4>
        <div>
          <AccessControl actions={[N3AEntitlements.Admin]}>
            <Dropdown isOpen={dropdownOpen} toggle={toggle} className="float-right" direction="down">
              <DropdownToggle className={styles.dropDownToggle} caret>
                Create New Project&nbsp;
              </DropdownToggle>
              <SelectProjectType
                onItemClick={type => onAddProjectClicked(type.id)}
                typesFilter={[ProjectTypes.Webcast, ProjectTypes.VirtualEvent, ProjectTypes.Plan, ProjectTypes.Misc]}
                right
              />
            </Dropdown>
          </AccessControl>

          <Button className={'margin-right-10'} color="primary" onClick={resetAllFilters}>
            Reset Filters
          </Button>
        </div>
      </div>
      <Grid
        className={styles.parentGrid}
        rowData={Array.isArray(parentProjects) ? parentProjects : []}
        gridOptions={gridOptions}
        onRowClicked={onRowClicked}
        onColumnMoved={saveState}
        onColumnVisible={saveState}
        onColumnResized={saveState}
      />
    </>
  );
};

export default ParentProjectGrid;
