import createReducer from '../utils/redux-create-reducer';
import { GENERATE_REPORT, GENERATE_REPORT_SUCCESS, GENERATE_REPORT_FAILURE } from '../actions/reports';

export interface IReportGenerationState {
  isLoading?: boolean;
  error?: Error;
}

interface IState {
  reportGeneration: IReportGenerationState;
}

export const initialState: IReportGenerationState = {
  isLoading: false,
  error: undefined,
};

export const generateReport = (state: IState): IReportGenerationState => state.reportGeneration;

export default createReducer<IReportGenerationState>(initialState, {
  [GENERATE_REPORT]: () => ({ isLoading: true }),
  [GENERATE_REPORT_SUCCESS]: () => ({ isLoading: false }),
  [GENERATE_REPORT_FAILURE]: error => ({ error, isLoading: false }),
});
