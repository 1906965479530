import { FormShape } from '../utils/api.d';

export const eventsListGridColumns: FormShape = {
  eventId: { label: 'Event ID', type: 'text', filter: 'agTextColumnFilter' },
  name: { label: 'Event Name', type: 'text', filter: 'agTextColumnFilter' },
  projectName: { label: 'Project Name', type: 'text', filter: 'agTextColumnFilter' },
  platform: { label: 'Platform', type: 'select', filter: 'agSetColumnFilter' },
  dsaCustomerName: { label: 'DSA Name', type: 'text', filter: 'agTextColumnFilter' },
  accountId: { label: 'Account ID', type: 'text', filter: 'agTextColumnFilter' },
  accountName: { label: 'Account', type: 'text', filter: 'agTextColumnFilter' },
  accountUsageType: { label: 'Account Usage Type', type: 'text', filter: 'agSetColumnFilter' },
  accountMmcResellerType: { label: 'Reseller Name', type: 'text', filter: 'agSetColumnFilter' },
  description: { label: 'Event Description', type: 'text', filter: 'agTextColumnFilter' },
  eventType: { label: 'Type', type: 'select', filter: 'agSetColumnFilter' },
  eventSubType: { label: 'Subtype', type: 'select', filter: 'agSetColumnFilter' },
  startDate: { label: 'Start DateTime', type: 'datetime-local', filter: 'agDateColumnFilter' },
  endDate: { label: 'End DateTime', type: 'datetime-local', filter: 'agDateColumnFilter' },
  expiryDate: { label: 'Expiry DateTime', type: 'datetime-local', filter: 'agDateColumnFilter' },
  serviceModelType: { label: 'Service Model', type: 'select', filter: 'agSetColumnFilter' },
  contentType: { label: 'Content', type: 'select', filter: 'agSetColumnFilter' },
  deliveryType: { label: 'Delivery', type: 'select', filter: 'agSetColumnFilter' },
  eventMode: { label: 'Mode', type: 'select', filter: 'agSetColumnFilter' },
  usageType: { label: 'Event Usage Type', type: 'select', filter: 'agSetColumnFilter' },
  isActive: { label: 'Active', type: 'boolean', filter: 'agSetColumnFilter' },
  isVideoChat: { label: 'Videochat', type: 'boolean', filter: 'agSetColumnFilter' },
  languageCount: { label: 'Languages', type: 'number', filter: 'agNumberColumnFilter' },
  linksCount: { label: 'Links', type: 'number', filter: 'agNumberColumnFilter' },
  projectId: { label: 'Project ID', type: 'number', filter: 'agNumberColumnFilter' },
  supportRegion: { label: 'Region', type: 'text', filter: 'agSetColumnFilter' },
  projectManager: { label: 'Project manager', type: 'text', filter: 'agTextColumnFilter' },
  breakoutRoomsCount: { label: 'Breakout Rooms', type: 'number', filter: 'agNumberColumnFilter' },
  registrations: { label: 'Registrations', type: 'number', filter: 'agNumberColumnFilter' },
  liveViewers: { label: 'Viewers Live', type: 'number', filter: 'agNumberColumnFilter' },
  onDemandViewers: { label: 'Viewers Ondemand', type: 'number', filter: 'agNumberColumnFilter' },
  duration: { label: 'Duration', type: 'number', filter: 'agNumberColumnFilter' },
  participants: { label: 'Participants', type: 'number', filter: 'agNumberColumnFilter' },
  mmcItemId: { label: 'MMC Item ID', type: 'text', filter: 'agTextColumnFilter' },
  mmcItemHash: { label: 'MMC Item Hash', type: 'text', filter: 'agTextColumnFilter' },
  mmcPlayerId: { label: 'MMC Player ID', type: 'text', filter: 'agTextColumnFilter' },
  mmcPlayerHash: { label: 'MMC Player Hash', type: 'text', filter: 'agTextColumnFilter' },
  studioEventKey: { label: 'Studio Event Key', type: 'text', filter: 'agTextColumnFilter' },
  studioShowKey: { label: 'Studio Show Key', type: 'text', filter: 'agTextColumnFilter' },
  studioShowName: { label: 'Studio Show Name', type: 'text', filter: 'agTextColumnFilter' },
  hostingDuration: { label: 'Hosting Duration', type: 'number', filter: 'agNumberColumnFilter' },
  eventCallProvider: { label: 'Call Provider', type: 'select', filter: 'agSetColumnFilter' },
  lobby: { label: 'Lobby', type: 'select', filter: 'agSetColumnFilter' },
};

export const eventsConfig: FormShape = {
  isPrimary: { label: 'Primary', type: 'boolean', filter: 'agSetColumnFilter' },
  name: { label: 'Event Name', type: 'text', filter: 'agTextColumnFilter' },
  startDate: { label: 'Event Start', type: 'datetime-local', filter: 'agDateColumnFilter' },
  eventSubType: { label: 'Event Type', type: 'select', filter: 'agSetColumnFilter' },
  serviceModelType: { label: 'Service Model', type: 'select', filter: 'agSetColumnFilter' },
  usageType: { label: 'Usage Type', type: 'select', filter: 'agSetColumnFilter' },
  languageCount: { label: 'Languages', type: 'number', filter: 'agNumberColumnFilter' },
  duration: { label: 'Duration', type: 'number', filter: 'agNumberColumnFilter' },
  hostingDuration: { label: 'Hosting Duration', type: 'number', filter: 'agNumberColumnFilter' },
  liveViewers: { label: 'Viewers Live', type: 'number', filter: 'agNumberColumnFilter' },
  contentType: { label: 'Content', type: 'select', filter: 'agSetColumnFilter' },
  deliveryType: { label: 'Delivery', type: 'select', filter: 'agSetColumnFilter' },
  studioEventKey: { label: 'Studio Event Key', type: 'text', filter: 'agTextColumnFilter' },
  studioShowKey: { label: 'Studio Show Key', type: 'text', filter: 'agTextColumnFilter' },
};
