// TODO: refactor this file!
import React, { useEffect, useState } from 'react';
import { IProject, IEvent, ProjectTypes, IProjectHeader } from '../../../utils/api.d';
import styles from '../AddNewProject.module.scss';
import { eventTypeDropdownOptions, MiscSubProject, usageTypeDropdownOptions } from '../constants';
import AccessControl from '../../../components/AccessControl';
import { N3AEntitlements } from '../../../constants';
import { contentType, deliveryType, serviceModelType } from '../../../enums';

interface IProjectEventFormFields {
  events: IEvent[];
  projectType: string;
  formDataInit: Partial<IProject>;
  eventHeaderData?: Partial<IProjectHeader>;
  showEventDetailsFields?: boolean;
}

type SectionType = Partial<IProjectEventFormFields>;

const SubTypeSection = ({ projectType, eventHeaderData }: SectionType) => {
  const projectTypeId =
    (eventHeaderData && eventHeaderData.projectType && eventHeaderData.projectType.id) || projectType;
  const typeOfProject =
    projectTypeId && +projectTypeId === ProjectTypes.Misc && eventHeaderData && eventHeaderData.parentProject
      ? MiscSubProject
      : projectTypeId;
  return (
    <div className="col-sm-3">
      <label className={'col-form-label ' + styles.requiredField}>Event Type</label>
      <select
        form="projectFormId"
        defaultValue={
          (eventHeaderData && eventHeaderData.projectSubType && eventHeaderData.projectSubType.value) || 'Unknown'
        }
        className="form-control"
        name="projectSubType"
        required={true}
      >
        <option value="Unknown">Unknown</option>
        <React.Fragment>
          {typeOfProject &&
            eventTypeDropdownOptions[typeOfProject] &&
            eventTypeDropdownOptions[typeOfProject].map((eventType: { id: number; value: string; name: string }) => (
              <option key={eventType.id} value={eventType.value}>
                {eventType.name}
              </option>
            ))}
        </React.Fragment>
      </select>
    </div>
  );
};

const ServiceModelSection = ({ eventHeaderData }: SectionType) => (
  <div className="col-sm-3">
    <label className={'col-form-label ' + styles.requiredField}>Service Model</label>
    <select
      form="projectFormId"
      defaultValue={
        (eventHeaderData && eventHeaderData.serviceModelType && eventHeaderData.serviceModelType.value) || 'Unknown'
      }
      className="form-control"
      name="serviceModelType"
      required={true}
    >
      {Object.values(serviceModelType) &&
        Object.values(serviceModelType).map(type => {
          return (
            <option value={type.value} key={type.id}>
              {type.name}
            </option>
          );
        })}
    </select>
  </div>
);

const UsageSection = ({ projectType, eventHeaderData }: SectionType) => {
  const projectTypeId =
    (eventHeaderData && eventHeaderData.projectType && eventHeaderData.projectType.id) || projectType;

  return (
    <div className="col-sm-3">
      <label className={'col-form-label ' + styles.requiredField}>Usage</label>
      <select
        form="projectFormId"
        defaultValue={(eventHeaderData && eventHeaderData.usageType && eventHeaderData.usageType.value) || 'Unknown'}
        className="form-control"
        name="usageType"
        required={true}
      >
        {projectTypeId &&
          usageTypeDropdownOptions[projectTypeId] &&
          usageTypeDropdownOptions[projectTypeId].map((usageType: { id: number; value: string; name: string }) => (
            <option key={usageType.id} value={usageType.value}>
              {usageType.name}
            </option>
          ))}
      </select>
    </div>
  );
};

const EventDurationSection = ({ eventHeaderData }: SectionType) => (
  <div className="col-sm-3">
    <label className={'col-form-label ' + styles.requiredField}>Purchased Duration (minutes)</label>
    <input
      form="projectFormId"
      defaultValue={eventHeaderData && eventHeaderData.duration}
      type="number"
      min="0"
      className="form-control"
      name="duration"
      required={true}
    />
  </div>
);

const HostingDurationSection = ({ eventHeaderData }: SectionType) => (
  <div className="col-sm-3">
    <label className={'col-form-label ' + styles.requiredField}>Hosting Duration (days)</label>
    <input
      form="projectFormId"
      defaultValue={eventHeaderData && eventHeaderData.hostingDays}
      type="number"
      min="0"
      className="form-control"
      name="hostingDays"
      required={true}
    />
  </div>
);

const LanguagesSection = ({ eventHeaderData }: SectionType) => (
  <div className="col-sm-3">
    <label className={'col-form-label ' + styles.requiredField}>Language Qty</label>
    <input
      form="projectFormId"
      defaultValue={eventHeaderData && eventHeaderData.languagesQty}
      type="number"
      min="1"
      className="form-control"
      name="languages"
      required={true}
    />
  </div>
);

const ViewersLiveSection = ({ eventHeaderData }: SectionType) => (
  <div className="col-sm-3">
    <label className={'col-form-label ' + styles.requiredField}>Viewers Purchased</label>
    <input
      form="projectFormId"
      defaultValue={eventHeaderData && eventHeaderData.liveViewers}
      type="number"
      min="0"
      className="form-control"
      name="liveViewers"
      required={true}
    />
  </div>
);

const ContentSection = ({ eventHeaderData }: SectionType) => (
  <div className="col-sm-6">
    <label className="col-form-label">Content</label>
    <select
      form="projectFormId"
      defaultValue={(eventHeaderData && eventHeaderData.contentType && eventHeaderData.contentType.value) || 'Unknown'}
      className="form-control"
      name="contentType"
      required={false}
    >
      {Object.values(contentType) &&
        Object.values(contentType).map(type => {
          return (
            <option value={type.value} key={type.id}>
              {type.name}
            </option>
          );
        })}
    </select>
  </div>
);

const DeliverySection = ({ eventHeaderData }: SectionType) => (
  <div className="col-sm-6">
    <label className="col-form-label">Delivery</label>
    <select
      form="projectFormId"
      defaultValue={
        (eventHeaderData && eventHeaderData.deliveryType && eventHeaderData.deliveryType.value) || 'Unknown'
      }
      className="form-control"
      name="deliveryType"
      required={false}
    >
      {Object.values(deliveryType) &&
        Object.values(deliveryType).map(type => {
          return (
            <option value={type.value} key={type.id}>
              {type.name}
            </option>
          );
        })}
    </select>
  </div>
);

const EventsAllowedSection = ({ eventHeaderData }: SectionType) => (
  <div className="col-sm-1">
    <label className={'col-form-label ' + styles.requiredField}>Allowed</label>
    <input
      form="projectFormId"
      defaultValue={eventHeaderData && eventHeaderData.eventsAllowed}
      type="number"
      min="0"
      className="form-control"
      name="eventsAllowed"
      required={true}
    />
  </div>
);

const EventsUsedSection = ({ eventHeaderData }: SectionType) => (
  <div className="col-sm-1">
    <label className="col-form-label">Used</label>
    <input
      form="projectFormId"
      defaultValue={eventHeaderData && eventHeaderData.eventsUsed}
      type="number"
      min="0"
      className="form-control"
      name="eventsUsed"
      readOnly={true}
    />
  </div>
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const EventsRemainingSection = (data: any) => (
  <div className="col-sm-1">
    <label className="col-form-label">Remaining</label>
    <input
      form="projectFormId"
      defaultValue={data.remainingData}
      type="number"
      min="0"
      className="form-control"
      name="eventsRemaining"
      readOnly={true}
    />
  </div>
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const RegistrantsFieldSection = ({ registrants }: { registrants?: any }) => (
  <div className="col-sm-3">
    <label className="col-form-label">Registrants</label>
    <input
      form="projectFormId"
      defaultValue={registrants}
      type="number"
      min="0"
      className="form-control"
      name="registrants"
    />
  </div>
);

export function EventFields({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  formDataInit,
  projectType,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  events,
  eventHeaderData,
  showEventDetailsFields,
}: IProjectEventFormFields) {
  const [display, setDisplay] = useState(true);
  let remainingData = '';
  if (eventHeaderData) {
    remainingData = ((eventHeaderData.eventsAllowed || 0) - (eventHeaderData.eventsUsed || 0)).toString();
  }

  // TODO: investigate these hooks; seems, that needs refactoring
  useEffect(() => {
    // refresh default field data
    setDisplay(false);
  }, [eventHeaderData]);

  useEffect(() => {
    // put display back
    if (!display) {
      setDisplay(true);
    }
  }, [display]);

  if (!display) return null;
  return (
    <AccessControl actions={[N3AEntitlements.Admin]} errorProps={{ disabled: true }}>
      <fieldset>
        <div className="projectForm">
          <div className="form-group row">
            <EventsAllowedSection eventHeaderData={eventHeaderData} />
            <EventsUsedSection eventHeaderData={eventHeaderData} />
            <EventsRemainingSection remainingData={remainingData} />
            {showEventDetailsFields && (
              <>
                <SubTypeSection eventHeaderData={eventHeaderData} projectType={projectType} />
                <ServiceModelSection eventHeaderData={eventHeaderData} />
                <UsageSection eventHeaderData={eventHeaderData} />
                <EventDurationSection eventHeaderData={eventHeaderData} />
                <HostingDurationSection eventHeaderData={eventHeaderData} />
                <LanguagesSection eventHeaderData={eventHeaderData} />
                <ViewersLiveSection eventHeaderData={eventHeaderData} />
                {projectType !== ProjectTypes.VirtualEvent.toString() &&
                  projectType !== ProjectTypes.VESubProject.toString() && (
                    <>
                      <ContentSection eventHeaderData={eventHeaderData} />
                      <DeliverySection eventHeaderData={eventHeaderData} />
                    </>
                  )}
                {(projectType === ProjectTypes.VirtualEvent.toString() ||
                  projectType === ProjectTypes.PlanSubProject.toString()) && (
                  <RegistrantsFieldSection registrants={eventHeaderData && eventHeaderData.registrants} />
                )}
              </>
            )}
          </div>
        </div>
      </fieldset>
    </AccessControl>
  );
}
