import { ColDef, ColGroupDef, ValueGetterParams, ValueFormatterParams } from 'ag-grid-community';
import { tracingTypes } from '../NewTimeTracking/tracingTypesAndDuration';
import {
  dateFormatter,
  durationConverter,
  durationParserForFilter,
  numberParserForFilter as numberParser,
  transformToColumns,
  createMapFromArrayOfObjects,
} from '../../utils/helpers';
import { timeTrackingListGridColumns } from '../../tablesFormShapes/timeTrackingList';
import { supportRegions } from '../../enums';
import {
  today,
  tomorrow,
  yesterday,
  thisWeek,
  nextWeek,
  thisMonth,
  lastMonth,
} from '../../utils/agGridCustomFilterOptions';

const timeTrackingTypesMap = {};
const timeTrackingSubTypesMap = {};
const regionsMap = createMapFromArrayOfObjects(supportRegions, 'id', 'name');
// eslint-disable-next-line @typescript-eslint/no-explicit-any
tracingTypes.forEach((type: any) => {
  const { id, name, subtypes } = type;

  timeTrackingTypesMap[id] = name;

  subtypes.forEach((subtype: { value: string; name: string; id: number }) => {
    const { id, name } = subtype;
    timeTrackingSubTypesMap[id] = name;
  });
});

export default function(): (ColDef | ColGroupDef)[] {
  return transformToColumns(timeTrackingListGridColumns, {
    id: {
      hide: true,
      filterParams: {
        numberParser,
      },
    },
    staffId: {
      hide: true,
      filterParams: {
        numberParser,
      },
    },
    projectId: {
      hide: true,
      filterParams: {
        numberParser,
      },
    },
    staffName: { sort: 'asc' },
    type: {
      filterParams: {
        suppressSorting: true,
        debounceMs: 1000,
        values: Object.keys(timeTrackingTypesMap),
        valueFormatter: (params: ValueFormatterParams) => timeTrackingTypesMap[params.value] || params.value,
      },
      valueGetter: (params: ValueGetterParams) => timeTrackingTypesMap[params.data.type] || params.data.type,
    },
    subType: {
      filterParams: {
        suppressSorting: true,
        debounceMs: 1000,
        values: Object.keys(timeTrackingSubTypesMap),
        valueFormatter: (params: ValueFormatterParams) => timeTrackingSubTypesMap[params.value] || params.value,
      },
      valueGetter: (params: ValueGetterParams) => timeTrackingSubTypesMap[params.data.subType] || params.data.subType,
    },
    duration: {
      floatingFilter: false,
      filterParams: {
        allowedCharPattern: `\\d\\:`, // new RegExp(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/); // HH:MM - duration pattern; doesn't work
        numberParser: durationParserForFilter,
      },
      valueGetter: (params: ValueGetterParams) => (params.data.duration ? durationConverter(params.data.duration) : ''),
    },
    date: {
      floatingFilter: false,
      valueGetter: dateFormatter('date', false),
      filterParams: {
        filterOptions: [
          'equals',
          'greaterThan',
          'lessThan',
          'notEqual',
          'inRange',
          today,
          yesterday,
          tomorrow,
          thisWeek,
          nextWeek,
          thisMonth,
          lastMonth,
        ],
      },
    },
    staffRegion: {
      filterParams: {
        suppressSorting: true,
        debounceMs: 1000,
        values: Object.keys(regionsMap),
        valueFormatter: (params: ValueFormatterParams) => regionsMap[params.value] || params.value,
      },
      valueGetter: (params: ValueGetterParams) => regionsMap[params.data.staffRegion] || params.data.staffRegion,
    },
  });
}
