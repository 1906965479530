import { all, call, put } from 'redux-saga/effects';
import timeTracking from '../actions/timeTracking';
import staffs from '../actions/staffs';
import { Routes } from '../constants';
import { appLoadingPlaceholder } from '../utils/helpers';

export function* fetcher() {
  return yield all([put(timeTracking.fetchOne()), put(staffs.fetch({ isProjectManager: false }))]);
}

export default function* newTimeTrackingPage() {
  while (true) {
    yield call(appLoadingPlaceholder, [Routes.NewTimeTracking, Routes.EditTimeTracking], fetcher);
  }
}
