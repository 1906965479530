import { FormShape } from '../utils/api.d';

export const salesforceListGridColumns: FormShape = {
  projectId: { label: 'Project ID', type: 'text', filter: 'agTextColumnFilter' },
  projectName: { label: 'Project Name', type: 'text', filter: 'agTextColumnFilter' },
  projectType: { label: 'Project Type', type: 'text', filter: 'agTextColumnFilter' },
  id: { label: 'ID', type: 'text', filter: 'agTextColumnFilter' },
  accountCountry: { label: 'Account Country', type: 'text', filter: 'agTextColumnFilter' },
  accountLongId: { label: 'Account Long ID', type: 'text', filter: 'agTextColumnFilter' },
  accountNumber: { label: 'Account #', type: 'text', filter: 'agTextColumnFilter' },
  accountName: { label: 'Account Name', type: 'text', filter: 'agTextColumnFilter' },
  accountRegion: { label: 'Account Region', type: 'text', filter: 'agTextColumnFilter' },
  accountOwner: { label: 'Account Owner', type: 'text', filter: 'agTextColumnFilter' },
  accountCustomerSuccessMgr: { label: 'Account Customer Success Mgr', type: 'text', filter: 'agTextColumnFilter' },
  accountTotalContractedValueUsd: {
    label: 'Account Total Contracted Value (USD)',
    type: 'number',
    filter: 'agNumberColumnFilter',
  },
  parentAccountLongId: { label: 'Parent Account Long ID', type: 'text', filter: 'agTextColumnFilter' },
  parentAccountName: { label: 'Parent Account Name', type: 'text', filter: 'agTextColumnFilter' },
  accountLastModifiedByDate: {
    label: 'Account Last Modified By Date',
    type: 'datetime-local',
    filter: 'agDateColumnFilter',
  },
  opportunityLongId: { label: 'Opportunity Long ID', type: 'text', filter: 'agTextColumnFilter' },
  opportunityStage: { label: 'Opportunity Stage', type: 'text', filter: 'agTextColumnFilter' },
  opportunityCloseDate: { label: 'Opportunity Close Date', type: 'datetime-local', filter: 'agDateColumnFilter' },
  opportunityOwner: { label: 'Opportunity Owner', type: 'text', filter: 'agTextColumnFilter' },
  opportunityOneTimeTotal: { label: 'Opportunity One Time Total', type: 'number', filter: 'agNumberColumnFilter' },
  opportunityAnnualRecurringTotal: {
    label: 'Opportunity Annual Recurring Total',
    type: 'number',
    filter: 'agNumberColumnFilter',
  },
  opportunityFirstYearContractValue: {
    label: 'Opportunity First Year Contract Value',
    type: 'text',
    filter: 'agTextColumnFilter',
  },
  opportunityCreatedDate: { label: 'Opportunity Created Date', type: 'datetime-local', filter: 'agDateColumnFilter' },
  opportunityLastModifiedDate: {
    label: 'Opportunity Last Modified Date',
    type: 'datetime-local',
    filter: 'agDateColumnFilter',
  },
  quoteLongId: { label: 'Quote Long ID', type: 'text', filter: 'agTextColumnFilter' },
  quoteName: { label: 'Quote Name', type: 'text', filter: 'agTextColumnFilter' },
  orderFormNotes: { label: 'Order Form Notes', type: 'text', filter: 'agTextColumnFilter' },
  additionalDetails: { label: 'Additional Details', type: 'text', filter: 'agTextColumnFilter' },
  venueAddress: { label: 'Venue Address', type: 'text', filter: 'agTextColumnFilter' },
  isEventDateKnown: { label: 'Is Event Date Known', type: 'text', filter: 'agTextColumnFilter' },
  av: { label: 'AV', type: 'text', filter: 'agTextColumnFilter' },
  duration: { label: 'Duration', type: 'text', filter: 'agTextColumnFilter' },
  clientContactName: { label: 'Client Contact Name', type: 'text', filter: 'agTextColumnFilter' },
  featuresDiscussed: { label: 'Features Discussed', type: 'text', filter: 'agTextColumnFilter' },
  onsite: { label: 'Onsite', type: 'text', filter: 'agTextColumnFilter' },
  orderLongId: { label: 'Order Long ID', type: 'text', filter: 'agTextColumnFilter' },
  orderNumber: { label: 'Order Number', type: 'text', filter: 'agTextColumnFilter' },
  orderFirstYearContractValue: { label: 'Order First Year Contract Value', type: 'text', filter: 'agTextColumnFilter' },
  orderProductLongId: { label: 'Order Product Long ID', type: 'text', filter: 'agTextColumnFilter' },
  currency: { label: 'Currency', type: 'text', filter: 'agTextColumnFilter' },
  orderProductQuantity: { label: 'Order Product Quantity', type: 'number', filter: 'agNumberColumnFilter' },
  orderProductNetQuantity: { label: 'Order Product Net Quantity', type: 'number', filter: 'agNumberColumnFilter' },
  unitPrice: { label: 'Unit Price', type: 'text', filter: 'agTextColumnFilter' },
  oneTimeTotal: { label: 'One Time Total', type: 'number', filter: 'agNumberColumnFilter' },
  annualRecurringTotal: { label: 'Annual Recurring Total', type: 'number', filter: 'agNumberColumnFilter' },
  frequency: { label: 'Frequency', type: 'text', filter: 'agTextColumnFilter' },
  billingSchedule: { label: 'Billing Schedule', type: 'text', filter: 'agTextColumnFilter' },
  requiredBy: { label: 'Required By', type: 'text', filter: 'agTextColumnFilter' },
  revisedProductId: { label: 'Revised Product ID', type: 'text', filter: 'agTextColumnFilter' },
  orderProductBillingStatus: { label: 'Order Product Billing Status', type: 'text', filter: 'agTextColumnFilter' },
  ecbPushStatus: { label: 'ECB Push Status', type: 'text', filter: 'agTextColumnFilter' },
  ecbPushStatusOfFinalPayment: {
    label: 'ECB Push Status of Final Payment',
    type: 'text',
    filter: 'agTextColumnFilter',
  },
  revenueRecognized: { label: 'Revenue Recegonized', type: 'text', filter: 'agTextColumnFilter' },
  orderProductEventTitle: { label: 'Order Product Event Title', type: 'text', filter: 'agTextColumnFilter' },
  eventId: { label: 'Event ID', type: 'text', filter: 'agTextColumnFilter' },
  orderProductCreatedDate: {
    label: 'Order Product Created Date',
    type: 'datetime-local',
    filter: 'agDateColumnFilter',
  },
  orderProductLastModifiedDate: {
    label: 'Order Product Last Modified Date',
    type: 'datetime-local',
    filter: 'agDateColumnFilter',
  },
  productName: { label: 'Product Name', type: 'text', filter: 'agTextColumnFilter' },
  productCode: { label: 'Product Code', type: 'text', filter: 'agTextColumnFilter' },
  productFamily: { label: 'Product Family', type: 'text', filter: 'agTextColumnFilter' },
  productBrand: { label: 'Product Brand', type: 'text', filter: 'agTextColumnFilter' },
  productCategory: { label: 'Product Category', type: 'text', filter: 'agTextColumnFilter' },
  productRevenueType: { label: 'Product Revenue Type', type: 'text', filter: 'agTextColumnFilter' },
  productLastModifiedDate: {
    label: 'Product Last Modified Date',
    type: 'datetime-local',
    filter: 'agDateColumnFilter',
  },
  contractLongId: { label: 'Contract Long ID', type: 'text', filter: 'agTextColumnFilter' },
  recurringInitialTerm: { label: 'Recurring Initial Term', type: 'number', filter: 'agNumberColumnFilter' },
  oneTimeTerm: { label: 'One Time Term', type: 'number', filter: 'agNumberColumnFilter' },
  contractStartDate: { label: 'Contract Start Date', type: 'datetime-local', filter: 'agDateColumnFilter' },
  contractEndDate: { label: 'Contract End Date', type: 'datetime-local', filter: 'agDateColumnFilter' },
  contractLastModifiedDate: {
    label: 'Contract Last Modified Date',
    type: 'datetime-local',
    filter: 'agDateColumnFilter',
  },
  subscriptionLongId: { label: 'Subscription Long ID', type: 'text', filter: 'agTextColumnFilter' },
  subscriptionName: { label: 'Subscription Name', type: 'text', filter: 'agTextColumnFilter' },
  subscriptionStatus: { label: 'Subscription Status', type: 'text', filter: 'agTextColumnFilter' },
  quantity: { label: 'Quantity', type: 'number', filter: 'agNumberColumnFilter' },
  netQuantity: { label: 'NET Quantity', type: 'number', filter: 'agNumberColumnFilter' },
  contractNumber: { label: 'Contract Number', type: 'text', filter: 'agTextColumnFilter' },
  opportunityName: { label: 'Opportunity Name', type: 'text', filter: 'agTextColumnFilter' },
  eventDate: { label: 'Event Date', type: 'datetime-local', filter: 'agDateColumnFilter' },
  recurringRolloverTerm: { label: 'Recurring Rollover Term', type: 'number', filter: 'agNumberColumnFilter' },
  eventFulfillmentDate: { label: 'Event Fulfillment Date', type: 'datetime-local', filter: 'agDateColumnFilter' },
  subscriptionAmountUsd: { label: 'Subscription Amount (USD)', type: 'text', filter: 'agTextColumnFilter' },
  subscriptionDbaNumber: { label: 'Subscription DBA #', type: 'text', filter: 'agTextColumnFilter' },
  subscriptionStartDate: { label: 'Subscription Start Date', type: 'datetime-local', filter: 'agDateColumnFilter' },
  subscriptionEndDate: { label: 'Subscription End Date', type: 'datetime-local', filter: 'agDateColumnFilter' },
  subscriptionRenewalDate: { label: 'Subscription Renewal Date', type: 'datetime-local', filter: 'agDateColumnFilter' },
  subscriptionTerminatedDate: {
    label: 'Subscription Terminated Date',
    type: 'datetime-local',
    filter: 'agDateColumnFilter',
  },
  subscriptionLastModifiedDate: {
    label: 'Subscription Last Mosified Date',
    type: 'datetime-local',
    filter: 'agDateColumnFilter',
  },
  caseNumber: { label: 'Case Number', type: 'text', filter: 'agTextColumnFilter' },
  caseStatus: { label: 'Case Status', type: 'text', filter: 'agTextColumnFilter' },
  caseSubject: { label: 'Case Subject', type: 'text', filter: 'agTextColumnFilter' },
  orderProductEventDate: { label: 'Order Product Event Date', type: 'datetime-local', filter: 'agDateColumnFilter' },
  orderProductEventId: { label: 'Order Product Event ID', type: 'text', filter: 'agTextColumnFilter' },
  wdmsEventName: { label: 'WDMS Event Name', type: 'text', filter: 'agTextColumnFilter' },
  wdmsEventStatus: { label: 'WDMS Event Status', type: 'text', filter: 'agTextColumnFilter' },
  wdmsEventOwner: { label: 'WDMS Event Owner', type: 'text', filter: 'agTextColumnFilter' },
  wdmsEventId: { label: 'WDMS Event-Event ID', type: 'text', filter: 'agTextColumnFilter' },
  wdmsEventTitle: { label: 'WDMS Event-Event Title', type: 'text', filter: 'agTextColumnFilter' },
  wdmsEventDate: { label: 'WDMS Event-Event Date', type: 'datetime-local', filter: 'agDateColumnFilter' },
  wdmsEventCreatedDate: { label: 'WDMS Event Created Date', type: 'datetime-local', filter: 'agDateColumnFilter' },
  wdmsEventLastModifiedDate: {
    label: 'WDMS Event Last Modified Date',
    type: 'datetime-local',
    filter: 'agDateColumnFilter',
  },
};
