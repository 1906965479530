import React, { useState } from 'react';
import { Button } from 'reactstrap';
import getColumns from './columns';
import styles from './salesforce.module.scss';
import makeGridOptions from '../../gridOptions';
import { Grid } from '../../components/Grid';
import { useParams } from 'react-router-dom';
import { GridReadyEvent, RowDoubleClickedEvent } from 'ag-grid-community/dist/lib/events';
import { IPlis } from '../../utils/api.d';
import { history } from '../../store';
import { Routes } from '../../constants';
import { GridApi, ColumnApi, ServerSideStoreType } from 'ag-grid-community';
import { urls } from '../../utils/api';
import {
  createServerSideDatasource,
  parseQueryParams,
  getPathToPreviousScreen,
  checkForSavedGrid,
  gridStateSaver,
  resetAllGridFilters,
} from '../../utils/helpers';
import { getAccountLongId } from '../../reducers/dsas';
import { shallowEqual, useSelector } from 'react-redux';
import { AppGrids, projectType as ProjectTypeEnum, subscriptionStatus } from '../../enums';
import { Link } from 'react-router-dom';
import { getUser } from '../../reducers/user';

const projectTypeMap = new Map();

Object.keys(ProjectTypeEnum).forEach(type => {
  const projectType = ProjectTypeEnum[type];

  if (projectType.id) {
    const { id, name } = projectType;
    projectTypeMap.set(name, id);
  }
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getProjectDetailsLink = (pli: any, field: string) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let link: any = '';
  const projectId = pli.projectId;

  if (projectId) {
    const projectTypeId = projectTypeMap.get(pli.projectType);

    const route = Routes.NewEditProject.replace(':id', pli.projectId.toString())
      .replace(':type', projectTypeId.toString())
      .replace(':backUrl', encodeURIComponent(location.pathname));

    link = (
      <Link to={route} target="_blank" rel="noreferrer noopener" className={styles.gridLink}>
        {pli[field]}
      </Link>
    );
  }

  return link;
};

const rowClassRules = {
  [styles.disabledRow]: (row: { data: { projectId: number; subscriptionStatus: string } }) =>
    row.data ? !!row.data.projectId || row.data.subscriptionStatus === subscriptionStatus.cancelled : false,
};

const Salesforce = () => {
  const { backUrl } = useParams();
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [gridColumnApi, setGridColumnApi] = useState<ColumnApi | null>(null);
  const [selectedPlis, setSelectedPlis] = useState<IPlis[]>([]);
  const accountLongId = useSelector(getAccountLongId, shallowEqual);
  const { emailAddress } = useSelector(getUser, shallowEqual) || {};

  const columnDefs = getColumns();
  const rowsLimit = 500;
  const onSelectionChanged = () => {
    if (gridApi) {
      const selectedRows = gridApi.getSelectedRows();
      setSelectedPlis(selectedRows.map(i => i.id));
    }
  };
  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);

    const addressBarQueryParams = parseQueryParams(location.href);
    const longId = accountLongId || addressBarQueryParams.accountLongId;
    const apiQueryParams = longId && backUrl ? `?accountLongId=${longId}` : '';
    const datasource = createServerSideDatasource(`${urls.salesforceLazy}${apiQueryParams}`);
    params.api.setServerSideDatasource(datasource);
  };

  const onRowSelected = (row: {
    node: { setSelected: (selected: boolean) => void };
    data: { projectId: number; subscriptionStatus: string };
  }) => {
    if (row.data && (row.data.projectId || row.data.subscriptionStatus === subscriptionStatus.cancelled)) {
      row.node.setSelected(false);
    }
  };

  const backToProject = () => {
    if (backUrl && backUrl !== ':backUrl') {
      history.push(getPathToPreviousScreen(backUrl));
    } else {
      history.push(Routes.Salesforce);
    }
  };

  const onRowDoubleClicked = (row: RowDoubleClickedEvent) => {
    if (row.data && row.data.id) {
      const url = Routes.PLIDetail.replace(':id', row.data.id);
      window.open(url);
    }
  };

  const gridOptions = makeGridOptions(columnDefs, {
    rowModelType: 'serverSide',
    rowClassRules: rowClassRules,
    rowSelection: 'multiple',
    cacheBlockSize: rowsLimit,
    serverSideStoreType: ServerSideStoreType.Partial,
    animateRows: true,
    statusBar: { statusPanels: [] },
    onGridReady: onGridReady,
    onRowSelected: onRowSelected,
    onRowDoubleClicked: onRowDoubleClicked,
  });
  const noItemsSelected = !selectedPlis.length;

  const addPLIsToProject = () => {
    if (backUrl && backUrl !== ':backUrl') {
      history.push(getPathToPreviousScreen(backUrl) + `?plis=` + selectedPlis.join(','));
    }
  };

  const gridName = `${emailAddress}-${AppGrids.SalesForceList}`;
  const gridConfigName = `${emailAddress}-${AppGrids.SalesForceList}-config`;

  const saveState = () => gridColumnApi && gridStateSaver(gridColumnApi, gridName);

  if (gridColumnApi) {
    checkForSavedGrid(gridColumnApi, gridName, gridConfigName, columnDefs);
    gridStateSaver(gridColumnApi, gridName);
  }

  const resetAllFilters = () => resetAllGridFilters(gridApi);

  return (
    <>
      <div>
        <div className={styles.title}>Salesforce</div>
        {backUrl && (
          <div className="float-right">
            <Button className={'margin-left-10'} color="primary" onClick={backToProject}>
              Back to project
            </Button>
            <Button className={'margin-left-10'} color="primary" onClick={addPLIsToProject} disabled={noItemsSelected}>
              Add selected to project
            </Button>
          </div>
        )}

        <div className="float-right">
          <Button color="primary" onClick={resetAllFilters}>
            Reset Filters
          </Button>
        </div>
      </div>
      <Grid
        gridOptions={gridOptions}
        onSelectionChanged={onSelectionChanged}
        onColumnMoved={saveState}
        onColumnVisible={saveState}
        onColumnResized={saveState}
      />
    </>
  );
};

export default Salesforce;
