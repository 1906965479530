import React, { useState, FormEvent, useMemo, useEffect, useRef, useCallback } from 'react';
import { IAccount, IProjectHeader } from '../../../utils/api.d';
import { IStaffsState } from '../../../reducers/staffs';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import Select, { components } from 'react-select';
import './projectEditForm.scss';
import ProjectsHeader from '../../../components/ProjectsHeader';
import { get } from 'lodash';
import { Button } from 'reactstrap';
import styles from '../AddNewProject.module.scss';
import { projectType as ProjectTypeEnum, projectStatus, supportRegions, fulfillmentStatus } from '../../../enums';
import { getDate } from '../../../utils/helpers';
import { showInProjectPickerConfig, showAccountsSection, accountPlatformMap } from '../constants';
import { N3AEntitlements, Routes } from '../../../constants';
import AccessControl from '../../../components/AccessControl';
import CustomSelectMenuList from '../../../components/CustomSelectMenuList';
import Tooltip from '@atlaskit/tooltip';
import AsyncSelect from 'react-select/async';
import throttle from 'lodash/throttle';
import { getAccountOptions, createPlatformQueryString } from '../helpers';
import { history } from '../../../store';

interface IWebcastingFormProps {
  formDataInit: Partial<IProjectHeader>;
  staffs: IStaffsState;
  projectType: string;
  onSubmit: (event: FormEvent<HTMLFormElement>) => void;
  projectPlan: boolean;
  showProjectPicker: boolean;
  isProjectPickerDisabled?: boolean;
  isProjectManagerDisabled: boolean;
}

const ProjectEditForm = ({
  formDataInit,
  staffs,
  onSubmit,
  projectType,
  projectPlan,
  showProjectPicker,
  isProjectPickerDisabled,
  isProjectManagerDisabled,
}: IWebcastingFormProps) => {
  const projectTypeId =
    formDataInit.projectType && formDataInit.projectType.id ? formDataInit.projectType.id : parseInt(projectType);
  const [fulfillmentStart, setFulfillmentStart] = useState(getDate(formDataInit, 'fulfillmentStart', false));
  const [fulfillmentEnd, setFulfillmentEnd] = useState(getDate(formDataInit, 'fulfillmentEnd', false));
  const [cfpOpenDate, setCfpOpenDate] = useState(getDate(formDataInit, 'cfpOpenDate', false));
  const [cfpCloseDate, setCfpCloseDate] = useState(getDate(formDataInit, 'cfpCloseDate', false));
  const showFulfillment =
    projectTypeId !== ProjectTypeEnum.Misc.id && projectTypeId !== ProjectTypeEnum.VirtualEventSubProject.id;
  const showFulfillmentStatus =
    projectTypeId === ProjectTypeEnum.WebcastSubProject.id || projectTypeId === ProjectTypeEnum.VirtualEvent.id;
  const showInProjectPickerValue =
    (formDataInit.showInProjectPicker && formDataInit.showInProjectPicker.toString()) || showInProjectPickerConfig.NO;
  const recurringRolloverTerm = formDataInit && formDataInit.recurringRolloverTerm;
  const showSiteInfo = projectTypeId === ProjectTypeEnum.VirtualEvent.id;
  const showCFPStatusSection = projectTypeId === ProjectTypeEnum.VirtualEvent.id;
  const nameSectionWidthClass = showCFPStatusSection ? 'col-sm-6' : 'col-sm-12';
  const showClientContractInfo =
    projectTypeId === ProjectTypeEnum.VirtualEvent.id ||
    projectTypeId === ProjectTypeEnum.WebcastSubProject.id ||
    projectTypeId === ProjectTypeEnum.PlatformPlanSubProject.id;

  const supportRegionsOptions = useMemo(() => {
    return supportRegions.map(region => {
      return {
        value: `${region.id}`,
        label: `${region.value}`,
      };
    });
    // TODO: investigate and remove exhaustive dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supportRegions]);

  const defSupportRegion: Option = {
    value: (formDataInit.supportRegion && formDataInit.supportRegion.id.toString()) || '1',
    label: (formDataInit.supportRegion && formDataInit.supportRegion.name) || 'AMER',
  };

  const getDefAccounts = () => {
    const prAccounts = get(formDataInit, 'projectAccounts', []) as IAccount[];
    return prAccounts.map((account: IAccount) => {
      return {
        value: `${account.platform} ${account.accountId}`,
        label: `${account.name} (${accountPlatformMap[account.platform]})`,
      };
    });
  };

  const [defAccounts, setDefAccounts] = useState(getDefAccounts());

  const [refresh, setRefresh] = useState(true);

  const timeRef = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    if (formDataInit !== undefined) {
      setRefresh(true);
      setDefAccounts(getDefAccounts());
      timeRef.current && clearTimeout(timeRef.current);
      timeRef.current = setTimeout(() => {
        setRefresh(false);
      }, 200);
    } else {
      setRefresh(false);
    }
    return () => {
      return timeRef.current && clearTimeout(timeRef.current);
    };
    // TODO: investigate and remove exhaustive dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formDataInit]);

  const projectStatusId = formDataInit && formDataInit.projectStatus ? formDataInit.projectStatus.id : 1;
  const fulfillmentStatus = formDataInit && formDataInit.fulfillmentStatus ? formDataInit.fulfillmentStatus.id : 1;

  const onFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit(event);
  };

  const pmId = useMemo(() => {
    const prStaff = get(formDataInit, 'projectStaff', []);
    return ((prStaff.find(i => i.staffRoleType === 'ProjectManager') || {}).staff || { id: null }).id;
  }, [formDataInit]);

  if (refresh) return <div style={{ height: '500px' }} />;

  return (
    <form id="projectFormId" onSubmit={onFormSubmit} className="projectForm" autoComplete="off">
      <ProjectsHeader data={formDataInit ? formDataInit : {}} type={projectTypeId} />
      <div className="form-group row">
        <NameSection projectName={formDataInit.name} widthClass={nameSectionWidthClass} />
        {showCFPStatusSection && (
          <CFPDatesSection
            cfpOpenDate={cfpOpenDate}
            cfpCloseDate={cfpCloseDate}
            setCfpOpenDate={setCfpOpenDate}
            setCfpCloseDate={setCfpCloseDate}
          />
        )}
      </div>
      <div className="form-group row">
        <ProjectManagerSection
          staffs={staffs}
          formDataInit={formDataInit}
          pmId={pmId}
          isProjectManagerDisabled={isProjectManagerDisabled}
        />
        <SupportRegionSection defRegion={defSupportRegion} regionOptions={supportRegionsOptions} />
        <ProjectStatus status={projectStatusId} />
        {showAccountsSection[projectTypeId] && (
          <AccountSection
            defAccounts={defAccounts}
            projectSubtype={formDataInit.projectSubType}
            projectId={formDataInit.id}
          />
        )}
      </div>
      <div className="form-group row">
        {showProjectPicker && (
          <div className="col-sm-3">
            <label className={'col-form-label ' + styles.requiredField}>Show in project picker(s) </label>
            <select
              className="form-control"
              name="showInProjectPicker"
              id="showInProjectPicker"
              required={true}
              disabled={isProjectPickerDisabled}
            >
              <option
                value={showInProjectPickerConfig.YES}
                key={'showInProjectPickerYes'}
                selected={showInProjectPickerConfig.YES === showInProjectPickerValue}
              >
                Yes
              </option>
              <option
                value={showInProjectPickerConfig.NO}
                key={'showInProjectPickerNo'}
                selected={showInProjectPickerConfig.NO === showInProjectPickerValue}
              >
                No
              </option>
            </select>
          </div>
        )}
        {showFulfillment && (
          <AccessControl actions={[N3AEntitlements.Admin]} errorProps={{ disabled: true }}>
            <FulfillmentStart
              disabled={false}
              fulfillmentStart={fulfillmentStart}
              setFulfillmentStart={setFulfillmentStart}
            />
          </AccessControl>
        )}
        {showFulfillment && (
          <AccessControl actions={[N3AEntitlements.Admin]} errorProps={{ disabled: true }}>
            <FulfillmentEnd disabled={false} fulfillmentEnd={fulfillmentEnd} setFulfillmentEnd={setFulfillmentEnd} />
          </AccessControl>
        )}
        {showFulfillmentStatus && <FulfilmentStatus status={fulfillmentStatus} />}
      </div>

      {projectPlan && (
        <div className="form-group row">
          <RecurringRolloverTermSection value={recurringRolloverTerm} />
        </div>
      )}
      {showClientContractInfo && (
        <div className="form-group row">
          <ClientContactNameSection clientContactName={formDataInit.clientContactName} />
          <ClientContactEmailSection clientContactEmail={formDataInit.clientContactEmail} />
        </div>
      )}

      {showSiteInfo && (
        <div className="form-group row">
          <SiteIdSection siteId={formDataInit.siteId} />
          <SiteUrlSection siteUrl={formDataInit.siteUrl} />
        </div>
      )}
    </form>
  );
};

type SectionType = Partial<IWebcastingFormProps>;

interface Option {
  value: string;
  label: string;
}

const RecurringRolloverTermSection = ({ value }: { value: number | null | undefined }) => (
  <div className="col-sm-3">
    <label className={'col-form-label ' + styles.requiredField}>Recurring Rollover Term</label>
    <AccessControl actions={[N3AEntitlements.Admin]} errorProps={{ disabled: true }}>
      <input
        form="projectFormId"
        defaultValue={value !== null && value !== undefined ? value : ''}
        type="number"
        min="0"
        className="form-control"
        name="recurringRolloverTerm"
        required={true}
      />
    </AccessControl>
  </div>
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const NameSection = ({ projectName, widthClass }: { projectName?: string; widthClass: string }) => (
  <div className={widthClass}>
    <label className={'col-form-label ' + styles.requiredField}>Project Name</label>
    <input type="text" className="form-control" name="name" defaultValue={projectName} required={true} />
  </div>
);

const ProjectManagerSection = ({ staffs, pmId, isProjectManagerDisabled }: SectionType & { pmId: number | null }) => (
  <div className="col-sm-3">
    <label className="col-form-label">Project Manager</label>
    <select
      className="form-control"
      name="projectManager"
      required={true}
      defaultValue={pmId || undefined}
      disabled={isProjectManagerDisabled}
    >
      <option value="null">Unassigned</option>
      {staffs &&
        staffs.briefInfoList.map(staff => (
          <option value={staff.id} key={staff.id}>
            {staff.firstName} {staff.lastName}
          </option>
        ))}
    </select>
  </div>
);

const SupportRegionSection = ({ defRegion, regionOptions }: { regionOptions: Option[]; defRegion: Option }) => (
  <div className="col-sm-3">
    <label className={'col-form-label ' + styles.requiredField}>Support Region</label>
    <Select
      defaultValue={defRegion}
      name="supportRegion"
      options={regionOptions}
      className="basic-multi-select"
      classNamePrefix="select"
    />
  </div>
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ProjectStatus = ({ status }: any) => (
  <div className="col-sm-3">
    <label className={'col-form-label ' + styles.requiredField}>Project Status</label>
    <select className="form-control" name="projectStatus" required={true} defaultValue={status}>
      {Object.values(projectStatus) &&
        Object.values(projectStatus).map(ps => {
          return (
            <option value={ps.id} key={ps.id} selected={ps.id === status}>
              {ps.name}
            </option>
          );
        })}
    </select>
  </div>
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const FulfilmentStatus = ({ status }: any) => (
  <div className="col-sm-3">
    <label className="col-form-label">Fulfillment Status</label>
    <select className="form-control" name="fulfillmentStatus" required={false} disabled={true} defaultValue={status}>
      {Object.values(fulfillmentStatus) &&
        Object.values(fulfillmentStatus).map(fs => {
          return (
            <option value={fs.id} key={fs.id} selected={fs.id === status}>
              {fs.name}
            </option>
          );
        })}
    </select>
  </div>
);

// TODO: think about moving this into separate file
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const MultiValueContainer = (props: any) => {
  return (
    <div className={styles.tooltipWrapper}>
      <Tooltip content={props.data.label}>
        <components.MultiValueContainer {...props} />
      </Tooltip>
    </div>
  );
};

const AccountSection = ({
  defAccounts,
  projectId,
  projectSubtype,
}: {
  defAccounts: Option[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  projectSubtype: any;
  projectId: number | undefined;
}) => {
  const platformQueryString = createPlatformQueryString(projectSubtype);

  const loadOptions = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    throttle((inputText: string, callback: any) => {
      getAccountOptions(inputText, platformQueryString).then(options => callback(options));
    }, 2000),
    [],
  );

  const goToAccounts = () => {
    const queryParams = projectId ? `?projectIdLink=${projectId}` : '';
    const url = `${Routes.AccountsToProject.replace(':backUrl', encodeURIComponent(location.pathname))}${queryParams}`;

    return history.push(url);
  };

  return (
    <div className="col-sm-3">
      <label className="col-form-label">Account(s) </label>
      {projectId && (
        <Button className={styles.addAccountsBtn} onClick={goToAccounts}>
          <i className={styles.searchIcon}></i>
        </Button>
      )}
      <AsyncSelect
        defaultValue={defAccounts}
        isMulti
        name="projectAccounts"
        className="basic-multi-select"
        classNamePrefix="select"
        captureMenuScroll={false}
        components={{ MenuList: CustomSelectMenuList, MultiValueContainer }}
        loadOptions={loadOptions}
        backspaceRemovesValue={false}
      />
    </div>
  );
};

const FulfillmentStart = ({
  fulfillmentStart,
  setFulfillmentStart,
  disabled,
}: {
  fulfillmentStart: Date;
  setFulfillmentStart: (d: Date) => void;
  disabled: boolean;
}) => (
  <div className="col-sm-3">
    <label className={'col-form-label ' + styles.requiredField}>Fulfillment Start</label>
    <DatePicker
      selected={fulfillmentStart}
      onChange={(date: Date) => setFulfillmentStart(date)}
      wrapperClassName="form-control"
      className="form-control"
      name="fulfillmentStart"
      required={true}
      disabled={disabled}
      autoComplete={'off'}
    />
  </div>
);

const FulfillmentEnd = ({
  fulfillmentEnd,
  setFulfillmentEnd,
  disabled,
}: {
  fulfillmentEnd: Date;
  setFulfillmentEnd: (d: Date) => void;
  disabled: boolean;
}) => (
  <div className="col-sm-3">
    <label className={'col-form-label ' + styles.requiredField}>Fulfillment End</label>
    <DatePicker
      selected={fulfillmentEnd}
      onChange={(date: Date) => setFulfillmentEnd(date)}
      wrapperClassName="form-control"
      className="form-control"
      name="fulfillmentEnd"
      required={true}
      disabled={disabled}
      autoComplete={'off'}
    />
  </div>
);

export const ActionPanel = ({
  onClose,
  onDelete,
  completeEventButtonConfig,
  copyProjectButtonConfig,
  canDeleteProject,
}: {
  onClose: () => void;
  onDelete: () => void;
  completeEventButtonConfig: {
    isVisible: boolean;
    isDisabled: boolean;
    text: string;
    completeEvent: () => void;
  };
  copyProjectButtonConfig: {
    canCopyProject: boolean;
    copyProject: () => void;
  };
  canDeleteProject: boolean;
}) => {
  return (
    <div className={'row ' + styles.actionPanel}>
      <div className={'col-sm-12'}>
        <div className={'float-left'}>
          <Button type="submit" form={'projectFormId'} color="primary" className={'actionPanelButton'}>
            Save
          </Button>
          <Button color="secondary" className={'actionPanelButton'} onClick={onClose}>
            Close
          </Button>
          {copyProjectButtonConfig.canCopyProject && (
            <Button color="primary" className={'actionPanelButton'} onClick={copyProjectButtonConfig.copyProject}>
              Copy Project
            </Button>
          )}
        </div>
        <div className={'float-right'}>
          <div>
            {completeEventButtonConfig.isVisible && (
              <Button
                className={styles.completeEvent}
                color="primary"
                onClick={completeEventButtonConfig.completeEvent}
                disabled={completeEventButtonConfig.isDisabled}
              >
                {completeEventButtonConfig.text}
              </Button>
            )}
            {canDeleteProject && (
              <Button color="danger" onClick={onDelete}>
                Delete
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const CFPDatesSection = ({
  cfpOpenDate,
  cfpCloseDate,
  setCfpOpenDate,
  setCfpCloseDate,
}: {
  cfpOpenDate?: Date;
  cfpCloseDate?: Date;
  setCfpOpenDate: (d: Date) => void;
  setCfpCloseDate: (d: Date) => void;
}) => (
  <div className="col-sm-6">
    <label className={'col-form-label'}>CFP Open/Close:</label>
    <div className={'row'}>
      <div className="col-sm-6">
        <DatePicker
          selected={cfpOpenDate}
          onChange={(date: Date) => setCfpOpenDate(date)}
          wrapperClassName="form-control"
          className="form-control"
          name="cfpOpenDate"
          autoComplete={'off'}
        />
      </div>
      <div className="col-sm-6">
        <DatePicker
          selected={cfpCloseDate}
          onChange={(date: Date) => setCfpCloseDate(date)}
          wrapperClassName="form-control"
          className="form-control"
          name="cfpCloseDate"
          autoComplete={'off'}
        />
      </div>
    </div>
  </div>
);

const ClientContactEmailSection = ({ clientContactEmail }: { clientContactEmail?: string }) => (
  <div className="col-sm-6">
    <label className="col-form-label">Client Contact Email:</label>
    <input
      form="projectFormId"
      className="form-control"
      name="clientContactEmail"
      type="email"
      defaultValue={clientContactEmail}
    />
  </div>
);

const ClientContactNameSection = ({ clientContactName }: { clientContactName?: string }) => (
  <div className="col-sm-6">
    <label className="col-form-label">Client Contact Name:</label>
    <input form="projectFormId" className="form-control" name="clientContactName" defaultValue={clientContactName} />
  </div>
);

const SiteIdSection = ({ siteId }: { siteId?: string }) => (
  <div className="col-sm-6">
    <label className="col-form-label">Site Id:</label>
    <input form="projectFormId" className="form-control" name="siteId" defaultValue={siteId} />
  </div>
);

const SiteUrlSection = ({ siteUrl }: { siteUrl?: string }) => (
  <div className="col-sm-6">
    <label className="col-form-label">Site URL:</label>
    <input form="projectFormId" className="form-control" name="siteUrl" defaultValue={siteUrl} />
  </div>
);

export default ProjectEditForm;
