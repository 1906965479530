import { all, call, put, take } from 'redux-saga/effects';
import { Routes } from '../constants';
import { appLoadingPlaceholder } from '../utils/helpers';
import { clean, FETCH_BY_ID_LIST_FAILURE, FETCH_BY_ID_LIST_SUCCESS, fetchByIdList } from '../actions/salesforce';

export function* fetcher(match: { params: Record<string, string> }) {
  const params = match.params || {};
  const expectList = [];
  if (params.id) {
    yield put(fetchByIdList('?id=' + params.id));
    expectList.push(take([FETCH_BY_ID_LIST_SUCCESS, FETCH_BY_ID_LIST_FAILURE]));
  } else {
    yield put(clean()); // remove existing plis
  }
  return yield all(expectList);
}

export default function* pLIDetailPage() {
  while (true) {
    yield call(appLoadingPlaceholder, Routes.PLIDetail, fetcher);
  }
}
