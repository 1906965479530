export enum Routes {
  Root = '/',
  Projects = '/projects',
  AddNewProject = '/add-new-project/:type/:parentId/:dsaId/:backUrl',
  NewEditProject = '/new-edit-project/:type/:id/:dsaId/:backUrl',
  ProjectAuditLog = '/projects/:id/auditlog/:backUrl',
  Events = '/events',
  EventsToProject = '/events/:backUrl',
  EventDetail = '/events/:eventId/:projectId/:backUrl',
  TimeTracking = '/time-tracking',
  NewTimeTracking = '/new-time-tracking/:projectId/:backUrl',
  EditTimeTracking = '/edit-time-tracking/:id/:projectId/:backUrl',
  Accounts = '/accounts',
  AccountsToProject = '/accounts/:backUrl',
  AccountsDetail = '/accounts-detail/:id',
  Staff = '/staff',
  Salesforce = '/salesforce',
  PLIDetail = '/plidetail/:id',
  SalesforceToProject = '/salesforce/:backUrl',
  Callback = '/callback',
  Logout = '/logout',
  NewStaff = '/newstaff',
  DSAListView = '/dsa',
  DSADetail = '/dsa/:dsaid',
  Reports = '/reports',
}
export enum N3AEntitlements {
  Teamwork = 'Teamwork.Access',
  Admin = 'Teamwork.Admin',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const replaceRoute = (route: string, params: Record<string, any>) =>
  Object.keys(params).reduce((res, key) => res.replace(`:${key}`, params[key]), route);
