import { FormShape } from '../utils/api.d';

export const dsaListGridColumns: FormShape = {
  accountLongId: { label: 'Account ID', type: 'text', filter: 'agTextColumnFilter' },
  accountName: { label: 'Account Name', type: 'text', filter: 'agTextColumnFilter' },
  accountOwner: { label: 'Account Owner', type: 'text', filter: 'agTextColumnFilter' },
  accountCustomerSuccessMgr: { label: 'Account Customer Success Mgr', type: 'text', filter: 'agTextColumnFilter' },
  parentAccountName: { label: 'SFDC GUPA', type: 'text', filter: 'agTextColumnFilter' },
  accountCountry: { label: 'Account Country', type: 'text', filter: 'agTextColumnFilter' },
  accountRegion: { label: 'Account Region', type: 'text', filter: 'agTextColumnFilter' },
};
