import React, { useMemo } from 'react';
import styles from './AccountsDetail.module.scss';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { shallowEqual, useSelector } from 'react-redux';
import { history } from '../../store';
import { Routes } from '../../constants';
import AjaxSpinner from '../../components/AjaxSpinner';
import { IAccount } from '../../utils/api.d';
import { getCurrentAccount } from '../../reducers/accounts';

const goBack = () => {
  history.push(Routes.Accounts);
};

const AccountsDetail = () => {
  const accountState = useSelector(getCurrentAccount, shallowEqual);

  const editedAccounts = useMemo(() => {
    return accountState.account || {};
  }, [accountState]) as IAccount;

  return (
    <div className={styles.root}>
      <div className={styles['new-staff-view-form-container']}>
        <div className={styles['new-staff-view-title']}>Accounts View</div>
        {accountState.isLoading ? (
          <AjaxSpinner />
        ) : (
          <Form className={'card shadow ' + styles.form}>
            <FormGroup row>
              <Label className="col-sm-2" for="name">
                Name
              </Label>
              <div className="col-sm-10">
                <Input disabled defaultValue={editedAccounts.name} type="text" name="name" id="name" required />
              </div>
            </FormGroup>
            <FormGroup row>
              <Label className="col-sm-2" for="description">
                Description
              </Label>
              <div className="col-sm-10">
                <Input
                  disabled
                  defaultValue={editedAccounts.description}
                  type="text"
                  name="description"
                  id="description"
                  required
                />
              </div>
            </FormGroup>
            <FormGroup row>
              <Label className="col-sm-2" for="platform">
                Platform
              </Label>
              <div className="col-sm-10">
                <Input
                  disabled
                  defaultValue={editedAccounts.platform}
                  type="text"
                  name="platform"
                  id="platform"
                  required
                />
              </div>
            </FormGroup>
            <FormGroup row>
              <Label className="col-sm-2" for="accountId">
                Account ID
              </Label>
              <div className="col-sm-10">
                <Input disabled defaultValue={editedAccounts.accountId} type="text" name="accountId" id="accountId" />
              </div>
            </FormGroup>
            <FormGroup row>
              <Label className="col-sm-2" for="isFolder">
                MMC Is folder
              </Label>
              <div className="col-sm-10">
                <Input
                  disabled
                  defaultValue={editedAccounts.mmcIsFolder ? 1 : 0}
                  type="select"
                  name="isFolder"
                  id="isFolder"
                >
                  <option value={0}>No</option>
                  <option value={1}>Yes</option>
                </Input>
              </div>
            </FormGroup>
            <FormGroup row>
              <Label className="col-sm-2" for="parentId">
                MMC Parent ID
              </Label>
              <div className="col-sm-10">
                <Input disabled defaultValue={editedAccounts.mmcParentId} type="text" name="parentId" id="parentId" />
              </div>
            </FormGroup>
            <FormGroup row>
              <Label className="col-sm-2" for="masterId">
                Studio Master ID
              </Label>
              <div className="col-sm-10">
                <Input
                  disabled
                  defaultValue={editedAccounts.studioMasterId}
                  type="text"
                  name="masterId"
                  id="masterId"
                />
              </div>
            </FormGroup>
            <FormGroup row>
              <Label className="col-sm-2" for="masterName">
                Studio Master Name
              </Label>
              <div className="col-sm-10">
                <Input
                  disabled
                  defaultValue={editedAccounts.studioMasterName}
                  type="text"
                  name="masterName"
                  id="masterName"
                />
              </div>
            </FormGroup>
            <FormGroup row>
              <Label className="col-sm-2" for="isActive">
                Active
              </Label>
              <div className="col-sm-10">
                <Input
                  disabled
                  defaultValue={editedAccounts.active ? 1 : 0}
                  type="select"
                  name="isActive"
                  id="isActive"
                >
                  <option value={0}>No</option>
                  <option value={1}>Yes</option>
                </Input>
              </div>
            </FormGroup>
            <FormGroup row>
              <Label className="col-sm-2" for="usageType">
                Usage Type
              </Label>
              <div className="col-sm-10">
                <Input disabled defaultValue={editedAccounts.usageType} type="text" name="usageType" id="usageType" />
              </div>
            </FormGroup>
            <FormGroup row>
              <Label className="col-sm-2" for="mmcType">
                MMC Type
              </Label>
              <div className="col-sm-10">
                <Input disabled defaultValue={editedAccounts.mmcType} type="text" name="mmcType" id="mmcType" />
              </div>
            </FormGroup>
            <FormGroup row>
              <Label className="col-sm-2" for="mmcProduct">
                MMC Product
              </Label>
              <div className="col-sm-10">
                <Input
                  disabled
                  defaultValue={editedAccounts.mmcProduct}
                  type="text"
                  name="mmcProduct"
                  id="mmcProduct"
                />
              </div>
            </FormGroup>
            <FormGroup row>
              <Label className="col-sm-2" for="mmcResellerType">
                MMC Reseller Name
              </Label>
              <div className="col-sm-10">
                <Input
                  disabled
                  defaultValue={editedAccounts.mmcResellerType}
                  type="text"
                  name="mmcResellerType"
                  id="mmcResellerType"
                />
              </div>
            </FormGroup>
            <FormGroup row>
              <Label className="col-sm-2" for="studioServiceType">
                Studio Service Type
              </Label>
              <div className="col-sm-10">
                <Input
                  disabled
                  defaultValue={editedAccounts.studioServiceType}
                  type="text"
                  name="studioServiceType"
                  id="studioServiceType"
                />
              </div>
            </FormGroup>
            <FormGroup row>
              <Label className="col-sm-2" for="studioProduct">
                Studio Product
              </Label>
              <div className="col-sm-10">
                <Input
                  disabled
                  defaultValue={editedAccounts.studioProduct}
                  type="text"
                  name="studioProduct"
                  id="studioProduct"
                />
              </div>
            </FormGroup>
            <FormGroup row>
              <div className="offset-sm-2 col-sm-10">
                <Button color="secondary" onClick={goBack} className="col-sm-auto col-12">
                  Close
                </Button>
              </div>
            </FormGroup>
          </Form>
        )}
      </div>
    </div>
  );
};

export default AccountsDetail;
