import { put, take, call, StrictEffect } from 'redux-saga/effects';
import { fetchById, FETCH_BY_ID_FAILURE, FETCH_BY_ID_SUCCESS } from '../actions/events';
import { Routes } from '../constants';
import { appLoadingPlaceholder } from '../utils/helpers';
import { IAction } from '../utils/redux-create-reducer';

export function* fetcher(match: { params: Record<string, string> }): Generator<StrictEffect, IAction, IAction> {
  yield put(fetchById(match.params.eventId));
  return yield take([FETCH_BY_ID_SUCCESS, FETCH_BY_ID_FAILURE]);
}

export default function* eventPage() {
  while (true) {
    yield call(appLoadingPlaceholder, Routes.EventDetail, fetcher);
  }
}
