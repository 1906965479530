// use 'npm run g:a {name}' - to generate new action
import { createEmptyAction, createAction } from '../utils/actions';

export const FETCH = 'TIME_TRACKING/FETCH';
export const FETCH_SUCCESS = 'TIME_TRACKING/FETCH_SUCCESS';
export const FETCH_FAILURE = 'TIME_TRACKING/FETCH_FAILURE';

export const FETCH_BY_PROJECT_ID = 'TIME_TRACKING/FETCH_BY_PROJECT_ID';
export const FETCH_BY_PROJECT_ID_SUCCESS = 'TIME_TRACKING/FETCH_BY_PROJECT_ID_SUCCESS';
export const FETCH_BY_PROJECT_ID_FAILURE = 'TIME_TRACKING/FETCH_BY_PROJECT_ID_FAILURE';

export const FETCH_ONE = 'TIME_TRACKING/FETCH_ONE';
export const FETCH_ONE_SUCCESS = 'TIME_TRACKING/FETCH_ONE_SUCCESS';
export const FETCH_ONE_FAILURE = 'TIME_TRACKING/FETCH_ONE_FAILURE';

export const UPDATE_BUCKET = 'TIME_TRACKING/UPDATE_BUCKET';
export const FETCH_BUCKETS = 'TIME_TRACKING/FETCH_BUCKETS';
export const FETCH_BUCKETS_SUCCESS = 'TIME_TRACKING/FETCH_BUCKETS_SUCCESS';
export const FETCH_BUCKETS_FAILURE = 'TIME_TRACKING/FETCH_BUCKETS_FAILURE';

export const ADD_TIME_TRACKING = 'TIME_TRACKING/PUT';
export const ADD_SUCCESS = 'TIME_TRACKING/ADD_SUCCESS';
export const ADD_FAILURE = 'TIME_TRACKING/ADD_FAILURE';

export const UPDATE_TIME_TRACKING = 'TIME_TRACKING/UPDATE';
export const UPDATE_SUCCESS = 'TIME_TRACKING/UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'TIME_TRACKING/UPDATE_FAILURE';

export const DELETE = 'TIME_TRACKING/DELETE';
export const DELETE_SUCCESS = 'TIME_TRACKING/DELETE_SUCCESS';
export const DELETE_FAILURE = 'TIME_TRACKING/DELETE_FAILURE';

export const types = {
  FETCH_BUCKETS,
  FETCH_BUCKETS_SUCCESS,
  FETCH_BUCKETS_FAILURE,
  FETCH_ONE,
  FETCH_ONE_SUCCESS,
  FETCH_ONE_FAILURE,
  FETCH_BY_PROJECT_ID,
  FETCH_BY_PROJECT_ID_SUCCESS,
  FETCH_BY_PROJECT_ID_FAILURE,
  ADD_TIME_TRACKING,
  ADD_SUCCESS,
  ADD_FAILURE,
  UPDATE_TIME_TRACKING,
  UPDATE_SUCCESS,
  UPDATE_FAILURE,
  FETCH,
  FETCH_SUCCESS,
  FETCH_FAILURE,
  DELETE,
  DELETE_SUCCESS,
  DELETE_FAILURE,
};

export const add = createAction(ADD_TIME_TRACKING);
export const addSuccess = createAction(ADD_SUCCESS);
export const addFailure = createAction<Error>(ADD_FAILURE);

export const fetch = createEmptyAction(FETCH);
export const fetchSuccess = createAction(FETCH_SUCCESS);
export const fetchFailure = createAction<Error>(FETCH_FAILURE);

export const updateBucket = createAction(UPDATE_BUCKET);

export const fetchBuckets = createAction<string>(FETCH_BUCKETS);
export const fetchBucketsSuccess = createAction(FETCH_BUCKETS_SUCCESS);
export const fetchBucketsFailure = createAction<Error>(FETCH_BUCKETS_FAILURE);

export const fetchByProjectId = createAction(FETCH_BY_PROJECT_ID);
export const fetchByProjectIdSuccess = createAction(FETCH_BY_PROJECT_ID_SUCCESS);
export const fetchByProjectIdFailure = createAction<Error>(FETCH_BY_PROJECT_ID_FAILURE);

export const fetchOne = createEmptyAction(FETCH_ONE);
export const fetchOneSuccess = createAction(FETCH_ONE_SUCCESS);
export const fetchOneFailure = createAction<Error>(FETCH_ONE_FAILURE);

export const update = createAction(UPDATE_TIME_TRACKING);
export const updateSuccess = createAction(UPDATE_SUCCESS);
export const updateFailure = createAction<Error>(UPDATE_FAILURE);

export const del = createAction(DELETE);
export const delSuccess = createEmptyAction(DELETE_SUCCESS);
export const delFailure = createAction<Error>(DELETE_FAILURE);

export default {
  types,
  add,
  addSuccess,
  addFailure,
  updateBucket,
  fetchBuckets,
  fetchBucketsSuccess,
  fetchBucketsFailure,
  fetch,
  fetchSuccess,
  fetchFailure,
  fetchByProjectId,
  fetchByProjectIdSuccess,
  fetchByProjectIdFailure,
  fetchOne,
  fetchOneSuccess,
  fetchOneFailure,
};
