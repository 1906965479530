import { put, take, call, StrictEffect } from 'redux-saga/effects';
import { Routes } from '../constants';
import { appLoadingPlaceholder } from '../utils/helpers';
import { IAction } from '../utils/redux-create-reducer';
import {
  fetchGridConfigs,
  FETCH_GRID_CONFIGS,
  FETCH_GRID_CONFIGS_SUCCESS,
  FETCH_GRID_CONFIGS_FAILURE,
} from '../actions/gridConfig';
import { gridTypes } from '../enums';

export function* fetcher(): Generator<StrictEffect, IAction, IAction> {
  yield put(fetchGridConfigs(gridTypes.EventsList.id));
  return yield take([FETCH_GRID_CONFIGS, FETCH_GRID_CONFIGS_SUCCESS, FETCH_GRID_CONFIGS_FAILURE]);
}

export default function* eventsListViewPage() {
  while (true) {
    yield call(appLoadingPlaceholder, [Routes.Events, Routes.EventsToProject], fetcher);
  }
}
