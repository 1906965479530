import React, { useMemo, useState } from 'react';
import styles from './NewStaff.module.scss';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { history } from '../../store';
import { Routes } from '../../constants';
import { addStaff, deleteStaff, updateStaff } from '../../actions/staffs';
import { getCurrentStaff } from '../../reducers/staffs';
import { IStaff } from '../../utils/api.d';
import AjaxSpinner from '../../components/AjaxSpinner';
import { IStaffsState, getStaffs } from '../../reducers/staffs';

const fieldsToSave = ['first_name', 'last_name', 'company', 'email', 'title', 'region', 'team'];
const goBack = () => {
  history.push(Routes.Staff);
};

const NewStaff = () => {
  const dispatch = useDispatch();
  const staff = useSelector(getCurrentStaff, shallowEqual);
  const { list, isLoading: isStaffListLoading }: IStaffsState = useSelector(getStaffs, shallowEqual);
  const [updating, setUpdating] = useState(false); //TODO: looks like, that we can remove it. Investigate in future

  const editedStaff = useMemo(() => {
    return staff.data || {};
  }, [staff.data]) as IStaff;

  const staffList = useMemo(() => {
    return list || [];
  }, [list]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const submitNewStaff = (event: any) => {
    event.preventDefault();
    const form = event.target;
    const newStaff = editedStaff.id ? editedStaff : ({} as IStaff);

    fieldsToSave.forEach(field => {
      newStaff[field] = form.elements[field].value;
    });
    newStaff.isActive = form.elements.isActive.value === 'true';
    newStaff.managerId = form.elements.managerId.value === 'null' ? null : Number(form.elements.managerId.value);
    setUpdating(true);
    if (editedStaff.id) {
      dispatch(updateStaff(newStaff));
    } else {
      dispatch(addStaff(newStaff));
    }
    setUpdating(false);
  };

  const projectMangersList = Array.isArray(staffList)
    ? staffList.map(i => {
        if (editedStaff && editedStaff.id && editedStaff.id === i.id) return;
        return (
          <option
            selected={editedStaff && editedStaff.manager && editedStaff.manager.id === i.id}
            value={i.id}
            key={i.id}
          >
            {i.first_name} {i.last_name}
          </option>
        );
      })
    : [];

  const title = 'Staff Edit';
  const activeChecked = editedStaff.isActive === undefined ? true : editedStaff.isActive === true;
  const onDelete = () => dispatch(deleteStaff(editedStaff.id.toString()));
  return (
    <div id="NewStaffPage" className={styles['root']}>
      <div className={styles['new-staff-view-form-container']}>
        <div className={styles['new-staff-view-title']}>{title}</div>
        {staff.isLoading || isStaffListLoading || updating ? (
          <AjaxSpinner />
        ) : (
          <Form onSubmit={submitNewStaff} id="NewStaffForm" className={'card shadow ' + styles.form}>
            <FormGroup row>
              <Label className="col-sm-2" for="first_name">
                First name
              </Label>
              <div className="col-sm-10">
                <Input defaultValue={editedStaff.first_name} type="text" name="first_name" id="first_name" required />
              </div>
            </FormGroup>
            <FormGroup row>
              <Label className="col-sm-2" for="last_name">
                Last name
              </Label>
              <div className="col-sm-10">
                <Input defaultValue={editedStaff.last_name} type="text" name="last_name" id="last_name" required />
              </div>
            </FormGroup>
            <FormGroup row>
              <Label className="col-sm-2" for="title">
                Title
              </Label>
              <div className="col-sm-10">
                <Input defaultValue={editedStaff.title} type="text" name="title" id="title" required />
              </div>
            </FormGroup>
            <FormGroup row>
              <Label className="col-sm-2" for="email">
                Email
              </Label>
              <div className="col-sm-10">
                <Input defaultValue={editedStaff.email} type="email" name="email" id="email" required />
              </div>
            </FormGroup>
            <FormGroup row>
              <Label className="col-sm-2" for="company">
                Company
              </Label>
              <div className="col-sm-10">
                <Input defaultValue={editedStaff.company} type="text" name="company" id="company" />
              </div>
            </FormGroup>
            <FormGroup row>
              <Label className="col-auto col-sm-2" for="region">
                Region
              </Label>
              <div className="col-auto col-sm-10">
                <Input type="select" name="region" id="region" required>
                  <option selected={editedStaff.region === 'AMER'} value="AMER">
                    AMER
                  </option>
                  <option selected={editedStaff.region === 'EMEA'} value="EMEA">
                    EMEA
                  </option>
                  <option selected={editedStaff.region === 'APAC'} value="APAC">
                    APAC
                  </option>
                  <option selected={editedStaff.region === 'GLOBAL'} value="GLOBAL">
                    GLOBAL
                  </option>
                </Input>
              </div>
            </FormGroup>
            <FormGroup row>
              <Label className="col-sm-2" for="team">
                Team
              </Label>
              <div className="col-sm-10">
                <Input defaultValue={editedStaff.team} type="text" name="team" id="team" />
              </div>
            </FormGroup>
            <FormGroup row>
              <Label className="col-auto col-sm-2" for="manager">
                Manager
              </Label>
              <div className="col-auto col-sm-10">
                <Input type="select" name="managerId" id="managerId">
                  <option value="null">Unassigned</option>
                  {projectMangersList}
                </Input>
              </div>
            </FormGroup>
            <FormGroup row>
              <Label className="col-auto col-sm-2" for="isActive">
                Active
              </Label>
              <div className="col-auto col-sm-10">
                <Input type="select" name="isActive" id="isActive">
                  <option selected={activeChecked} value="true">
                    Yes
                  </option>
                  <option selected={!activeChecked} value="false">
                    No
                  </option>
                </Input>
              </div>
            </FormGroup>
            <FormGroup row>
              <div className="offset-sm-2 col-sm-10">
                <Button color="primary" type="submit" className="col-sm-auto col-12 mt-2 mt-sm-0">
                  Save
                </Button>
                <Button color="secondary" onClick={goBack} className="col-sm-auto col-12 mt-2 mt-sm-0 ml-0 ml-sm-2">
                  Close
                </Button>
                <Button color="danger" className="col-sm-auto col-12 mt-2 mt-sm-0 float-right" onClick={onDelete}>
                  Delete
                </Button>
              </div>
            </FormGroup>
          </Form>
        )}
      </div>
    </div>
  );
};

export default NewStaff;
