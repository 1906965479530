import React, { useState } from 'react';
import styles from '../AddNewProject.module.scss';
import { Routes } from '../../../constants';
import { history } from '../../../store';
import { Grid } from '../../../components/Grid';
import {
  AgGridEvent,
  ColumnApi,
  GridApi,
  IServerSideDatasource,
  RowClickedEvent,
  ServerSideStoreType,
} from 'ag-grid-community';
import { Button } from 'reactstrap';
import getTimeTrackingColumns from '../GridSettings/timeTracking';
import makeGridOptions from '../../../gridOptions';
import {
  checkForSavedGrid,
  createServerSideDatasource,
  gridStateSaver,
  resetAllGridFilters,
} from '../../../utils/helpers';
import { TimeTrackingBuckets } from '../TimeTrackingBuckets';
import { AppGrids } from '../../../enums';
import { urls } from '../../../utils/api';

interface IProps {
  emailAddress: string;
  isAdmin: boolean;
  projectId: string;
}

const getUri = (url: string, id?: string | null, projectId?: string) => {
  let resultUrl = url;
  if (id) {
    resultUrl = resultUrl.replace(':id', id);
  }
  if (projectId) {
    resultUrl = resultUrl.replace(':projectId', projectId);
  }
  resultUrl = resultUrl.replace(':backUrl', encodeURIComponent(location.pathname));
  return resultUrl;
};

const rowsLimit = 500;

export default function TimeTracking({ emailAddress, isAdmin, projectId }: IProps) {
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [gridColumnApi, setColumnApi] = useState<ColumnApi | null>(null);
  const goToTimeTracking = (prId: string) => () => history.push(getUri(Routes.NewTimeTracking, null, prId));
  const goToEditTimeTracking = (dId: string, prId: string) => window.open(getUri(Routes.EditTimeTracking, dId, prId));

  const getDataSource = (projectId?: string): IServerSideDatasource => {
    return createServerSideDatasource(urls.timeTrackingLazy, `projectId=${projectId}`);
  };

  const onGridReady = (event: AgGridEvent) => {
    setGridApi(event.api);
    setColumnApi(event.columnApi);

    const datasource = getDataSource(projectId);
    event.api.setServerSideDatasource(datasource);
  };

  const timeTrackingColumnDefs = getTimeTrackingColumns(isAdmin);

  const gridOptions = makeGridOptions(timeTrackingColumnDefs, {
    onGridReady,
    rowModelType: 'serverSide',
    suppressCellSelection: true,
    cacheBlockSize: rowsLimit,
    serverSideStoreType: ServerSideStoreType.Partial,
    animateRows: true,
    statusBar: { statusPanels: [] },
  });

  const gridName = `${emailAddress}-${AppGrids.ProjectDetailsTimeTracking}`;
  const gridConfigName = `${emailAddress}-${AppGrids.ProjectDetailsTimeTracking}-config`;

  const saveState = () => gridColumnApi && gridStateSaver(gridColumnApi, gridName);

  if (gridColumnApi) {
    checkForSavedGrid(gridColumnApi, gridName, gridConfigName, timeTrackingColumnDefs);
    gridStateSaver(gridColumnApi, gridName);
  }

  const resetAllFilters = () => resetAllGridFilters(gridApi);

  return (
    <div>
      <div className={styles.flexButton}>
        <h5>Time Tracking</h5>
        <div>
          <Button color="primary" onClick={resetAllFilters} className={'margin-right-10'}>
            Reset Filters
          </Button>
          <Button color="primary" onClick={goToTimeTracking(projectId)}>
            Add time tracking
          </Button>
        </div>
      </div>
      <TimeTrackingBuckets />
      <Grid
        defaultHeight={false}
        className={styles.gridDefault}
        onRowClicked={(row: RowClickedEvent) => goToEditTimeTracking(row.data.id, projectId)}
        gridOptions={gridOptions}
        onColumnMoved={saveState}
        onColumnVisible={saveState}
        onColumnResized={saveState}
      />
      <br />
      <br />
    </div>
  );
}
