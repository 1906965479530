import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import styles from './AddAccountsModal.module.scss';

interface AddAccountsModalProps {
  isOpen: boolean;
  proceedAddAccounts: () => void;
  cancelAddAccounts: () => void;
}

export function AddAccountsModal({ isOpen, proceedAddAccounts, cancelAddAccounts }: AddAccountsModalProps) {
  return (
    <div>
      <Modal className={styles.modal} isOpen={isOpen}>
        <ModalHeader>Add Accounts</ModalHeader>
        <ModalBody>
          <span>Are you sure you want to add selected accounts?</span>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={cancelAddAccounts}>
            Cancel
          </Button>
          <Button color="primary" onClick={proceedAddAccounts}>
            Add
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default AddAccountsModal;
