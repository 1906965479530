import React, { useState } from 'react';
import { Routes } from '../../constants';
import getColumns from './columns';
import makeGridOptions from '../../gridOptions';
import {
  checkForSavedGrid,
  createServerSideDatasource,
  gridStateSaver,
  resetAllGridFilters,
  isValidJSON,
  createDefaultGridConfigViewOptions,
  createGridConfigOption,
} from '../../utils/helpers';
import { ColumnApi, GridApi, RowClickedEvent, ServerSideStoreType } from 'ag-grid-community';
import { Grid } from '../../components/Grid';
import { AgGridEvent } from 'ag-grid-community/dist/lib/events';
import { urls } from '../../utils/api';
import { AppGrids, gridTypes } from '../../enums';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getUser } from '../../reducers/user';
import { Button } from 'reactstrap';
import GridViewConfig from '../../components/GridViewConfig';
import GridViewConfigModal from '../../components/GridViewConfigModal';
import AjaxSpinner from '../../components/AjaxSpinner';
import { updateGridConfig } from '../../actions/gridConfig';
import { getGridConfigs } from '../../reducers/gridConfig';
import { IGridConfiguration } from '../../utils/api.d';

export default function Projects() {
  const dispatch = useDispatch();
  const { data: gridConfigs, isLoading: isGridConfigLoading } = useSelector(getGridConfigs, shallowEqual) || [];
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [gridColumnApi, setGridColumnApi] = useState<ColumnApi | null>(null);
  const { emailAddress } = useSelector(getUser, shallowEqual) || {};

  const columnDefs = getColumns();
  const rowsLimit = 500;

  const onGridReady = (event: AgGridEvent) => {
    setGridApi(event.api);
    setGridColumnApi(event.columnApi);

    const datasource = createServerSideDatasource(`${urls.projectsLazy}`);
    event.api.setServerSideDatasource(datasource);
  };

  const gridOptions = makeGridOptions(columnDefs, {
    rowModelType: 'serverSide',
    cacheBlockSize: rowsLimit,
    serverSideStoreType: ServerSideStoreType.Partial,
    animateRows: true,
    statusBar: { statusPanels: [] },
    onGridReady,
  });

  const onRowClicked = (row: RowClickedEvent) => {
    const type = row.data.type || 0;
    const dsaId = row.data.dsaCustomerId || ':dsaId';
    const url = Routes.NewEditProject.replace(':id', row.data.id)
      .replace(':type', type)
      .replace(':dsaId', dsaId);
    window.open(url);
  };

  const gridName = `${emailAddress}-${AppGrids.ProjectsList}`;
  const gridConfigName = `${emailAddress}-${AppGrids.ProjectsList}-config`;

  const saveState = () => gridColumnApi && gridStateSaver(gridColumnApi, gridName);

  if (gridColumnApi) {
    checkForSavedGrid(gridColumnApi, gridName, gridConfigName, columnDefs);
    gridStateSaver(gridColumnApi, gridName);
  }

  const resetAllFilters = () => resetAllGridFilters(gridApi);

  const defaultGridConfigs = createDefaultGridConfigViewOptions(gridTypes.ProjectsList.value, 5);
  const finalGridConfigs = createGridConfigOption(defaultGridConfigs, gridConfigs, columnDefs);
  const [editedGridViewConfig, setEditedGridViewConfig] = useState(defaultGridConfigs[0]);
  const [editedGridViewConfigName, setEditedGridViewConfigName] = useState('');
  const [isGridViewConfigModalOpen, setGridViewConfigModalOpen] = useState(false);
  const gridViewConfigModalToggle = () => setGridViewConfigModalOpen(!isGridViewConfigModalOpen);

  // TODO: think about moving this method to helpers or smth like that
  const saveGridViewConfig = (gridViewTitle: string) => {
    const finalData = {
      gridType: gridTypes.ProjectsList.value,
      optionIndex: editedGridViewConfig.optionIndex,
      paramsJson: JSON.stringify({
        defaultConfig: columnDefs,
        customConfig: gridColumnApi && gridColumnApi.getColumnState(),
        filterParams: gridApi && gridApi.getFilterModel(),
      }),
      title: gridViewTitle,
    };

    dispatch(updateGridConfig(finalData));
    gridViewConfigModalToggle();
  };

  // TODO: think about moving this method to helpers or smth like that
  const selectForEditingGridViewConfig = (gridConfig: IGridConfiguration) => {
    setEditedGridViewConfig(gridConfig);
    setEditedGridViewConfigName(gridConfig.title);
    gridViewConfigModalToggle();
  };

  // TODO: think about moving this method to helpers or smth like that
  const applyGridViewConfig = (gridViewConfig: IGridConfiguration) => {
    const savedConfigs = isValidJSON(gridViewConfig.paramsJson) && JSON.parse(gridViewConfig.paramsJson);

    savedConfigs && gridColumnApi && gridColumnApi.setColumnState(savedConfigs.customConfig);
    savedConfigs && gridApi && gridApi.setFilterModel(savedConfigs.filterParams);
  };

  return (
    <>
      {isGridConfigLoading && <AjaxSpinner />}
      <div>
        <div
          className="float-left"
          style={{
            fontSize: '24px',
            fontFamily:
              '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
          }}
        >
          Projects
        </div>
        <div className="float-right">
          <Button color="primary" onClick={resetAllFilters}>
            Reset Filters
          </Button>
        </div>

        <div className="float-right margin-left-10 margin-right-10">
          <GridViewConfig
            applyGridViewConfig={applyGridViewConfig}
            saveGridViewConfig={selectForEditingGridViewConfig}
            gridViewConfigList={finalGridConfigs}
          ></GridViewConfig>
        </div>
      </div>
      <Grid
        gridOptions={gridOptions}
        onRowClicked={onRowClicked}
        onColumnMoved={saveState}
        onColumnVisible={saveState}
        onColumnResized={saveState}
      />

      <GridViewConfigModal
        key={editedGridViewConfigName}
        isOpen={isGridViewConfigModalOpen}
        saveGridViewConfig={saveGridViewConfig}
        cancelSaveGridView={gridViewConfigModalToggle}
        gridViewName={editedGridViewConfigName}
      ></GridViewConfigModal>
    </>
  );
}
