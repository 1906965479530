import { FormShape } from '../utils/api.d';

export const projectsListGridColumns: FormShape = {
  id: { label: 'ID', type: 'number', filter: 'agNumberColumnFilter' },
  name: { label: 'Name', type: 'text', filter: 'agTextColumnFilter' },
  type: { label: 'Type', type: 'text', filter: 'agSetColumnFilter' },
  parentId: { label: 'Parent ID', type: 'text', filter: 'agNumberColumnFilter' },
  package: { label: 'Event Type', type: 'text', filter: 'agSetColumnFilter' },
  projectManager: { label: 'Project Manager', type: 'text', filter: 'agTextColumnFilter' },
  webcastLead: { label: 'Webcast Lead', type: 'text', filter: 'agTextColumnFilter' },
  eventProducer: { label: 'Event Producer', type: 'text', filter: 'agTextColumnFilter' },
  webcastProducer: { label: 'Webcast Producer', type: 'text', filter: 'agTextColumnFilter' },
  teamManager: { label: 'Team Manager', type: 'text', filter: 'agTextColumnFilter' },
  status: { label: 'Project Status', type: 'text', filter: 'agSetColumnFilter' },
  serviceModelType: { label: 'Service Model', type: 'text', filter: 'agSetColumnFilter' },
  usageType: { label: 'Usage Type', type: 'text', filter: 'agSetColumnFilter' },
  duration: { label: 'Duration', type: 'number', filter: 'agNumberColumnFilter' },
  hostingDays: { label: 'Hosting', type: 'number', filter: 'agNumberColumnFilter' },
  languages: { label: 'Languages', type: 'number', filter: 'agNumberColumnFilter' },
  viewers: { label: 'Viewers Live', type: 'number', filter: 'agNumberColumnFilter' },
  deliveryType: { label: 'Delivery', type: 'text', filter: 'agSetColumnFilter' },
  contentType: { label: 'Content', type: 'text', filter: 'agSetColumnFilter' },
  eventStart: { label: 'Event Start', type: 'datetime-local', filter: 'agDateColumnFilter' },
  eventEnd: { label: 'Event End', type: 'datetime-local', filter: 'agDateColumnFilter' },
  supportRegion: { label: 'Support Region', type: 'text', filter: 'agSetColumnFilter' },
  fulfillmentStatus: { label: 'Fulfillment Status', type: 'text', filter: 'agSetColumnFilter' },
  fulfillmentStart: { label: 'Fulfillment Start Date', type: 'datetime-local', filter: 'agDateColumnFilter' },
  fulfillmentEnd: { label: 'Fulfillment End Date', type: 'datetime-local', filter: 'agDateColumnFilter' },
  recurringRolloverTerm: { label: 'Rollover Term', type: 'number', filter: 'agNumberColumnFilter' },
  dsaCustomerLongId: { label: 'DSA ID', type: 'text', filter: 'agTextColumnFilter' },
  dsaCustomerName: { label: 'DSA', type: 'text', filter: 'agTextColumnFilter' },
  eventsAllowed: { label: 'Allowed', type: 'number', filter: 'agNumberColumnFilter' },
  eventsUsed: { label: 'Used', type: 'number', filter: 'agNumberColumnFilter' },
  eventsRemaining: { label: 'Remaining', type: 'number', filter: 'agNumberColumnFilter' },
  cfpOpenDate: { label: 'CFP Open', type: 'datetime-local', filter: 'agDateColumnFilter' },
  cfpCloseDate: { label: 'CFP Close', type: 'datetime-local', filter: 'agDateColumnFilter' },
  clientContactName: { label: 'Client Contact Name', type: 'text', filter: 'agTextColumnFilter' },
  clientContactEmail: { label: 'Client Contact Email', type: 'text', filter: 'agTextColumnFilter' },
  siteId: { label: 'Site Id', type: 'text', filter: 'agTextColumnFilter' },
  siteUrl: { label: 'Site Url', type: 'text', filter: 'agTextColumnFilter' },
  registrants: { label: 'Registrants', type: 'number', filter: 'agNumberColumnFilter' },
  personnel: { label: 'Personnel', type: 'text', filter: 'agTextColumnFilter' },
};
