import { FormShape } from '../utils/api.d';

export const subProjectsTableConfig: FormShape = {
  id: { label: 'ID', type: 'number', filter: 'agNumberColumnFilter' },
  name: { label: 'Project Name', type: 'text', filter: 'agTextColumnFilter' },
  eventQtyPurchased: { label: 'Events Allowed', type: 'number', filter: 'agNumberColumnFilter' },
  eventQtyUsed: { label: 'Events Used', type: 'number', filter: 'agNumberColumnFilter' },
  type: { label: 'Project Type', type: 'select', filter: 'agTextColumnFilter' },
  viewers: { label: 'Viewers Purchased', type: 'number', filter: 'agNumberColumnFilter' },
  projectManager: { label: 'Project Manager', type: 'select', filter: 'agTextColumnFilter' },
  status: { label: 'Sub-Project Status', type: 'select', filter: 'agTextColumnFilter' },
  eventStart: { label: 'Event Start', type: 'datetime-local', filter: 'agDateColumnFilter' },
  eventEnd: { label: 'Event End', type: 'datetime-local', filter: 'agDateColumnFilter' },
  fulfillmentEnd: { label: 'Fulfillment End date', type: 'datetime-local', filter: 'agDateColumnFilter' },
  package: { label: 'Event Type', type: 'select', filter: 'agTextColumnFilter' },
};
