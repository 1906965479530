import createReducer from '../utils/redux-create-reducer';
import {
  FETCH_GRID_CONFIGS,
  FETCH_GRID_CONFIGS_SUCCESS,
  FETCH_GRID_CONFIGS_FAILURE,
  UPDATE_GRID_CONFIG,
  UPDATE_GRID_CONFIG_SUCCESS,
  UPDATE_GRID_CONFIG_FAILURE,
} from '../actions/gridConfig';
import { IGridConfiguration } from '../utils/api.d';

export interface IGridConfigState {
  isLoading: boolean;
  error?: Error;
  data: IGridConfiguration[];
}

interface IState {
  gridConfig: IGridConfigState;
}

export const initialState: IGridConfigState = {
  isLoading: false,
  error: undefined,
  data: [],
};

export const getGridConfigs = (state: IState) => state.gridConfig;

export default createReducer<IGridConfigState>(initialState, {
  [FETCH_GRID_CONFIGS]: () => ({ isLoading: true }),
  [FETCH_GRID_CONFIGS_SUCCESS]: data => ({ data, isLoading: false }),
  [FETCH_GRID_CONFIGS_FAILURE]: error => ({ error, isLoading: false }),

  [UPDATE_GRID_CONFIG]: () => ({ isLoading: true }),
  [UPDATE_GRID_CONFIG_SUCCESS]: data => ({ data, isLoading: false }),
  [UPDATE_GRID_CONFIG_FAILURE]: error => ({ error, isLoading: false }),
});
