import React, { FormEvent, useState } from 'react';
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import styles from '../CompleteEventModal/CompleteEventModal.module.scss';

interface CopyProjectModalProps {
  isOpen: boolean;
  proceedCopyProject: (numberOfCopies: number) => void;
  cancelCopyProject: () => void;
}

const projectCopiesRange = {
  max: 25,
  min: 1,
};

const defaultNumberOfCopies = 1;

export function CopyProjectModal({ isOpen, proceedCopyProject, cancelCopyProject }: CopyProjectModalProps) {
  const [numberOfCopies, setNumberOfCopies] = useState(defaultNumberOfCopies);

  const resetModalToInitialState = () => setNumberOfCopies(defaultNumberOfCopies);

  const cancelCopy = () => {
    cancelCopyProject();
    resetModalToInitialState();
  };

  const copyProject = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    proceedCopyProject(numberOfCopies);

    resetModalToInitialState();
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleNumberOfProjectCopiesChange = (event: any) => {
    const result = event.target.value ? Number(event.target.value) : event.target.value;
    setNumberOfCopies(result);
  };

  return (
    <div>
      <Modal className={styles.modal} isOpen={isOpen}>
        <ModalHeader>Copy Project</ModalHeader>
        <ModalBody>
          <form id="copyProjectForm" autoComplete="off" onSubmit={copyProject}>
            <FormGroup>
              <div>
                <strong>Please save project first, any changes not saved will not be updated</strong>
              </div>
              <Label for="completeEventId">
                Enter the number of copies you’d like to create ({projectCopiesRange.min}-{projectCopiesRange.max}):
              </Label>
              <Input
                required
                type="number"
                min={projectCopiesRange.min}
                max={projectCopiesRange.max}
                defaultValue={numberOfCopies}
                name="numberOfProjectCopies"
                id="copyProject"
                onChange={handleNumberOfProjectCopiesChange}
              />
            </FormGroup>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={cancelCopy}>
            Cancel
          </Button>
          <Button
            color="primary"
            form={'copyProjectForm'}
            type="submit"
            disabled={
              !numberOfCopies ||
              !Number.isInteger(numberOfCopies) ||
              numberOfCopies < projectCopiesRange.min ||
              numberOfCopies > projectCopiesRange.max
            }
          >
            Copy
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default CopyProjectModal;
