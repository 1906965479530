import React, { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import styles from './subprojectgrid.module.scss';
import getColumns from './columns';
import { IProjectsState, getProjects } from '../../reducers/projects';
import makeGridOptions from '../../gridOptions';
import { Grid } from '../Grid';
import { AgGridEvent, ColumnApi, GridApi, RowClickedEvent } from 'ag-grid-community';
import { Routes } from '../../constants';
import { AppGrids } from '../../enums';
import { checkForSavedGrid, gridStateSaver, resetAllGridFilters } from '../../utils/helpers';
import { Button } from 'reactstrap';

interface ISubProjectGridProps {
  dsaId: string;
  emailAddress: string;
}

const SubProjectsGrid = (props: ISubProjectGridProps) => {
  const { dsaId, emailAddress } = props;
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [gridColumnApi, setColumnApi] = useState<ColumnApi | null>(null);
  const { projects }: IProjectsState = useSelector(getProjects, shallowEqual);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const subProjects = projects.filter((p: any) => {
    return p.parentId !== null;
  });

  const subProjectsWithPN = subProjects.map(sp => {
    const project = projects.find(p => p.id === sp.parentId);
    const pn = project && project.name ? project.name : '';
    const pEventStartDate = project && project.eventStart ? project.eventStart : '';
    const pEventEndDate = project && project.eventEnd ? project.eventEnd : '';
    const pid = project && project.id ? project.id : sp.id;
    return {
      id: pid,
      spid: sp.id,
      projectType: sp.projectType,
      name: sp.name,
      eventStart: pEventStartDate,
      eventEnd: pEventEndDate,
      eventQuantity: sp.eventQuantity,
      projectSubType: sp.projectSubType,
      projectStaff: sp.projectStaff,
      projectStatus: sp.projectStatus,
      projectName: pn,
      projectTypeId: sp.projectType.id,
    };
  });

  const columnDefs = getColumns();
  const onGridReady = (event: AgGridEvent) => {
    setGridApi(event.api);
    setColumnApi(event.columnApi);
  };

  const gridOptions = makeGridOptions(columnDefs, {
    onGridReady,
    statusBar: { statusPanels: [] },
  });

  const onRowClicked = (row: RowClickedEvent) => {
    const url = Routes.NewEditProject.replace(':id', row.data.spid)
      .replace(':type', row.data.projectTypeId)
      .replace(':dsaId', dsaId);
    window.open(url);
  };

  const gridName = `${emailAddress}-${AppGrids.DsaDetailsSubProjects}`;
  const gridConfigName = `${emailAddress}-${AppGrids.DsaDetailsSubProjects}-config`;

  const saveState = () => gridColumnApi && gridStateSaver(gridColumnApi, gridName);

  if (gridColumnApi) {
    checkForSavedGrid(gridColumnApi, gridName, gridConfigName, columnDefs);
    gridStateSaver(gridColumnApi, gridName);
  }

  const resetAllFilters = () => resetAllGridFilters(gridApi);

  return (
    <>
      <div className={styles.projectsHeader}>
        <h4 className={styles.projectsHeaderTitle}>Sub-Projects</h4>
        <div>
          <Button color="primary" onClick={resetAllFilters}>
            Reset Filters
          </Button>
        </div>
      </div>

      <Grid
        className={styles.subProjectGrid}
        rowData={Array.isArray(subProjectsWithPN) ? subProjectsWithPN : []}
        gridOptions={gridOptions}
        onRowClicked={onRowClicked}
        onColumnMoved={saveState}
        onColumnVisible={saveState}
        onColumnResized={saveState}
      />
    </>
  );
};

export default SubProjectsGrid;
