import { AgGridReactProps } from 'ag-grid-react/lib/agGridReact';
import { AgGridReact } from 'ag-grid-react';
import React from 'react';
import cn from 'classnames';

interface IGrid extends AgGridReactProps {
  className?: string;
  defaultHeight?: boolean;
}

export function Grid(props: IGrid) {
  const { className, defaultHeight = true, ...rest } = props;
  return (
    <div className={cn(className, defaultHeight && 'default-grid', 'ag-theme-alpine shadow')}>
      <AgGridReact {...rest} />
    </div>
  );
}
