import { call, take, put, select, takeEvery } from 'redux-saga/effects';
import { callApi } from '../utils/helpers';
import {
  FETCH,
  fetchSuccess,
  fetchFailure,
  PUT_PROJECTS,
  putFailure,
  putSuccess,
  POST_PROJECTS,
  postSuccess,
  postFailure,
  fetchByIdSuccess,
  fetchByIdFailure,
  FETCH_BYID,
  ADD_NEW_PROJECT,
  FETCH_WITH_SUBS,
  fetchWithSubsSuccess,
  fetchWithSubsFailure,
  DELETE_PROJECT,
  deleteProjectFailure,
  deleteProjectSuccess,
  copyProjectSuccess,
  COPY_PROJECT,
  copyProjectFailure,
  DELETE_STAFF_FROM_PROJECT,
  deleteStaffFromProjectSuccess,
  deleteStaffFromProjectFailure,
  ADD_STAFF_TO_PROJECT,
  addStaffToProjectSuccess,
  addStaffToProjectFailure,
} from '../actions/projects';
import api from '../utils/api';
import { notify } from '../actions/notification';
import { replace } from 'connected-react-router';
import { IProject, ProjectTypes, IPlis, IEvent } from '../utils/api.d';
import { getSalesforce } from '../reducers/salesforce';
import { getUpdatedTimeTrackingBuckets } from '../reducers/timeTracking';
import { matchPath } from 'react-router-dom';
import { Routes } from '../constants';
import { getEvents } from '../reducers/events';

const getRedirectUrl = () => {
  const newProjectMatches = matchPath(location.pathname, {
    path: Routes.AddNewProject,
    exact: true,
  });
  const editProjectMatches = matchPath(location.pathname, {
    path: Routes.NewEditProject,
    exact: true,
  });
  const matches = newProjectMatches || editProjectMatches;
  const backUrl = matches && matches.params['backUrl'];

  return backUrl ? backUrl : ':backUrl';

  //TODO: think about moving ':backUrl' into enums, config or something like that;
};

export function* projects() {
  while (true) {
    yield take(FETCH);
    try {
      const response = yield call(callApi, api.getProjects);
      yield put(fetchSuccess(response.data));
    } catch (e) {
      console.error(e);
      yield put(fetchFailure(e));
    }
  }
}

export function* addNewProject() {
  while (true) {
    const action = yield take(ADD_NEW_PROJECT);
    yield put(fetchByIdSuccess([action.payload]));
  }
}

export function* projectsById() {
  while (true) {
    const action = yield take(FETCH_BYID);
    try {
      const projectId = action.payload.projectId;
      const newProject: Partial<IProject[]> = [];
      if (action.payload.new) {
        newProject.push(action.payload.new as IProject);
      }
      const response = yield call(callApi, api.getProjectsById, projectId);
      yield put(fetchByIdSuccess(newProject.concat(response.data)));
    } catch (e) {
      console.error(e);
      yield put(fetchByIdFailure(e));
    }
  }
}

export function* putProject() {
  while (true) {
    const project = yield take(PUT_PROJECTS);
    const backUrl = getRedirectUrl();
    try {
      const plis = yield select(getSalesforce);
      const pliIds = (plis.list || []).map((pli: IPlis) => pli.id);
      const events = yield select(getEvents);
      const eventIds = (events.list || []).map((event: IEvent) => event.id);
      const timetrackingBuckets = yield select(getUpdatedTimeTrackingBuckets);
      yield call(api.putProject, {
        ...project.payload,
        timetrackingBuckets,
        pliIds,
        eventIds,
      });
      yield put(putSuccess());
      yield put(
        replace(
          `/new-edit-project/${ProjectTypes[project.payload.projectType]}/${project.payload.id}/${project.payload
            .dsaCustomerId || ':dsaid'}/${backUrl}`,
        ),
      );
      yield put(notify('Your changes have been saved.', 'success'));
    } catch (e) {
      console.error(e);
      yield put(putFailure(e));
      yield put(notify("We're sorry, there was a problem saving this Project please try again.", 'danger'));
    }
  }
}

export function* postProject() {
  while (true) {
    const project = yield take(POST_PROJECTS);
    const backUrl = getRedirectUrl();
    try {
      const plis = yield select(getSalesforce);
      const timetrackingBuckets = yield select(getUpdatedTimeTrackingBuckets);
      const pliIds = (plis.list || []).map((pli: IPlis) => pli.id);
      const response = yield call(api.postProject, {
        ...project.payload,
        pliIds,
        timetrackingBuckets,
      });
      yield put(postSuccess(response.data));
      const newProject = response.data[0];
      yield put(
        replace(
          `/new-edit-project/${newProject.projectType.id}/${newProject.id}/${newProject.dsaCustomerId ||
            ':dsaid'}/${backUrl}`,
        ),
      );
      yield put(notify('Your changes have been saved.', 'success'));
    } catch (e) {
      console.error(e);
      yield put(postFailure(e));
      yield put(notify("We're sorry, there was a problem saving this Project please try again.", 'danger'));
    }
  }
}

export function* deleteProject() {
  while (true) {
    const action = yield take(DELETE_PROJECT);
    try {
      yield call(callApi, api.deleteProject, action.payload.id);
      yield put(deleteProjectSuccess());

      const { dsaId } = action.payload;
      const route = Routes.DSADetail.replace(':dsaid', dsaId);

      yield put(replace(route));
    } catch (e) {
      console.error(e);
      yield put(deleteProjectFailure(e));
      yield put(notify("We're sorry, there was a problem deleting this Project please try again.", 'danger'));
    }
  }
}

export function* projectsWithSubs() {
  while (true) {
    yield take(FETCH_WITH_SUBS);
    try {
      const response = yield call(callApi, api.getProjectsWithSubs);
      yield put(fetchWithSubsSuccess(response.data));
    } catch (e) {
      console.error(e);
      yield put(fetchWithSubsFailure(e));
    }
  }
}

function* projectCopy(data: { type: string; payload: { projectId: number; numberOfCopies: number } }) {
  try {
    const response = yield call(api.copyProject, data.payload);
    yield put(copyProjectSuccess(response.data));
    yield put(notify('Your project have been copied.', 'success'));
  } catch (e) {
    console.error(e);
    yield put(copyProjectFailure(e));
    yield put(notify("We're sorry, there was a problem. Please try again.", 'danger'));
  }
}

export function* copyProject() {
  yield takeEvery(COPY_PROJECT, projectCopy);
}

export function* removeStaffFromProject() {
  while (true) {
    const action = yield take(DELETE_STAFF_FROM_PROJECT);
    const backUrl = getRedirectUrl();
    try {
      yield call(callApi, api.deleteStaffFromProjects, action.payload);
      yield put(deleteStaffFromProjectSuccess());
      // TODO: temp solution; call "header" endpoint instead of that
      yield put(
        replace(
          `/new-edit-project/${action.payload.projectTypeId}/${action.payload.projectId}/${action.payload
            .dsaCustomerId || ':dsaid'}/${backUrl}`,
        ),
      );
    } catch (e) {
      console.error(e);
      yield put(deleteStaffFromProjectFailure(e));
    }
  }
}

export function* addStaffToProject() {
  while (true) {
    const action = yield take(ADD_STAFF_TO_PROJECT);
    const backUrl = getRedirectUrl();
    try {
      yield call(callApi, api.addStaffToProject, action.payload);
      yield put(addStaffToProjectSuccess());
      // TODO: temp solution; call "header" endpoint instead of that
      yield put(
        replace(
          `/new-edit-project/${action.payload.projectTypeId}/${action.payload.projectId}/${action.payload
            .dsaCustomerId || ':dsaid'}/${backUrl}`,
        ),
      );
    } catch (e) {
      console.error(e);
      yield put(addStaffToProjectFailure(e));
    }
  }
}
