import { ColDef } from 'ag-grid-community';
import { transformToColumns, dateFormatter, dateFiltering } from '../../utils/helpers';
import { reportsListGridColumns } from '../../tablesFormShapes/reports';

function createDownloadLink(url: string) {
  return `<a href=${url} download>DOWNLOAD</a>`;
}

export default function(): ColDef[] {
  return transformToColumns(
    reportsListGridColumns,
    {
      id: {
        hide: true,
      },
      reportUid: {
        hide: true,
      },
      url: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        cellRenderer: (params: any) => {
          return params && params.value ? createDownloadLink(params.value) : '';
        },
      },
      created: {
        valueGetter: dateFormatter('created'),
        filterParams: {
          inRangeInclusive: true,
          comparator: dateFiltering,
        },
      },
    },
    { minWidth: 200 },
  );
}
