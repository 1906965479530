// use 'npm run g:s {name}' - to generate new saga, reducer and action (use --skip if action and/or reducer not needed)
import { call, cancel, delay, fork, put, select, take } from 'redux-saga/effects';
import { ColDef, GridApi, IServerSideGetRowsParams, ColGroupDef } from 'ag-grid-community';
import { LOCATION_CHANGE, push } from 'connected-react-router';
import { matchPath } from 'react-router';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import { SET_SESSION } from '../actions/session';
import { init, setAppError, startLoading, stopLoading } from '../actions/application';
import { getSession } from '../reducers/session';
import { getApplication } from '../reducers/application';
import { Routes } from '../constants';
import { FormShape, IEnumType, IFilterModel, IGridConfiguration } from './api.d';
import isObject from 'lodash/isObject';
import moment from 'moment';
import { dataGridLazy } from './api';
import { convertEnumTypes, platformType } from '../enums';
import { ColumnApi } from 'ag-grid-community';
import { customFilterOptionsMap } from './agGridCustomFilterOptions';

export interface IURLOptions {
  search?: Record<string, string | number>;
  params?: Record<string, string | number>;
}

export const isValidJSON = (jsonStr: string) => {
  try {
    const JSONObject = JSON.parse(jsonStr);

    return JSONObject && typeof JSONObject === 'object';
  } catch (error) {
    console.log(`Invalid JSON - ${jsonStr}`);
  }

  return false;
};

export const buildURL = (url: string, options?: IURLOptions) => {
  let search = '';
  let URL = url;
  if (options && options.search) {
    search = `?${Object.keys(options.search)
      .map(key => `${key}=${options && options.search && options.search[key]}`)
      .join('&')}`;
  }
  if (options && options.params) {
    URL = Object.keys(options.params).reduce(
      (u, key) => (u || '').replace(`:${key}`, (options && options.params && String(options.params[key])) || ''),
      url,
    );
  }

  return URL + search;
};

export function* waitCurrentRouteAndDetect(name: string, isRoot?: boolean) {
  const locAction = yield take(LOCATION_CHANGE);

  const path = get(locAction, 'payload.location.pathname');
  return yield path.toLowerCase() === name.toLowerCase() || (isRoot && path === Routes.Root);
}

export function* startDelayedLoading(time: number, immediately?: boolean) {
  if (!immediately) {
    yield delay(time);
  }
  yield put(startLoading());
}

export const getRouteData = (path: string, route: string, isRoot?: boolean) => {
  const match = matchPath(path, route);

  const { params = {} } = match || {};
  const routeName = buildURL(route, { params });
  const isProperPage = path.toLowerCase() === routeName.toLowerCase() || (isRoot && path === Routes.Root);

  return {
    match,
    isProperPage,
  };
};

/*
this helper control a sequence of data loading, you should use it as wrapper for {PAGE} sagas
to ensure that all data is ready you can use application state - isLoading,
because this helper controls START and END of loading data
*/
export function* appLoadingPlaceholder(
  routes: Routes | Routes[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  childrenSaga: (match: any) => void,
  isRoot?: boolean,
) {
  const locAction = yield take(LOCATION_CHANGE);

  const path = get(locAction, 'payload.location.pathname');

  const { isProperPage, match } = (routes instanceof Array ? routes : [routes])
    .map(r => getRouteData(path, r, isRoot))
    .find(r => r.isProperPage) || { isProperPage: false, match: undefined };

  if (isProperPage) {
    const app = yield select(getApplication);
    const loaderTask = yield fork(startDelayedLoading, 600, app.isFirstLoading);
    try {
      const session = yield select(getSession);
      if (!session && !app.error) {
        yield take(SET_SESSION);
        yield put(init());
      }
      yield call(childrenSaga, match);
    } catch (err) {
      console.error('appLoadingPlaceholder', err);
    } finally {
      yield cancel(loaderTask);
      yield put(stopLoading());
    }
  }
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const log = (...args: any[]) => console.log(...args);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const logError = (...args: any[]) => console.error(...args);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* callApi(fn: any, ...args: any[]) {
  try {
    return yield call(fn, ...args);
  } catch (err) {
    yield put(setAppError(err));
    throw err;
  }
}

export class HTTPError extends Error {
  status: number;

  constructor(status: number, message: string) {
    super(message);
    this.name = 'HTTPError';
    this.status = status;
  }
}

export const parseQueryParams = (url: string, isHash = false): Record<string, string> => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, qParams] = url.split(isHash ? '#' : '?');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const params = (qParams || '').split('&').reduce((result, pair) => {
    const [key, value] = pair.split('=');
    result[key] = value;
    return result;
  }, {});

  return params;
};

export const redirectTo = (url: string, options?: IURLOptions) => push(buildURL(url, options));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deepStateUpdate = (updateKey: string) => (action: Record<string, any>, state: Record<string, any>) => {
  return {
    ...state,
    [updateKey]: {
      ...state[updateKey],
      ...action,
    },
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function makeColumns<T = any>(array: T[], columnsLength: number): T[][] {
  const elements: T[][] = [];
  array.forEach((el, i) => {
    const elsInCol = Math.floor(array.length % columnsLength);
    const isInsert = !(i % elsInCol);

    if (isInsert) {
      elements.push([el]);
    } else {
      (elements[elements.length - 1] || []).push(el);
    }
  });
  return elements;
}

export const getPlatformValue = (platform: string) => {
  const platformTypeConverted = convertEnumTypes(platformType);
  const platformConverted = platformTypeConverted.filter((o: IEnumType) => o.name === platform);
  return platformConverted && platformConverted.length === 1 ? platformConverted[0].value : undefined;
};

/**
 * @param obj
 * @param enumField - field, that will be used for displaying value;
 *                    Possible values (IEnumType): 'id', 'value', 'name';
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function convertToPlainObjectWithEnums<T = any>(obj: Record<string, any>, enumField = 'value'): T {
  return Object.keys(obj).reduce((result, key) => {
    let val = obj[key];

    if (isObject(val)) {
      val = (obj[key] as IEnumType)[enumField];
    }
    return { ...result, [key]: val };
  }, {}) as T;
}

export function transformToColumns<K>(shape: FormShape<K>, modificators?: Record<string, ColDef>, shared?: ColDef) {
  const result: ColDef[] = [];
  Object.keys(shape).forEach(key => {
    const val = shape[key];
    const maybeModificator = (modificators || {})[key];
    result.push({
      headerName: val.label,
      filter: val.filter,
      field: key,
      ...(shared || {}),
      ...((maybeModificator && maybeModificator) || {}),
    });
  });

  return result;
}

export function getDateNoOffset(date: string | Date): string {
  try {
    const m = moment(date).startOf('day');
    m.add(m.utcOffset(), 'm');
    return m.toDate().toISOString();
  } catch (e) {
    console.warn(e);
    return '';
  }
}

export const formatDateForFiltering = (value: string, columnDefinition: ColDef | null): string | undefined => {
  if (!value || value.length < 1) return undefined;
  const includeTime = columnDefinition && columnDefinition.filterParams && columnDefinition.filterParams.includeTime;

  if (includeTime) {
    // if there is time part - inlcude time based on user timezone
    const startOfTheDay = moment(value).startOf('day');
    const dateTime = moment.utc(startOfTheDay).toISOString();
    return dateTime;
  } else {
    // if date only - do not send time part
    const dateOnly = moment.utc(value).toISOString();
    return dateOnly;
  }
};

export function getTodayDate() {
  return moment(new Date())
    .startOf('day')
    .format('YYYY-MM-DD HH:mm:ss');
}

export function getTomorrowDate() {
  return moment(new Date())
    .startOf('day')
    .add(1, 'days')
    .format('YYYY-MM-DD HH:mm:ss');
}

export function getYesterdayDate() {
  return moment(new Date())
    .startOf('day')
    .subtract(1, 'days')
    .format('YYYY-MM-DD HH:mm:ss');
}

export function getThisWeekRange() {
  const today = new Date();
  const startOfThisWeek = moment(today)
    .startOf('week')
    .format('YYYY-MM-DD HH:mm:ss');

  const endOfThisWeek = moment(today)
    .endOf('week')
    .startOf('day')
    .format('YYYY-MM-DD HH:mm:ss');

  return { startOfThisWeek, endOfThisWeek };
}

export function getNextWeekRange() {
  const today = new Date();
  const startOfNextWeek = moment(today)
    .add(1, 'week')
    .startOf('week')
    .format('YYYY-MM-DD HH:mm:ss');

  const endOfNextWeek = moment(today)
    .add(1, 'week')
    .endOf('week')
    .startOf('day')
    .format('YYYY-MM-DD HH:mm:ss');

  return { startOfNextWeek, endOfNextWeek };
}

export function getThisMonthRange() {
  const today = new Date();
  const startOfThisMonth = moment(today)
    .startOf('month')
    .format('YYYY-MM-DD HH:mm:ss');

  const endOfThisMonth = moment(today)
    .endOf('month')
    .startOf('day')
    .format('YYYY-MM-DD HH:mm:ss');

  return { startOfThisMonth, endOfThisMonth };
}

export function getLastMonthRange() {
  const today = new Date();
  const startOfLastMonth = moment(today)
    .subtract(1, 'month')
    .startOf('month')
    .format('YYYY-MM-DD HH:mm:ss');

  const endOfLastMonth = moment(today)
    .subtract(1, 'month')
    .endOf('month')
    .startOf('day')
    .format('YYYY-MM-DD HH:mm:ss');

  return { startOfLastMonth, endOfLastMonth };
}

export function createServerSideDatasource(url: string, queryParams?: string) {
  return {
    getRows: function(gridParams: IServerSideGetRowsParams) {
      const params = cloneDeep(gridParams);
      const payload = params.request;
      payload.filterModel = Object.keys(params.request.filterModel).map((filter: string) => {
        const filterModel = params.request.filterModel;
        const gridColumn = params.columnApi.getColumn(filter);
        const colDef = gridColumn && gridColumn.getColDef();
        const obj = {} as IFilterModel;
        obj.colId = filter;

        filterModel[filter].type &&
          customFilterOptionsMap[filterModel[filter].type] &&
          Object.assign(filterModel[filter], customFilterOptionsMap[filterModel[filter].type]());

        filterModel[filter].condition1 &&
          customFilterOptionsMap[filterModel[filter].condition1.type] &&
          Object.assign(filterModel[filter].condition1, customFilterOptionsMap[filterModel[filter].condition1.type]());

        filterModel[filter].condition2 &&
          customFilterOptionsMap[filterModel[filter].condition2.type] &&
          Object.assign(filterModel[filter].condition2, customFilterOptionsMap[filterModel[filter].condition2.type]());

        obj.operator = filterModel[filter].operator;
        obj.conditions =
          typeof filterModel[filter].condition1 === 'undefined'
            ? [
                {
                  type: filterModel[filter].type || null,
                  filterType: filterModel[filter].filterType,
                  filter:
                    filterModel[filter].filter ||
                    (filterModel[filter].values && `[${filterModel[filter].values}]`) ||
                    null,
                  dateFrom: formatDateForFiltering(filterModel[filter].dateFrom, colDef),
                  dateTo: formatDateForFiltering(filterModel[filter].dateTo, colDef),
                  filterTo: filterModel[filter].filterTo || null,
                },
              ]
            : Object.keys(filterModel[filter])
                .filter((condition: string) => {
                  return condition === 'condition1' || condition === 'condition2';
                })
                .map((condition: string) => {
                  return {
                    type: filterModel[filter][condition].type,
                    filterType: filterModel[filter][condition].filterType,
                    filter: filterModel[filter][condition].filter || null,
                    dateFrom: formatDateForFiltering(filterModel[filter][condition].dateFrom, colDef),
                    dateTo: formatDateForFiltering(filterModel[filter][condition].dateTo, colDef),
                    filterTo: filterModel[filter][condition].filterTo || null,
                  };
                });
        return obj;
      });
      dataGridLazy(url, payload, queryParams)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((res: any) => res.data)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((rowData: any) => {
          if (rowData.rowData.length > 0) {
            gridParams.api.hideOverlay();
            gridParams.success(rowData);
          } else {
            gridParams.success({ rowData: [], rowCount: 0 });
            gridParams.api.showNoRowsOverlay();
          }
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          console.error('Error', error);
          gridParams.fail();
        });
    },
  };
}

export const createUTCDate = (date: string | Date): string | undefined => {
  try {
    return moment(date)
      .utc()
      .toISOString();
  } catch (e) {
    console.warn(e);
    return;
  }
};

//  TW Global date format.
export const standardDateFormat = (value: string | undefined, hasTime = true) => {
  if (!value || value.length < 1) return '';
  return hasTime
    ? `${moment(value).format('MM/DD/YYYY hh:mm A')} (${Intl.DateTimeFormat().resolvedOptions().timeZone})`
    : moment.utc(value).format('MM/DD/YYYY');
};

//  Used for grid columns valuegetters.
export const dateFormatter = (field: string, hasTime = true) => (params: { data: { [key: string]: string } }) => {
  if (!params || !params.data) return ' ';
  const value = params.data[field];
  return value ? standardDateFormat(value, hasTime) : value;
};

//  Used with date pickers
export const getDate = (data: object, key: string, hasTime = true) => {
  let value = get(data, key, null);

  if (value) {
    const date = new Date(value);

    value = hasTime ? date : new Date(date.getTime() + date.getTimezoneOffset() * 60000); // 60000 - milliseconds;
  }

  return value;
};

// Used for filtering dates on client side
export const dateFiltering = (filterLocalDateAtMidnight: Date, cellValue: string) => {
  /**
   *  NOTE: if date format has a time like "08:54:38", and then you compare it to
   *  filterLocalDateAtMidnight.getTime() which is 0, it won't compare anything.
   *  So you have to change the hour to 0.
   */
  const cellDate = moment(cellValue)
    .startOf('day')
    .toDate();

  let res;

  if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
    res = 0;
  }

  if (cellDate < filterLocalDateAtMidnight) {
    res = -1;
  }

  if (cellDate > filterLocalDateAtMidnight) {
    res = 1;
  }

  return res;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getProjectManager = (params: any) => {
  const projectManager = params.data && params.data.projectStaff ? params.data.projectStaff[0] : null;
  if (!projectManager || !projectManager.staff || !projectManager.staff.firstName || !projectManager.staff.lastName)
    return '';
  return `${projectManager.staff.firstName} ${projectManager.staff.lastName}`;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getProjectType = (params: any) => {
  return params.data && params.data.projectType && params.data.projectType.name ? params.data.projectType.name : '';
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getProjectSubType = (params: any) => {
  return params.data && params.data.projectSubType && params.data.projectSubType.name
    ? params.data.projectSubType.name
    : '';
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getProjectStatus = (params: any) => {
  return params.data && params.data.projectStatus && params.data.projectStatus.name
    ? params.data.projectStatus.name
    : '';
};
/** This method is used for filter params (ag-Grid).
 * Transform Number value into String (for correct filtering on BE side)
 * @params: value - string | number | undefined | null
 * */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const numberParserForFilter = (value: any) => (value === null || value === undefined ? null : value.toString());

export const durationParserForFilter = (text: string) => {
  if (text === null || text === undefined) {
    return null;
  }

  const duration = text.split(':');
  const hours = (duration[0] && parseInt(duration[0])) || 0;
  const minutes = (duration[1] && parseInt(duration[1])) || 0;
  const filterValue = hours * 60 + minutes;

  return filterValue > 0 ? filterValue.toString() : null;
};

export const durationConverter = (duration: number) => {
  const hours = duration / 60;
  const rHours = Math.floor(hours);
  const minutes = (hours - rHours) * 60;
  const rMinutes = Math.round(minutes);

  const hoursRepresentation = rHours < 10 ? `0${rHours}` : rHours;
  const minutesRepresentation = rMinutes < 10 ? `0${rMinutes}` : rMinutes;

  return `${hoursRepresentation}:${minutesRepresentation}`;
};

export const downloadFile = (url: string) => {
  const link = document.createElement('a');

  link.setAttribute('download', '');
  link.href = url;

  document.body.appendChild(link);

  link.click();

  link.parentNode && link.parentNode.removeChild(link);
};

/** https://jira.ops1.clb.wdc.west.com/browse/TEAMWORK-406
 *  this issue is reproducible in case, when we have backUrl with encoded path from previous screen.
 *  decodeURIComponent method will decode it also, and we will have path, that doesn't exist.
 */
export const getPathToPreviousScreen = (backUrl: string) => {
  let finalBackURLS = null;
  const fullPath = decodeURIComponent(backUrl);
  const backURLS = fullPath.split('//');

  if (backURLS.length > 1) {
    finalBackURLS = backURLS.map((path, index) => (index > 0 ? encodeURIComponent(`/${path}`) : `${path}/`));
  }

  return finalBackURLS ? finalBackURLS.join('') : fullPath;
};

const removeGridState = (gridName: string) => localStorage.removeItem(gridName);
const saveGridConfig = (gridConfigName: string, gridConfig: object) =>
  localStorage.setItem(gridConfigName, JSON.stringify(gridConfig));

const gridStateRestore = (gridColumnApi: ColumnApi, savedGridState: string) => {
  gridColumnApi && isValidJSON(savedGridState) && gridColumnApi.setColumnState(JSON.parse(savedGridState));
};

export const gridStateSaver = (gridColumnApi: ColumnApi, gridName: string) => {
  const columnState = gridColumnApi && gridColumnApi.getColumnState();

  columnState && columnState.length && localStorage.setItem(gridName, JSON.stringify(columnState));
};

export const checkForSavedGrid = (
  gridColumnApi: ColumnApi,
  gridName: string,
  savedGridConfigName: string,
  currentGridConfig: object,
) => {
  const previousGridColumns = localStorage.getItem(savedGridConfigName);
  const areSameColumns = previousGridColumns && JSON.stringify(currentGridConfig) === previousGridColumns;
  const savedGridState = localStorage.getItem(gridName);

  if (savedGridState && areSameColumns) {
    gridStateRestore(gridColumnApi, savedGridState);
  }

  if (!areSameColumns) {
    removeGridState(gridName);
    saveGridConfig(savedGridConfigName, currentGridConfig);
  }
};

export const resetAllGridFilters = (gridApi: GridApi | null) => {
  if (gridApi) {
    gridApi.setFilterModel(null);
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createMapFromArrayOfObjects = (source: any, keyField: string, valueField: string) => {
  const result = {};

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  source.forEach((item: any) => {
    const key = item[keyField];

    result[key] = item[valueField];
  });

  return result;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createMapFromObjectOfObjects = (source: any, keyField: string, valueField: string) => {
  const result = {};

  Object.keys(source).forEach((field: string) => {
    const key = source[field][keyField];

    result[key] = source[field][valueField];
  });

  return result;
};

export const createDefaultGridConfigViewOptions = (gridType: string, numberOfConfigs: number) => {
  const configs = [];
  const defConfig = {
    gridType: gridType,
    optionIndex: 0,
    title: '',
    paramsJson: '',
  };

  if (numberOfConfigs) {
    for (let i = 0; i < numberOfConfigs; i++) {
      const configItem = JSON.parse(JSON.stringify(defConfig));
      configItem.optionIndex = i;

      configs.push(configItem);
    }
  }

  return configs;
};

export const createGridConfigOption = (
  defaultConfigs: IGridConfiguration[],
  savedConfigs: IGridConfiguration[],
  defGridConfig: (ColDef | ColGroupDef)[],
) => {
  const result = JSON.parse(JSON.stringify(defaultConfigs));

  savedConfigs &&
    savedConfigs.forEach((option: IGridConfiguration) => {
      result.find((defaultOption: IGridConfiguration, index: number) => {
        if (defaultOption.optionIndex === option.optionIndex) {
          result[index] = JSON.parse(JSON.stringify(option));

          const savedGridConfig = isValidJSON(result[index].paramsJson) && JSON.parse(result[index].paramsJson);
          result[index].isConfigInvalid =
            !savedGridConfig || JSON.stringify(defGridConfig) !== JSON.stringify(savedGridConfig.defaultConfig);
        }
      });
    });

  return result;
};
