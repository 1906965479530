// use 'npm run g:r {name}' - to generate new reducer and action (use --skip if action not needed)
import createReducer from '../utils/redux-create-reducer';
import {
  FETCH,
  FETCH_SUCCESS,
  FETCH_FAILURE,
  FETCH_ONE,
  FETCH_ONE_SUCCESS,
  FETCH_ONE_FAILURE,
} from '../actions/accounts';
import { IAccount } from '../utils/api.d';
import { deepStateUpdate } from '../utils/helpers';

export interface CurrentAccountState {
  isLoading?: boolean;
  account?: IAccount;
  error?: Error;
}

export interface IAccountsState {
  isLoading?: boolean;
  accounts: IAccount[];
  error?: Error;
  currentAccount: CurrentAccountState;
}

interface IState {
  accounts: IAccountsState;
}

export const initialState: IAccountsState = {
  isLoading: false,
  accounts: [],
  error: undefined,
  currentAccount: {
    isLoading: false,
    account: undefined,
    error: undefined,
  },
};

export const getAccounts = (state: IState): IAccountsState => state.accounts;
export const getCurrentAccount = (state: IState): CurrentAccountState => state.accounts.currentAccount;

const deepUpdate = deepStateUpdate('currentAccount');

export default createReducer<IAccountsState>(initialState, {
  [FETCH]: () => ({ isLoading: true }),
  [FETCH_SUCCESS]: accounts => ({ accounts, isLoading: false }),
  [FETCH_FAILURE]: error => ({ error, isLoading: false }),
  [FETCH_ONE]: (_, state) =>
    deepUpdate(
      {
        isLoading: true,
        account: undefined,
        error: undefined,
      },
      state,
    ),
  [FETCH_ONE_SUCCESS]: (account, state) => deepUpdate({ account, isLoading: false }, state),
  [FETCH_ONE_FAILURE]: (error, state) => deepUpdate({ error, isLoading: false }, state),
});
