import { ColDef, ColGroupDef } from 'ag-grid-community';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function actionCellRenderer(params: any) {
  const eGui = document.createElement('div');

  const editingCells = params.api.getEditingCells();
  // checks if the rowIndex matches in at least one of the editing cells
  const isCurrentRowEditing = editingCells.some((cell: { rowIndex: any; }) => {
    return cell.rowIndex === params.node.rowIndex;
  });

  if (isCurrentRowEditing) {
    eGui.innerHTML = `
<button class="btn btn-primary grid-action-button-save" data-action="save">Save</button>
<button class="btn btn-secondary grid-action-button-cancel" data-action="cancel" >Cancel</button>
`;
  } else if (params.data.canDeleteRecord) {
    eGui.innerHTML = `
<button class="btn btn-danger grid-action-button-delete" data-action="delete">Delete</button>
`;
  }

  return eGui;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function(staffOptions: any = [], rolesOptions: any = []): (ColDef | ColGroupDef)[] {
  return [
    {
      field: 'staffName',
      headerName: 'Staff',
      editable: true,
      cellEditor: 'gridSelect',
      cellEditorParams: {
        data: staffOptions,
      },
      valueGetter: params => {
        return (
          (params.data.staff && `${params.data.staff.firstName} ${params.data.staff.lastName}`) || params.data.staffName
        );
      },
      minWidth: 200,
      sortable: true,
    },
    {
      field: 'roleName',
      headerName: 'Role',
      editable: true,
      cellEditor: 'agSelectCellEditor',
      sortable: true,
      valueGetter: params => {
        return params.data.name || params.data.roleName;
      },
      cellEditorParams: {
        values: rolesOptions,
      },
      minWidth: 200,
    },
    {
      headerName: '',
      maxWidth: 150,
      cellRenderer: actionCellRenderer,
      editable: false,
      colId: 'action',
      sortable: false,
      suppressColumnsToolPanel: true,
    },
  ];
}
