// use 'npm run g:a {name}' - to generate new action
import { createEmptyAction, createAction } from '../utils/actions';

export const FETCH = 'PROJECT/FETCH';
export const FETCH_SUCCESS = 'PROJECT/FETCH_SUCCESS';
export const FETCH_FAILURE = 'PROJECT/FETCH_FAILURE';

export const ADD_NEW_PROJECT = 'PROJECT/ADD_NEW_UI';

export const FETCH_BYID = 'PROJECT/FETCH_BYID';
export const FETCH_BYID_SUCCESS = 'PROJECT/FETCH_BYID_SUCCESS';
export const FETCH_BYID_FAILURE = 'PROJECT/FETCH_BYID_FAILURE';

export const GET_PARENT_PROJECT_INFO = 'PROJECT/GET_PARENT_PROJECT_INFO';
export const GET_PARENT_PROJECT_INFO_SUCCESS = 'PROJECT/GET_PARENT_PROJECT_INFO_SUCCESS';
export const GET_PARENT_PROJECT_INFO_FAILURE = 'PROJECT/GET_PARENT_PROJECT_INFO_FAILURE';

export const ADD_ACCOUNTS_TO_PROJECT = 'PROJECT/ADD_ACCOUNTS_TO_PROJECT';
export const ADD_ACCOUNTS_TO_PROJECT_SUCCESS = 'PROJECT/ADD_ACCOUNTS_TO_PROJECT_SUCCESS';
export const ADD_ACCOUNTS_TO_PROJECT_FAILURE = 'PROJECT/ADD_ACCOUNTS_TO_PROJECT_FAILURE';

export const types = {
  FETCH,
  FETCH_SUCCESS,
  FETCH_FAILURE,
  FETCH_BYID,
  FETCH_BYID_SUCCESS,
  FETCH_BYID_FAILURE,
  GET_PARENT_PROJECT_INFO,
  GET_PARENT_PROJECT_INFO_SUCCESS,
  GET_PARENT_PROJECT_INFO_FAILURE,
  ADD_ACCOUNTS_TO_PROJECT,
  ADD_ACCOUNTS_TO_PROJECT_SUCCESS,
  ADD_ACCOUNTS_TO_PROJECT_FAILURE,
};

export const addNewProject = createAction(ADD_NEW_PROJECT);

export const fetch = createEmptyAction(FETCH);
export const fetchSuccess = createAction(FETCH_SUCCESS);
export const fetchFailure = createAction<Error>(FETCH_FAILURE);

export const fetchById = createAction(FETCH_BYID);
export const fetchByIdSuccess = createAction(FETCH_BYID_SUCCESS);
export const fetchByIdFailure = createAction<Error>(FETCH_BYID_FAILURE);

export const getParentProjectInfo = createAction(GET_PARENT_PROJECT_INFO);
export const getParentProjectInfoSuccess = createAction(GET_PARENT_PROJECT_INFO_SUCCESS);
export const getParentProjectInfoFailure = createAction<Error>(GET_PARENT_PROJECT_INFO_FAILURE);

export const addAccountsToProject = createAction(ADD_ACCOUNTS_TO_PROJECT);
export const addAccountsToProjectSuccess = createEmptyAction(ADD_ACCOUNTS_TO_PROJECT_SUCCESS);
export const addAccountsToProjectFailure = createAction<Error>(ADD_ACCOUNTS_TO_PROJECT_FAILURE);

export default {
  types,
  fetch,
  fetchSuccess,
  fetchFailure,
  fetchById,
  fetchByIdSuccess,
  fetchByIdFailure,
  getParentProjectInfo,
  getParentProjectInfoSuccess,
  getParentProjectInfoFailure,
  addAccountsToProject,
  addAccountsToProjectSuccess,
  addAccountsToProjectFailure,
};
