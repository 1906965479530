import { ColDef, ColGroupDef, GridOptions } from 'ag-grid-community';

export function makeGridOptions(columnDefs: (ColDef | ColGroupDef)[], options: GridOptions = {}): GridOptions {
  return {
    statusBar: {
      statusPanels: [
        { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
        { statusPanel: 'agFilteredRowCountComponent' },
        { statusPanel: 'agSelectedRowCountComponent' },
        { statusPanel: 'agAggregationComponent' },
      ],
    },
    sideBar: {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
      ],
    },
    columnDefs,
    rowClass: 'default-grid-row-styles',
    suppressCellSelection: true,
    defaultColDef: {
      flex: 1,
      sortable: true,
      resizable: true,
      filter: true,
      floatingFilter: true,
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
    },
    ...options,
  };
}

export default makeGridOptions;
