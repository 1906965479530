import { ColDef, ColGroupDef, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import {
  dateFormatter,
  numberParserForFilter as numberParser,
  transformToColumns,
  createMapFromObjectOfObjects,
  createMapFromArrayOfObjects,
} from '../../utils/helpers';
import { projectsListGridColumns } from '../../tablesFormShapes/projects';
import {
  projectType as projectTypeEnum,
  eventSubType as eventSubTypeEnum,
  projectStatus as projectStatusEnum,
  serviceModelType as serviceModelTypeEnum,
  usageType as usageTypeEnum,
  deliveryType as deliveryTypeEnum,
  contentType as contentTypeEnum,
  supportRegions as supportRegionsEnum,
  fulfillmentStatus as fulfillmentStatusEnum,
} from '../../enums';
import {
  lastMonth,
  nextWeek,
  thisMonth,
  thisWeek,
  today,
  tomorrow,
  yesterday,
} from '../../utils/agGridCustomFilterOptions';

const projectTypesMap = {};
const eventSubTypesMap = createMapFromObjectOfObjects(eventSubTypeEnum, 'id', 'name');
const projectStatusesMap = createMapFromObjectOfObjects(projectStatusEnum, 'id', 'name');
const serviceModelTypesMap = createMapFromObjectOfObjects(serviceModelTypeEnum, 'id', 'name');
const usageTypesMap = createMapFromObjectOfObjects(usageTypeEnum, 'id', 'name');
const deliveryTypesMap = createMapFromObjectOfObjects(deliveryTypeEnum, 'id', 'name');
const contentTypesMap = createMapFromObjectOfObjects(contentTypeEnum, 'id', 'name');
const supportRegionsMap = createMapFromArrayOfObjects(supportRegionsEnum, 'id', 'name');
const fulfillmentStatusesMap = createMapFromObjectOfObjects(fulfillmentStatusEnum, 'id', 'name');

const redundantProjectTypes = [
  projectTypeEnum.Unknown.value,
  projectTypeEnum.ResellerPlan.value,
  projectTypeEnum.ProjectGroup.value,
];

Object.keys(projectTypeEnum).forEach((projectType: string) => {
  const projectTypeValue = projectTypeEnum[projectType].value;

  if (!redundantProjectTypes.includes(projectTypeValue)) {
    const { id, name } = projectTypeEnum[projectType];

    projectTypesMap[id] = name;
  }
});

const dateFilterOptions = [
  'equals',
  'greaterThan',
  'lessThan',
  'notEqual',
  'inRange',
  today,
  yesterday,
  tomorrow,
  thisWeek,
  nextWeek,
  thisMonth,
  lastMonth,
];

export default function(): (ColDef | ColGroupDef)[] {
  return transformToColumns(
    projectsListGridColumns,
    {
      id: {
        hide: true,
        filterParams: {
          numberParser,
        },
      },
      name: { sort: 'asc' },
      type: {
        filterParams: {
          suppressSorting: true,
          debounceMs: 1000,
          values: Object.keys(projectTypesMap),
          valueFormatter: (params: ValueFormatterParams) => projectTypesMap[params.value] || params.value,
        },
        valueGetter: (params: ValueGetterParams) => projectTypesMap[params.data.type] || params.data.type,
      },
      parentId: {
        hide: true,
        filterParams: {
          numberParser,
        },
      },
      package: {
        filterParams: {
          suppressSorting: true,
          debounceMs: 1000,
          values: Object.keys(eventSubTypesMap),
          valueFormatter: (params: ValueFormatterParams) => eventSubTypesMap[params.value] || params.value,
        },
        valueGetter: (params: ValueGetterParams) => eventSubTypesMap[params.data.package] || params.data.package,
      },
      status: {
        filterParams: {
          suppressSorting: true,
          debounceMs: 1000,
          values: Object.keys(projectStatusesMap),
          valueFormatter: (params: ValueFormatterParams) => projectStatusesMap[params.value] || params.value,
        },
        valueGetter: (params: ValueGetterParams) => projectStatusesMap[params.data.status] || params.data.status,
      },
      serviceModelType: {
        hide: true,
        filterParams: {
          suppressSorting: true,
          debounceMs: 1000,
          values: Object.keys(serviceModelTypesMap),
          valueFormatter: (params: ValueFormatterParams) => serviceModelTypesMap[params.value] || params.value,
        },
        valueGetter: (params: ValueGetterParams) =>
          serviceModelTypesMap[params.data.serviceModelType] || params.data.serviceModelType,
      },
      usageType: {
        hide: true,
        filterParams: {
          suppressSorting: true,
          debounceMs: 1000,
          values: Object.keys(usageTypesMap),
          valueFormatter: (params: ValueFormatterParams) => usageTypesMap[params.value] || params.value,
        },
        valueGetter: (params: ValueGetterParams) => usageTypesMap[params.data.usageType] || params.data.usageType,
      },
      duration: {
        hide: true,
        filterParams: {
          numberParser,
        },
      },
      hostingDays: {
        hide: true,
        filterParams: {
          numberParser,
        },
      },
      languages: {
        hide: true,
        filterParams: {
          numberParser,
        },
      },
      viewers: {
        hide: true,
        filterParams: {
          numberParser,
        },
      },
      deliveryType: {
        hide: true,
        filterParams: {
          suppressSorting: true,
          debounceMs: 1000,
          values: Object.keys(deliveryTypesMap),
          valueFormatter: (params: ValueFormatterParams) => deliveryTypesMap[params.value] || params.value,
        },
        valueGetter: (params: ValueGetterParams) =>
          deliveryTypesMap[params.data.deliveryType] || params.data.deliveryType,
      },
      contentType: {
        hide: true,
        filterParams: {
          suppressSorting: true,
          debounceMs: 1000,
          values: Object.keys(contentTypesMap),
          valueFormatter: (params: ValueFormatterParams) => contentTypesMap[params.value] || params.value,
        },
        valueGetter: (params: ValueGetterParams) => contentTypesMap[params.data.contentType] || params.data.contentType,
      },
      eventStart: {
        floatingFilter: false,
        valueGetter: dateFormatter('eventStart'),
        filterParams: {
          includeTime: true,
          filterOptions: dateFilterOptions,
        },
      },
      eventEnd: {
        floatingFilter: false,
        valueGetter: dateFormatter('eventEnd'),
        filterParams: {
          includeTime: true,
          filterOptions: dateFilterOptions,
        },
      },
      supportRegion: {
        filterParams: {
          suppressSorting: true,
          debounceMs: 1000,
          values: Object.keys(supportRegionsMap),
          valueFormatter: (params: ValueFormatterParams) => supportRegionsMap[params.value] || params.value,
        },
        valueGetter: (params: ValueGetterParams) =>
          supportRegionsMap[params.data.supportRegion] || params.data.supportRegion,
      },
      fulfillmentStatus: {
        filterParams: {
          suppressSorting: true,
          debounceMs: 1000,
          values: Object.keys(fulfillmentStatusesMap),
          valueFormatter: (params: ValueFormatterParams) => fulfillmentStatusesMap[params.value] || params.value,
        },
        valueGetter: (params: ValueGetterParams) =>
          fulfillmentStatusesMap[params.data.fulfillmentStatus] || params.data.fulfillmentStatus,
      },
      fulfillmentStart: {
        floatingFilter: false,
        valueGetter: dateFormatter('fulfillmentStart', false),
        filterParams: {
          filterOptions: dateFilterOptions,
        },
      },
      fulfillmentEnd: {
        floatingFilter: false,
        valueGetter: dateFormatter('fulfillmentEnd', false),
        filterParams: {
          filterOptions: dateFilterOptions,
        },
      },
      recurringRolloverTerm: {
        filterParams: {
          numberParser,
        },
      },
      eventsAllowed: {
        filterParams: {
          numberParser,
        },
      },
      eventsUsed: {
        filterParams: {
          numberParser,
        },
      },
      eventsRemaining: {
        filterParams: {
          numberParser,
        },
      },
      cfpOpenDate: {
        floatingFilter: false,
        valueGetter: dateFormatter('cfpOpenDate', false),
        filterParams: {
          filterOptions: dateFilterOptions,
        },
      },
      cfpCloseDate: {
        floatingFilter: false,
        valueGetter: dateFormatter('cfpCloseDate', false),
        filterParams: {
          filterOptions: dateFilterOptions,
        },
      },
      registrants: {
        filterParams: {
          numberParser,
        },
      },
      personnel: {
        hide: true,
        suppressColumnsToolPanel: true,
        filterParams: {
          filterOptions: ['contains', 'notContains'],
        },
      },
    },
    { minWidth: 200 },
  );
}
