import { ColDef } from 'ag-grid-community';
import { transformToColumns } from '../../../utils/helpers';
import { salesforceListGridColumns } from '../../../tablesFormShapes/salesforce';
import { dateFormatter } from '../../../utils/helpers';
import omit from 'lodash/omit';

export default function(hidePlanProject: boolean): ColDef[] {
  // TODO - MAKE CORRECT GENERIC TO DESCRIBE ALL PROPS TYPE
  return transformToColumns<keyof typeof salesforceListGridColumns>(
    omit(salesforceListGridColumns, 'projectId', 'projectType', 'projectName'),
    {
      productName: {
        headerName: 'Product Name',
        hide: false,
        sort: 'asc',
        filter: 'agSetColumnFilter',
      },
      productCode: {
        filter: 'agSetColumnFilter',
      },
      productRevenueType: {
        headerName: 'Revenue Type',
        hide: false,
        filter: 'agSetColumnFilter',
      },
      subscriptionStatus: {
        headerName: 'Subscription Status',
        hide: false,
        filter: 'agSetColumnFilter',
      },
      netQuantity: {
        headerName: 'Net Qty',
        hide: false,
      },
      contractNumber: {
        headerName: 'Contract Number',
        hide: false,
      },
      opportunityName: {
        headerName: 'Opportunity Name',
        hide: false,
      },
      eventDate: {
        headerName: 'End Date',
        valueGetter: dateFormatter('subscriptionEndDate', false),
        hide: hidePlanProject,
      },
      recurringRolloverTerm: {
        headerName: 'Rollover Term',
        hide: hidePlanProject,
      },
      eventFulfillmentDate: {
        headerName: 'Event Fulfillment Date',
        valueGetter: dateFormatter('eventFulfillmentDate', false),
        hide: hidePlanProject,
      },
      accountName: {
        hide: true,
        headerName: 'DSA Name',
      },
      orderProductQuantity: {
        hide: true,
      },
      accountLastModifiedByDate: {
        valueGetter: dateFormatter('accountLastModifiedByDate'),
        filterParams: {
          includeTime: true,
        },
      },
      opportunityCloseDate: {
        valueGetter: dateFormatter('opportunityCloseDate', false),
      },
      opportunityCreatedDate: {
        valueGetter: dateFormatter('opportunityCreatedDate'),
        filterParams: {
          includeTime: true,
        },
      },
      opportunityLastModifiedDate: {
        valueGetter: dateFormatter('opportunityLastModifiedDate'),
        filterParams: {
          includeTime: true,
        },
      },
      orderProductCreatedDate: {
        valueGetter: dateFormatter('orderProductCreatedDate'),
        filterParams: {
          includeTime: true,
        },
      },
      orderProductLastModifiedDate: {
        valueGetter: dateFormatter('orderProductLastModifiedDate'),
        filterParams: {
          includeTime: true,
        },
      },
      productLastModifiedDate: {
        valueGetter: dateFormatter('productLastModifiedDate'),
        filterParams: {
          includeTime: true,
        },
      },
      contractStartDate: {
        valueGetter: dateFormatter('contractStartDate', false),
      },
      contractEndDate: {
        valueGetter: dateFormatter('contractEndDate', false),
      },
      contractLastModifiedDate: {
        valueGetter: dateFormatter('contractLastModifiedDate'),
        filterParams: {
          includeTime: true,
        },
      },
      subscriptionStartDate: {
        valueGetter: dateFormatter('subscriptionStartDate', false),
      },
      subscriptionEndDate: {
        valueGetter: dateFormatter('subscriptionEndDate', false),
      },
      subscriptionRenewalDate: {
        valueGetter: dateFormatter('subscriptionRenewalDate', false),
      },
      subscriptionTerminatedDate: {
        valueGetter: dateFormatter('subscriptionTerminatedDate', false),
      },
      subscriptionLastModifiedDate: {
        valueGetter: dateFormatter('subscriptionLastModifiedDate'),
        filterParams: {
          includeTime: true,
        },
      },
      orderProductEventDate: {
        valueGetter: dateFormatter('orderProductEventDate'),
        filterParams: {
          includeTime: true,
        },
      },
      wdmsEventDate: {
        valueGetter: dateFormatter('wdmsEventDate'),
        filterParams: {
          includeTime: true,
        },
      },
      wdmsEventCreatedDate: {
        valueGetter: dateFormatter('wdmsEventDate'),
        filterParams: {
          includeTime: true,
        },
      },
      wdmsEventLastModifiedDate: {
        valueGetter: dateFormatter('wdmsEventLastModifiedDate'),
        filterParams: {
          includeTime: true,
        },
      },
      accountCountry: {
        filter: 'agSetColumnFilter',
      },
      accountRegion: {
        filter: 'agSetColumnFilter',
      },
      opportunityStage: {
        filter: 'agSetColumnFilter',
      },
      isEventDateKnown: {
        filter: 'agSetColumnFilter',
      },
      currency: {
        filter: 'agSetColumnFilter',
      },
      frequency: {
        filter: 'agSetColumnFilter',
      },
      billingSchedule: {
        filter: 'agSetColumnFilter',
      },
      orderProductBillingStatus: {
        filter: 'agSetColumnFilter',
      },
      ecbPushStatus: {
        filter: 'agSetColumnFilter',
      },
      ecbPushStatusOfFinalPayment: {
        filter: 'agSetColumnFilter',
      },
      revenueRecognized: {
        filter: 'agSetColumnFilter',
      },
      productFamily: {
        filter: 'agSetColumnFilter',
      },
      productBrand: {
        filter: 'agSetColumnFilter',
      },
      productCategory: {
        filter: 'agSetColumnFilter',
      },
      wdmsEventStatus: {
        filter: 'agSetColumnFilter',
      },
    },
    { minWidth: 200, hide: true },
  );
}
