import React, { ChangeEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styles from './NewTimeTracking.module.scss';
import AjaxSpinner from '../../components/AjaxSpinner';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getStaffs } from '../../reducers/staffs';
import { add, del, update } from '../../actions/timeTracking';
import { history } from '../../store';
import { N3AEntitlements, Routes } from '../../constants';
import { getCurrentTimeTracking } from '../../reducers/timeTracking';
import { ITimeTracking } from '../../utils/api.d';
import moment from 'moment';
import { tracingTypes, duration } from './tracingTypesAndDuration';
import { getDate, getDateNoOffset, getPathToPreviousScreen } from '../../utils/helpers';
import { matchPath } from 'react-router';
import DatePicker from 'react-datepicker';
import { getUser } from '../../reducers/user';

const fieldsToSave = ['type', 'subType', 'staffId', 'duration', 'date', 'description'];
const SAVE_AND_CREATE_NEW = 'saveAndCreateNew';

export default function NewTimeTracking() {
  const dispatch = useDispatch();
  const { id, backUrl } = useParams();
  let { projectId } = useParams();
  const { list, isLoading } = useSelector(getStaffs, shallowEqual);
  const current = useSelector(getCurrentTimeTracking, shallowEqual);
  const editedTimeTracking = current.data || ({} as ITimeTracking);
  const [date, setDate] = useState<Date | null>(null);
  const [updating, setUpdating] = useState(true);
  const [selectedType, setSelectedType] = useState<string | null>(null);
  const tracingSubTypes = (() => {
    const tracingType = selectedType || editedTimeTracking.type || tracingTypes[0].value;
    const type = tracingTypes.find(type => type.value === tracingType);
    return type ? type.subtypes : [];
  })();
  const { entitlements, emailAddress } = useSelector(getUser, shallowEqual) || {};
  const isAdmin = entitlements.includes(N3AEntitlements.Admin);
  const isTimeTrackingOwner =
    Boolean(editedTimeTracking.staffEmail) &&
    Boolean(emailAddress) &&
    editedTimeTracking.staffEmail.toLowerCase() === emailAddress.toLowerCase();
  const hasEditPermission = isAdmin || isTimeTrackingOwner || !editedTimeTracking.id;
  const hasDeletePermission = Boolean(editedTimeTracking.id) && (isAdmin || isTimeTrackingOwner);

  useEffect(() => {
    if (!current.isLoading && editedTimeTracking.id) {
      const dateToSet = getDate(editedTimeTracking, 'date', false);
      setDate(dateToSet);
      setUpdating(false);
    }
    const newTT = matchPath(location.pathname, {
      path: Routes.NewTimeTracking,
      exact: true,
    });
    if (newTT) {
      setUpdating(false);
    }
  }, [current, editedTimeTracking.date, editedTimeTracking.id]);
  const project = !isNaN(+projectId) ? +projectId : null;
  if (project) {
    projectId = +projectId;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const submitTimeTracking = (event: any) => {
    event.preventDefault();
    const submitter = event.nativeEvent.submitter;
    const saveAndCreateNewTimeTracking = Boolean(submitter && submitter.value === SAVE_AND_CREATE_NEW);
    const form = event.target;
    const newTimeTracking = editedTimeTracking.id ? editedTimeTracking : ({} as ITimeTracking);

    fieldsToSave.forEach(field => {
      newTimeTracking[field] = form.elements[field].value;
    });
    setUpdating(true);
    ['type', 'subType', 'duration', 'staffId'].forEach(key => {
      const int = Number(newTimeTracking[key]);
      newTimeTracking[key] = isNaN(int) ? newTimeTracking[key] : int;
    });
    newTimeTracking.date = getDateNoOffset(newTimeTracking.date);
    if (editedTimeTracking.id) {
      dispatch(update(newTimeTracking));
    } else {
      dispatch(
        add({
          ...newTimeTracking,
          projectId,
          saveAndCreateNewTimeTracking,
        }),
      );
      setDate(null);
    }
  };
  const goBack = () => {
    if (backUrl && backUrl !== ':backUrl') {
      history.push(getPathToPreviousScreen(backUrl));
    } else {
      history.push(Routes.TimeTracking);
    }
  };

  const onDelete = () => {
    setUpdating(true);
    dispatch(del(id));
  };

  const typeIsChanged = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedType(e.target.value);
  };

  const title = id ? 'Time Tracking Edit' : 'Time Tracking Create';
  const backBtnText = project ? 'Back to Project' : 'Back';

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <div className={styles.title}>{title}</div>
        {updating || isLoading ? (
          <AjaxSpinner />
        ) : (
          <Form onSubmit={submitTimeTracking} id="newTimeTracking" className={'card shadow ' + styles.form}>
            <FormGroup row>
              <Label for="type" className="col-sm-2">
                Type
              </Label>
              <div className="col-sm-5">
                <Input
                  defaultValue={editedTimeTracking.type}
                  type="select"
                  name="type"
                  id="type"
                  required
                  onChange={typeIsChanged}
                  disabled={!hasEditPermission}
                >
                  {tracingTypes.map((type, i) => (
                    <option value={type.value} key={type.value + i}>
                      {type.name}
                    </option>
                  ))}
                </Input>
              </div>
              <div className="col-sm-5">
                <Input
                  defaultValue={editedTimeTracking.subType}
                  type="select"
                  name="subType"
                  id="subType"
                  required
                  disabled={!hasEditPermission}
                >
                  {tracingSubTypes.map((type, i) => (
                    <option value={type.value} key={type.value + i}>
                      {type.name}
                    </option>
                  ))}
                </Input>
              </div>
            </FormGroup>
            <FormGroup row>
              <Label for="description" className="col-sm-2">
                Description
              </Label>
              <div className="col-sm-10">
                <Input
                  defaultValue={editedTimeTracking.description}
                  type="textarea"
                  name="description"
                  id="description"
                  required={true}
                  disabled={!hasEditPermission}
                />
              </div>
            </FormGroup>
            {hasEditPermission && (
              <FormGroup row>
                <Label for="staffId" className="col-sm-2">
                  Staff
                </Label>
                <div className="col-sm-10">
                  <Input defaultValue={editedTimeTracking.staffId} type="select" name="staffId" id="staffId" required>
                    {list.map(
                      staff =>
                        (staff.isActive || staff.id === editedTimeTracking.staffId) && (
                          <option value={staff.id} key={staff.id}>
                            {staff.first_name} {staff.last_name}
                          </option>
                        ),
                    )}
                  </Input>
                </div>
              </FormGroup>
            )}
            <FormGroup row>
              <Label for="duration" className="col-sm-2">
                Duration
              </Label>
              <div className="col-sm-10">
                <Input
                  defaultValue={editedTimeTracking.duration}
                  type="select"
                  name="duration"
                  id="duration"
                  required
                  disabled={!hasEditPermission}
                >
                  {duration.map(dur => (
                    <option value={dur.key} key={dur.key}>
                      {dur.value}
                    </option>
                  ))}
                </Input>
              </div>
            </FormGroup>
            <FormGroup row>
              <Label for="date" className="col-sm-2">
                Date
              </Label>
              <div className="col-sm-10">
                <DatePicker
                  selected={date}
                  onChange={(date: Date) => setDate(date)}
                  wrapperClassName="form-control"
                  className="form-control"
                  name="date"
                  id="date"
                  required={true}
                  autoComplete={'off'}
                  disabled={!hasEditPermission}
                />
              </div>
            </FormGroup>
            <FormGroup row>
              <div className={'offset-sm-2 col-sm-10'}>
                {hasEditPermission && (
                  <>
                    <Button color="primary" type="submit" className="col-sm-auto col-12 mt-2 mt-sm-0">
                      Save
                    </Button>

                    {!editedTimeTracking.id && (
                      <Button
                        color="primary"
                        type="submit"
                        value={SAVE_AND_CREATE_NEW}
                        className="col-sm-auto col-12 mt-2 mt-sm-0 ml-0 ml-sm-2"
                      >
                        Save And New
                      </Button>
                    )}
                  </>
                )}
                <Button color="secondary" onClick={goBack} className="col-sm-auto col-12 mt-2 mt-sm-0 ml-0 ml-sm-2">
                  {backBtnText}
                </Button>
                {hasDeletePermission && (
                  <Button color="danger" className="col-sm-auto col-12 mt-2 mt-sm-0 float-right" onClick={onDelete}>
                    Delete
                  </Button>
                )}
              </div>
            </FormGroup>
          </Form>
        )}
      </div>
    </div>
  );
}
