import { ColDef } from 'ag-grid-community';
import { transformToColumns, dateFormatter } from '../../utils/helpers';
import { salesforceListGridColumns } from '../../tablesFormShapes/salesforce';
import { getProjectDetailsLink } from './index';

export default function(): ColDef[] {
  return transformToColumns(
    salesforceListGridColumns,
    {
      projectId: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        cellRendererFramework: (params: any) => {
          return params && params.value ? getProjectDetailsLink(params.data, 'projectId') : '';
        },
      },
      projectName: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        cellRendererFramework: (params: any) => {
          return params && params.value ? getProjectDetailsLink(params.data, 'projectName') : '';
        },
      },
      id: {
        minWidth: 80,
      },
      accountLastModifiedByDate: {
        valueGetter: dateFormatter('accountLastModifiedByDate'),
        filterParams: {
          includeTime: true,
        },
      },
      opportunityCloseDate: {
        valueGetter: dateFormatter('opportunityCloseDate', false),
      },
      opportunityCreatedDate: {
        valueGetter: dateFormatter('opportunityCreatedDate'),
        filterParams: {
          includeTime: true,
        },
      },
      opportunityLastModifiedDate: {
        valueGetter: dateFormatter('opportunityLastModifiedDate'),
        filterParams: {
          includeTime: true,
        },
      },
      eventFulfillmentDate: {
        valueGetter: dateFormatter('eventFulfillmentDate', false),
      },
      eventDate: {
        valueGetter: dateFormatter('eventDate'),
        filterParams: {
          includeTime: true,
        },
      },
      orderProductCreatedDate: {
        valueGetter: dateFormatter('orderProductCreatedDate'),
        filterParams: {
          includeTime: true,
        },
      },
      orderProductLastModifiedDate: {
        valueGetter: dateFormatter('orderProductLastModifiedDate'),
        filterParams: {
          includeTime: true,
        },
      },
      productLastModifiedDate: {
        valueGetter: dateFormatter('productLastModifiedDate'),
        filterParams: {
          includeTime: true,
        },
      },
      contractStartDate: {
        valueGetter: dateFormatter('contractStartDate', false),
      },
      contractEndDate: {
        valueGetter: dateFormatter('contractEndDate', false),
      },
      contractLastModifiedDate: {
        valueGetter: dateFormatter('contractLastModifiedDate'),
        filterParams: {
          includeTime: true,
        },
      },
      subscriptionStartDate: {
        valueGetter: dateFormatter('subscriptionStartDate', false),
      },
      subscriptionEndDate: {
        valueGetter: dateFormatter('subscriptionEndDate', false),
      },
      subscriptionRenewalDate: {
        valueGetter: dateFormatter('subscriptionRenewalDate', false),
      },
      subscriptionTerminatedDate: {
        valueGetter: dateFormatter('subscriptionTerminatedDate', false),
      },
      subscriptionLastModifiedDate: {
        valueGetter: dateFormatter('subscriptionLastModifiedDate'),
        filterParams: {
          includeTime: true,
        },
      },
      orderProductEventDate: {
        valueGetter: dateFormatter('orderProductEventDate'),
        filterParams: {
          includeTime: true,
        },
      },
      wdmsEventDate: {
        valueGetter: dateFormatter('wdmsEventDate'),
        filterParams: {
          includeTime: true,
        },
      },
      wdmsEventCreatedDate: {
        valueGetter: dateFormatter('wdmsEventCreatedDate'),
        filterParams: {
          includeTime: true,
        },
      },
      wdmsEventLastModifiedDate: {
        valueGetter: dateFormatter('wdmsEventLastModifiedDate'),
        filterParams: {
          includeTime: true,
        },
      },
      orderFormNotes: {
        hide: true,
        cellRenderer: params => {
          return params.data.orderFormNotes;
        },
      },
    },
    { minWidth: 200 },
  );
}
