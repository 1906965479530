// use 'npm run g:r {name}' - to generate new reducer and action (use --skip if action not needed)
import createReducer from '../utils/redux-create-reducer';
import { NOTIFY, CLOSE } from '../actions/notification';

export interface INotificationState {
  isOpen: boolean;
  message: string;
  duration: number;
  type: 'success' | 'danger' | 'warning' | 'info';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  messageVars: Record<string, any>;
}

interface IState {
  notification: INotificationState;
}

export const initialState: INotificationState = {
  isOpen: false,
  message: '',
  duration: 5000,
  type: 'info',
  messageVars: {},
};

export const getNotification = (state: IState): INotificationState => state.notification;

export default createReducer(initialState, {
  [NOTIFY]: ({ duration, type, message }) => ({
    isOpen: true,
    type,
    message,
    duration: duration !== undefined ? duration : initialState.duration,
  }),
  [CLOSE]: () => ({ isOpen: false }),
});
