// TODO: think about splitting actions into different files
import { createEmptyAction, createAction } from '../utils/actions';

export const FETCH_REPORTS = 'FETCH_REPORTS';
export const fetchReports = createEmptyAction(FETCH_REPORTS);

export const FETCH_REPORTS_SUCCESS = 'GET_REPORTS_SUCCESS';
export const fetchReportsSuccess = createAction(FETCH_REPORTS_SUCCESS);

export const FETCH_REPORTS_FAILURE = 'GET_REPORTS_FAILURE';
export const fetchReportsFailure = createAction(FETCH_REPORTS_FAILURE);

export const GENERATE_REPORT = 'GENERATE_REPORT';
export const generateReport = createEmptyAction(GENERATE_REPORT);

export const GENERATE_REPORT_SUCCESS = 'GENERATE_REPORT_SUCCESS';
export const generateReportSuccess = createAction(GENERATE_REPORT_SUCCESS);

export const GENERATE_REPORT_FAILURE = 'GENERATE_REPORT_FAILURE';
export const generateReportFailure = createAction(GENERATE_REPORT_FAILURE);

export const GENERATE_REPORT_AND_REFRESH = 'GENERATE_REPORT_REFRESH';
export const generateReportAndRefresh = createEmptyAction(GENERATE_REPORT_AND_REFRESH);
