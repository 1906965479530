import { createAction } from '../utils/actions';

export const FETCH_GRID_CONFIGS = 'GRID_CONFIG/FETCH_GRID_CONFIGS';
export const FETCH_GRID_CONFIGS_SUCCESS = 'GRID_CONFIG/FETCH_GRID_CONFIGS_SUCCESS';
export const FETCH_GRID_CONFIGS_FAILURE = 'GRID_CONFIG/FETCH_GRID_CONFIGS_FAILURE';

export const UPDATE_GRID_CONFIG = 'GRID_CONFIG/UPDATE_GRID_CONFIG';
export const UPDATE_GRID_CONFIG_SUCCESS = 'GRID_CONFIG/UPDATE_GRID_CONFIG_SUCCESS';
export const UPDATE_GRID_CONFIG_FAILURE = 'GRID_CONFIG/UPDATE_GRID_CONFIG_FAILURE';

export const types = {
  FETCH_GRID_CONFIGS,
  FETCH_GRID_CONFIGS_SUCCESS,
  FETCH_GRID_CONFIGS_FAILURE,
  UPDATE_GRID_CONFIG,
  UPDATE_GRID_CONFIG_SUCCESS,
  UPDATE_GRID_CONFIG_FAILURE,
};

export const fetchGridConfigs = createAction(FETCH_GRID_CONFIGS);
export const fetchGridConfigsSuccess = createAction(FETCH_GRID_CONFIGS_SUCCESS);
export const fetchGridConfigsFailure = createAction<Error>(FETCH_GRID_CONFIGS_FAILURE);

export const updateGridConfig = createAction(UPDATE_GRID_CONFIG);
export const updateGridConfigSuccess = createAction(UPDATE_GRID_CONFIG_SUCCESS);
export const updateGridConfigFailure = createAction<Error>(UPDATE_GRID_CONFIG_FAILURE);

export default {
  fetchGridConfigs,
  fetchGridConfigsSuccess,
  fetchGridConfigsFailure,
  updateGridConfig,
  updateGridConfigSuccess,
  updateGridConfigFailure,
};
