import React from 'react';
import { Button, Modal, ModalFooter, ModalHeader, ModalBody } from 'reactstrap';
import styles from './AddPlisToFulfilledProjectsModal.module.scss';

interface AddPlisToFulfilledProjectsModalProps {
  isModalOpen: boolean;
  proceedAddingPlis: () => void;
  cancelAddingPlis: () => void;
}

export function AddPlisToFulfilledProjectsModal({
  isModalOpen,
  proceedAddingPlis,
  cancelAddingPlis,
}: AddPlisToFulfilledProjectsModalProps) {
  return (
    <Modal className={styles.modal} isOpen={isModalOpen} toggle={cancelAddingPlis}>
      <ModalHeader>Add PLIs to project</ModalHeader>
      <ModalBody>
        You’re about to add a product to a Project that’s already been fulfilled. This will automatically fulfill the
        PLI’s you are adding, and this cannot be undone. Are you sure you want to continue?
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={cancelAddingPlis}>
          Cancel
        </Button>
        <Button color="primary" onClick={proceedAddingPlis}>
          Yes
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default AddPlisToFulfilledProjectsModal;
