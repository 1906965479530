import { all, call, put, take } from 'redux-saga/effects';
import { Routes } from '../constants';
import { appLoadingPlaceholder, parseQueryParams } from '../utils/helpers';
import * as staffs from '../actions/staffs';
import * as salesforce from '../actions/salesforce';
import * as events from '../actions/events';
import * as timeTracking from '../actions/timeTracking';
import * as dsas from '../actions/dsas';
import * as projects from '../actions/projects';
import { ProjectTypes } from '../utils/api.d';
import * as project from '../actions/project';
import { projectType as projectTypeEnum } from '../enums';

export function* fetcher(match: { params: Record<string, string> }) {
  const params = match.params || {};
  yield put(staffs.fetchStaffListBriefInfo());
  const expectList = [take([staffs.FETCH_STAFF_LIST_BRIEF_INFO_SUCCESS, staffs.FETCH_STAFF_LIST_BRIEF_INFO_FAILURE])];
  // project EDIT
  if (params.id) {
    expectList.push(
      take([project.FETCH_BYID_SUCCESS, project.FETCH_BYID_FAILURE]),
      take([salesforce.FETCH_BY_PROJECT_SUCCESS, salesforce.FETCH_BY_PROJECT_FAILURE]),
      take([events.FETCH_BY_PROJECT_SUCCESS, events.FETCH_BY_PROJECT_FAILURE]),
      take([events.FETCH_PRIMARY_DETAILS_SUCCESS, events.FETCH_PRIMARY_DETAILS_FAILURE]),
    );
    yield put(project.fetchById({ projectId: params.id }));
    yield put(timeTracking.fetchBuckets(params.id));
    yield put(salesforce.fetchByProject(params.id));
    yield put(events.fetchByProject(params.id));
    yield put(events.fetchPrimaryDetails(params.id));
    const res = yield all(expectList);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);
    return res;
  } else {
    if (params.dsaId) {
      yield put(dsas.fetchById(params.dsaId));
      expectList.push(take[(dsas.FETCH_BY_ID_SUCCESS, dsas.FETCH_BY_ID_FAILURE)]);
    }

    const queryParams = parseQueryParams(location.href);
    if (queryParams.plis) {
      // transform '1,2,3' to '?id=1&id=2&id=3'
      const plis =
        '?' +
        queryParams.plis
          .split(',')
          .map(id => 'id=' + id)
          .join('&');
      yield put(salesforce.fetchByIdList(plis));
      expectList.push(take([salesforce.FETCH_BY_ID_LIST_SUCCESS, salesforce.FETCH_BY_ID_LIST_FAILURE]));
    } else {
      yield put(salesforce.clean()); // remove existing plis
    }
  }

  const parentId = params.parentId;
  const type = params.type;
  const virtualEventSubProjectId = projectTypeEnum.VirtualEventSubProject.id.toString();

  // TODO: temp solution
  if (parentId && !isNaN(Number(parentId)) && !params.id && type === virtualEventSubProjectId) {
    expectList.push(take([project.GET_PARENT_PROJECT_INFO_SUCCESS, project.GET_PARENT_PROJECT_INFO_FAILURE]));
    yield put(project.getParentProjectInfo({ parentId }));
  }

  // TODO: seems, that this code is "dead". Investigate in future
  // load parent project in case we refreshed page while new project creation
  if (parentId) {
    const newProject = {
      name: 'Unnamed ' + ProjectTypes[type],
      id: -1,
      projectType: {
        id: type,
        name: ProjectTypes[type],
      },
    };
    // if no parent we just create new project
    if (isNaN(Number(parentId))) {
      yield put(projects.addNewProject(newProject));
    } else {
      Object.assign(newProject, { parentId });
      yield put(
        projects.fetchById({
          projectId: parentId,
          new: newProject,
        }),
      );
    }
    const res = yield all(expectList.concat([take([projects.FETCH_BYID_SUCCESS, projects.FETCH_BYID_FAILURE])]));
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);
    return res;
  }
}

export default function* addNewProjectsPage() {
  while (true) {
    yield call(appLoadingPlaceholder, [Routes.AddNewProject, Routes.NewEditProject], fetcher);
  }
}
